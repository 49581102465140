import React from 'react';
import { Avatar, Row, Col, Divider } from 'antd';
import '../styles/membercardstyle.scss';
import { getTranslatedText } from '../../../services/appService';

function MemberCard(props) {
  const {
    memberName,
    memberCode,
    memberAvatar,
    memberScore,
    setRenderChildContainer,
  } = props;

  return (
    <React.Fragment>
      <div id="member-card-container">
        <Avatar id="member-avatar" size={141} src={memberAvatar} />
        <div id="card-description">
          <span id="description-header">
            <p id="greeting">{getTranslatedText('hello')}!</p>
            <p id="full-name">{memberName}</p>
            <p id="short-name">({memberCode})</p>
          </span>
          <Divider id="description-divider" />
          <span id="description-footer">
            <p id="explainer">
              {getTranslatedText('current_score_text')}
            </p>
            <p id="score-text">{memberScore}</p>
            <p
              id="score-unit"
              onClick={() => setRenderChildContainer('redemHistory')}
            >
              {getTranslatedText('history_exchange')}
            </p>
          </span>
        </div>
        <button
          onClick={() => setRenderChildContainer('swapMoney')}
          className="btn-swap-money"
        >
          {getTranslatedText("exchange_money")}
        </button>
      </div>
    </React.Fragment>
  );
}

export default MemberCard;
