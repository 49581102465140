import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { filter } from 'lodash';
import CourseBackground from '../../assets/images/course_background.png';
import CourseCard from '../CourseCard/CourseCard';
import { getCoursesAction } from '../../actions/courses';
import { getTranslatedText, checkIsEventDay, } from '../../services/appService';
import { Carousel, } from 'antd';
import { LeftCircleOutlined, RightCircleOutlined, } from '@ant-design/icons';
import './CourseCardContainer.scss';

class CourseCardContainer extends Component {
  state = {
    isDayOfEvent: false,
  };
  
  componentDidMount() {
    this.props.actions.getCoursesAction();
    let checkIsDayOfEvent = checkIsEventDay(this.props.eventStartDate, this.props.eventEndDate);
    this.setState({ isDayOfEvent: checkIsDayOfEvent });
  }

  render() {
    let {
      courses: { courses },
      excludeId,
      slidesToShow,
      eventCourses,
    } = this.props;

    if (excludeId) {
      courses = filter(courses, course => course.id !== excludeId);
    };

    function checkCourseInEvent(course) {
      let check = false;
      eventCourses?.forEach((eventCourse) => {
        if (course.id === eventCourse.course_id) {
          check = true;
        };
      });
      return check;
    };

    function getEventSellInfo(course) {
      let sellInfo = {
        soldNum: 0,
        remainNum: 0,
        total: 0,
      };
      eventCourses?.forEach((eventCourse) => {
        if (course.id === eventCourse.course_id) {
          sellInfo.soldNum = eventCourse.sold_number;
          sellInfo.remainNum = eventCourse.remain_display;
          sellInfo.total = eventCourse.total;
        };
      });
      return sellInfo;
    };

    return (
      <div>
        <div className="CourseTitleContainer">
          <div className="CourseTitle">
            <div className="Title">
              {getTranslatedText('our_courses')}
            </div> <br/>
            <div className="CourseNote" dangerouslySetInnerHTML={{
              __html: getTranslatedText("my_course_note")
            }}/>
          </div>
        </div>

        <div className="CourseCardContainer">
          <img className="CourseBackground" alt="course background" src={CourseBackground} />
          <Carousel
            className='english-course-list-wrapper'
            slidesToShow={slidesToShow}
            arrows={true}
            nextArrow={<RightCircleOutlined />}
            prevArrow={<LeftCircleOutlined />}
            dots={false}
          >
            {courses.map((courseCarouselItem, index) => (
              <CourseCard
                courseCarouselItem={courseCarouselItem}
                key={index}
                isDayOfEvent={this.state.isDayOfEvent}
                eventDiscount={this.props.eventDiscount}
                isCourseInEvent={checkCourseInEvent(courseCarouselItem)}
                eventSellInfo={getEventSellInfo(courseCarouselItem)}
              />
            ))}
          </Carousel>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    courses: state.courses,
    eventCourses: state.eventWeek.activeEventData?.courses,
    eventStartDate: state.eventWeek.activeEventData?.start,
    eventEndDate: state.eventWeek.activeEventData?.end,
    eventDiscount: state.eventWeek.activeEventData?.discount,
  };
};
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    actions: bindActionCreators(
      {
        getCoursesAction,
      },
      dispatch,
    ),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CourseCardContainer);
