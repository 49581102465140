import React, { useState, useEffect } from 'react';
import './styles/partner-list-style.scss';
import { enquireScreen } from 'enquire-js';
import PartnerComponent from './components/PartnerComponent/PartnerComponent';
import AsiaImg from '../../assets/images/hinh_asia_insure.jpg';
import { getTranslatedText} from '../../services/appService';
let isMobile;
enquireScreen((b) => {
  isMobile = b;
});



export default function PartnerList () {
    return (
        <div className='partners-list-wrap'>
            <p id='title-partner'>{getTranslatedText('partner_list_title')}</p>
            <div id='partner-list-wrapper'>
                <PartnerComponent src={AsiaImg} href="https://asiainsure.app/" target="_blank"/>
            </div>
        </div>
    )
}