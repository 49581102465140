import axios from 'axios';
import {
  BASE_URL,
  GET_LIST_AUDIO_BOOK_REQUEST,
  GET_LIST_AUDIO_BOOK_SUCCESS,
  GET_LIST_AUDIO_BOOK_FAILURE,
  GET_DETAIL_AUDIO_BOOK_REQUEST,
  GET_DETAIL_AUDIO_BOOK_SUCCESS,
  GET_DETAIL_AUDIO_BOOK_FAILURE,
  BUY_AUDIO_BOOK_REQUEST,
  BUY_AUDIO_BOOK_SUCCESS,
  BUY_AUDIO_BOOK_FAILURE,
  TOKEN_KEY,
  GET_DETAIL_KIDZ_BOOK_SUCCESS,
  GET_DETAIL_KIDZ_BOOK_FAILURE,
  GET_DETAIL_KIDZ_BOOK_REQUEST,
  GET_LIST_KIDZ_BOOK_SUCCESS, GET_LIST_KIDZ_BOOK_FAILURE, GET_LIST_KIDZ_BOOK_REQUEST,
} from './index';

export function getListAudioBookAction(payload) {
  return dispatch => {
    dispatch(getListAudioRequest());
    axios
      .get(`${BASE_URL}/audio-books`, { params: payload })
      .then(response => {
        dispatch(getListAudioBookResult(response.data));
      })
      .catch(error => {
        dispatch(getListAudioBookResult(error.message, false));
      });
  };
};

function getListAudioRequest() {
  return {
    type: GET_LIST_AUDIO_BOOK_REQUEST
  };
};

function getListAudioBookResult(payload, success = true) {
  return {
    type: success ? GET_LIST_AUDIO_BOOK_SUCCESS : GET_LIST_AUDIO_BOOK_FAILURE,
    payload
  };
};

export function getDetailAudioBookAction(payload) {
  return dispatch => {
    dispatch(getDetailAudioRequest());
    if (payload?.token) {
      axios
        .get(`${BASE_URL}/users/audio-books/detail?token=${payload?.token}`, { params: payload?.request })
        .then(response => {
          dispatch(getDetailAudioBookResult(response.data));
        })
        .catch(error => {
          dispatch(getDetailAudioBookResult(error.message, false));
        });
    }
    else{
      axios
        .get(`${BASE_URL}/users/audio-books/detail/public`, { params: payload?.request })
        .then(response => {
          dispatch(getDetailAudioBookResult(response.data));
        })
        .catch(error => {
          dispatch(getDetailAudioBookResult(error.message, false));
        });
    }
  };
};

function getDetailAudioRequest() {
  return {
    type: GET_DETAIL_AUDIO_BOOK_REQUEST
  };
};

function getDetailAudioBookResult(payload, success = true) {
  return {
    type: success ? GET_DETAIL_AUDIO_BOOK_SUCCESS : GET_DETAIL_AUDIO_BOOK_FAILURE,
    payload
  };
};
export function getListKidzBookAction(payload) {
  return dispatch => {
    dispatch(getListKidzBookRequest());
    axios
      .get(`${BASE_URL}/kidz-books`, { params: payload })
      .then(response => {
        dispatch(getListKidzBookResult(response.data));
      })
      .catch(error => {
        dispatch(getListKidzBookResult(error.message, false));
      });
  };
};

function getListKidzBookRequest() {
  return {
    type: GET_LIST_KIDZ_BOOK_REQUEST
  };
};

function getListKidzBookResult(payload, success = true) {
  return {
    type: success ? GET_LIST_KIDZ_BOOK_SUCCESS : GET_LIST_KIDZ_BOOK_FAILURE,
    payload
  };
};

export function getDetailKidzBookAction(payload) {
  return dispatch => {
    dispatch(getDetailKidzBookRequest());
    if (payload?.token) {
      axios
        .get(`${BASE_URL}/users/kidz-books/detail?token=${payload?.token}`, { params: payload?.request })
        .then(response => {
          dispatch(getDetailKidzBookResult(response.data));
        })
        .catch(error => {
          dispatch(getDetailKidzBookResult(error.message, false));
        });
    }
    else{
      axios
        .get(`${BASE_URL}/users/kidz-books/detail/public`, { params: payload?.request })
        .then(response => {
          dispatch(getDetailKidzBookResult(response.data));
        })
        .catch(error => {
          dispatch(getDetailKidzBookResult(error.message, false));
        });
    }
  };
};

function getDetailKidzBookRequest() {
  return {
    type: GET_DETAIL_KIDZ_BOOK_REQUEST
  };
};

function getDetailKidzBookResult(payload, success = true) {
  return {
    type: success ? GET_DETAIL_KIDZ_BOOK_SUCCESS : GET_DETAIL_KIDZ_BOOK_FAILURE,
    payload
  };
};

export function buyAudioBookAction() {
  return dispatch => {
    dispatch(buyAudioBookRequest());
    axios.post(`${BASE_URL}/users/buying-audio-license`, 
    {
      token: localStorage.getItem(TOKEN_KEY || '')
    })
    .then(response => {
      dispatch(buyAudioBookResult(response.data));
    })
    .catch(error => {
      dispatch(buyAudioBookResult(error.message, false));
    })
  };
};

function buyAudioBookRequest() {
  return {
    type: BUY_AUDIO_BOOK_REQUEST
  };
};

function buyAudioBookResult(payload, success = true) {
  return {
    type: success ? BUY_AUDIO_BOOK_SUCCESS : BUY_AUDIO_BOOK_FAILURE,
    payload,
  };
};