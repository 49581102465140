import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  getCoursesAction,
  getUserCoursesAction,
  getCourseDetailAction,
  getUserCourseDetailAction,
  getCourseProgress,
  completeVideo
} from '../../../actions/courses';
import { getProfileAction } from '../../../actions/profile';
import { useParams } from 'react-router-dom';
import {
  Row,
  Col,
  Carousel,
  Tabs,
} from 'antd';
import { LeftCircleOutlined, RightCircleOutlined } from '@ant-design/icons';

import CurriculumInformation from '../components/CurriculumInformation/CurriculumInformation';
import CourseInformation from '../components/CourseInformation/CourseInformation';

import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb';
import moment from 'moment';

import './styles/english-course-view-styles.scss';
import { routes } from '../../../constants';
import { getTranslatedText, getUserFormLocal, checkIsEventDay } from '../../../services/appService';
import CourseCard from '../../../components/CourseCard/CourseCard';
import EnglishCourseCombo from '../components/EnglishCourseCombo/EnglishCourseCombo';
import EnglishCourseList from '../EnglishCourseList/EnglishCourseList';
import { enquireScreen } from 'enquire-js';
import { CURRENT_LANG_KEY } from '../../../actions';
import ReactPlayer from 'react-player';
import { get } from 'lodash';
import BannerPromotion from '../../../components/GoldenWeekPromotion/components/BannerPromotion';
import AudioPriceCard from '../../../components/AudioPriceCard';

let isMobile;
enquireScreen((b) => {
  isMobile = b;
});

const { TabPane } = Tabs;


function EnglishCourseView(props) {

  const {
    courses,
    courseDetail,
    actions,
    english_courses,
    courseProgress,
    nextAttachment,
    eventCourses,
    eventStartDate,
    eventEndDate,
    eventDiscount,
    user,
  } = props;

  const { id } = useParams();

  const [slidesToShow, setSlideToShow] = useState(3);
  const [isThisCoursesBought, setIsThisCoursesBought] = useState('');
  const [whichRender, setWhichRender] = useState('');
  const [isUserStartStudy, setIsUserStartStudy] = useState(false);
  const [videoLink, setVideoLink] = useState('');
  const [visibleButton, setVisibleButton] = useState(false);
  const [duration, setDuration] = useState(0);
  const [displayEventPrice, setDisplayEventPrice] = useState(false);
  const [eventSellInfo, setEventSellInfo] = useState({});
  const [audioBookPriceVisible, setAudioBookPriceVisible] = useState(true);

  useEffect(() => {
    if (user?.is_license_permanently === 1) {
      setAudioBookPriceVisible(false);
    }
    if (user?.is_license_permanently === 0 && moment().isBefore(user?.audio_license)) {
      setAudioBookPriceVisible(false);
    }
  }, [user]);

  function checkCourseInEvent() {
    let check = false;
    let sellInfo = {
      soldNum: 0,
      remainNum: 0,
      total: 0,
    };
    eventCourses?.forEach((eventCourse) => {
      if (courseDetail?.id === eventCourse.course_id) {
        check = true;
        sellInfo.soldNum = eventCourse.sold_number;
        sellInfo.remainNum = eventCourse.remain_display;
        sellInfo.total = eventCourse.total;
      };
    });
    setEventSellInfo(sellInfo);
    let checkIsDayOfEvent = checkIsEventDay(eventStartDate, eventEndDate);
    if (checkIsDayOfEvent && check) {
      return true;
    } else {
      return false;
    };
  };

  useEffect(() => {
    let user = getUserFormLocal();
    if (user) {
      actions.getProfileAction({
        token: localStorage.getItem('token')
      });
    };
    if (isMobile) {
      setSlideToShow(1);
    } else {
      setSlideToShow(3);
    };
  }, []);

  useEffect(() => {
    if (nextAttachment) {
      setVideoLink(nextAttachment);
    };
  }, [nextAttachment]);

  useEffect(() => {
    let user = getUserFormLocal();
    if (user) {
      setWhichRender('user-login');
      actions.getUserCourseDetailAction(id);
    } else {
      setWhichRender('user-logout');
      actions.getCourseDetailAction(id);
    }
    actions.getCoursesAction();
  }, [id]);

  useEffect(() => {
    let user = getUserFormLocal();
    if (whichRender === 'user-login') {
      if (user.max_price_english >= courseDetail.price) {
        setIsThisCoursesBought(true);
        actions.getCourseProgress({course_id: courseDetail.id})
      }
      else {
        setIsThisCoursesBought(false);
      }
    } else {
      setIsThisCoursesBought(false);
    }
    courseDetail.have_promotion = (user && user.max_price > 0);
    setDisplayEventPrice(checkCourseInEvent());
  }, [courseDetail]);


  function setVideoLesson(video_link) {
    setIsUserStartStudy(true);
    setVideoLink(video_link);
    window.scrollTo({ top: 50, behavior: 'smooth' });
  };

  function progressVideo(event){
    let d = duration;
    let play = event.playedSeconds;
    d = parseFloat(d);
    play = parseFloat(play);
    if ((play + 10) > d)
      setVisibleButton(true)
    else
      setVisibleButton(false)

  }

  async function setDurationState(event){
    let d = 0;
    if (!(event.getDuration())){
      d = await event.getInternalPlayer().getDuration().then(function(d){
        return d;
      })
    }
    else
      d = event.getDuration();

    setDuration(d);
  }

  function watchedVideo(course_id){
    actions.completeVideo({
      course_id: course_id,
      attachment_id: videoLink.id
    })
  };

  function checkCourseItemInEvent(course) {
    let check = false;
    eventCourses?.forEach((eventCourse) => {
      if (course.id === eventCourse.course_id) {
        check = true;
      };
    });
    return check;
  };

  function getItemEventSellInfo(course) {
    let sellInfo = {
      soldNum: 0,
      remainNum: 0,
      total: 0,
    };
    eventCourses?.forEach((eventCourse) => {
      if (course.id === eventCourse.course_id) {
        sellInfo.soldNum = eventCourse.sold_number;
        sellInfo.remainNum = eventCourse.remain_display;
        sellInfo.total = eventCourse.total;
      };
    });
    return sellInfo;
  };

  return (
    <React.Fragment>
      <Breadcrumb
        data={[
          { link: routes.home, text: getTranslatedText('HOME') },
          { link: routes.courses, text: getTranslatedText('COURSE') },
          { link: routes.englishCourse.englishCourseView, text: getTranslatedText('DETAIL') },
        ]}
      />
      {eventCourses && <BannerPromotion /> }
      <div id='english-course-view-wrapper'>
        <h1>{courseDetail.level ? courseDetail.level.toUpperCase() : ''}</h1>
        <Row id='content-course-english-course-view-wrapper'>
          <Col
            id='control-content-course-english-course-view-wrapper'
            lg={15}
            xs={24}
          >
            {isUserStartStudy ? (
              <div id='content-control-content-course-english-course-view-wrapper' style={{position: "relative"}}>
                <ReactPlayer width={"auto"} url={(localStorage.getItem(CURRENT_LANG_KEY) == 'vi')? videoLink.link_file_vi : videoLink.link_file} controls={true} playing onProgress={event => progressVideo(event, this)} onReady={event => setDurationState(event, this)} />
                {visibleButton ? (
                  <button style={{position: "absolute", top: 5, right: 45}} className="PayButton" onClick={() => watchedVideo(courseDetail.id)} id="button-complete">{getTranslatedText("complete")}</button>
                ) : ( <React.Fragment/>)}
              </div>
            ) : (
              <div id='content-control-content-course-english-course-view-wrapper'>
                <img
                  alt=''
                  src={courseDetail.avatar}
                />
              </div>
            )}
            <Tabs
              defaultActiveKey='1'
              className='tab-info-content-course-english-course-view-wrapper'
            >
              <TabPane
                tab={getTranslatedText('course_info')}
                key='1'
              >
                <p style={{whiteSpace: 'pre-line'}} dangerouslySetInnerHTML={{__html: (localStorage.getItem(CURRENT_LANG_KEY) == 'vi')
                    ? courseDetail.description_vi
                    : courseDetail.description}}/>
              </TabPane>
              {isThisCoursesBought ? (
                <React.Fragment>
                  <TabPane
                    tab={getTranslatedText('curriculum_info')}
                    key='2'
                  >
                    <CurriculumInformation
                      lessons={courseDetail.child}
                      setVideoLesson={setVideoLesson}
                    />
                  </TabPane>
                  <TabPane
                    tab={getTranslatedText('progress')}
                    key='3'
                  >
                    {courseProgress.en && (
                      <p>
                        <h5>{getTranslatedText("course")}: {getTranslatedText("lang_en")}</h5>
                        {getTranslatedText("total_video")}: {courseProgress.en.total_video} videos <br/>
                        {getTranslatedText("total_video_watch")}: {courseProgress.en.total_video_watched} videos <br/>
                        {getTranslatedText("progress")}: {courseProgress.en.percent}%
                      </p>
                    )}
                    <br/>
                    {courseProgress.vi && (
                      <p>
                        <h5>{getTranslatedText("course")}: {getTranslatedText("lang_vi")}</h5>
                        {getTranslatedText("total_video")}: {courseProgress.vi.total_video} videos <br/>
                        {getTranslatedText("total_video_watch")}: {courseProgress.vi.total_video_watched} videos <br/>
                        {getTranslatedText("progress")}: {courseProgress.vi.percent}%
                      </p>
                    )}
                  </TabPane>
                </React.Fragment>
              ) : (
                null
              )}
            </Tabs>
            {!isThisCoursesBought ? (
              <div id='course-combo-control-content-course-english-course-view-wrapper'>
                <h3>{getTranslatedText('combo_include')}</h3>
                {english_courses.map((item, index) => (
                  <React.Fragment key={index}>
                    {item.price <= courseDetail.price ? (
                      <EnglishCourseCombo
                        englishCourseComboItem={item}
                      />
                    ) : (null)}
                  </React.Fragment>
                ))}
              </div>
            ) : (null)}
          </Col>
          <Col
            id='data-board-content-course-english-course-view-wrapper'
            lg={8}
            xs={24}
          >
            <CourseInformation
              courseDetail={courseDetail}
              isThisCoursesBought={isThisCoursesBought}
              whichRender={whichRender}
              displayEventPrice={displayEventPrice}
              eventDiscount={eventDiscount}
              eventSellInfo={eventSellInfo}
            />
            {audioBookPriceVisible && (
              <div className='mt-3 AudioBookContainerEnglish'>
                <AudioPriceCard />
              </div>
            )}
          </Col>
        </Row>
      </div>
      <EnglishCourseList
        title={'suggest'}
        excludeId={courseDetail.id}
      />
      <Row id='suggest-courses-english-course-view-wrapper'>
          <h2>{getTranslatedText('our_courses')}</h2>
          <Carousel
            className='english-course-list-wrapper'
            slidesToShow={slidesToShow}
            arrows={true}
            nextArrow={<RightCircleOutlined />}
            prevArrow={<LeftCircleOutlined />}
            dots={false}
          >
            {courses.courses.map((courseCarouselItem, index) => (
              <CourseCard
                courseCarouselItem={courseCarouselItem}
                key={index}
                isDayOfEvent={checkIsEventDay(eventStartDate, eventEndDate)}
                eventDiscount={eventDiscount}
                isCourseInEvent={checkCourseItemInEvent(courseCarouselItem)}
                eventSellInfo={getItemEventSellInfo(courseCarouselItem)}
              />
            ))}
          </Carousel>
        </Row>
    </React.Fragment>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    english_courses: state.courses.english_courses,
    courses: state.courses,
    courseDetail: state.courses.courseDetail,
    loading: state.courses.loading,
    courseProgress: state.courses.course_progress || {},
    nextAttachment: get(state, "courses.next_attachment", null),
    eventCourses: state.eventWeek.activeEventData?.courses,
    eventStartDate: state.eventWeek.activeEventData?.start,
    eventEndDate: state.eventWeek.activeEventData?.end,
    eventDiscount: state.eventWeek.activeEventData?.discount,
    user: state.profile.data
  };
};
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    actions: bindActionCreators(
      {
        getCoursesAction,
        getCourseDetailAction,
        getUserCoursesAction,
        getUserCourseDetailAction,
        getProfileAction,
        getCourseProgress,
        completeVideo
      },
      dispatch,
    ),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EnglishCourseView);