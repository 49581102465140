import React, { useState, useEffect, } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { routes } from '../../constants';
import { CURRENT_LANG_KEY } from '../../actions';

function GoldenWeekPromotion(props) {
  const {
    bannerURL,
    bannerURLEn,
  } = props;
  const [currentLanguage, setCurrentLanguage] = useState('');

  useEffect(() => {
    let current_language =
      window.localStorage.getItem(CURRENT_LANG_KEY) || 'en';
    setCurrentLanguage(current_language);
  }, []);

  return (
    <div className="w-full">
      <Link to={routes.courses}>
        <img className="mx-auto" src={currentLanguage === 'en' ? bannerURLEn : bannerURL} alt="promotion-banner" />
      </Link>
    </div>
  );
};

GoldenWeekPromotion.propTypes = {
  bannerURL: PropTypes.string,
  bannerURLEn: PropTypes.string,
};

export default GoldenWeekPromotion;