import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { routes } from '../../constants';
import { getTranslatedText } from '../../services/appService';

function ExtendMenu(props) {
  const {
    setDrawerVisible,
    history,
  } = props;

  function handleOnClick(target) {
    history.push(target);
    setDrawerVisible(false);
  };

  return (
    <div className="w-full">
      <div className="w-full grid grid-cols-3 gap-x-16 py-2.5 px-4 border-b border-grey">
          <div className="aspect-w-4 aspect-h-3">
            <img className="object-fill" src="/assets/images/logo.png" alt="logo" />
          </div>
          <div className="col-span-2 flex items-center justify-end">
            <button onClick={() => setDrawerVisible(false)}>
              <img src='assets/images/icon_cancel.svg' alt='icon-cancel' />
            </button>
          </div>
        </div>
      <div className="w-full grid grid-cols-1 pt-12 pl-4">
        <button onClick={() => handleOnClick(routes.home)}>
          <p className={history.location.pathname === routes.home ? "text-left text-brown border-b-2 max-w-max border-brown uppercase pb-1.5 pt-3 pr-2.5 font-bold" : "text-left max-w-max text-black uppercase pb-1.5 pt-3 pr-2.5 font-bold"}>{getTranslatedText('home')}</p>
        </button>
        <button onClick={() => handleOnClick(routes.courses)}>
          <p className={history.location.pathname === routes.courses ? "text-left text-brown border-b-2 max-w-max border-brown uppercase pb-1.5 pt-3 pr-2.5 font-bold" : "text-left max-w-max text-black uppercase pb-1.5 pt-3 pr-2.5 font-bold"}>{getTranslatedText('course')}</p>
        </button>
        <button onClick={() => handleOnClick(routes.speaker.speakerList)}>
          <p className={history.location.pathname === routes.speaker.speakerList ? "text-left text-brown border-b-2 max-w-max border-brown uppercase pb-1.5 pt-3 pr-2.5 font-bold" : "text-left max-w-max text-black uppercase pb-1.5 pt-3 pr-2.5 font-bold"}>{getTranslatedText('speaker')}</p>
        </button>
        <button onClick={() => handleOnClick(routes.audio_book.list)}>
          <p className={history.location.pathname === routes.audio_book.list ? "text-left text-brown border-b-2 max-w-max border-brown uppercase pb-1.5 pt-3 pr-2.5 font-bold" : "text-left max-w-max text-black uppercase pb-1.5 pt-3 pr-2.5 font-bold"}>{getTranslatedText('audio_book')}</p>
        </button>
        <button onClick={() => handleOnClick(routes.kidz_book.list)}>
          <p className={history.location.pathname === routes.kidz_book.list ? "text-left text-brown border-b-2 max-w-max border-brown uppercase pb-1.5 pt-3 pr-2.5 font-bold" : "text-left max-w-max text-black uppercase pb-1.5 pt-3 pr-2.5 font-bold"}>{getTranslatedText('kidz_book')}</p>
        </button>
        <button onClick={() => handleOnClick(routes.evaluate)}>
          <p className={history.location.pathname === routes.evaluate ? "text-left text-brown border-b-2 max-w-max border-brown uppercase pb-1.5 pt-3 pr-2.5 font-bold" : "text-left max-w-max text-black uppercase pb-1.5 pt-3 pr-2.5 font-bold"}>
            {getTranslatedText('opinions')}
          </p>
        </button>
        <button onClick={() => handleOnClick(routes.how_it_work)}>
          <p className={history.location.pathname === routes.how_it_work ? "text-left text-brown border-b-2 max-w-max border-brown uppercase pb-1.5 pt-3 pr-2.5 font-bold" : "text-left max-w-max text-black uppercase pb-1.5 pt-3 pr-2.5 font-bold"}>
            {getTranslatedText('how_it_work')
          }</p>
        </button>
        <button onClick={() => handleOnClick(routes.contact)}>
          <p className={history.location.pathname === routes.contact ? "text-left text-brown border-b-2 max-w-max border-brown uppercase pb-1.5 pt-3 pr-2.5 font-bold" : "text-left max-w-max text-black uppercase pb-1.5 pt-3 pr-2.5 font-bold"}>{getTranslatedText('contact')}</p>
        </button>
      </div>
    </div>
  );
};

ExtendMenu.propTypes = {
  setDrawerVisible: PropTypes.func,
  history: PropTypes.any,
};

export default ExtendMenu;