import React, { useState, useEffect } from 'react';
import { Button } from 'antd';
import {
  postChangePointMoney,
  getExChangeRatio,
} from '../../../actions/accumulate';
import { getTranslatedText } from '../../../services/appService';
import * as types from '../../../actions';

import '../styles/privilegestyle.scss';
import ModalSuccessExchange from '../component/ModalSuccessExchange';

export default function SwapMoney({
  setRenderChildContainer,
  currentPoint,
}) {
  const [point, setPoint] = useState(null);
  const [ratio, setRatio] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const toggleModal = () => {
    setIsModalVisible(prevState => !prevState);
  };

  const getRatio = async () => {
    setLoading(true);
    const data = await getExChangeRatio();
    setRatio(data);
    setLoading(false);
  };

  const swapPoint = async () => {
    setLoading(true);
    const res = await postChangePointMoney({
      token: localStorage.getItem(types.TOKEN_KEY),
      point,
    });
    setLoading(false);
    if (res.data.statusCode === 200) {
      toggleModal();
    }
  };

  useEffect(() => {
    getRatio();
  }, []);

  return (
    <div id="privilege-container">
      <ModalSuccessExchange
        point={point}
        money={point / ratio}
        toggleModal={toggleModal}
        isModalVisible={isModalVisible}
      />
      <div id="privilege-container-header">
        <img alt="" src="../../../assets/images/privilege-icon.png" />
        <p>{getTranslatedText('user_promotion')}</p>
      </div>
      <div id="privilege-container-body">
        <div className="swap-money-wrapper">
          <div className="roles">
            <div className="current-point">
              <div className="d-flex flex-column mb-4">
                <p className="mb-2 label-point">
                  {getTranslatedText('your_current_point')}:
                </p>
                <div className="d-flex align-items-center">
                  <div className="points">{currentPoint}</div>
                  <span>{getTranslatedText('point')}</span>
                </div>
              </div>
            </div>
            <div className="role-swap">
              <p className="mb-2 label-role">
                {getTranslatedText('exchange_rule')}:
              </p>
              <div className="role-point">
                1.000 {getTranslatedText('points')} = {1000 / ratio}$
              </div>
              <p dangerouslySetInnerHTML={{__html: getTranslatedText("ratio_change")}}/>
            </div>
          </div>
          <div className="form-swap">
            <div className="field-point">
              <p className="mb-2 label-field-point">
                {getTranslatedText('point_want_to_redeem')}:
              </p>
              <input
                className="input-point"
                type="number"
                placeholder={getTranslatedText(
                  'point_want_to_redeem',
                )}
                onChange={e => setPoint(e.target.value)}
                disabled={!ratio}
              />
            </div>
            <div className="field-money">
              <p className="mb-2 label-money">
                {getTranslatedText('amount_corresponding')}:
              </p>
              <div className="d-flex">
                <div className="money mr-2">{point / ratio}</div>
                <span>USD</span>
              </div>
            </div>
          </div>
          <div className="group-btn">
            <Button
              className="btn-swap"
              disabled={!point || loading}
              onClick={() => swapPoint()}
            >
              {getTranslatedText('exchange_now')}
            </Button>
            <Button
              className="btn-cancel"
              onClick={() => setRenderChildContainer('promotion')}
            >
              {getTranslatedText('cancel')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
