import React, { useEffect } from 'react';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { routes } from '../../constants';
import { getTranslatedText } from '../../services/appService';
import AudioBookOrderSuccess from './components/OrderSuccess';
import { getProfileAction } from '../../actions/profile'

const OrderSuccess = ({ actions }) => {
  
  useEffect(() => {
    actions.getProfileAction({
      token: localStorage.getItem('token'),
    })
  }, [])

  return (
    <div>
      <Breadcrumb
        data={[
          { link: routes.home, text: getTranslatedText('HOME') },
          { link: routes.audio_book.order, text: getTranslatedText('AUDIO_BOOK_ORDER')}
        ]}
      />
      <AudioBookOrderSuccess />
    </div>
  );
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    actions: bindActionCreators(
      {
        getProfileAction,
      },
      dispatch,
    ),
  };
};

export default connect(null, mapDispatchToProps)(OrderSuccess);