import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { buyAudioBookAction } from '../../actions/audio_book';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import AudioBookCheckout from './components/Checkout';
import { routes } from '../../constants';
import { getTranslatedText } from '../../services/appService';
import LoadingOverlay from 'react-loading-overlay';
import ScaleLoader from 'react-spinners/ScaleLoader';

const AudioBookOrder = ({ user, actions, isBuyingAudioBook }) => {
  
  const handleBuyAudioBook = () => {
    if (user?.total_price < 15) {
      window.alert('Bạn không đủ số dư')
    } else {
      actions.buyAudioBookAction();
    };
  };
  return (
    <LoadingOverlay active={isBuyingAudioBook} spinner={<ScaleLoader />}>
      <div>
        <Breadcrumb
          data={[
            { link: routes.home, text: getTranslatedText('HOME') },
            { link: routes.audio_book.order, text: getTranslatedText('AUDIO_BOOK_ORDER')}
          ]}
        />
        <AudioBookCheckout balance={user?.total_price} onClick={handleBuyAudioBook} />
      </div>
    </LoadingOverlay>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.profile.data,
    isBuyingAudioBook: state.audioBook.isBuyingAudioBook
  };
};

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(
      {
        buyAudioBookAction,
      },
      dispatch
    ),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AudioBookOrder);