import React, { useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import {
  getListDocumentAction,
} from '../../../actions/documents';
import {
  List,
} from 'antd';

import DocumentCard from '../components/DocumentCard/DocumentCard'

import './styles/document-list-styles.scss';

function DocumentList(props) {

  const {
    actions,
    document_list,
  } = props;

  useEffect(() => {
    const payload = {
      type: 'document',
      length: 8,
      page: 1,
    };
    actions.getListDocumentAction(payload);
  }, []);

  function handleOnPageChange(page) {
    const payload = {
      type: 'document',
      length: 8,
      page: page,
    };
    actions.getListDocumentAction(payload);
  };

  return (
    <React.Fragment>
      <List
        id='document-list-wrapper'
        dataSource={document_list !== null ? document_list.data : []}
        grid={{
          xs: 2,
          md: 4,
          lg: 4,
          xl: 4,
          xxl: 4
        }}
        pagination={{
          pageSize: 8,
          total: document_list !== null ? document_list.total : 0,
          onChange: (page) => handleOnPageChange(page)
        }}
        renderItem={document => (
          <DocumentCard
            document={document}
          />
        )}
      />
    </React.Fragment>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    document_list: state.document.document_state,
  };
};
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    actions: bindActionCreators(
      {
        getListDocumentAction,
      },
      dispatch,
    ),
  };
};


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DocumentList);