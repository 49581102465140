import React, { Component, useEffect } from 'react';
import AccountBreadcrumb from '../AccountBreadcrumb/AccountBreadcrumb';
import BoxTotalMoney from './component/BoxTotalMoney';
import ItemBoxInsurance from './component/ItemBoxInsurance';
import './styles.scss';
import { get } from 'lodash';
import { bindActionCreators } from 'redux';
import { getCourseInsurance } from '../../actions/courses';
import { connect } from 'react-redux';
import { getTranslatedText } from '../../services/appService';

class CapitalInsurancePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      insurances: {
        insurance_list: [],
        total_refund: 0,
        refund_list: []
      }
    }
  }

  componentDidMount() {
    this.props.actions.getCourseInsurance();
  }

  // componentWillReceiveProps(nextProps, nextContext) {
  //   const { insurances } = nextProps;
  //   console.log(insurances)
  // }

  render() {
    let { insurances } = this.props;
    return (
      <>
        <AccountBreadcrumb />
        <div className="container__capital">
          <div className="left">
            <h4>
              <b>{getTranslatedText("insurance_purchased")}</b>
            </h4>
            <hr />
            <div className="list__insurance">
              {insurances.insurance_list.map((item, index) => (
                <ItemBoxInsurance insurance={item} />
              ))}

            </div>
          </div>
          <div className="right money__back">
            <h4>
              <b>{getTranslatedText("insurance_refunded")}</b>
            </h4>
            <hr />
            <BoxTotalMoney totalReferral={insurances.total_insurance_referral} totalRefund={insurances.total_refund} list={insurances.refund_list} />
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = ({ courses }, ownProps) => {
  return {
    insurances: courses.insurances
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    actions: bindActionCreators(
      {
        getCourseInsurance
      },
      dispatch,
    ),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CapitalInsurancePage);