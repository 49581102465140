import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import './TransferMoney.scss';
import AccountBreadcrumb from '../AccountBreadcrumb/AccountBreadcrumb';
import {
  getUserFormLocal,
  currencyFormatter,
  getTranslatedText,
} from '../../services/appService';
import DefaultUserAvatar from '../../assets/images/user_default_avatar.png';
import {
  postTransferMoney,
  getProfileAction,
  getUsers,
  submitVerifyTransferCode
} from '../../actions/profile';
import { keys, routes } from '../../constants';
import ArrowRight from '../../assets/images/icon_arrow_right.svg';
import * as types from '../../actions/index';
import { PayPalButton } from 'react-paypal-button-v2';
import { Button, Col, Layout, Modal, Popover, Row, Typography } from 'antd';
import { toast } from 'react-toastify';
import ReCAPTCHA from 'react-google-recaptcha';
import logo from '../../assets/images/logo.png';

class TransferMoney extends Component {
  state = {
    currentUser: {},
    user_code: '',
    amount: 0,
    note: '',
    recaptcha: '',
    modal_visible: false,
    verify_code: '',
    transfer_code: ""
  };

  checkCurrentUser() {
    if (getUserFormLocal()) {
      this.setState({
        currentUser: getUserFormLocal(),
      });
    }
  }

  setCaptcha = value => {
    this.setState({
      recaptcha: value,
    });
  };

  componentDidMount() {
    this.props.actions.getProfileAction({
      token: localStorage.getItem(types.TOKEN_KEY),
    });
    this.checkCurrentUser();
    // this.props.actions.getUsers();
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      currentUser: nextProps.currentUser,
    });
    this.checkCurrentUser();

    const {transfer} = nextProps;
    if (transfer != null)
      this.setState({modal_visible: true, transfer_code: transfer.code});
  }

  requestTransferMoney = () => {
    let payload = {
      code: this.state.user_code,
      amount: this.state.amount,
      recaptcha: this.state.recaptcha,
      note: this.state.note,
    };

    if (!payload.code || !payload.amount || !payload.recaptcha) {
      toast.error(getTranslatedText('fill_input'));
      return;
    }

    this.props.actions.postTransferMoney(payload);
  };

  verifyTransferMoney = () => {
    let payload = {
      transfer_code: this.state.transfer_code,
      verify_code: this.state.verify_code
    }

    this.props.actions.submitVerifyTransferCode(payload);

    this.setState({verify_code: ""});
  }



  handleChange = name => event => {
    this.setState({
      [name]: event.target.value,
    });
  };

  render() {
    const { userID, amount, note, verify_code } = this.state;

    return (
      <div>
        <AccountBreadcrumb />
        <div className="MyWallet_Withdraw">
          <div className="Title">
            <div>{getTranslatedText('my_wallet')}</div>
            <img alt="arrow right" src={ArrowRight}></img>
            <div>{getTranslatedText('transfer_money')}</div>
          </div>

          <div className="ContentContainer">
            <div className="TransactionInfo">
              <div className="WithdrawAmount">
                <div>{getTranslatedText('receive_username')}*</div>
                <input
                  type="text"
                  placeholder={getTranslatedText('receive_username')}
                  value={this.state.user_code}
                  onChange={this.handleChange('user_code')}
                />
              </div>
              <div>{getTranslatedText('input_transfer')}</div>
              <div className="WithdrawAmount">
                <div>{getTranslatedText('amount')}* (USD)</div>
                <input
                  type="number"
                  placeholder={getTranslatedText('amount_deposit')}
                  value={amount}
                  onChange={this.handleChange('amount')}
                />
              </div>
              <div className="WithdrawAmount">
                <div>{getTranslatedText('note')}</div>
                <input
                  type="text"
                  placeholder={getTranslatedText('note')}
                  value={note}
                  onChange={this.handleChange('note')}
                />
              </div>
              <ReCAPTCHA
                sitekey={keys.googleRecaptchaSiteKey}
                onChange={this.setCaptcha}
              />

              <div className="Note">
                {getTranslatedText('important_deposit')} <br/>
                {getTranslatedText('note_transfer')}
              </div>
            </div>

            <div className="Card">
              <div className="User">
                <img
                  className="Photo"
                  alt="avatar"
                  src={
                    this.state.currentUser.avatar || DefaultUserAvatar
                  }
                />
                <div>{this.state.currentUser.name || ''}</div>
              </div>
              <div className="Balance">
                <div className="Text">
                  {getTranslatedText('balance')}
                </div>
                <div className="Number">
                  {currencyFormatter(
                    this.state.currentUser.total_price,
                  )}
                </div>
              </div>
            </div>
          </div>
          <div
            className="TransactionRequest"
            onClick={() => this.requestTransferMoney()}
          >
            {getTranslatedText('REQUEST')}
          </div>

          <Link to={routes.accountWallet}>
            <div className="CancelButton">
              {getTranslatedText('CANCEL')}
            </div>
          </Link>
          <Modal
            visible={this.state.modal_visible}
            footer={false}
            width="500px"
            onCancel={() => this.setState({ modal_visible: false })}
            destroyOnClose={true}
            style={{zIndex: "2"}}
          >
            <React.Fragment>
              <Layout.Header style={{ height: 'auto', backgroundColor: '#FFFFFF', textAlign: 'center' }}>
                <img src={logo} style={{width: '200px'}} />
              </Layout.Header>
              <Layout.Content className="content__container">
                <Row>
                  <Col span={24}>
                    <Typography.Text className="content_heading">
                      {getTranslatedText('verify_transfer')}
                    </Typography.Text>
                  </Col>
                  <Col span={24}>
                    <div className="form-group">
                      <div>{getTranslatedText('input_verify_code')}</div>
                      <input
                        type="text"
                        placeholder={getTranslatedText('your_verify_code')}
                        value={verify_code}
                        onChange={this.handleChange('verify_code')}
                      />
                    </div>
                  </Col>
                </Row>
              </Layout.Content>
              <Layout.Footer className="footer__container">
                <Row gutter={16}>
                  <Col span={12} xs={24} xl={12}>
                    <Button
                      className="transfer__modal__button"
                      onClick={() => this.verifyTransferMoney()}
                      disabled={!this.state.verify_code}
                    >
                      <Typography.Text className="button_label">
                        {getTranslatedText('contract_request')}
                      </Typography.Text>
                    </Button>
                  </Col>
                </Row>
              </Layout.Footer>
            </React.Fragment>
          </Modal>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ profile }, ownProps) => {
  return {
    currentUser: profile.data,
    users: profile.users,
    transfer: profile.transfer
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    actions: bindActionCreators(
      {
        postTransferMoney,
        getProfileAction,
        getUsers,
        submitVerifyTransferCode
      },
      dispatch,
    ),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TransferMoney);
