import React from 'react';
import PropTypes from 'prop-types';

function PromotionDiscount(props) {
  const {
    containerClassName = "absolute right-1.5 top-1.5 md:right-2.5 md:top-2.5",
    discount
  } = props;
  
  return (
    <div className={containerClassName}>
      <p className="w-8 md:w-10 text-center rounded-md bg-red-600 text-white text-6 md:text-10 py-1.5">
        -{discount}%
      </p>  
    </div>
  );
};

PromotionDiscount.propTypes = {
  containerClassName: PropTypes.string,
  discount: PropTypes.number.isRequired,
};

export default PromotionDiscount;