import React, {useEffect} from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  getListSpeakerAction,
} from '../../../actions/speakers';
import {
  List,
} from 'antd';

import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb';
import SpeakerCard from '../components/SpeakerCard/SpeakerCard';

import { routes } from '../../../constants';
import { getTranslatedText } from '../../../services/appService';

import './styles/speaker-list-styles.scss';

function SpeakerList(props) {

  const {
    actions,
    speaker_list,
    total_speakers,
  } = props;

  useEffect(() => {
    const payload = {
      page: 1,
      length: 30,
    };
    actions.getListSpeakerAction(payload);
  }, []);

  function handleOnPageChange(page) {
    const payload = {
      page: page,
      length: 30,
    };
    actions.getListSpeakerAction(payload);
  };

  return (
    <React.Fragment>
      <Breadcrumb
        data={[
          { link: routes.home, text: getTranslatedText('HOME') },
          { link: routes.speaker.speakerList, text: getTranslatedText('speaker_breadcrumb') },
        ]}
      />
      <List
        id='list-speaker-wrapper'
        dataSource={speaker_list.length !== 0 ? speaker_list : []}
        grid={{
          xxl: 3,
          xl: 3,
          lg: 3,
          md: 3,
          xs: 2,
        }}
        pagination={{
          pageSize: 30,
          total: speaker_list.length !== 0 ? total_speakers : 0,
          onChange: (page) => handleOnPageChange(page)
        }}
        renderItem={speaker => (
          <SpeakerCard
            speaker={speaker}
          />
        )}
      />
    </React.Fragment>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    speaker_list: state.speaker.speaker_list || [],
    total_speakers: state.speaker.total_speaker || 0
  };
};
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    actions: bindActionCreators(
      {
        getListSpeakerAction,
      },
      dispatch,
    ),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SpeakerList);