import React, { Component, Fragment } from 'react';
import {
  Popover,
  Row,
  Col,
  Radio,
  Form,
  Input,
  InputNumber,
  Button,
  DatePicker,
} from 'antd';
import 'antd/dist/antd.css';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { bindActionCreators, compose } from 'redux';
import { get } from 'lodash';

import './BuyInsurance.scss';
import TimeIcon from '../../assets/images/icon_time.svg';
import BookIcon from '../../assets/images/icon_book.svg';
import OwnerIcon from '../../assets/images/icon_owner.svg';
import {
  currencyFormatter,
  formatDurationText,
  getTranslatedText,
} from '../../services/appService';
import {
  buyCourseAction,
  depositAction,
  getCourseDetailAction,
  joinCourseInsurance
} from '../../actions/courses';
import * as types from '../../actions/index';
import { getProfileAction } from '../../actions/profile';

import { routes, toastDuration } from '../../constants';
import { toast } from 'react-toastify';
import { PayPalButton } from 'react-paypal-button-v2';
import Breadcrumb from '../Breadcrumb/Breadcrumb';
import OrderInfo from '../OrderInfo/OrderInfo';

class BuyInsurance extends Component {
  state = {
    radioValue: 'master_card',
    inputDisable: false,
    paypalPay: false,
    join: false,
  };

  componentDidMount() {
    this.props.actions.getProfileAction({
      token: localStorage.getItem(types.TOKEN_KEY),
    });

    let courseId = parseInt(get(this.props, 'match.params.id'), 0);
    this.props.actions.getCourseDetailAction(courseId);
  }

  pay = (wallet, need_purchase, course_id) => {
    if (!window.confirm(getTranslatedText('confirm_buy_insurance'))) return;
    if (wallet < need_purchase){
      toast.error("Your wallet is not enough!")
      setTimeout(() => {
        window.location.href = routes.accountDeposit
      }, toastDuration)
    }
    this.props.actions.joinCourseInsurance(course_id);
  };

  render() {
    const { id } = this.props.match.params;
    const { courseDetail, profile } = this.props;
    const lang = localStorage.getItem('current_lang');

    let wallet = profile.total_price;
    let course_price = courseDetail.price;
    let currency = 'USD';

    return (
      <Fragment>
        <div>
          <Breadcrumb
            data={[
              { link: routes.home, text: getTranslatedText('HOME') },
              {
                link: routes.courses,
                text: getTranslatedText('COURSE'),
              },
              {
                link: routes.courseDetail.replace(':id', id),
                text: getTranslatedText('DETAIL'),
              },
              {
                link: null,
                text: getTranslatedText('INSURANCE'),
              },
            ]}
          />
        </div>
        <div className="OrderInfoContainer">
          <div className="Title">
            {getTranslatedText('your_order_info')}
          </div>
          <div className="OrderInfo">
            <div className="CourseInfo">
              <div>{getTranslatedText('insurance_for_course')}</div>
              <div className="CourseHeader">
                <div className="HeaderText">{(lang == 'vi') ? courseDetail.title_vi : courseDetail.title}</div>
                <div className="LevelPrice">
                  <div className="LevelContainer">
                    <div className="Text">
                      {getTranslatedText('Level')}
                    </div>
                    <div className="Level">{courseDetail.level}</div>
                  </div>
                  <div className="PriceContainer">
                    <div className="Text">
                      {getTranslatedText('Price')}
                    </div>
                    <div className="NumberContainer">
                      <div className="PriceNumber">
                        <span>{currencyFormatter(course_price, true)}</span>
                        <sup className="Currency">{currency}</sup>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/*<div className="Info">*/}
              {/*  <div className="Container">*/}
              {/*    <img alt="time" src={TimeIcon}></img>*/}
              {/*    <div className="Text">*/}
              {/*      {getTranslatedText('duration')}{' '}*/}
              {/*      <span>*/}
              {/*        {formatDurationText(courseDetail.duration)}*/}
              {/*      </span>*/}
              {/*    </div>*/}
              {/*  </div>*/}
              {/*  <div className="Container">*/}
              {/*    <img alt="book" src={BookIcon}></img>*/}
              {/*    <div className="Text">*/}
              {/*      {getTranslatedText('content')}{' '}*/}
              {/*      <span>{courseDetail.total_lesson} videos</span>*/}
              {/*    </div>*/}
              {/*  </div>*/}
              {/*  <div className="Container">*/}
              {/*    <img alt="own" src={OwnerIcon}></img>*/}
              {/*    <div className="Text">*/}
              {/*      {getTranslatedText('Lifetime')}*/}
              {/*    </div>*/}
              {/*  </div>*/}
              {/*</div>*/}
            </div>
            {/*{!this.state.paypalPay ? (*/}
            <div className="WalletInfoContainer">
              <div className="WalletTitle">
                {getTranslatedText('your_wallet')}
              </div>
              <div className="WalletInfo">
                <div className="WalletInfoItems">
                  <div className="Container Remaining">
                    <div className="Text">
                      {getTranslatedText('Balance')}
                    </div>
                    <div className="Number">{currencyFormatter(wallet, true)}</div>
                    <div className="Currency">{currency}</div>
                  </div>
                  <div className="Container PendingOrder">
                    <div className="Text">
                      {getTranslatedText('need_purchase')}
                    </div>
                    <div className="Number">{currencyFormatter(course_price / 10, true)}</div>
                    <div className="Currency">{currency}</div>
                  </div>
                  <div className="Container Missing">
                    <div className="Text">
                      {getTranslatedText('amount_top_up')}
                    </div>
                    <div className="Number">{
                      currencyFormatter(((course_price / 10) - wallet < 0) ? 0 : (course_price / 10) - wallet, true)}
                    </div>
                    <div className="Currency">{currency}</div>
                  </div>
                  {/*<div className="Container Missing">*/}
                  {/*  <div className="Text">*/}
                  {/*    {getTranslatedText('amount_top_up')}*/}
                  {/*  </div>*/}
                  {/*  <div*/}
                  {/*    className="Number">{currencyFormatter(*/}
                  {/*    (course_price - wallet < 0) ? 0 : course_price - wallet,*/}
                  {/*    true)}</div>*/}
                  {/*  <div className="Currency">{currency}</div>*/}
                  {/*</div>*/}

                </div>
                <div className="CTAButton"
                     onClick={() => this.pay(profile.total_price, courseDetail.price / 10, courseDetail.id)}>
                  {getTranslatedText('purchase_now')}
                </div>
                {/*{shouldDeposit ? (*/}
                {/*<Popover*/}
                {/*  placement="bottom"*/}
                {/*  // content={<RenderButtons courseDetail={this.props.courseDetail} />}*/}
                {/*  content={this.renderButtons(*/}
                {/*    this.props.courseDetail,*/}
                {/*    courseDetail.price + courseDetail.price / 10,*/}
                {/*  )}*/}
                {/*  trigger="click"*/}
                {/*  overlayStyle={{ width: '255px' }}*/}
                {/*>*/}
                {/*  */}
                {/*</Popover>*/}
                {/*) : (*/}
                {/*  <div*/}
                {/*    className="CTAButton"*/}
                {/*    onClick={() =>*/}
                {/*      this.pay(shouldDeposit, shouldDepositAmount)*/}
                {/*    }*/}
                {/*  >*/}
                {/*    {getTranslatedText('purchase_now')}*/}
                {/*  </div>*/}
                {/*)}*/}
              </div>
            </div>
          </div>
          {/*<div className="TextNotice">*/}
          {/*  {getTranslatedText('note_after_payment')}*/}
          {/*</div>*/}
        </div>

      </Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    courseDetail: get(state, 'courses.courseDetail', {}),
    profile: get(state, 'profile.data'),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    actions: bindActionCreators(
      {
        buyCourseAction,
        depositAction,
        getProfileAction,
        getCourseDetailAction,
        joinCourseInsurance
      },
      dispatch,
    ),
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(BuyInsurance);
