import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getDetailAudioBookAction, getDetailKidzBookAction } from '../../actions/audio_book';
import { useParams } from 'react-router-dom';
import { TOKEN_KEY, CURRENT_LANG_KEY } from '../../actions/index';
import AccountBreadcrumb from '../../components/AccountBreadcrumb/AccountBreadcrumb';
import PlayIcon from '../../assets/images/icon_play_yellow.svg';
import LockIcon from '../../assets/images/icon_lock.svg';
import moment from 'moment';
import AlertModal from './components/AlertModal/AlertModal';
import AudioPriceCard from '../../components/AudioPriceCard';
import './styles/index.scss';

import Player from './components/AudioPlayer/AudioPlayer';
import ReactPlayer from 'react-player';
import { truncateText } from '../../services/appService';

const AudioBookDetail = ({
  actions,
  loading,
  audioBookDetail,
  user,
  type = "book",
}) => {
  const token = localStorage.getItem(TOKEN_KEY);
  const { id } = useParams();
  const [audioBookPriceVisible, setAudioBookPriceVisible] = useState(
    true,
  );
  const [currentAudio, setCurrentAudio] = useState({});
  const [visible, setVisible] = useState(false);
  const audioList = audioBookDetail?.data?.audios || [];

  useEffect(() => {
    if (type === "book"){
      if (user?.is_license_permanently === 1) {
        setAudioBookPriceVisible(false);
      }
      if (
        user?.is_license_permanently === 0 &&
        moment().isBefore(user?.audio_license)
      ) {
        setAudioBookPriceVisible(false);
      }
    }
    else
      setAudioBookPriceVisible(false);

  }, [user, type]);

  useEffect(() => {
    const payload = {
      token,
      request: {
        book_id: id,
      },
    };
    if (type === "book")
      actions.getDetailAudioBookAction(payload);
    else
      actions.getDetailKidzBookAction(payload);

  }, [type]);

  useEffect(() => {
    if (audioList && !(audioList?.[0]?.lock))
      setCurrentAudio(audioList?.[0]);
  }, [audioBookDetail]);

  const handleOnPlayClick = audio => {
    if (audio?.lock === 1) {
      setVisible(true);
    } else {
      setCurrentAudio(audio);
    }
  };

  return (
    <div>
      <AccountBreadcrumb />
      <div className="w-full max-w-screen-xl mx-auto flex px-2 px-md-3 px-lg-5 px-xl-0 flex-col-reverse md:flex-row  gap-20 pb-40 pt-8 start">
        <div className="w-full lg:w-2/3 flex flex-col items-center">
          <div className="w-full">
            {audioBookDetail?.data?.type === 'course' ? (
              <ReactPlayer
                width={'auto'}
                url={
                  localStorage.getItem(CURRENT_LANG_KEY) === 'vi'
                    ? currentAudio?.link_file_vi
                    : currentAudio?.link_file
                }
                controls
                config={{
                  file: {
                    attributes: {
                      controlsList: 'nofullscreen',
                    },
                  },
                }}
              />
            ) : (
              <Player
                playList={audioList}
                currentAudio={currentAudio}
                setCurrentAudio={setCurrentAudio}
              />
            )}
          </div>
          <div className="w-full px-6 shadow mt-4">
            {audioList?.map(item => (
              <div
                key={item.id}
                className="w-full flex justify-between border-b items-center py-3"
              >
                <div>
                  <p className="text-black">
                    {localStorage.getItem(CURRENT_LANG_KEY) === 'vi'
                      ? item.name_vi
                      : item.name}
                  </p>
                  <p>
                    {moment
                      .duration(item.duration, 'second')
                      .format('hh:mm:ss')}
                  </p>
                </div>
                <div className="pr-8 flex items-center gap-2.5">
                  <button onClick={() => handleOnPlayClick(item)}>
                    <img src={PlayIcon} alt="" />
                  </button>
                  {item.lock === 1 && (
                    <div>
                      <img src={LockIcon} alt="" />
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="w-full lg:w-1/3">
          <div className="w-full space-y-8">
            <h3 className="w-full text-xl uppercase text-darkgrey font-bold">
              {localStorage.getItem(CURRENT_LANG_KEY) === 'vi'
                ? audioBookDetail?.data?.name_vi
                : audioBookDetail?.data?.name}
            </h3>
            <div className="w-full">
              <img
                id="detail_thumbnail"
                className="w-full"
                src={audioBookDetail?.data?.thumbnail}
                alt="thumbnail"
              />
            </div>
            <p className="text-darkgrey" dangerouslySetInnerHTML={{__html: localStorage.getItem(CURRENT_LANG_KEY) === 'vi'
                ? audioBookDetail?.data?.summary_vi
                : audioBookDetail?.data?.summary}}>
            </p>
          </div>
          {audioBookPriceVisible && (
            <div className="w-full mt-20">
              <AudioPriceCard />
            </div>
          )}
        </div>
      </div>
      <AlertModal
        visible={visible}
        onCancel={() => setVisible(false)}
        type={type}
        course={Math.max(user.max_price, user.max_price_english)}
      />
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.profile.data,
    loading: state.audioBook.loading,
    audioBookDetail: state.audioBook.audioBookDetail,
  };
};
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    actions: bindActionCreators(
      {
        getDetailAudioBookAction,
        getDetailKidzBookAction,
      },
      dispatch,
    ),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AudioBookDetail);
