import React, { Component } from 'react';
import './AccountBreadcrumb.scss';
import { Link } from 'react-router-dom';
import ArrowDown from '../../assets/images/icon_grey_arrow_down.svg';
import {
  getTranslatedText,
  getUserFormLocal,
} from '../../services/appService';
import { routes } from '../../constants';
import { Dropdown } from 'antd';

class AccountBreadcrumb extends Component {
  state = {
    currentUser: {},
  };

  checkCurrentUser() {
    if (getUserFormLocal()) {
      this.state.currentUser = getUserFormLocal();
    }
  }

  render() {
    this.checkCurrentUser();

    return (
      <div className="AccountBreadcrumb">
        <div className="Container w-full max-w-screen-xl mx-auto flex flex-col-reverse lg:flex-row lg:px-1.5 xl:px-0">
          <div className="LinkContainer w-full">
            <Link
              to={routes.accountDashboard}
              className={
                window.location.pathname === routes.accountDashboard
                  ? 'ActiveTab'
                  : ''
              }
            >
              <span>{getTranslatedText('dashboard')}</span>
            </Link>

            <div className="profile-menu">
              <Link
                to={routes.accountProfile}
                className={
                  window.location.pathname === routes.accountProfile
                    ? 'ActiveTab'
                    : ''
                }
              >
                <span>Profile</span>
              </Link>
                <img className="dropdown-icon cursor-pointer" alt="arrow down" src={ArrowDown} />
                <div className="dropdown-content bg-gold grid grid-cols-1 shadow-2xl">
                  <Link to={routes.accountProfile}>
                    <p className="text-white uppercase font-semibold text-8 md:text-sm p-1.5 border-b border-white hover:bg-white profile-item">{getTranslatedText('personal_info')}</p>
                  </Link>
                  <Link to={routes.accountWallet}>
                    <p className="text-white uppercase font-semibold text-8 md:text-sm p-1.5 border-b border-white hover:bg-white profile-item">{getTranslatedText('my_wallet')}</p>
                  </Link>
                  <Link to={routes.accumulate}>
                    <p className="text-white uppercase font-semibold text-8 md:text-sm p-1.5 border-b border-white hover:bg-white profile-item">{getTranslatedText('accumulate_text')}</p>
                  </Link>
                  <Link to={routes.accountWithdraw}>
                    <p className="text-white uppercase font-semibold text-8 md:text-sm p-1.5 border-b border-white hover:bg-white profile-item">{getTranslatedText('withdraw')}</p>
                  </Link>
                  <Link to={routes.accountDeposit}>
                    <p className="text-white uppercase font-semibold text-8 md:text-sm p-1.5 border-b border-white hover:bg-white profile-item">{getTranslatedText('deposit')}</p>
                  </Link>
                  <Link to={routes.accountTransferMoney}>
                    <p className="text-white uppercase font-semibold text-8 md:text-sm p-1.5 hover:bg-white profile-item">{getTranslatedText('transfer_money')}</p>
                  </Link>
                </div>
            </div>
            <Link
              to={routes.accountCourses}
              className={
                window.location.pathname === routes.accountCourses
                  ? 'ActiveTab'
                  : ''
              }
            >
              <span>{getTranslatedText('course')}</span>
            </Link>
            <Link
              to={routes.audio_book.list}
              className={
                window.location.pathname === routes.audio_book.list ||
                window.location.pathname.includes('/audio-book/detail')
                  ? 'ActiveTab'
                  : ''
              }
            >
              <span>{getTranslatedText('audio_book')}</span>
            </Link>
            <Link
              to={routes.kidz_book.list}
              className={
                window.location.pathname === routes.kidz_book.list ||
                window.location.pathname.includes('/kidz-book/detail')
                  ? 'ActiveTab'
                  : ''
              }
            >
              <span>{getTranslatedText('kidz_book')}</span>
            </Link>
            <Link
             to={routes.game}
             className={
               window.location.pathname === routes.game
                 ? 'ActiveTab'
                 : ''
             }
            >
             <span>Lucky Spinner</span>
            </Link>

          </div>
          <div className="AccountContainer">
            <div className="Text md:text-sm">{getTranslatedText('hello')}! </div>
            <div className="Fullname md:text-base">
              {this.state.currentUser.name}
            </div>
            <div className="ml-1.5 Username">
              {' '}
              ({this.state.currentUser.code})
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AccountBreadcrumb;
