import React, {useEffect} from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import {
  useParams
} from 'react-router-dom';

import {
  getDetailSpeakerAction,
} from '../../../actions/speakers';

import { 
  Timeline,
} from 'antd';

import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb';

import { routes } from '../../../constants';
import { getTranslatedText } from '../../../services/appService';

import './styles/speaker-view-styles.scss';

function SpeakerView(props) {

  const {
    actions,
    speaker_detail,
  } = props;

  const param = useParams();

  useEffect(() => {
    actions.getDetailSpeakerAction(param.id);
  }, [param.id]);

  return (
    <React.Fragment>
      <Breadcrumb
        data={[
          { link: routes.home, text: getTranslatedText('HOME') },
          { link: routes.speaker.speakerList, text: getTranslatedText('SPEAKER') },
          { link: routes.speaker.speakerView, text: getTranslatedText('PROFILE') },
        ]}
      />
      {speaker_detail !== null && (
        <div id='speaker-view-wrapper'>
          {/* SPEAKER SUMMANY INFORMATION COMPONENT */}
          <div id='summary-speaker-view-wrapper'>
            <div id='avatar-summary-speaker-view-wrapper'>
              <img src={speaker_detail.avatar} alt='' />
            </div>
            <div id='information-summary-speaker-view-wrapper'>
              <p id='header-information-summary-speaker-view-wrapper'>
                {speaker_detail.name.toUpperCase()}
              </p>
              <p id='body-information-summary-speaker-view-wrapper'>
                {speaker_detail.level.toUpperCase()}
              </p>
              <p id='footer-information-summary-speaker-view-wrapper'>
                {speaker_detail.description}
              </p>
            </div>
          </div>
          {/* SPEAKER STUDY INFORMATION */}
          {speaker_detail.educate_process && (
            <div id='study-info-speaker-view-wrapper'>
              <p id='title-study-info-speaker-view-wrapper'>
                {getTranslatedText('about_me')}
              </p>
              <p
                className='item-study-info-speaker-view-wrapper'
              >
                {speaker_detail.educate_process}
              </p>
            </div>
          )}
          {/* SPEAKER WORK INFORMATION */}
          {speaker_detail.teaching_process.length > 0 && (
            <div id='work-info-speaker-view-wrapper'>
              <p id='title-work-info-speaker-view-wrapper'>
                {getTranslatedText('teaching_process')}
              </p>
              <Timeline id='content-work-info-speaker-view-wrapper'>
                {speaker_detail.teaching_process.map(item => (
                  <Timeline.Item>
                    <p className='start-end'>{item.start} - {item.end}</p>
                    <p className='work-place'>{item.location}</p>
                    <p className='description'>{item.description}</p>
                  </Timeline.Item>
                ))}
              </Timeline>
            </div>
          )}
          </div>
      )}
    </React.Fragment>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    speaker_detail: state.speaker.speaker_detail,
    loading: state.speaker.loading,
  };
};
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    actions: bindActionCreators(
      {
        getDetailSpeakerAction,
      },
      dispatch,
    ),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SpeakerView);