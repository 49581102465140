import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import QuoteContainer from '../QuoteContainer/QuoteContainer';
import CourseCardContainer from '../CourseCardContainer/CourseCardContainer';
import Breadcrumb from '../Breadcrumb/Breadcrumb';
import { routes } from '../../constants';
import { getTranslatedText } from '../../services/appService';
import EnglishCourseList from '../../containers/EnglishCourseScreen/EnglishCourseList/EnglishCourseList';

import { enquireScreen } from 'enquire-js';
import BannerPromotion from '../GoldenWeekPromotion/components/BannerPromotion';
import AudioBookBanner from '../../containers/AudioBook/components/AudioBookBanner';

let isMobile;
enquireScreen((b) => {
  isMobile = b;
});

const NOT_BUY_AUDIO = 'Not bought yet'

function CoursePage(props) {
  const {
    displayEventBanner,
    user
  } = props;
  const [slidesToShow, setSlideToShow] = useState(3);

  useEffect(() => {
    if (isMobile) {
      setSlideToShow(1);
    } else {
      setSlideToShow(3);
    }
  }, []);

  return (
    <div>
      <Breadcrumb
        data={[
          { link: routes.home, text: getTranslatedText('HOME') },
          { link: routes.courses, text: getTranslatedText('COURSE') },
        ]}
      />
      <QuoteContainer />
      {!user?.audio_license_expire || user?.audio_license_expire === NOT_BUY_AUDIO ? (
        <div className='mt-3'>
          <AudioBookBanner />
        </div>
      ): null}
      {displayEventBanner && <BannerPromotion />}
      <CourseCardContainer slidesToShow={slidesToShow} />
      <EnglishCourseList />
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.profile?.data || {},
  };
};

CoursePage.propTypes = {
  displayEventBanner: PropTypes.bool,
}

export default connect(mapStateToProps, null)(CoursePage);
