import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { Avatar } from 'antd';
import { routes } from '../../constants';
import { getTranslatedText } from '../../services/appService';
import { WhatsAppOutlined, FacebookOutlined } from '@ant-design/icons';
import './styles.scss';

const toDay = new Date();

function Footer() {
  return (
    <div className='w-full pt-3 pt-md-5 shadow-top mt-5'>
      <div className='w-full bg-white pl-2 pr-1 pb-1 pb-md-4'>
        <div className='w-full max-w-screen-xl mx-auto grid grid-cols-4 gap-5 md:gap-8'>
          <div className='footer-logo'>
            <a href={process.env.REACT_APP_USER_WEBSITE_URL}>
              <img src='/assets/images/footer_logo.png' alt='logo' />
            </a>
          </div>
          <div className='w-full'>
            <Link to={routes.home}>
              <p
                className='text-left text-brown max-w-max uppercase text-8 md:text-base pb-1.5 md:pb-2.5 font-bold'>{getTranslatedText('home')}</p>
            </Link>
            <Link to={routes.courses}>
              <p
                className='text-left text-brown max-w-max uppercase text-8 md:text-base pb-1.5 md:pb-2.5 font-bold'>{getTranslatedText('course')}</p>
            </Link>
            <Link to={routes.evaluate}>
              <p className='text-left text-brown max-w-max uppercase text-8 md:text-base pb-1.5 md:pb-2.5 font-bold'>
                {getTranslatedText('opinions')}
              </p>
            </Link>
            <Link to={routes.how_it_work}>
              <p className='text-left text-brown max-w-max uppercase text-8 md:text-base pb-1.5 md:pb-2.5 font-bold'>
                {getTranslatedText('how_it_work')}
              </p>
            </Link>
            <a href='https://blog.optimagroup.sg' target='_blank'>
              <p className='text-left text-brown max-w-max uppercase text-8 md:text-base pb-1.5 md:pb-2.5 font-bold'>
                BLOG
              </p>
            </a>
          </div>
          <div className='w-full'>
            <p className='text-left text-brown max-w-max uppercase text-8 md:text-base pb-1.5 md:pb-2.5 font-bold'>
              {getTranslatedText('security_privacy')}
            </p>
            <Link to={routes.privacyPolicy}>
              <p className='text-6 md:text-10 pb-1 pb-md-2 text-black'>
                {getTranslatedText('privacy')}
              </p>
            </Link>
            <Link to={routes.termOfService}>
              <p className='text-6 md:text-10 pb-1 pb-md-2 text-black'>
                {getTranslatedText('terms')}
              </p>
            </Link>
            <Link to={routes.operationRegulation}>
              <p className='text-6 md:text-10 pb-1 pb-md-2 text-black'>
                {getTranslatedText('regulation')}
              </p>
            </Link>
          </div>
          <div className='w-full'>
            <p
              className='text-left text-brown max-w-max uppercase text-8 md:text-base pb-1.5 md:pb-2.5 font-bold'>{getTranslatedText('connect_with_us')}</p>
            <p className='text-6 md:text-10 text-black pb-1 pb-md-2'>Email: admin@optimagroup.sg</p>
            <div className='w-full flex items-center gap-1.5 md:gap-5 mt-1'>
              <a href='https://www.facebook.com/OptimaGroupSingapore/' target='_blank' rel='noreferrer'>
                <img
                  className='social-icon'
                  src='/assets/images/logo_fb.png'
                  alt='social-icon'
                />
              </a>
              <a href='https://wa.me/6583857374' target='_blank' rel='noreferrer'>
                <img
                  className='social-icon'
                  src='/assets/images/logo_whatsapp.png'
                  alt='social-icon'
                />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className='w-full bg-gold pl-2 py-2 py-md-3'>
        <div className='w-full max-w-screen-xl mx-auto'>
          <div className='w-20 h-1 border-b border-white mb-1'></div>
          <p className='font-light text-8 md:text-10 text-white'>@{moment(toDay).format('YYYY')} by Optima Group.
            Allright reserved.</p>
        </div>
      </div>
    </div>
  );
}

export default Footer;