import React from 'react';

import './styles/video-card-styles.scss';

function VideoCard(props) {

  const {
    cover,
    title,
    video_link,
    handleOnVideoCardClick,
  } = props;

  return (
    <React.Fragment>
      <div
        className='video-card-wrapper'
        onClick={() => handleOnVideoCardClick(video_link, title)}
      >
        <div className='cover-video-card-wrapper'>
          <img
            className='image-cover-video-card-wrapper'
            src={cover}
            alt=''
          />
        </div>
        <div className='content-video-card-wrapper'>
          <p>{title}</p>
        </div>
      </div>
    </React.Fragment>
  );
}

export default VideoCard;