import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  getPromotionAction,
  getListSpecialOption,
} from '../../../actions/accumulate';
import { List, Avatar, Modal } from 'antd';
import GiftCard from '../component/GiftCard';
import ComfirmExchangePrevilegeModal from '../component/ComfirmExchangePrevilegeModal';

import '../styles/privilegestyle.scss';
import { getTranslatedText } from '../../../services/appService';

function PrivilegeContainer(props) {
  const { actions, promotions, specialOptions, memberRank } = props;

  const [renderType, setRenderType] = useState('gift');
  const [modalVisible, setModalVisible] = useState(false);
  const [currentPromotion, setCurrentPromotion] = useState({});

  useEffect(() => {
    if (memberRank) {
      actions.getPromotionAction({
        token: localStorage.getItem('token'),
        rank: memberRank,
      });
      actions.getListSpecialOption({
        token: localStorage.getItem('token'),
        rank: memberRank,
      });
    }
  }, [memberRank]);

  const handleOpenModal = item => {
    let currentData = {
      promotionId: item.id,
      cover: item.image,
      giftName: item.title,
      giftScore: item.point,
    };
    setCurrentPromotion(currentData);
    setModalVisible(true);
  };

  function renderTabPanel(renderType) {
    switch (renderType) {
      case 'gift':
        return (
          <React.Fragment>
            <List
              grid={{
                gutter: 16,
                xs: 1,
                sm: 1,
                md: 2,
                lg: 2,
              }}
              dataSource={promotions}
              renderItem={item => (
                <List.Item onClick={() => handleOpenModal(item)}>
                  <GiftCard
                    rank={item.rank}
                    memberRank={memberRank}
                    cover={item.image}
                    giftName={item.title}
                    giftScore={item.point}
                  />
                </List.Item>
              )}
            />
          </React.Fragment>
        );
      case 'other':
        return (
          <React.Fragment>
            <List
              dataSource={specialOptions}
              renderItem={item => (
                <List.Item>
                  <List.Item.Meta
                    avatar={<Avatar size={52} src={item.icon} />}
                    title={<p>{item.title.toUpperCase()}</p>}
                    description={item.content}
                  />
                </List.Item>
              )}
            />
          </React.Fragment>
        );
    }
  }

  return (
    <React.Fragment>
      <div id="privilege-container">
        <div id="privilege-container-header">
          <img
            alt=""
            src="../../../assets/images/privilege-icon.png"
          />
          <p>{getTranslatedText('user_promotion')}</p>
        </div>
        <div id="privilege-container-body">
          {memberRank === 'member' ? (
            <div id="body-button-container-member">
              <button onClick={() => setRenderType('gift')}>
                {getTranslatedText('button_gift')}
              </button>
              <button onClick={() => setRenderType('other')}>
                {getTranslatedText('button_other_option')}
              </button>
            </div>
          ) : (
            <React.Fragment>
              {memberRank === 'gold' ? (
                <div id="body-button-container-gold">
                  <button onClick={() => setRenderType('gift')}>
                    {getTranslatedText('button_gift')}
                  </button>
                  <button onClick={() => setRenderType('other')}>
                    {getTranslatedText('button_other_option')}
                  </button>
                </div>
              ) : (
                <div id="body-button-container-platinum">
                  <button onClick={() => setRenderType('gift')}>
                    {getTranslatedText('button_gift')}
                  </button>
                  <button onClick={() => setRenderType('other')}>
                    {getTranslatedText('button_other_option')}
                  </button>
                </div>
              )}
            </React.Fragment>
          )}

          <div id="body-content">{renderTabPanel(renderType)}</div>
          <Modal
            className="custom-modal"
            maskClosable
            destroyOnClose={true}
            visible={modalVisible}
            onCancel={() => setModalVisible(false)}
            footer={null}
          >
            <ComfirmExchangePrevilegeModal
              promotionId={currentPromotion.promotionId}
              cover={currentPromotion.cover}
              giftName={currentPromotion.giftName}
              giftScore={currentPromotion.giftScore}
              setModalVisible={setModalVisible}
            />
          </Modal>
        </div>
      </div>
    </React.Fragment>
  );
}

const mapStateToProps = state => {
  return {
    promotions: state.accumulate.promotions || [],
    specialOptions: state.accumulate.specialOptions || [],
  };
};

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(
      {
        getPromotionAction,
        getListSpecialOption,
      },
      dispatch,
    ),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PrivilegeContainer);
