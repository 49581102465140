import React, { useState, useEffect } from 'react';
import { Row, Col } from 'antd';
import MDReactComponent from 'markdown-react-js';
import { CURRENT_LANG_KEY } from '../../../actions';
import 'antd/dist/antd.css';
import '../RuleGame/RuleGame.scss';

const gameRuleContent_en = `###### **CONDITIONS OF PARTICIPATION**
* As a member of OPTIMA GROUP and does not violate any provision on OPTIMA GROUP.

###### **RULES**
* Each OPTIMA GROUP member who has 1 sale will receive a lucky draw.
* When the lucky spin starts, the member has a chance to receive the corresponding bonuses on the spin.

###### **REGULATIONS**
* Members are not allowed to use the software to disrupt and adjust the results of the Lucky Draw. If violating this regulation and discovered, members will be permanently locked account at OPTIMA GROUP.
* The amount of member drawn will be deposited directly into that member's wallet account.
* When entering the member gift box, please fill in the required information so that Optima Group can send gifts directly to members.`;

const gameRuleContent_vn = `###### **ĐIỀU KIỆN THAM GIA**
* Là thành viên của OPTIMA GROUP và không vi phạm bất kỳ quy định nào trên OPTIMA GROUP.

###### **THỂ LỆ**
* Mỗi thành viên OPTIMA GROUP khi có 1 Sale phát sinh sẽ được nhận 1 lượt quay may mắn.
* Khi Vòng quay may mắn bắt đầu khởi động, thành viên có cơ hội nhận được những số tiền thưởng hoặc phần quà tương ứng trên vòng quay.

###### **QUY ĐỊNH**
* Thành viên không được sử dụng phần mềm để gây rối, điều chỉnh kết quả của Vòng quay may mắn. Nếu vi phạm quy định này và bị phát hiện, thành viên sẽ bị khóa tài khoản vĩnh viễn tại OPTIMA GROUP.
* Số tiền thành viên đã quay trúng thưởng sẽ được nạp trực tiếp vào tài khoản Ví của thành viên đó.
* Khi quay vào ô quà tặng thành viên vui lòng điền đúng thông tin yêu cầu để Optima Group có thể gửi quà tặng đến trực tiếp thành viên.`;

const RuleGame = () => {
  const [currentLanguage, setCurrentLanguage] = useState('');

  useEffect(() => {
    let current_language =
      window.localStorage.getItem(CURRENT_LANG_KEY) || 'en';
    setCurrentLanguage(current_language);
  }, []);
  return (
    <React.Fragment>
      <Row>
        <Col span={24} className="rule_content__container">
          {currentLanguage === 'en' ? (
            <MDReactComponent text={gameRuleContent_en} />
          ) : (
            <MDReactComponent text={gameRuleContent_vn} />
          )}
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default RuleGame;
