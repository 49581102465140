import * as actionTypes from '../actions/index';

const initialState = {
  total_speaker: 0,
  speaker_list: [],
  speaker_detail: null,
  loading: false,
  error: null,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET_LIST_SPEAKER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    
    case actionTypes.GET_LIST_SPEAKER_SUCCESS:
      return {
        ...state,
        loading: false,
        speaker_list: action.payload.data.data,
        total_speaker: action.payload.data.total
      }

    case actionTypes.GET_LIST_SPEAKER_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      }

    case actionTypes.GET_DETAIL_SPEAKER_REQUEST:
      return {
        ...state,
        loading: true,
      }
    
    case actionTypes.GET_DETAIL_SPEAKER_SUCCESS:
      return {
        ...state,
        loading: false,
        speaker_detail: action.payload.data,
      }

    case actionTypes.GET_DETAIL_SPEAKER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      }
    
    default:
      return state;
  };
};