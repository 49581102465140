import React, { useState, useEffect } from 'react';
import { Row, Col, Button } from 'antd';
import { connect } from 'react-redux';
import 'antd/dist/antd.css';
import { Link } from 'react-router-dom';
import { routes } from '../../constants';
import './HomePage.scss';
import Carousel from '../Carousel/Carousel';
import QuoteContainer from '../QuoteContainer/QuoteContainer';
import CourseCardContainer from '../CourseCardContainer/CourseCardContainer';
import { getTranslatedText } from '../../services/appService';
import EnglishCourseList from '../../containers/EnglishCourseScreen/EnglishCourseList/EnglishCourseList';
import PartnerList from '../../containers/PartnerScreen/PartnerList';
import { enquireScreen } from 'enquire-js';
import AudioBookBanner from '../../containers/AudioBook/components/AudioBookBanner';
import audioBookBanner from '../../assets/images/audioBookBanner.png';
import hinhAsiaInsure from '../../assets/images/hinh_asia_insure.jpg';

let isMobile;
enquireScreen((b) => {
  isMobile = b;
});

const NOT_BUY_AUDIO = 'Not bought yet'

function HomePage({ user }) {

  const [slidesToShow, setSlideToShow] = useState(3);

  useEffect(() => {
    if (isMobile) {
      setSlideToShow(1);
    } else {
      setSlideToShow(3);
    }
  }, []);
  
  const infoCarouselData = [
    {
      icon: 'icon_plane.svg',
      title: getTranslatedText('VISION'),
      subtitle: '',
      description: getTranslatedText('vision_detail'),
      image_url: 'info_carousel_1.png',
    },
    {
      icon: 'icon_link.svg',
      title: getTranslatedText('MISSION'),
      subtitle: '',
      description: getTranslatedText('mission_detail'),
      image_url: 'info_carousel_1.png',
    },
    {
      icon: 'icon_link.svg',
      title: getTranslatedText('core_values'),
      subtitle: '',
      description: getTranslatedText('core_value_detail'),
      image_url: 'info_carousel_1.png',
    },
  ];

  return (
    <div>
      {/*<div className="Video">*/}
      {/*  <iframe*/}
      {/*    title="home-video"*/}
      {/*    src="https://player.vimeo.com/video/362199467"*/}
      {/*    width="640"*/}
      {/*    height="360"*/}
      {/*    frameBorder="0"*/}
      {/*    allow="autoplay; fullscreen"*/}
      {/*    allowFullScreen*/}
      {/*  />*/}
      {/*</div>*/}

      <div className="InfoCarousel">
        <Carousel title="Carousel">
          {infoCarouselData.map((infoCarouselItem, index) => (
            <div className="InfoCardContainer" key={index}>
              <div className="InfoText">
                <div className="Icon">
                  <img
                    src={`/assets/images/${infoCarouselItem.icon}`}
                    alt="icon"
                  />
                </div>
                <div className="Title">{infoCarouselItem.title}</div>
                <div className="Subtitle">
                  {infoCarouselItem.subtitle}
                </div>
                <div className="Description">
                  {infoCarouselItem.description}
                </div>
              </div>
              <div className="Image">
                <img
                  src={`/assets/images/${infoCarouselItem.image_url}`}
                  alt="info carousel"
                />
              </div>
            </div>
          ))}
        </Carousel>
      </div>

      <QuoteContainer />
      {/* <LoginGame /> */}
      {!user?.audio_license_expire || user?.audio_license_expire === NOT_BUY_AUDIO ? (
        <div className='mt-3'>
          <AudioBookBanner />
        </div>
      ): null}
      <CourseCardContainer
        slidesToShow={slidesToShow}
      />
      <EnglishCourseList />
      <PartnerList />
    </div>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.profile?.data || {},
  };
};

export default connect(mapStateToProps, null)(HomePage);
