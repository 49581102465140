import React, { useEffect, useRef } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { getListAudioBookAction } from '../../actions/audio_book';
import { submitActiveCode } from '../../actions/profile';

import './styles/index.scss';
// import AccountBreadcrumb from '../../components/AccountBreadcrumb/AccountBreadcrumb';
import { getTranslatedText } from '../../services/appService';
import { Link } from 'react-router-dom';
import { routes } from '../../constants';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import AccountBreadcrumb from '../../components/AccountBreadcrumb/AccountBreadcrumb';

const InputKeyActive = props => {
  const { actions } = props;
  useEffect(() => {

  }, []);

  const inputRef = useRef()

  const activeCode = () => {
    let val = inputRef.current.value;

    actions.submitActiveCode(val)
  }

  return (
    <div>
      <AccountBreadcrumb/>
      <div className='w-full max-w-screen-xl mx-auto'>
        <div className='w-full space-y-10 pt-12 mb-24'>
          <h4 className='font-bold px-4'>{getTranslatedText('active_your_course')}</h4>
          <p className='px-4'>{getTranslatedText('insert_code_below')}</p>
          <input type='text' ref={inputRef} className='form-control mx-4' placeholder='' />
          <button className='btn-cta mx-4' onClick={() => activeCode()}>{getTranslatedText('active_license')}</button>
          <p className='mx-4' style={{fontStyle: "italic"}}>{getTranslatedText('key_active_note')}
            <a href='mailto:admin@optimagroup.sg'>admin@optimagroup.sg</a>
          </p>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    success: state.profile.success,
    loading: state.profile.loading
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    actions: bindActionCreators(
      {
        submitActiveCode
      },
      dispatch,
    ),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(InputKeyActive);
