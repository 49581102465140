import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import {
  Result,
  Tabs,
} from 'antd';

import { filter } from 'lodash';

import './AccountCoursePage.scss';
import AccountBreadcrumb from '../AccountBreadcrumb/AccountBreadcrumb';
import CourseCard from '../CourseCard/CourseCard';
import {
  getCoursesAction,
  getUserCoursesAction,
  buyCourseAction,
} from '../../actions/courses';
import { getProfileAction } from '../../actions/profile';
import { getTranslatedText } from '../../services/appService';
import EnglishCourseCard from '../EnglishCourseCard/EnglishCourseCard';
import * as types from '../../actions/index';

const {
  TabPane
} = Tabs;

class AccountCoursePage extends Component {
  componentWillMount() {
    this.props.actions.getUserCoursesAction();
    this.props.actions.getProfileAction({
      token: localStorage.getItem(types.TOKEN_KEY),
    });
  }

  render() {
    const { userCourses, courses } = this.props.courses
    const { buy = [], not_buy = [], buy_english = [], not_buy_english = [] } = userCourses;

    return (
      <div>
        <AccountBreadcrumb />
        <div className='account-course-wrapper'>
          <p className='title-account-course-wrapper'>{getTranslatedText('improve_skill_course')}</p>
          <Tabs
            defaultActiveKey='1'
          >
            <TabPane
              tab={getTranslatedText('your_course')}
              key='1'
            >
              {buy.length > 0 ? (
                <div className="CoursesContainer">
                  {buy.map((course, index) => (
                    <CourseCard
                      courseCarouselItem={course}
                      key={index}
                      isBought={true}
                    />
                  ))}
                </div>
              ) : (
                <Result
                  title='No course'
                  status='info'
                />
              )}
            </TabPane>
            <TabPane
              tab={getTranslatedText('other_courses')}
              key='2'
            >
              {not_buy.length > 0 ? (
                <div className="CourseBlock">
                  {/* <div className="CourseName">
                    {getTranslatedText('other_courses')}
                  </div> */}
                  <div className="CoursesContainer">
                    {not_buy.map((course, index) => (
                      <CourseCard courseCarouselItem={course} key={index} />
                    ))}
                  </div>
                </div>
              ) : (
                <Result
                  title={getTranslatedText('no_courses')}
                  status='info'
                />
              )}
            </TabPane>
          </Tabs>
        </div>
        <div className='account-course-wrapper'>
          <p className='title-account-course-wrapper'>{getTranslatedText('english_course')}</p>
          <Tabs
            defaultActiveKey='1'
          >
            <TabPane
              tab={getTranslatedText('your_course')}
              key='1'
            >
              {buy_english.length > 0 ? (
                <div className="CoursesContainer">
                  {buy_english.map((course, index) => (
                    <EnglishCourseCard
                      coursesItemData={course}
                      hideButton={true}
                      isBuy={true}
                    />
                  ))}
                </div>
              ) : (
                <Result
                  title={getTranslatedText('no_courses')}
                  status='info'
                />
              )}
            </TabPane>
            <TabPane
              tab={getTranslatedText('other_courses')}
              key='2'
            >
              {not_buy_english.length > 0 ? (
                <div className="CourseBlock">
                  <div className="CoursesContainer">
                    {not_buy_english.map((course, index) => (
                      <EnglishCourseCard
                        coursesItemData={course}
                        hideButton={true}
                      />
                    ))}
                  </div>
                </div>
              ) : (
                <Result
                  title='No course'
                  status='info'
                />
              )}
            </TabPane>
          </Tabs>
        </div>
        {/* <div className="CourseBlock">
          <div className="CourseName">
            {getTranslatedText('your_course')}
          </div>
          <div className="CoursesContainer">
            {buy.map((course, index) => (
              <CourseCard
                courseCarouselItem={course}
                key={index}
                isBought={true}
              />
            ))}
          </div>
        </div>

        {not_buy.length > 0 && (
          <div className="CourseBlock">
            <div className="CourseName">
              {getTranslatedText('other_courses')}
            </div>
            <div className="CoursesContainer">
              {not_buy.map((course, index) => (
                <CourseCard courseCarouselItem={course} key={index} />
              ))}
            </div>
          </div>
        )} */}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    courses: state.courses,
    userCourses: state.userCourses,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    actions: bindActionCreators(
      { getCoursesAction, getUserCoursesAction, buyCourseAction, getProfileAction },
      dispatch,
    ),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AccountCoursePage);
