import axios from 'axios';
import {
  BASE_URL,
  GET_LIST_EVALUATE_FAILURE,
  GET_LIST_EVALUATE_REQUEST,
  GET_LIST_EVALUATE_SUCCESS,
  GET_LIST_FEATURE_EVALUATE_FAILURE,
  GET_LIST_FEATURE_EVALUATE_REQUEST,
  GET_LIST_FEATURE_EVALUATE_SUCCESS,
} from './index';

export function getListEvaluate(payload) {
  return dispatch => {
    dispatch(getListEvaluateRequest());
    axios
      .get(`${BASE_URL}/guest/member-evaluate`, {params: payload})
      .then(response => {
        dispatch(getListEvaluateSuccess(response.data))
      })
      .catch(error => {
        dispatch(getListEvaluateFailure(error.message))
      });
  };
};

function getListEvaluateRequest() {
  return {
    type: GET_LIST_EVALUATE_REQUEST,
  };
};

function getListEvaluateSuccess(payload) {
  return {
    type: GET_LIST_EVALUATE_SUCCESS,
    payload: payload,
  };
};

function getListEvaluateFailure(payload) {
  return {
    type: GET_LIST_EVALUATE_FAILURE,
    payload: payload,
  };
};

export function getListFeatureEvaluate() {
  return dispatch => {
    dispatch(getListFeatureEvaluateRequest());
    axios
      .get(`${BASE_URL}/guest/member-evaluate`, {params: {feature: 1}})
      .then(response => {
        dispatch(getListFeatureEvaluateSuccess(response.data))
      })
      .catch(error => {
        dispatch(getListFeatureEvaluateFailure(error.message))
      });
  };
};

function getListFeatureEvaluateRequest() {
  return {
    type: GET_LIST_FEATURE_EVALUATE_REQUEST,
  };
};

function getListFeatureEvaluateSuccess(payload) {
  return {
    type: GET_LIST_FEATURE_EVALUATE_SUCCESS,
    payload: payload,
  };
};

function getListFeatureEvaluateFailure(payload) {
  return {
    type: GET_LIST_FEATURE_EVALUATE_FAILURE,
    payload: payload,
  };
};