import React, { Component } from 'react';
import './MyProfile.scss';
import EditIcon from '../../assets/images/icon_edit.svg';
import DefaultUserAvatar from '../../assets/images/user_default_avatar.png';
import {
  getTranslatedText,
  getUserFormLocal,
} from '../../services/appService';
import { USER_WEBSITE_URL } from '../../actions/index';
import { routes } from '../../constants';
import { toast } from 'react-toastify';
import icon_copy from "../../assets/images/icon_copy.png"

class MyProfile extends Component {
  state = {
    isLogined: false,
    currentUser: {}
  };

  checkCurrentUser() {
    if (getUserFormLocal()) {
      this.state.isLogined = true;
      this.state.currentUser = getUserFormLocal();
    }
  }

  copyReferralLink(link) {
    navigator.clipboard.writeText(link);
    toast.success("Copied!");
  }

  render() {
    this.checkCurrentUser();

    return (
      <div className="MyProfile max-w-screen-xl mx-auto">
        <div className="Title">{getTranslatedText('my_wallet')}</div>
        <div className="Profile">
          <img
            className="EditIcon"
            alt="edit"
            src={EditIcon}
            onClick={this.props.toggleEditProfileForm}
          ></img>
          <div className="Avatar">
            <img
              className="Photo"
              alt="avatar"
              src={this.state.currentUser.avatar || DefaultUserAvatar}
            ></img>
            <div>{this.state.currentUser.name}</div>
          </div>
          <div className="GroupProfile">
            <div className="Fullname">
              <div className="Text">
                {getTranslatedText('full_name')}
              </div>
              <div className="Data">
                {this.state.currentUser.name}
              </div>
            </div>
            <div className="Email">
              <div className="Text">Email</div>
              <div className="Data">
                {this.state.currentUser.email}
              </div>
            </div>
            <div className="PhoneNumber">
              <div className="Text">
                {getTranslatedText('your_phone')}
              </div>
              <div className="Data">
                {this.state.currentUser.phone}
              </div>
            </div>
            <div className="Country">
              <div className="Text">
                {getTranslatedText('Country')}
              </div>
              <div className="Data">
                {this.state.currentUser.country}
              </div>
            </div>

            <div className="ReferralCode">
              <div className="Text">
                Username
              </div>
              <div className="Data">
                {this.state.currentUser.code}
              </div>
            </div>
            {this.state.currentUser.disable_ref ? null :
              <div className="Password">
                <div className="Text" style={{ position: "relative" }}>
                  {getTranslatedText('ref_link')}
                  <span style={{ position: "absolute", right: "5px", cursor: "pointer" }}
                        onClick={() => this.copyReferralLink(`${USER_WEBSITE_URL}${routes.signin}?refUser=${this.state.currentUser.code}`)}>
                  <img src={icon_copy} width="22px"/>
                </span>
                </div>
                <div className="Data">
                  {`${USER_WEBSITE_URL}${routes.signin}?refUser=${this.state.currentUser.code}`}
                </div>
              </div>
            }
            <div className="Sponsor">
              <div className="Text">
                {getTranslatedText('your_ref')}
              </div>
              <div className="Data">
                {this.state.currentUser.presenter_code}
              </div>
            </div>
          </div>
          <div className="GroupProfile">
            <div className="Sponsor">
              <div className="Text">
                {getTranslatedText('id_card')}
              </div>
              <div className="Data">
                {this.state.currentUser.id_card}
              </div>
            </div>
            <div className="Sponsor">
              <div className="Text">
                {getTranslatedText('image_of')}{' '}
                {getTranslatedText('front_cmnd')}
              </div>
              <img src={(this.state.currentUser.front_id_card)? this.state.currentUser.front_id_card : ""} alt="" width="50%" />
            </div>
            <div className="Sponsor">
              <div className="Text">
                {getTranslatedText('image_of')}{' '}
                {getTranslatedText('back_cmnd')}
              </div>
              <img src={(this.state.currentUser.back_id_card)? this.state.currentUser.back_id_card : ""} alt="" width="50%" />
            </div>
            <div className="Sponsor">
              <div className="Text">
                {getTranslatedText('address')}
              </div>
              <div className="Data">
                {this.state.currentUser.address}
              </div>
            </div>
            <div className="Sponsor">
              <div className="Text">
                {getTranslatedText('tax_code')}
              </div>
              <div className="Data">
                {this.state.currentUser.tax_code}
              </div>
            </div>
            <div className="Sponsor">
              <div className="Text">
                {getTranslatedText('bank_number')}
              </div>
              <div className="Data">
                {this.state.currentUser.bank_account}
              </div>
            </div>
            <div className="Sponsor">
              <div className="Text">
                {getTranslatedText('bank_name')}
              </div>
              <div className="Data">
                {this.state.currentUser.bank_name}
              </div>
            </div>
            <div className="Sponsor">
              <div className="Text">
                {getTranslatedText('bank_full_name')}
              </div>
              <div className="Data">
                {this.state.currentUser.bank_full_name}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default MyProfile;
