import React from 'react';
import PropTypes from 'prop-types';
import { getTranslatedText } from '../../../../../../services/appService';

function SellInfo(props) {
  const {
    containerClassName = "bg-gold-50 bg-opacity-50 rounded-2xl w-60 h-8 mx-auto flex items-center justify-center divide-x divide-grey -mt-6 md:-mt-12 lg:-mt-10 mb-2",
    numberSize,
    textSize = "text-8 md:text-xs",
    soldNum = 0,
    remainNum = 0,
    total,
  } = props;

  return (
    <div className={containerClassName}>
      {soldNum < total ? (
        <>
          <p className={`text-brown font-semibold pr-2 ${numberSize}`}>
            <span className={`text-black ${textSize}`}>{getTranslatedText('event_sold_title')}</span> {soldNum}
          </p>
          <p className={`text-brown font-semibold pl-2 ${numberSize}`}>
            <span className={`text-black ${textSize}`}>{getTranslatedText('event_remain_title')}</span> {remainNum}
          </p>
        </>
      ) : (
        <p className="text-red-600 font-bold text-8 md:text-xs">
          {getTranslatedText('event_sold_out_title')}
        </p>
      )}
    </div>
  );
};

SellInfo.propTypes = {
  containerClassName: PropTypes.string,
  numberSize: PropTypes.string,
  textSize: PropTypes.string,
  soldNum: PropTypes.number.isRequired,
  remainNum: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
};

export default SellInfo;