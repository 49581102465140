import React from 'react';
import PropTypes from 'prop-types';
import { currencyFormatter } from '../../../../../../services/appService';

function PromotionPrice(props) {
  const {
    containerClassName = "bg-gold rounded-xl max-w-max px-7 py-1.5 flex items-center gap-2 mb-3 absolute -top-8 md:-top-14 left-1/4 md:left-2/5",
    discountPrice,
    stockPrice,
    total,
    soldNum
  } = props;

  return (
    <div className={total <= soldNum ? 'bg-gold rounded-xl max-w-max px-8 py-1.5 flex items-center gap-2 mb-3 absolute -top-8 md:-top-14 left-1/4 md:left-2/5' : containerClassName}>
      {total <= soldNum ? (
        <p className="text-white text-sm md:text-md lg:text-xl">$ {currencyFormatter(stockPrice, true)}</p>
      ) : (
        <>
          <p className="text-red-600 font-bold text-sm md:text-md lg:text-xl">$ {currencyFormatter(discountPrice, true)}</p>
          <p className="text-white text-8 line-through md:text-10 lg:text-md">$ {currencyFormatter(stockPrice, true)}</p>
        </>
      )}
    </div>
  );
};

PromotionPrice.propTypes = {
  containerClassName: PropTypes.string,
  discountPrice: PropTypes.number.isRequired,
  stockPrice: PropTypes.number.isRequired,
  soldNum: PropTypes.number,
  total: PropTypes.number,
};

export default PromotionPrice;