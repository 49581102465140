import React, { useState, useEffect } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Select, Drawer, Dropdown } from 'antd';
import { getTranslatedText, getUserFormLocal, clearLocalStorage } from '../../services/appService';
import './styles.scss';
import ExtendMenu from '../ExtendMenu';
import { routes } from '../../constants';
import * as types from '../../actions';

const { Option } = Select;

function Header() {
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [currentPath, setCurrentPath] = useState();
  const [currentUser, setCurrentUser] = useState();
  const [isLogined, setIsLogined] = useState(false);
  const [currentLanguage, setCurrentLanguage] = useState();

  const history = useHistory();
  const location = useLocation();

  function checkCurrentUser() {
    let currentUser = getUserFormLocal();
    if (currentUser) {
      setCurrentUser(currentUser);
      setIsLogined(true);
    }
    ;
  };

  useEffect(() => {
    checkCurrentUser();
    let current_language = window.localStorage.getItem(types.CURRENT_LANG_KEY) || 'en';
    setCurrentLanguage(current_language);
  }, []);

  useEffect(() => {
    setCurrentPath(location.pathname);
  }, [location]);

  function doLogout() {
    clearLocalStorage();
  };

  function selectLang(value) {
    localStorage.setItem(types.CURRENT_LANG_KEY, value);
    window.location.reload();
  };

  return (
    <div className='w-full lg:shadow-xl'>
      <div className='w-full max-w-screen-xl mx-auto'>
        <div
          className='w-full grid grid-cols-3 lg:flex lg:justify-between lg:items-center gap-x-16 lg:gap-x-0  shadow-xl lg:shadow-none py-2.5 pl-4 pr-3 pl-lg-0 pr-lg-0'>
          <div className='logo-wrap aspect-w-4 aspect-h-3 lg:aspect-none'>
            <a href={process.env.REACT_APP_USER_WEBSITE_URL}>
              <img className='lg:object-cover object-fill' src='/assets/images/logo.png' alt='logo' />
            </a>
          </div>
          <div className='col-span-2 flex lg:flex-col lg:gap-5 items-center'>
            <div className='w-full flex items-center justify-end'>
              <Link to={routes.key_active}>
                <span
                  className='key-active'>
                  Key active
                </span>
              </Link>
              <Link to={routes.document.documentList}>
                <span
                  className='flex gap-1 items-center bg-lightgrey font-light text-gold text-8 md:text-sm px-3 py-1.5 rounded-lg download'>
                  <img src='/speaker/small_download_icon.png' alt='icon-download' />
                  Download
                </span>
              </Link>
              <Select
                className='lang-selector'
                value={currentLanguage}
                onChange={(value) => selectLang(value)}
              >
                <Option className='lang-selector-item' value='en'>
                  {getTranslatedText('lang_en')}
                </Option>
                <Option className='lang-selector-item' value='vi'>
                  {getTranslatedText('lang_vi')}
                </Option>
              </Select>
            </div>
            <div className='w-full hidden lg:flex items-center gap-2.5'>
              <Link to={routes.home}>
                <p
                  className={currentPath === routes.home ? 'text-left text-brown border-b-2 border-brown uppercase pb-1.5 pt-3 pr-1 font-bold text-base' : 'text-left text-black uppercase pb-1.5 pt-3 pr-1 font-bold text-base'}>{getTranslatedText('home')}</p>
              </Link>
              <Link to={routes.courses}>
                <p
                  className={currentPath === routes.courses ? 'text-left text-brown border-b-2 border-brown uppercase pb-1.5 pt-3 pr-1 font-bold text-base' : 'text-left text-black uppercase pb-1.5 pt-3 pr-1 font-bold text-base'}>{getTranslatedText('course')}</p>
              </Link>
              <Link to={routes.speaker.speakerList}>
                <p
                  className={currentPath === routes.speaker.speakerList ? 'text-left text-brown border-b-2 border-brown uppercase pb-1.5 pt-3 pr-1 font-bold text-base' : 'text-left text-black uppercase pb-1.5 pt-3 pr-1 font-bold text-base'}>{getTranslatedText('speaker')}</p>
              </Link>
              <Link to={routes.audio_book.list}>
                <p
                  className={currentPath === routes.audio_book.list ? 'text-left text-brown border-b-2 border-brown uppercase pb-1.5 pt-3 pr-1 font-bold text-base' : 'text-left text-black uppercase pb-1.5 pt-3 pr-1 font-bold text-base'}>{getTranslatedText('audio_book')}</p>
              </Link>
              <Link to={routes.kidz_book.list}>
                <p
                  className={currentPath === routes.kidz_book.list ? 'text-left text-brown border-b-2 border-brown uppercase pb-1.5 pt-3 pr-1 font-bold text-base' : 'text-left text-black uppercase pb-1.5 pt-3 pr-1 font-bold text-base'}>{getTranslatedText('kidz_book')}</p>
              </Link>
              <Link to={routes.evaluate}>
                <p
                  className={currentPath === routes.evaluate ? 'text-left text-brown border-b-2 border-brown uppercase pb-1.5 pt-3 pr-1 font-bold text-base' : 'text-left text-black uppercase pb-1.5 pt-3 pr-1 font-bold text-base'}>
                  {getTranslatedText('opinions')}
                </p>
              </Link>
              <Link to={routes.how_it_work}>
                <p
                  className={currentPath === routes.how_it_work ? 'text-left text-brown border-b-2 border-brown uppercase pb-1.5 pt-3 pr-1 font-bold text-base' : 'text-left text-black uppercase pb-1.5 pt-3 pr-1 font-bold text-base'}>
                  {getTranslatedText('how_it_work')
                  }</p>
              </Link>
              <Link to={routes.contact}>
                <p
                  className={currentPath === routes.contact ? 'text-left text-brown border-b-2 border-brown uppercase pb-1.5 pt-3 pr-1 font-bold text-base' : 'text-left text-black uppercase pb-1.5 pt-3 pr-1 font-bold text-base'}>{getTranslatedText('contact')}</p>
              </Link>
              {isLogined ? (
                <div className='flex items-center gap-1.5'>
                  <div className='w-12 h-12 rounded-full overflow-hidden'>
                    <img className='w-full h-full' src={currentUser?.avatar || '/assets/images/default_avatar.png'}
                         alt='default_avatar' />
                  </div>
                  <Dropdown
                    overlay={
                      <div className='bg-white px-3 pb-3'>
                        <Link to={routes.accountDashboard}>
                          <div
                            className='text-black font-semibold text-10 md:text-sm pt-2.5 pb-2 border-b border-grey hover:text-gold'>{getTranslatedText('dashboard')}</div>
                        </Link>
                        <Link to={routes.accountProfile}>
                          <div
                            className='text-black font-semibold text-10 md:text-sm pt-2.5 pb-2 border-b border-grey hover:text-gold'>{getTranslatedText('profile')}</div>
                        </Link>
                        <Link to={routes.accountCourses}>
                          <div
                            className='text-black font-semibold text-10 md:text-sm pt-2.5 pb-2 border-b border-grey hover:text-gold'>{getTranslatedText('my_course')}</div>
                        </Link>
                        <div
                          className='text-black font-semibold text-10 md:text-sm pt-2.5 pb-2 border-b border-grey cursor-pointer hover:text-gold'
                          onClick={() => doLogout()}>
                          {getTranslatedText('logout')}
                        </div>
                      </div>
                    }
                  >
                    <button>
                      <img src='/assets/images/icon_arrow_down_grey.svg' alt='dropdown-icon' />
                    </button>
                  </Dropdown>
                </div>
              ) : (
                <Link to={routes.signin}>
                  <div className='flex items-center'>
                    <img className='w-9' src='/assets/images/default_avatar.png' alt='default_avatar' />
                  </div>
                </Link>
              )}
            </div>
          </div>
        </div>
        <div className='lg:hidden w-full flex items-center justify-between py-1.5 px-4 border-b border-grey'>
          <button onClick={() => setDrawerVisible(true)}>
            <img className='w-5 h-3.5' src='/assets/images/icon_hamburger.svg' alt='menu' />
          </button>
          {isLogined ? (
            <div className='flex items-center gap-1.5'>
              <div className='w-9 h-9 rounded-full overflow-hidden'>
                <img className='w-full h-full' src={currentUser?.avatar || '/assets/images/default_avatar.png'}
                     alt='default_avatar' />
              </div>
              <Dropdown
                overlay={
                  <div className='bg-white px-3 pb-3'>
                    <Link to={routes.accountDashboard}>
                      <div
                        className='text-black font-bold text-10 md:text-sm pt-2.5 pb-2 border-b border-grey cursor-pointer'>{getTranslatedText('dashboard')}</div>
                    </Link>
                    <Link to={routes.accountProfile}>
                      <div
                        className='text-black font-bold text-10 md:text-sm pt-2.5 pb-2 border-b border-grey cursor-pointer'>{getTranslatedText('profile')}</div>
                    </Link>
                    <Link to={routes.accountCourses}>
                      <div
                        className='text-black font-bold text-10 md:text-sm pt-2.5 pb-2 border-b border-grey cursor-pointer'>{getTranslatedText('my_course')}</div>
                    </Link>
                    <div
                      className='text-black font-bold text-10 md:text-sm pt-2.5 pb-2 border-b border-grey cursor-pointer'
                      onClick={() => doLogout()}>
                      {getTranslatedText('logout')}
                    </div>
                  </div>
                }
              >
                <button>
                  <img src='/assets/images/icon_arrow_down_grey.svg' alt='dropdown-icon' />
                </button>
              </Dropdown>
            </div>
          ) : (
            <Link to={routes.signin}>
              <div className='flex items-center'>
                <img src='/assets/images/default_avatar.png' alt='default_avatar' />
              </div>
            </Link>
          )}
        </div>
      </div>
      <Drawer
        visible={drawerVisible}
        placement='left'
        width='100%'
        onClose={() => setDrawerVisible(false)}
        closeIcon={false}
        bodyStyle={{ padding: 0 }}
      >
        <ExtendMenu history={history} setDrawerVisible={setDrawerVisible} />
      </Drawer>
    </div>
  );
}

export default Header;