import React, { useState, useEffect } from 'react';
import {
  Collapse,
} from 'antd';

import {
  MinusOutlined,
  PlayCircleOutlined,
  PlusOutlined,
} from '@ant-design/icons';

import './styles/curriculum-information-styles.scss';
import { formatDuration } from '../../../../services/appService';

const {
  Panel,
} = Collapse;


function CurriculumInformation(props) {

  const {
    lessons,
    setVideoLesson,
  } = props;

  const [lessonsList, setLessonsList] = useState(null);

  const lang = localStorage.getItem('current_lang') || 'en';

  useEffect(() => {
    setLessonsList(lessons);
  }, [lessons]);


  function customExpandIcon(props) {
    // console.log(props);
    if (props.isActive) {
      return (
        <MinusOutlined
          style={{
            color: '#FFFFFF',
          }}
        />
      );
    } else {
      return (
        <PlusOutlined
          style={{
            color: '#FFFFFF',
          }}
        />
      );
    }
  }

  return (
    <React.Fragment>
      <div id='curriculum-infor-wrapper'>
        <Collapse
          className='course-curriculum-infor-wrapper'
          expandIconPosition='right'
          expandIcon={(props) => customExpandIcon(props)}
        >
          {lessonsList && lessonsList.map(item => (
            <Panel header={(lang == "vi")? item.title_vi : item.title}>
              {item.parts.map(item => (
                <Collapse
                  className='term-course-curriculum-infor-wrapper'
                  expandIconPosition='right'
                >
                  <Panel header={(lang == "vi")? item.title_vi : item.title}>
                    {item.lessons.map(item => (
                        <div className='lesson-term-course-curriculum-infor-wrapper'>
                          <div
                            className={'infor-lesson-term-course-curriculum-infor-wrapper' + ((item.attachments[0] && item.attachments[0].watched) ? '-watch' : '')}>
                            {item.attachments.length > 0 && (
                              <div className='control-lesson-term-course-curriculum-infor-wrapper'>
                                <button
                                  onClick={() => setVideoLesson(item.attachments[0])}
                                >
                                  <PlayCircleOutlined />
                                </button>
                              </div>
                            )}
                            {item.attachments.length > 0 ? (
                              <p onClick={() => setVideoLesson(item.attachments[0])}
                                 className='title-infor-lesson-term-course-curriculum-infor-wrapper'>{(lang == 'vi') ? item.title_vi : item.title}</p>
                            ) : (
                              <p
                                className='title-infor-lesson-term-course-curriculum-infor-wrapper'>{(lang == 'vi') ? item.title_vi : item.title}</p>
                            )}
                          </div>
                          {item.duration && (
                            <p
                              className={'duration-infor-lesson-term-course-curriculum-infor-wrapper' + ((item.attachments[0] && item.attachments[0].watched) ? '-watch' : '')}
                            >
                              {formatDuration(item.duration)}
                            </p>
                          )}
                        </div>
                      ),
                    )}
                  </Panel>
                </Collapse>
              ))}
            </Panel>
          ))}
        </Collapse>
      </div>
    </React.Fragment>
  );
};

export default CurriculumInformation;