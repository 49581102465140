import * as actionTypes from '../actions/index';

const initialState = {
  loading: false,
  activeEventData: {},
  is_success: false,
  error: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET_ACTIVE_EVENT_WEEK_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_ACTIVE_EVENT_WEEK_SUCCESS:
      return {
        ...state,
        loading: false,
        activeEventData: action.payload.data,
        is_success: true,
      };
    case actionTypes.GET_ACTIVE_EVENT_WEEK_FAILURE:
      return {
        ...state,
        loading: false,
        is_success: false,
        error: action.payload,
      };
    default:
      return state;
  };
};