import { combineReducers } from 'redux';
import auth from './auth';
import profile from './profile';
import courses from './courses';
import contact from './contact';
import quiz from './quiz';
import accumulate from './accumulate';
import speaker from './speakers';
import document from './documents';
import evaluate from './evaluate';
import eventWeek from './eventWeek';
import audioBook from './audio_book';

export default combineReducers({
  auth,
  profile,
  courses,
  contact,
  quiz,
  accumulate,
  speaker,
  document,
  evaluate,
  eventWeek,
  audioBook,
});
