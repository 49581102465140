import React from 'react';
import PropTypes from 'prop-types';

function VideoItem(props) {
  const {
    imgURL = 'https://api.optimagroup.dev.gkcsoftware.com/uploads/optima/opinion/thumb_00_53_04_03_09_2021.jpeg',
    videoURL = 'https://www.youtube.com/embed/r4LlIhyQ9GY',
    memberName = 'Phạm Minh Tâm',
    setModalTitle,
    setModalVisible,
    setVideoLink,
  } = props;

  function handleOnButtonClick() {
    setVideoLink(videoURL);
    setModalTitle(memberName);
    setModalVisible(true);
  };

  return (
    <div className="w-full">
      <div className="w-full relative h-0 pb-2/7 pt-2/7 flex items-center justify-center">
        <button
          className="z-10 w-11 h-11 rounded-full bg-gold opacity-70 flex items-center justify-center focus:outline-none"
          onClick={() => handleOnButtonClick()}
        >
          <img src="/assets/images/icon_play_2.svg" alt="icon" />
        </button>
        <img
          className="absolute inset-0 object-cover w-full h-full"
          src={imgURL !== "" ? imgURL : '/assets/images/default_thumbnail.png'}
          alt="thumbnail"
        />
      </div>
      <p className="text-left text-gold font-bold text-md mt-2.5">
        {memberName}
      </p>
    </div>
  );
};

VideoItem.propTypes = {
  imgURL: PropTypes.string,
  memberName: PropTypes.string,
  videoURL: PropTypes.string,
  setModalVisible: PropTypes.func,
  setVideoLink: PropTypes.func,
  setModalTitle: PropTypes.func,
}

export default VideoItem;