import { CURRENT_LANG_KEY } from './actions';

export const routes = {
  home: '/',
  sendContactSuccessful: '/send-contact-successful',
  // course
  courses: '/courses',
  coursePaymentSuccessful: '/courses/payment/:status/:code', // this should be declared before courseDetail or "paymentsucessful" will become the id param
  courseOrder: '/courses/order/:id/:insurance', // this should be declared before courseDetail or "order" will become the id param
  courseDetail: '/courses/:id',
  // auth
  signin: '/signin',
  verify: '/verify',
  registerPendingActive: '/register-pending-active',
  resetPassword: '/reset-password',
  // account
  accountDashboard: '/account/dashboard',
  accountProfile: '/account/profile',
  accountWallet: '/account/profile/wallet',
  accountWithdraw: '/account/profile/withdraw',
  accountWithdrawNoti: '/account/profile/withdraw-noti/:type',
  accountTerminate: '/account/terminate',
  accountCourses: '/account/courses',
  accountDeposit: '/account/deposit',
  accountDepositNoti:
    '/account/profile/deposit-noti/:isBuyCourse/:code',
  accountTransferMoney: '/account/transfer',
  buyInsurance: '/courses/order-insurance/:id',
  buyInsuranceSuccessful: '/courses/joining-insurance/successful',
  // misc
  contact: '/contact',
  // Terms of Service
  privacyPolicy: '/privacy-policy',
  termOfService: '/term-of-service',
  operationRegulation: '/operation-regulation',
  // Game
  game: '/game',
  visaPayment: '/visa-payment/:id/:price',

  // Quiz
  quiz: {
    main: '/revenue',
    exam: '/quiz/test',
    result: '/quiz/result/:answer/:right/:target',
    rank: '/revenue/rank',
  },

  //Accumulate
  accumulate: '/accumulate',

  // Capital Insurance
  capitalInsurance: '/capital-insurance',

  //English Course
  englishCourse: {
    englishCourseList: '/english-courses',
    englishCourseView: '/english-courses/:id',
  },

  //Speaker + Document Download
  speaker: {
    speakerList: '/speaker/list',
    speakerView: '/speaker/view/:id',
  },

  document: {
    documentList: '/document/list',
    videoListByCategory: '/document/video/category/:id',
  },

  evaluate: '/evaluate',
  how_it_work: '/how-it-work',
  audio_book: {
    list: '/audio-book',
    detail: '/audio-book/detail/:id',
    order: '/audio-book/order',
    order_success: '/audio-book/order/success',
  },
  key_active: "/key-active",
  key_active_success: "/key-active-success",
  kidz_book: {
    list: '/kidz-book',
    detail: '/kidz-book/detail/:id',
    order: '/kidz-book/order',
    order_success: '/kidz-book/order/success',
  },
};

export const toastDuration = 3000;

export const keys = {
  // google key
  googleRecaptchaSiteKey: '6LfkxsAUAAAAABCC_nnWUKz-0OhBcio0H0rtInIH',
};

export const headerLang = {
  headers: {
    'Application-Lang': localStorage.getItem(CURRENT_LANG_KEY),
  },
};
