import React, { useState, useEffect } from 'react';
import { withRouter, useParams, } from 'react-router';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { get } from 'lodash';

import './CourseDetailPage.scss';
import Breadcrumb from '../Breadcrumb/Breadcrumb';
import CourseLevel from '../CourseLevel/CourseLevel';
import CourseInfo from '../CourseInfo/CourseInfo';
import CourseCard from '../../components/CourseCard/CourseCard';
import {
  getUserCoursesAction,
  getCourseDetailAction,
  getUserCourseDetailAction,
} from '../../actions/courses';
import {
  getTranslatedText,
  getUserFormLocal,
  checkIsEventDay,
} from '../../services/appService';
import {
  Carousel,
} from 'antd';
import {
  RightCircleOutlined,
  LeftCircleOutlined,
} from '@ant-design/icons';

import { routes } from '../../constants';
import EnglishCourseList from '../../containers/EnglishCourseScreen/EnglishCourseList/EnglishCourseList';

import { enquireScreen } from 'enquire-js';
import BannerPromotion from '../GoldenWeekPromotion/components/BannerPromotion';
import moment from 'moment';

let isMobile;
enquireScreen((b) => {
  isMobile = b;
});

function CourseDetailPage(props) {

  const {
    actions,
    courseDetail,
    userCourses,
    courses,
    eventCourses,
    eventStartDate,
    eventEndDate,
    eventDiscount,
    user,
  } = props;

  let params = useParams();

  const [slidesToShow, setSlideToShow] = useState(3);
  const [skillCourses, setSkillCourses] = useState([]);
  const [displayEventPrice, setDisplayEventPrice] = useState(false);
  const [eventSellInfo, setEventSellInfo] = useState({});
  const [audioBookPriceVisible, setAudioBookPriceVisible] = useState(true);

  useEffect(() => {
    if (user?.is_license_permanently === 1) {
      setAudioBookPriceVisible(false);
    }
    if (user?.is_license_permanently === 0 && moment().isBefore(user?.audio_license)) {
      setAudioBookPriceVisible(false);
    }
  }, [user]);

  function checkCourseInEvent() {
    let check = false;
    let sellInfo = {
      soldNum: 0,
      remainNum: 0,
      total: 0,
    };
    eventCourses?.forEach((eventCourse) => {
      if (courseDetail?.id === eventCourse.course_id) {
        check = true;
        sellInfo.soldNum = eventCourse.sold_number;
        sellInfo.remainNum = eventCourse.remain_display;
        sellInfo.total = eventCourse.total
      };
    });
    setEventSellInfo(sellInfo);
    let checkIsDayOfEvent = checkIsEventDay(eventStartDate, eventEndDate);
    if (checkIsDayOfEvent && check) {
      return true;
    } else {
      return false;
    };
  };

  useEffect(() => {
    if (isMobile) {
      setSlideToShow(1);
    } else {
      setSlideToShow(3);
    };
  }, []);

  useEffect(() => {
    let course_id = parseInt(params.id);
    let array = courses.courses.filter(item => item.id !== course_id);
    setSkillCourses(array);
    if (getUserFormLocal()) {
      actions.getUserCourseDetailAction(course_id);
    } else {
      actions.getCourseDetailAction(course_id);
    }
    actions.getUserCoursesAction();
  }, [params.id]);

  useEffect(() => {
    setDisplayEventPrice(checkCourseInEvent());
  }, [courseDetail]);

  function checkCourseItemInEvent(course) {
    let check = false;
    eventCourses?.forEach((eventCourse) => {
      if (course.id === eventCourse.course_id) {
        check = true;
      };
    });
    return check;
  };

  function getItemEventSellInfo(course) {
    let sellInfo = {
      soldNum: 0,
      remainNum: 0,
      total: 0,
    };
    eventCourses?.forEach((eventCourse) => {
      if (course.id === eventCourse.course_id) {
        sellInfo.soldNum = eventCourse.sold_number;
        sellInfo.remainNum = eventCourse.remain_display;
        sellInfo.total = eventCourse.total;
      };
    });
    return sellInfo;
  };

  return (
    <div className="CourseDetailPage">
      <Breadcrumb
        data={[
          { link: routes.home, text: getTranslatedText('HOME') },
          {
            link: routes.courses,
            text: getTranslatedText('COURSE').toUpperCase(),
          },
          {
            link: routes.courseDetail.replace(
              ':id',
              courseDetail.id,
            ),
            text: getTranslatedText('DETAIL'),
          },
        ]}
      />
      {eventCourses && <BannerPromotion />}
      <div className="CourseInfoLevelContainer mt-5">
        <CourseInfo
          courseDetail={courseDetail}
        />
        <CourseLevel
          courseDetail={courseDetail}
          userCourses={userCourses}
          displayEventPrice={displayEventPrice}
          eventSellInfo={eventSellInfo}
          eventDiscount={eventDiscount}
          audioBookPriceVisible={audioBookPriceVisible}
        />
      </div>
      <div>
      <h2 id="suggess-course-title">{getTranslatedText('courses_suggest')}</h2>
        <Carousel
          className='english-course-list-wrapper'
          slidesToShow={slidesToShow}
          arrows={true}
          nextArrow={<RightCircleOutlined />}
          prevArrow={<LeftCircleOutlined />}
          dots={false}
        >
          {skillCourses.map((courseCarouselItem, index) => (
            <CourseCard
              courseCarouselItem={courseCarouselItem}
              key={index}
              isDayOfEvent={checkIsEventDay(eventStartDate, eventEndDate)}
              eventDiscount={eventDiscount}
              isCourseInEvent={checkCourseItemInEvent(courseCarouselItem)}
              eventSellInfo={getItemEventSellInfo(courseCarouselItem)}
            />
          ))}
        </Carousel>
      </div>
      <EnglishCourseList />
      {/* <CourseCardContainer excludeId={courseDetail.id} /> */}
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    courses: state.courses,
    courseDetail: get(state, 'courses.courseDetail', {}),
    userCourses: get(state, 'courses.userCourses', []),
    loading: get(state, 'courses.loading', false),
    eventCourses: state.eventWeek.activeEventData?.courses,
    eventStartDate: state.eventWeek.activeEventData?.start,
    eventEndDate: state.eventWeek.activeEventData?.end,
    eventDiscount: state.eventWeek.activeEventData?.discount,
    user: state.profile.data
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    actions: bindActionCreators(
      {
        getUserCoursesAction,
        getCourseDetailAction,
        getUserCourseDetailAction,
      },
      dispatch,
    ),
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(CourseDetailPage);
