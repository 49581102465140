import React from 'react';
import { Link } from 'react-router-dom';

import lesson_icon from '../../assets/images/icons/lesson_icon.svg';
import ArrowRight from '../../assets/images/icon_arrow_right_white.svg';
import { routes } from '../../constants';
import { getTranslatedText } from '../../services/appService';

const AudioPriceCard = () => {
  return (
    <div className="w-full bg-white pb-4 px-6 shadow">
      <div className="w-full flex justify-between">
        <div className="bg-brown top-0 py-2.5 px-2.5 w-min">
          <p className="font-bold text-white">AUDIOBOOKS</p>
        </div>
        <div className="text-center mt-2.5">
          <p className="text-darkgrey font-bold">
            {getTranslatedText('only_title')}
          </p>
          <div className="rounded-xl bg-gold py-1 pl-6 pr-8 text-center text-white">
            <span className="font-bold text-xl">$ 15</span>/
            {getTranslatedText('year_title')}
          </div>
        </div>
      </div>
      <div className="w-full mt-8 mb-7">
        <p>{getTranslatedText('audio_book_content_1')}</p>
        <p>{getTranslatedText('audio_book_content_2')}</p>
      </div>
      <div className="w-full flex gap-x-1.5 items-center py-3.5 border-t border-b">
        <img src={lesson_icon} alt="" />
        <p>{getTranslatedText('audio_book_content_promo')}</p>
      </div>
      <Link to={routes.audio_book.order}>
        <div className="w-full flex items-center justify-center gap-x-7 mt-6 py-2 bg-gold text-center rounded-3xl">
          <p className="text-white">
            {getTranslatedText('btn_buy_now')}
          </p>
          <img src={ArrowRight} alt="" />
        </div>
      </Link>
    </div>
  );
};

export default AudioPriceCard;
