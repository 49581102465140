import * as types from '../actions';
import { get } from 'lodash';
import { toast } from 'react-toastify';
import { routes, toastDuration } from '../constants';
import {
  clearLocalStorage,
  getTranslatedText,
} from '../services/appService';

const initialState = {
  loading: false,
  loading_detail: false,
  courses: [],
  english_courses: [],
  courseDetail: {},
  userCourses: {
    buy: [],
    not_buy: [],
    buy_english: [],
    not_buy_english: [],
  },
  error: null,
  orderObj: {},
  insurances: {
    insurance_list: [],
    total_refund: 0,
    refund_list: [],
  },
  next_attachment: null,
  course_progress: {en: null, vi: null}
};

export default function(state = initialState, action) {
  switch (action.type) {
    case types.GET_COURSES_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.GET_COURSES_SUCCESS:
      return {
        ...state,
        loading: false,
        courses: action.payload.data,
      };
    case types.GET_COURSES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case types.GET_ENGLISH_COURSES_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.GET_ENGLISH_COURSES_SUCCESS:
      return {
        ...state,
        loading: false,
        english_courses: action.payload.data,
      };
    case types.GET_ENGLISH_COURSES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case types.GET_USER_COURSE_DETAIL_REQUEST:
    case types.GET_COURSE_DETAIL_REQUEST:
      return {
        ...state,
        courseDetail: {},
        loading: true,
        loading_detail: true,
      };
    case types.GET_USER_COURSE_DETAIL_SUCCESS:
    case types.GET_COURSE_DETAIL_SUCCESS:
      if (get(action, 'payload.statusCode', 0) >= 400) {
        toast("Something went wrong!");
        return;
      }
      state.courseDetail = action.payload.data;
      return {
        ...state,
        loading: false,
        loading_detail: false,
      };
    case types.GET_USER_COURSE_DETAIL_FAILURE:
    case types.GET_COURSE_DETAIL_FAILURE:
      if (get(action, 'payload.error.status', 0) >= 400) {
        toast("Something went wrong!");
      }
      return {
        ...state,
        loading: false,
        error:
          (action.payload &&
            action.payload.error &&
            action.payload.error.message) ||
          '',
      };
    case types.GET_USER_COURSES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.GET_USER_COURSES_SUCCESS:
      return {
        ...state,
        loading: false,
        userCourses: action.payload.data,
      };
    case types.GET_USER_COURSES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case types.JOIN_COURSE_INSURANCE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.JOIN_COURSE_INSURANCE_SUCCESS:
      let obj = action.payload.data;
      // console.log(action.payload.data.url);
      pathname = obj.url;

      toast.success('Joining course insurance success!');
      setTimeout(function() {
        window.location.href = pathname;
      }, toastDuration);

      return {
        ...state,
        loading: false,
        orderObj: orderObj,
      };
    case types.JOIN_COURSE_INSURANCE_FAILURE:
      toast.error(action.payload.error);
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case types.BUY_COURSE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.BUY_COURSE_SUCCESS:
      let pathname = '';
      let new_tab = false;
      let orderObj = action.payload.data;
      // console.log(action.payload.data.url);
      pathname = orderObj.url;
      // if (action.payload.shouldDepositAmount > 0) {
      //   orderObj = action.payload.data || {};
      //   message = getTranslatedText('request_buy_course');
      //   pathname = routes.accountDepositNoti
      //     .replace(':isBuyCourse', 'buy')
      //     .replace(':code', orderObj.payment_code)
      //     .replace(':amount', orderObj.amount_need);
      // } else {
      //   message = getTranslatedText('buy_course');
      //   pathname = routes.coursePaymentSuccessful.replace(
      //     ':status',
      //     'successful',
      //   );
      // }

      toast.success('Buy course successful!');
      setTimeout(function() {
        window.location.href = pathname;
      }, toastDuration);

      return {
        ...state,
        loading: false,
        orderObj: orderObj,
      };
    case types.BUY_COURSE_FAILURE:
      toast.error(action.payload.error);
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };

    // GET ORDER DETAIL BY CODE
    case types.GET_ORDER_DETAIL_BY_CODE_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.GET_ORDER_DETAIL_BY_CODE_SUCCESS:
      if (
        action.payload.statusCode === 200 &&
        action.payload.errors.length === 0
      ) {
        orderObj = action.payload.data || {};
      } else {
        toast.error(action.payload.errors[0]);
      }

      return {
        ...state,
        loading: false,
        orderObj: orderObj,
      };

    case types.GET_ORDER_DETAIL_BY_CODE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };

    // DEPOSIT REQUEST
    case types.DEPOSIT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.DEPOSIT_SUCCESS:
      window.location = action.payload.data.url;
      return {
        ...state,
        loading: false,
      };
    case types.DEPOSIT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    // GET COURSE INSURANCE
    case types.GET_COURSE_INSURANCE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.GET_COURSE_INSURANCE_SUCCESS:
      return {
        ...state,
        insurances: action.payload.data,
        loading: false,
      };
    case types.GET_COURSE_INSURANCE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    // CHARGE COURSE INSURANCE
    case types.CHARGE_COURSE_INSURANCE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.CHARGE_COURSE_INSURANCE_SUCCESS:
      toast.success('Add to your wallet successfully!');
      setTimeout(function() {
        window.location.href = routes.capitalInsurance;
      }, toastDuration);
      return {
        ...state,
        loading: false,
      };
    case types.CHARGE_COURSE_INSURANCE_FAILURE:
      toast.error(action.payload.error);

      return {
        ...state,
        loading: false,
      };
    // COMPLETE VIDEO
    case types.COMPLETE_VIDEO_REQUEST:
      return {
        ...state,
        next_attachment: null,
        loading: true,
      };
    case types.COMPLETE_VIDEO_SUCCESS:
      toast.success(getTranslatedText("complete_video_success"));
      return {
        ...state,
        next_attachment: action.payload.data,
        loading: false,
      };
    case types.COMPLETE_VIDEO_FAILURE:
      toast.error(action.payload.error);

      return {
        ...state,
        loading: false,
      };
    // GET COURSE PROGRESS
    case types.GET_COURSE_PROGRESS_REQUEST:
      return {
        ...state,
        course_progress: {en: null, vi: null},
        loading: true,
      };
    case types.GET_COURSE_PROGRESS_SUCCESS:
      return {
        ...state,
        course_progress: action.payload.data,
        loading: false,
      };
    case types.GET_COURSE_PROGRESS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
}
