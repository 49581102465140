import React from 'react';
import { getTranslatedText } from '../../../../services/appService';
import moment from 'moment';

const LicenseBanner = ({ user }) => {
  return (
    <div className='w-full max-w-screen-xl mx-auto pb-6 border-b-2 mb-8'>
      <div className='w-full text-right'>
        <p className='uppercase'>{getTranslatedText('audio_license_title')}: <span className='text-black font-semibold'>{user?.is_license_permanently === 1 ? getTranslatedText('lifetime') : moment(user?.audio_license_expire).format('DD-MM-YYYY')}</span></p>
      </div>
    </div>
  );
};

export default LicenseBanner;