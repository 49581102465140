import React, { Component } from 'react';
import { Modal, Layout, Row, Table } from 'antd';
import logo from '../../../../assets/images/logo.png';
import { chargeCourseInsurance } from '../../../../actions/courses';

import './styles.scss';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getTranslatedText } from '../../../../services/appService';

class BoxTotalMoney extends Component {
  state = {
    modal_visible: false,
  };
  columns = [
    {
      title: 'Insurance Box Name',
      dataIndex: 'title',
    },
    {
      title: 'Date Return Money',
      dataIndex: 'updated_at',
    },
    {
      title: 'Count Back',
      dataIndex: 'count_back',
    },
    {
      title: 'Total Money',
      dataIndex: 'amount',
      render: text => "$" + text
    },
  ];
  handleShowModal = () => {
    this.setState({ modal_visible: !this.state.modal_visible });
  };

  chargeInsurance = () => {
    if (!window.confirm("Add to your wallet?")) return;
    this.props.actions.chargeCourseInsurance();
  }
  render() {
    let { totalRefund, list, totalReferral } = this.props;
    return (
      <div className="container__total">
        <div className="cash__box">${totalRefund}</div>
        <div className="group__button">
          <div
            className="btn__history"
            onClick={this.handleShowModal}
          >
            {getTranslatedText("insurance_refund_history")}
          </div> <br/>
          <div className="btn__history">
            {getTranslatedText("insurance_total_referral")}: {totalReferral} {getTranslatedText("user").toLowerCase()}
          </div>
        </div>
        <Modal
          visible={this.state.modal_visible}
          footer={false}
          width="800px"
          onCancel={this.handleShowModal}
          destroyOnClose={true}
          style={{ zIndex: '2' }}
        >
          <React.Fragment>
            <Layout.Header className="logo__container">
              <img src={logo} width="150px" alt="" />
            </Layout.Header>
            <Layout.Content className="content__container">
              <Table
                // add key to fix warning missing key props
                dataSource={list}
                columns={this.columns}
                pagination={false}
              />
            </Layout.Content>
            <Layout.Footer className="footer__container">
              <Row gutter={16}></Row>
            </Layout.Footer>
          </React.Fragment>
        </Modal>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    actions: bindActionCreators(
      {
        chargeCourseInsurance
      },
      dispatch,
    ),
  };
};
export default connect(
  null,
  mapDispatchToProps,
)(BoxTotalMoney);
