export const USER_WEBSITE_URL =
  process.env.REACT_APP_USER_WEBSITE_URL;
export const BASE_URL = process.env.REACT_APP_API_ENV + '/v1';
export const TOKEN_KEY = 'token';
export const CURRENT_USER_KEY = 'current_user';
export const CURRENT_LANG_KEY = 'current_lang';

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

export const GET_PROFILE_REQUEST = 'GET_PROFILE_REQUEST';
export const GET_PROFILE_SUCCESS = 'GET_PROFILE_SUCCESS';
export const GET_PROFILE_FAILURE = 'GET_PROFILE_FAILURE';

export const UPDATE_PROFILE_REQUEST = 'UPDATE_PROFILE_REQUEST';
export const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS';
export const UPDATE_PROFILE_FAILURE = 'UPDATE_PROFILE_FAILURE';
export const SHOW_UPDATE_FORM = 'SHOW_UPDATE_FORM';

export const REGISTER_REQUEST = 'REGISTER_REQUEST';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAILURE = 'REGISTER_FAILURE';

export const GET_CHARGE_HISTORY_REQUEST =
  'GET_CHARGE_HISTORY_REQUEST';
export const GET_CHARGE_HISTORY_SUCCESS =
  'GET_CHARGE_HISTORY_SUCCESS';
export const GET_CHARGE_HISTORY_FAILURE =
  'GET_CHARGE_HISTORY_FAILURE';

export const ACTIVE_ACCOUNT_REQUEST = 'ACTIVE_ACCOUNT_REQUEST';
export const ACTIVE_ACCOUNT_SUCCESS = 'ACTIVE_ACCOUNT_SUCCESS';
export const ACTIVE_ACCOUNT_FAILURE = 'ACTIVE_ACCOUNT_FAILURE';

export const WITHDRAW_MONEY_REQUEST = 'WITHDRAW_MONEY_REQUEST';
export const WITHDRAW_MONEY_SUCCESS = 'WITHDRAW_MONEY_SUCCESS';
export const WITHDRAW_MONEY_FAILURE = 'WITHDRAW_MONEY_FAILURE';

export const DEPOSIT_MONEY_REQUEST = 'DEPOSIT_MONEY_REQUEST';
export const DEPOSIT_MONEY_SUCCESS = 'DEPOSIT_MONEY_SUCCESS';
export const DEPOSIT_MONEY_FAILURE = 'DEPOSIT_MONEY_FAILURE';

export const GET_COURSES_REQUEST = 'GET_COURSES_REQUEST';
export const GET_COURSES_SUCCESS = 'GET_COURSES_SUCCESS';
export const GET_COURSES_FAILURE = 'GET_COURSES_FAILURE';

export const GET_COURSE_DETAIL_REQUEST = 'GET_COURSE_DETAIL_REQUEST';
export const GET_COURSE_DETAIL_SUCCESS = 'GET_COURSE_DETAIL_SUCCESS';
export const GET_COURSE_DETAIL_FAILURE = 'GET_COURSE_DETAIL_FAILURE';

export const GET_USER_COURSE_DETAIL_REQUEST =
  'GET_USER_COURSE_DETAIL_REQUEST';
export const GET_USER_COURSE_DETAIL_SUCCESS =
  'GET_USER_COURSE_DETAIL_SUCCESS';
export const GET_USER_COURSE_DETAIL_FAILURE =
  'GET_USER_COURSE_DETAIL_FAILURE';

export const BUY_COURSE_REQUEST = 'BUY_COURSE_REQUEST';
export const BUY_COURSE_SUCCESS = 'BUY_COURSE_SUCCESS';
export const BUY_COURSE_FAILURE = 'BUY_COURSE_FAILURE';

export const UPDATE_ORDER_REQUEST = 'UPDATE_ORDER_REQUEST';
export const UPDATE_ORDER_SUCCESS = 'UPDATE_ORDER_SUCCESS';
export const UPDATE_ORDER_FAILURE = 'UPDATE_ORDER_FAILURE';

export const DEPOSIT_REQUEST = 'DEPOSIT_REQUEST';
export const DEPOSIT_SUCCESS = 'DEPOSIT_SUCCESS';
export const DEPOSIT_FAILURE = 'DEPOSIT_FAILURE';

export const GET_USER_COURSES_REQUEST = 'GET_USER_COURSES_REQUEST';
export const GET_USER_COURSES_SUCCESS = 'GET_USER_COURSES_SUCCESS';
export const GET_USER_COURSES_FAILURE = 'GET_USER_COURSES_FAILURE';

export const GET_USER_DASHBOARD_REQUEST =
  'GET_USER_DASHBOARD_REQUEST';
export const GET_USER_DASHBOARD_SUCCESS =
  'GET_USER_DASHBOARD_SUCCESS';
export const GET_USER_DASHBOARD_FAILURE =
  'GET_USER_DASHBOARD_FAILURE';

export const SEND_CONTACT_REQUEST = 'SEND_CONTACT_REQUEST';
export const SEND_CONTACT_SUCCESS = 'SEND_CONTACT_SUCCESS';
export const SEND_CONTACT_FAILURE = 'SEND_CONTACT_FAILURE';

export const TOGGLE_FORGOT_PASSWORD_POPUP =
  'TOGGLE_FORGOT_PASSWORD_POPUP';

export const SEND_FORGOT_PASSWORD_EMAIL_REQUEST =
  'SEND_FORGOT_PASSWORD_EMAIL_REQUEST';
export const SEND_FORGOT_PASSWORD_EMAIL_SUCCESS =
  'SEND_FORGOT_PASSWORD_EMAIL_SUCCESS';
export const SEND_FORGOT_PASSWORD_EMAIL_FAILURE =
  'SEND_FORGOT_PASSWORD_EMAIL_FAILURE';

export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE';

export const GET_ORDER_DETAIL_BY_CODE_REQUEST =
  'GET_ORDER_DETAIL_BY_CODE_REQUEST';
export const GET_ORDER_DETAIL_BY_CODE_SUCCESS =
  'GET_ORDER_DETAIL_BY_CODE_SUCCESS';
export const GET_ORDER_DETAIL_BY_CODE_FAILURE =
  'GET_ORDER_DETAIL_BY_CODE_FAILURE';

export const GET_NOTIFICATIONS_REQUEST = 'GET_NOTIFICATIONS_REQUEST';
export const GET_NOTIFICATIONS_SUCCESS = 'GET_NOTIFICATIONS_SUCCESS';
export const GET_NOTIFICATIONS_FAILURE = 'GET_NOTIFICATIONS_FAILURE';

export const GET_NOTIFICATION_REQUEST = 'GET_NOTIFICATION_REQUEST';
export const GET_NOTIFICATION_SUCCESS = 'GET_NOTIFICATION_SUCCESS';
export const GET_NOTIFICATION_FAILURE = 'GET_NOTIFICATION_FAILURE';

export const GET_PERMISSION_QUIZ_REQUEST =
  'GET_PERMISSION_QUIZ_REQUEST';
export const GET_PERMISSION_QUIZ_SUCCESS =
  'GET_PERMISSION_QUIZ_SUCCESS';
export const GET_PERMISSION_QUIZ_FAILURE =
  'GET_PERMISSION_QUIZ_FAILURE';

export const GET_QUIZ_REQUEST = 'GET_QUIZ_REQUEST';
export const GET_QUIZ_SUCCESS = 'GET_QUIZ_SUCCESS';
export const GET_QUIZ_FAILURE = 'GET_QUIZ_FAILURE';

export const GET_QUIZ_RANK_REQUEST = 'GET_QUIZ_RANK_REQUEST';
export const GET_QUIZ_RANK_SUCCESS = 'GET_QUIZ_RANK_SUCCESS';
export const GET_QUIZ_RANK_FAILURE = 'GET_QUIZ_RANK_FAILURE';
export const POST_RESULT_QUIZ_REQUEST = 'POST_RESULT_QUIZ_REQUEST';
export const POST_RESULT_QUIZ_SUCCESS = 'POST_RESULT_QUIZ_SUCCESS';
export const POST_RESULT_QUIZ_FAILURE = 'POST_RESULT_QUIZ_FAILURE';

export const GET_TIME_QUIZ_EVENT_REQUEST =
  'GET_TIME_QUIZ_EVENT_REQUEST';
export const GET_TIME_QUIZ_EVENT_SUCCESS =
  'GET_TIME_QUIZ_EVENT_SUCCESS';
export const GET_TIME_QUIZ_EVENT_FAILURE =
  'GET_TIME_QUIZ_EVENT_FAILURE';

export const GET_CHILD_USER_REQUEST = 'GET_CHILD_USER_REQUEST';
export const GET_CHILD_USER_SUCCESS = 'GET_CHILD_USER_SUCCESS';
export const GET_CHILD_USER_FAILURE = 'GET_CHILD_USER_FAILURE';

export const POST_CONTRACT_REQUEST = 'POST_CONTRACT_REQUEST';
export const POST_CONTRACT_SUCCESS = 'POST_CONTRACT_SUCCESS';
export const POST_CONTRACT_FAILURE = 'POST_CONTRACT_FAILURE';

export const GET_PROMOTION_LIST_REQUEST =
  'GET_PROMOTION_LIST_REQUEST';
export const GET_PROMOTION_LIST_SUCCESS =
  'GET_PROMOTION_LIST_SUCCESS';
export const GET_PROMOTION_LIST_FAILURE =
  'GET_PROMOTION_LIST_FAILURE';

export const GET_TRANSFER_LIST_REQUEST = 'GET_TRANSFER_LIST_REQUEST';
export const GET_TRANSFER_LIST_SUCCESS = 'GET_TRANSFER_LIST_SUCCESS';
export const GET_TRANSFER_LIST_FAILURE = 'GET_TRANSFER_LIST_FAILURE';

export const RECEIVE_PROMOTION_REQUEST = 'RECEIVE_PROMOTION_REQUEST';
export const RECEIVE_PROMOTION_SUCCESS = 'RECEIVE_PROMOTION_SUCCESS';
export const RECEIVE_PROMOTION_FAILURE = 'RECEIVE_PROMOTION_FAILURE';

export const GET_LIST_SPECIAL_OPTION_REQUEST =
  'GET_LIST_SPECIAL_OPTION_REQUEST';
export const GET_LIST_SPECIAL_OPTION_SUCCESS =
  'GET_LIST_SPECIAL_OPTION_SUCCESS';
export const GET_LIST_SPECIAL_OPTION_FAILURE =
  'GET_LIST_SPECIAL_OPTION_FAILURE';

export const GET_REDEEM_HISTORY_REQUEST =
  'GET_REDEEM_HISTORY_REQUEST';
export const GET_REDEEM_HISTORY_SUCCESS =
  'GET_REDEEM_HISTORY_SUCCESS';
export const GET_REDEEM_HISTORY_FAILURE =
  'GET_REDEEM_HISTORY_FAILURE';

export const GET_USERS_REQUEST = 'GET_USERS_REQUEST';
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
export const GET_USERS_FAILURE = 'GET_USERS_FAILURE';

export const POST_TRANSFER_MONEY_REQUEST =
  'POST_TRANSFER_MONEY_REQUEST';
export const POST_TRANSFER_MONEY_SUCCESS =
  'POST_TRANSFER_MONEY_SUCCESS';
export const POST_TRANSFER_MONEY_FAILURE =
  'POST_TRANSFER_MONEY_FAILURE';

export const JOIN_COURSE_INSURANCE_REQUEST =
  'JOIN_COURSE_INSURANCE_REQUEST';
export const JOIN_COURSE_INSURANCE_SUCCESS =
  'JOIN_COURSE_INSURANCE_SUCCESS';
export const JOIN_COURSE_INSURANCE_FAILURE =
  'JOIN_COURSE_INSURANCE_FAILURE';

export const GET_COURSE_INSURANCE_REQUEST =
  'GET_COURSE_INSURANCE_REQUEST';
export const GET_COURSE_INSURANCE_SUCCESS =
  'GET_COURSE_INSURANCE_SUCCESS';
export const GET_COURSE_INSURANCE_FAILURE =
  'GET_COURSE_INSURANCE_FAILURE';

export const CHARGE_COURSE_INSURANCE_REQUEST =
  'CHARGE_COURSE_INSURANCE_REQUEST';
export const CHARGE_COURSE_INSURANCE_SUCCESS =
  'CHARGE_COURSE_INSURANCE_SUCCESS';
export const CHARGE_COURSE_INSURANCE_FAILURE =
  'CHARGE_COURSE_INSURANCE_FAILURE';

export const SUBMIT_VERIFY_TRANSFER_REQUEST =
  'SUBMIT_VERIFY_TRANSFER_REQUEST';
export const SUBMIT_VERIFY_TRANSFER_SUCCESS =
  'SUBMIT_VERIFY_TRANSFER_SUCCESS';
export const SUBMIT_VERIFY_TRANSFER_FAILURE =
  'SUBMIT_VERIFY_TRANSFER_FAILURE';

export const COMPLETE_VIDEO_REQUEST =
  'COMPLETE_VIDEO_REQUEST';
export const COMPLETE_VIDEO_SUCCESS =
  'COMPLETE_VIDEO_SUCCESS';
export const COMPLETE_VIDEO_FAILURE =
  'COMPLETE_VIDEO_FAILURE';

export const GET_COURSE_PROGRESS_REQUEST =
  'GET_COURSE_PROGRESS_REQUEST';
export const GET_COURSE_PROGRESS_SUCCESS =
  'GET_COURSE_PROGRESS_SUCCESS';
export const GET_COURSE_PROGRESS_FAILURE =
  'GET_COURSE_PROGRESS_FAILURE';

export const GET_ENGLISH_COURSES_REQUEST =
  'GET_ENGLISH_COURSES_REQUEST';
export const GET_ENGLISH_COURSES_SUCCESS =
  'GET_ENGLISH_COURSES_SUCCESS';
export const GET_ENGLISH_COURSES_FAILURE =
  'GET_ENGLISH_COURSES_FAILURE';

export const GET_BONUS_LIST_REQUEST =
  'GET_BONUS_LIST_REQUEST';
export const GET_BONUS_LIST_SUCCESS =
  'GET_BONUS_LIST_SUCCESS';
export const GET_BONUS_LIST_FAILURE =
  'GET_BONUS_LIST_FAILURE';

export const GET_INTRODUCE_LIST_REQUEST =
  'GET_INTRODUCE_LIST_REQUEST';
export const GET_INTRODUCE_LIST_SUCCESS =
  'GET_INTRODUCE_LIST_SUCCESS';
export const GET_INTRODUCE_LIST_FAILURE =
  'GET_INTRODUCE_LIST_FAILURE';

export const GET_LIST_SPEAKER_REQUEST = 'GET_LIST_SPEAKER_REQUEST';
export const GET_LIST_SPEAKER_SUCCESS = 'GET_LIST_SPEAKER_SUCCESS';
export const GET_LIST_SPEAKER_FAILURE = 'GET_LIST_SPEAKER_FAILURE';

export const GET_DETAIL_SPEAKER_REQUEST = 'GET_DETAIL_SPEAKER_REQUEST';
export const GET_DETAIL_SPEAKER_SUCCESS = 'GET_DETAIL_SPEAKER_SUCCESS';
export const GET_DETAIL_SPEAKER_FAILURE = 'GET_DETAIL_SPEAKER_FAILURE';

export const GET_LIST_DOCUMENT_REQUEST = 'GET_LIST_DOCUMENT_REQUEST';
export const GET_LIST_DOCUMENT_SUCCESS = 'GET_LIST_DOCUMENT_SUCCESS';
export const GET_LIST_DOCUMENT_FAILURE = 'GET_LIST_DOCUMENT_FAILURE';

export const GET_LIST_VIDEO_CATEGORY_REQUEST = 'GET_LIST_VIDEO_CATEGORY_REQUEST';
export const GET_LIST_VIDEO_CATEGORY_SUCCESS = 'GET_LIST_VIDEO_CATEGORY_SUCCESS';
export const GET_LIST_VIDEO_CATEGORY_FAILURE = 'GET_LIST_VIDEO_CATEGORY_FAILURE';

export const GET_LIST_VIDEO_BY_CATEGORY_REQUEST = 'GET_LIST_VIDEO_BY_CATEGORY_REQUEST';
export const GET_LIST_VIDEO_BY_CATEGORY_SUCCESS = 'GET_LIST_VIDEO_BY_CATEGORY_SUCCESS';
export const GET_LIST_VIDEO_BY_CATEGORY_FAILURE = 'GET_LIST_VIDEO_BY_CATEGORY_FAILURE';

export const GET_LIST_EVALUATE_REQUEST = 'GET_LIST_EVALUATE_REQUEST';
export const GET_LIST_EVALUATE_SUCCESS = 'GET_LIST_EVALUATE_SUCCESS';
export const GET_LIST_EVALUATE_FAILURE = 'GET_LIST_EVALUATE_FAILURE';

export const GET_LIST_FEATURE_EVALUATE_REQUEST = 'GET_LIST_FEATURE_EVALUATE_REQUEST';
export const GET_LIST_FEATURE_EVALUATE_SUCCESS = 'GET_LIST_FEATURE_EVALUATE_SUCCESS';
export const GET_LIST_FEATURE_EVALUATE_FAILURE = 'GET_LIST_FEATURE_EVALUATE_FAILURE';

export const GET_ACTIVE_EVENT_WEEK_REQUEST = 'GET_ACTIVE_EVENT_WEEK_REQUEST';
export const GET_ACTIVE_EVENT_WEEK_SUCCESS = 'GET_ACTIVE_EVENT_WEEK_SUCCESS';
export const GET_ACTIVE_EVENT_WEEK_FAILURE = 'GET_ACTIVE_EVENT_WEEK_FAILURE';

export const GET_LIST_AUDIO_BOOK_REQUEST = 'GET_LIST_AUDIO_BOOK_REQUEST';
export const GET_LIST_AUDIO_BOOK_SUCCESS = 'GET_LIST_AUDIO_BOOK_SUCCESS';
export const GET_LIST_AUDIO_BOOK_FAILURE = 'GET_LIST_AUDIO_BOOK_FAILURE';

export const GET_DETAIL_AUDIO_BOOK_REQUEST = 'GET_DETAIL_AUDIO_BOOK_REQUEST';
export const GET_DETAIL_AUDIO_BOOK_SUCCESS = 'GET_DETAIL_AUDIO_BOOK_SUCCESS';
export const GET_DETAIL_AUDIO_BOOK_FAILURE = 'GET_DETAIL_AUDIO_BOOK_FAILURE';

export const GET_LIST_KIDZ_BOOK_REQUEST = 'GET_LIST_KIDZ_BOOK_REQUEST';
export const GET_LIST_KIDZ_BOOK_SUCCESS = 'GET_LIST_KIDZ_BOOK_SUCCESS';
export const GET_LIST_KIDZ_BOOK_FAILURE = 'GET_LIST_KIDZ_BOOK_FAILURE';

export const GET_DETAIL_KIDZ_BOOK_REQUEST = 'GET_DETAIL_KIDZ_BOOK_REQUEST';
export const GET_DETAIL_KIDZ_BOOK_SUCCESS = 'GET_DETAIL_KIDZ_BOOK_SUCCESS';
export const GET_DETAIL_KIDZ_BOOK_FAILURE = 'GET_DETAIL_KIDZ_BOOK_FAILURE';



export const BUY_AUDIO_BOOK_REQUEST = 'BUY_AUDIO_BOOK_REQUEST';
export const BUY_AUDIO_BOOK_SUCCESS = 'BUY_AUDIO_BOOK_SUCCESS';
export const BUY_AUDIO_BOOK_FAILURE = 'BUY_AUDIO_BOOK_FAILURE';

export const SUBMIT_ACTIVE_CODE_REQUEST = 'SUBMIT_ACTIVE_CODE_REQUEST';
export const SUBMIT_ACTIVE_CODE_SUCCESS = 'SUBMIT_ACTIVE_CODE_SUCCESS';
export const SUBMIT_ACTIVE_CODE_FAILURE = 'SUBMIT_ACTIVE_CODE_FAILURE';