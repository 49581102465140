import React from 'react';
import {
  Tabs,
} from 'antd';

import Breadcrumb from '../../components/Breadcrumb/Breadcrumb'
import DocumentList from './DocumentList/DocumentList';
import VideoList from './VideoList/VideoList';

import { routes } from '../../constants';
import { getTranslatedText } from '../../services/appService';
import './styles/document-screen-styles.scss';


const {
  TabPane,
} = Tabs;

function DocumentScreen(props) {
  return (
    <React.Fragment>
      <Breadcrumb
        data={[
          { link: routes.home, text: getTranslatedText('HOME') },
          { link: routes.document.documentList, text: getTranslatedText('download') },
        ]}
      />
      <Tabs
        id='document-tabs-wrapper'
        defaultActiveKey='1'
      >
        <TabPane
          tab={getTranslatedText('document')}
          key='1'
        >
          <DocumentList />
        </TabPane>
        <TabPane
          tab={getTranslatedText('video')}
          key='2'
        >
          <VideoList />
        </TabPane>
      </Tabs>
    </React.Fragment>
  );
}

export default DocumentScreen;