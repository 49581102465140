import * as actionTypes from '../actions/index';
import { routes } from '../constants';
import { toast } from 'react-toastify';
import { getTranslatedText } from '../services/appService';

const initalState = {
  loading: false,
  error: null,
  audioBookDetail: null,
  audioBookList: [],
  isBuyingAudioBook: false
};

export default function(state = initalState, action) {
  switch (action.type) {
    case actionTypes.GET_LIST_AUDIO_BOOK_REQUEST:
    case actionTypes.GET_DETAIL_AUDIO_BOOK_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.GET_LIST_AUDIO_BOOK_FAILURE:
    case actionTypes.GET_DETAIL_AUDIO_BOOK_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case actionTypes.GET_LIST_AUDIO_BOOK_SUCCESS:
      return {
        ...state,
        loading: false,
        audioBookList: action.payload,
      }
    case actionTypes.GET_DETAIL_AUDIO_BOOK_SUCCESS:
      return {
        ...state,
        loading: false,
        audioBookDetail: action.payload
      }
    case actionTypes.BUY_AUDIO_BOOK_REQUEST:
      return {
        ...state,
        isBuyingAudioBook: true
      }
    case actionTypes.GET_LIST_KIDZ_BOOK_REQUEST:
    case actionTypes.GET_DETAIL_KIDZ_BOOK_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.GET_LIST_KIDZ_BOOK_FAILURE:
    case actionTypes.GET_DETAIL_KIDZ_BOOK_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case actionTypes.GET_LIST_KIDZ_BOOK_SUCCESS:
      return {
        ...state,
        loading: false,
        audioBookList: action.payload,
      }
    case actionTypes.GET_DETAIL_KIDZ_BOOK_SUCCESS:
      return {
        ...state,
        loading: false,
        audioBookDetail: action.payload
      }
    case actionTypes.BUY_AUDIO_BOOK_SUCCESS:
      toast.success(getTranslatedText('audio_book_buy_success_message'));
      setTimeout(() => {
        window.location.pathname = routes.audio_book.order_success;
      }, 1000);
      return {
        ...state,
        isBuyingAudioBook: false
      }
    case actionTypes.BUY_AUDIO_BOOK_FAILURE:
      return {
        ...state,
        isBuyingAudioBook: false
      }
    default:
      return state;
  }
}