import axios from 'axios';
import {
  BASE_URL,
  TOKEN_KEY,
  GET_COURSES_REQUEST,
  GET_COURSES_SUCCESS,
  GET_COURSES_FAILURE,
  GET_COURSE_DETAIL_REQUEST,
  GET_COURSE_DETAIL_SUCCESS,
  GET_COURSE_DETAIL_FAILURE,
  GET_USER_COURSES_REQUEST,
  GET_USER_COURSES_SUCCESS,
  GET_USER_COURSES_FAILURE,
  GET_USER_COURSE_DETAIL_REQUEST,
  GET_USER_COURSE_DETAIL_SUCCESS,
  GET_USER_COURSE_DETAIL_FAILURE,
  BUY_COURSE_REQUEST,
  BUY_COURSE_SUCCESS,
  BUY_COURSE_FAILURE,
  DEPOSIT_REQUEST,
  DEPOSIT_SUCCESS,
  DEPOSIT_FAILURE,
  UPDATE_ORDER_REQUEST,
  UPDATE_ORDER_SUCCESS,
  UPDATE_ORDER_FAILURE,
  JOIN_COURSE_INSURANCE_REQUEST,
  JOIN_COURSE_INSURANCE_SUCCESS,
  JOIN_COURSE_INSURANCE_FAILURE,
  GET_COURSE_INSURANCE_REQUEST,
  GET_COURSE_INSURANCE_SUCCESS,
  GET_COURSE_INSURANCE_FAILURE,
  CHARGE_COURSE_INSURANCE_REQUEST,
  CHARGE_COURSE_INSURANCE_SUCCESS,
  CHARGE_COURSE_INSURANCE_FAILURE,
  COMPLETE_VIDEO_REQUEST,
  COMPLETE_VIDEO_SUCCESS,
  COMPLETE_VIDEO_FAILURE,
  GET_COURSE_PROGRESS_REQUEST, GET_COURSE_PROGRESS_SUCCESS, GET_COURSE_PROGRESS_FAILURE,
  GET_ENGLISH_COURSES_REQUEST,
  GET_ENGLISH_COURSES_FAILURE,
  GET_ENGLISH_COURSES_SUCCESS,
  GET_TIME_QUIZ_EVENT_FAILURE,
} from './index';
import { headerLang } from '../constants';

/**
 * +-------------+
 * | GET COURSES |
 * +-------------+
 */
export const getCoursesAction = () => {
  return dispatch => {
    dispatch(getCoursesRequest());
    axios
      .get(`${BASE_URL}/courses`, headerLang)
      .then(response => dispatch(getCoursesSuccess(response.data)))
      .catch(error => dispatch(getCoursesFailure(error.message)));
  };
};

export const getEnglishCoursesAction = () => {
  return dispatch => {
    dispatch(getEnglishCoursesRequest());
    axios
      .get(`${BASE_URL}/courses-english`, headerLang)
      .then(response => dispatch(getEnglishCoursesSuccess(response.data)))
      .catch(error => dispatch(getEnglishCoursesFailure(error.message)));
  }
};

const getEnglishCoursesRequest = () => ({
  type: GET_ENGLISH_COURSES_REQUEST,
  payload: {},
});

const getEnglishCoursesSuccess = response => ({
  type: GET_ENGLISH_COURSES_SUCCESS,
  payload: { ...response },
});

const getEnglishCoursesFailure = error => ({
  type: GET_ENGLISH_COURSES_FAILURE,
  payload: { error },
});

const getCoursesRequest = () => ({
  type: GET_COURSES_REQUEST,
  payload: {},
});

const getCoursesSuccess = response => ({
  type: GET_COURSES_SUCCESS,
  payload: { ...response },
});

const getCoursesFailure = error => ({
  type: GET_COURSES_FAILURE,
  payload: { error },
});

/**
 * +-------------------+
 * | GET COURSE DETAIL |
 * +-------------------+
 */
export const getCourseDetailAction = courseId => {
  return dispatch => {
    dispatch(getCourseDetailRequest());

    axios
      .get(`${BASE_URL}/courses/${courseId}`, headerLang)
      .then(response =>
        dispatch(getCourseDetailSuccess(response.data)),
      )
      .catch(error =>
        dispatch(getCourseDetailFailure(error.response)),
      );
  };
};

const getCourseDetailRequest = () => ({
  type: GET_COURSE_DETAIL_REQUEST,
  payload: {},
});

const getCourseDetailSuccess = response => ({
  type: GET_COURSE_DETAIL_SUCCESS,
  payload: { ...response },
});

const getCourseDetailFailure = error => ({
  type: GET_COURSE_DETAIL_FAILURE,
  payload: { error },
});

/**
 * +--------------------+
 * | GET USER'S COURSES |
 * +--------------------+
 */
export const getUserCoursesAction = () => {
  return dispatch => {
    dispatch(getUserCoursesRequest());

    const token = localStorage.getItem(TOKEN_KEY);
    axios
      .post(`${BASE_URL}/users/courses`, { token: token }, headerLang)
      .then(response =>
        dispatch(getUserCoursesSuccess(response.data)),
      )
      .catch(error => dispatch(getUserCoursesFailure(error.message)));
  };
};

const getUserCoursesRequest = () => ({
  type: GET_USER_COURSES_REQUEST,
  payload: {},
});

const getUserCoursesSuccess = response => ({
  type: GET_USER_COURSES_SUCCESS,
  payload: { ...response },
});

const getUserCoursesFailure = error => ({
  type: GET_USER_COURSES_FAILURE,
  payload: { error },
});

/**
 * +--------------------------+
 * | GET USER'S COURSE DETAIL |
 * +--------------------------+
 */
export const getUserCourseDetailAction = courseId => {
  return dispatch => {
    dispatch(getUserCourseDetailRequest(courseId));

    const token = localStorage.getItem(TOKEN_KEY);
    axios
      .post(
        `${BASE_URL}/users/courses/${courseId}`,
        { token: token },
        headerLang,
      )
      .then(response =>
        dispatch(getUserCourseDetailSuccess(response.data)),
      )
      .catch(error =>
        dispatch(getUserCourseDetailFailure(error.response)),
      );
  };
};

const getUserCourseDetailRequest = id => ({
  type: GET_USER_COURSE_DETAIL_REQUEST,
  payload: {},
});

const getUserCourseDetailSuccess = response => ({
  type: GET_USER_COURSE_DETAIL_SUCCESS,
  payload: { ...response },
});

const getUserCourseDetailFailure = error => ({
  type: GET_USER_COURSE_DETAIL_FAILURE,
  payload: { error },
});

/**
 * +------------+
 * | BUY COURSE |
 * +------------+
 */
export const buyCourseAction = (courseId, join_insurance = false) => {
  return dispatch => {
    dispatch(buyCourseRequest());

    const token = localStorage.getItem(TOKEN_KEY);
    axios
      .post(
        `${BASE_URL}/users/buying-course`,
        {
          course_id: courseId,
          is_join_insurance: join_insurance,
          token: token,
        },
        headerLang,
      )
      .then(response => {
        if (response.data.statusCode === 200)
          dispatch(buyCourseSuccess(response.data));
        else
          dispatch(buyCourseFailure(response.data.errors.join('. ')));
      })
      .catch(error => dispatch(buyCourseFailure(error.message)));
  };
};

const buyCourseRequest = courseId => ({
  type: BUY_COURSE_REQUEST,
  payload: { course_id: courseId },
});

const buyCourseSuccess = response => ({
  type: BUY_COURSE_SUCCESS,
  payload: { ...response },
});

const buyCourseFailure = error => ({
  type: BUY_COURSE_FAILURE,
  payload: { error },
});

export const joinCourseInsurance = courseId => {
  return dispatch => {
    dispatch(joinCourseInsuranceRequest());

    const token = localStorage.getItem(TOKEN_KEY);
    axios
      .post(
        `${BASE_URL}/users/join-insurance`,
        {
          course_id: courseId,
          token: token,
        },
        headerLang,
      )
      .then(response => {
        if (response.data.statusCode === 200)
          dispatch(joinCourseInsuranceSuccess(response.data));
        else
          dispatch(
            joinCourseInsuranceFailure(
              response.data.errors.join('. '),
            ),
          );
      })
      .catch(error =>
        dispatch(joinCourseInsuranceFailure(error.message)),
      );
  };
};

const joinCourseInsuranceRequest = courseId => ({
  type: JOIN_COURSE_INSURANCE_REQUEST,
  payload: { course_id: courseId },
});

const joinCourseInsuranceSuccess = response => ({
  type: JOIN_COURSE_INSURANCE_SUCCESS,
  payload: { ...response },
});

const joinCourseInsuranceFailure = error => ({
  type: JOIN_COURSE_INSURANCE_FAILURE,
  payload: { error },
});

export const updateOrderAction = payload => {
  return dispatch => {
    dispatch(updateOrderRequest(payload));

    const token = localStorage.getItem(TOKEN_KEY);
    axios
      .post(
        `${BASE_URL}/users/update-order`,
        {
          token: token,
          order_code: payload.order_code,
          method: payload.method,
          status: payload.status,
        },
        headerLang,
      )
      .then(response => {
        if (response.data.statusCode === 200)
          dispatch(updateOrderSuccess(response.data));
        else dispatch(updateOrderFailure(response.data.errors));
      })
      .catch(error => dispatch(updateOrderFailure(error.message)));
  };
};

const updateOrderRequest = payload => ({
  type: UPDATE_ORDER_REQUEST,
  payload: payload,
});

const updateOrderSuccess = payload => ({
  type: UPDATE_ORDER_SUCCESS,
  payload: payload,
});

const updateOrderFailure = payload => ({
  type: UPDATE_ORDER_FAILURE,
  payload: { payload },
});

/**
 * +---------+
 * | DEPOSIT |
 * +---------+
 */
export const depositAction = amount => {
  return dispatch => {
    dispatch(depositRequest(amount));

    const token = localStorage.getItem(TOKEN_KEY);
    axios
      .post(
        `${BASE_URL}/users/recharge`,
        {
          price: amount,
          token: token,
        },
        headerLang,
      )
      .then(response => {
        dispatch(depositSuccess(response.data));
      })
      .catch(error => dispatch(depositFailure(error.message)));
  };
};

const depositRequest = amount => ({
  type: DEPOSIT_REQUEST,
  payload: { amount: amount },
});

const depositSuccess = response => ({
  type: DEPOSIT_SUCCESS,
  payload: { ...response },
});

const depositFailure = error => ({
  type: DEPOSIT_FAILURE,
  payload: { error },
});

export const getCourseInsurance = payload => {
  return dispatch => {
    dispatch(getCourseInsuranceRequest(payload));

    const token = localStorage.getItem(TOKEN_KEY);
    axios
      .get(
        `${BASE_URL}/users/your-insurance?token=` + token,
        headerLang,
      )
      .then(response => {
        if (response.data.statusCode === 200)
          dispatch(getCourseInsuranceSuccess(response.data));
        else
          dispatch(
            getCourseInsuranceFailure(
              response.data.errors.join('. '),
            ),
          );
      })
      .catch(error =>
        dispatch(getCourseInsuranceFailure(error.message)),
      );
  };
};

const getCourseInsuranceRequest = payload => ({
  type: GET_COURSE_INSURANCE_REQUEST,
  payload: payload,
});

const getCourseInsuranceSuccess = response => ({
  type: GET_COURSE_INSURANCE_SUCCESS,
  payload: { ...response },
});

const getCourseInsuranceFailure = error => ({
  type: GET_COURSE_INSURANCE_FAILURE,
  payload: { error },
});

export const chargeCourseInsurance = payload => {
  return dispatch => {
    dispatch(chargeCourseInsuranceRequest(payload));

    const token = localStorage.getItem(TOKEN_KEY);
    axios
      .post(
        `${BASE_URL}/users/charge-insurance`,
        { token: token },
        headerLang,
      )
      .then(response => {
        if (response.data.statusCode === 200)
          dispatch(chargeCourseInsuranceSuccess(response.data));
        else
          dispatch(
            chargeCourseInsuranceFailure(
              response.data.errors.join('. '),
            ),
          );
      })
      .catch(error =>
        dispatch(getCourseInsuranceFailure(error.message)),
      );
  };
};

const chargeCourseInsuranceRequest = payload => ({
  type: CHARGE_COURSE_INSURANCE_REQUEST,
  payload: payload,
});

const chargeCourseInsuranceSuccess = response => ({
  type: CHARGE_COURSE_INSURANCE_SUCCESS,
  payload: { ...response },
});

const chargeCourseInsuranceFailure = error => ({
  type: CHARGE_COURSE_INSURANCE_FAILURE,
  payload: { error },
});

export const completeVideo = payload => {
  return dispatch => {
    dispatch(completeVideoRequest(payload));

    const token = localStorage.getItem(TOKEN_KEY);
    payload.token = token;
    axios
      .post(
        `${BASE_URL}/users/complete-video`,
        payload,
        headerLang,
      )
      .then(response => {
        if (response.data.statusCode === 200)
          dispatch(completeVideoSuccess(response.data));
        else
          dispatch(
            completeVideoFailure(
              response.data.errors.join('. '),
            ),
          );
      })
      .catch(error =>
        dispatch(completeVideoFailure(error.message)),
      );
  };
};

const completeVideoRequest = payload => ({
  type: COMPLETE_VIDEO_REQUEST,
  payload: payload,
});

const completeVideoSuccess = response => ({
  type: COMPLETE_VIDEO_SUCCESS,
  payload: { ...response },
});

const completeVideoFailure = error => ({
  type: COMPLETE_VIDEO_FAILURE,
  payload: { error },
});

export const getCourseProgress = payload => {
  return dispatch => {
    dispatch(getCourseProgressRequest(payload));
    const token = localStorage.getItem(TOKEN_KEY);
    axios
      .get(
        `${BASE_URL}/users/course-progress?token=${token}&course_id=${payload.course_id}`,
        headerLang,
      )
      .then(response => {
        if (response.data.statusCode === 200)
          dispatch(getCourseProgressSuccess(response.data));
        else
          dispatch(
            getCourseProgressFailure(
              response.data.errors.join('. '),
            ),
          );
      })
      .catch(error =>
        dispatch(getCourseProgressFailure(error.message)),
      );
  };
};

const getCourseProgressRequest = payload => ({
  type: GET_COURSE_PROGRESS_REQUEST,
  payload: payload,
});

const getCourseProgressSuccess = response => ({
  type: GET_COURSE_PROGRESS_SUCCESS,
  payload: { ...response },
});

const getCourseProgressFailure = error => ({
  type: GET_COURSE_PROGRESS_FAILURE,
  payload: { error },
});
