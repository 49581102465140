import React, { Component, Fragment } from 'react';
import { get } from 'lodash';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import './BuyInsuranceSuccessful.scss';
import { routes } from '../../constants';
import { getTranslatedText } from '../../services/appService';
import { bindActionCreators } from 'redux';
import { updateOrderAction } from '../../actions/courses';
import { connect } from 'react-redux';
import Breadcrumb from '../Breadcrumb/Breadcrumb';

class BuyInsuranceSuccessful extends Component {

  componentDidMount() {

  }

  render() {

    return (
      <Fragment>
        <Breadcrumb
          data={[
            { link: routes.home, text: getTranslatedText('HOME') },
            {
              link: routes.courses,
              text: getTranslatedText('COURSE'),
            },
            {
              link: null,
              text: getTranslatedText('purchase_success'),
            }
          ]}
        />
        <div className="BuyInsuranceSuccessful">
          <div className="Title">
            {getTranslatedText('purchase_success')}
          </div>
          <div>{getTranslatedText('buy_insurance_successful')}</div>
          <Link to={routes.accountCourses}>
            <div className="JoinClassButton">
              {getTranslatedText('learn_now')}
            </div>
          </Link>
          <Link to={routes.capitalInsurance}>
            <div className="JoinClassButton" style={{marginLeft: "50px"}}>
              {getTranslatedText('insurance_history').toUpperCase()}
            </div>
          </Link>

        </div>
      </Fragment>

    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return state;
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    actions: bindActionCreators(
      {
        updateOrderAction,
      },
      dispatch,
    ),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(BuyInsuranceSuccessful);
