import React, {useState, useEffect} from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import {
  getListVideoCategoryAction,
} from '../../../actions/documents';

import {
  List,
  Modal,
} from 'antd';

import ReactPlayer from 'react-player';


import VideoCategoryItem from '../components/VideoCategoryItem/VideoCategoryItem';

import './styles/video-list-styles.scss';


function VideoList(props) {

  const {
    actions,
    video_category_list,
  } = props;

  const [modalVisible, setModalVisible] = useState(false);
  const [videoLink, setVideoLink] = useState('');
  const [modalTitle, setModalTitle] = useState('');

  useEffect(() => {
    actions.getListVideoCategoryAction();
  }, []);

  function handleOnVideoCardClick(videoLink, title) {
    setVideoLink(videoLink);
    setModalTitle(title);
    setModalVisible(true);
  };

  return (
    <React.Fragment>
      <List
        id='video-category-list-wrapper'
        dataSource={video_category_list !== null ? video_category_list : []}
        renderItem={video_category => (
          <VideoCategoryItem
            video_category={video_category}
            handleOnVideoCardClick={handleOnVideoCardClick}
          />
        )}
      />
      <Modal
        className='video-modal-wrapper'
        destroyOnClose
        visible={modalVisible}
        onCancel={() => setModalVisible(!modalVisible)}
        title={modalTitle}
        footer={null}
      >
        <ReactPlayer
          className='react-player-video-modal-wrapper'
          url={videoLink}
          controls
          playing
        />
      </Modal>
    </React.Fragment>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    video_category_list: state.document.video_state,
  };
};
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    actions: bindActionCreators(
      {
        getListVideoCategoryAction,
      },
      dispatch,
    ),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VideoList);