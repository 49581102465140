import React from 'react';
import { Link } from 'react-router-dom';
import { routes } from '../../../../constants';
import { getTranslatedText } from '../../../../services/appService';

const AudioBookOrderSuccess = () => {
  return (
    <div className='w-full max-w-screen-xl mx-auto'>
      <div className='w-full space-y-10 pt-12 mb-24'>
        <p className='font-bold'>{getTranslatedText('request_payment_success')}</p>
        <p>{getTranslatedText('audio_book_buy_success_message')}</p>
        <div className='bg-brown w-80 py-2 rounded-xl'>
          <Link to={routes.audio_book.list}>
            <p className='text-white font-semibold text-center'>{getTranslatedText('audio_book_cta_btn')}</p>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default AudioBookOrderSuccess;