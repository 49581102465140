import React from 'react';
import PropTypes from 'prop-types';
import { getTranslatedText } from '../../../../services/appService';

const AudioBookCheckout = ({ balance, onClick }) => {
  return (
    <div className="w-full max-w-screen-xl mx-auto">
      <p className="text-darkgrey font-bold text-xl mb-2">
        {getTranslatedText('order_info_title')}
      </p>
      <div className="w-full flex flex-col lg:flex-row gap-y-10 gap-x-28">
        <div className="w-full lg:w-2/3">
          <div className="w-full px-3 pt-6 pb-11 shadow">
            <div className="w-full flex justify-between pb-3 border-b-2">
              <div className="text-darkgrey space-y-1">
                <p>{getTranslatedText('product_name_title')}</p>
                <p className="font-bold text-xl">AUDIOBOOKS</p>
              </div>
              <div>
                <p className="text-gold text-center mb-1">Price</p>
                <div className="bg-gold rounded-xl px-11 py-1 text-white text-xl">
                  <p>
                    $ <span className="font-bold">15</span>
                  </p>
                </div>
              </div>
            </div>
            <div className="w-full pt-3">
              <p>
                {getTranslatedText('license_title')}{' '}
                <span className="font-bold ml-9">
                  1 {getTranslatedText('year_title')}
                </span>
              </p>
            </div>
          </div>
        </div>
        <div className="w-full lg:w-1/3 bg-white border-2 border-dashed border-darkgrey">
          <div className="w-full bg-gold border-b-2 border-dashed border-darkgrey text-center py-1 font-bold text-white text-xl">
            {getTranslatedText('your_wallet')}
          </div>
          <div className="w-full px-2 pt-3">
            <div className="w-full pb-2 border-b-2 flex justify-between items-center">
              <p>{getTranslatedText('balance_title')}</p>
              <p className="text-black text-sm">
                <span className="font-bold text-2xl text-grey">
                  {balance}
                </span>{' '}
                usd
              </p>
            </div>
            <div className="w-full mt-3 pb-2 border-b-2 flex justify-between items-center">
              <p>{getTranslatedText('need_purchase_title')}</p>
              <p className="text-black text-sm">
                <span className="text-2xl font-bold">15</span> usd
              </p>
            </div>
            <button
              className="w-full bg-brown mt-10 font-bold text-white py-1 text-center text-lg"
              onClick={onClick}
            >
              {getTranslatedText('purchase_now')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

AudioBookCheckout.propTypes = {
  balance: PropTypes.number,
  onClick: PropTypes.func,
};

export default AudioBookCheckout;
