import React from 'react';
import './styles/document-card-styles.scss';

function DocumentCard(props) {

  const {
    document,
  } = props;
  
  function handleOnClickDownload() {
    window.open(document.link, "_self");
  };
  
  return (
    <React.Fragment>
      <div className='document-card-wrapper'>
        <div className='cover-document-card-wrapper'>
          <img
            className='image-cover-document-card-wrapper'
            src={document.thumbnail}
            alt=''
          />
          <button
            className='download-button-document-card-wrapper'
            onClick={() => handleOnClickDownload()}
          >
            <img src='/speaker/download_icon.png' alt='' />
          </button>
        </div>
        <div className='content-document-card-wrapper'>
          <p>{document.name}</p>
        </div>
      </div>
    </React.Fragment>
  );
};

export default DocumentCard;