import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Row, Col } from 'antd';
import { getTranslatedText } from '../../../services/appService';
import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb';
import { routes } from '../../../constants';

import MemberCard from '../component/MemberCard';
import MemberRank from '../component/MemberRank';
import PrivilegeContainer from './PrivilegeContainer';
import RedemHistory from './RedemHistory';
import AccountBreadCrumb from '../../../components/AccountBreadcrumb/AccountBreadcrumb';

import { getProfileAction } from '../../../actions/profile';

import '../styles/accumulatecontainerstyle.scss';
import SwapMoney from './SwapMoney';

function AccumulateContainer() {
  const [currentUser, setCurrentUser] = useState({});
  const [currentPoint, setCurrentPoint] = useState('0');
  const [renderChildContainer, setRenderChildContainer] = useState(
    'promotion',
  );

  useEffect(() => {
    let user = localStorage.getItem('current_user');
    if (user) {
      let userJson = JSON.parse(user);
      setCurrentUser(userJson);
      setCurrentPoint(userJson.stats.point);
    }
  }, []);

  useEffect(() => {
    console.log(currentUser);
    console.log(currentPoint);
  }, [currentUser]);

  function renderChilContainerAction() {
    switch (renderChildContainer) {
      case 'promotion':
        return (
          <React.Fragment>
            <PrivilegeContainer memberRank={currentUser.rank} />
          </React.Fragment>
        );
      case 'redemHistory':
        return (
          <React.Fragment>
            <RedemHistory />
          </React.Fragment>
        );
      case 'swapMoney':
        return (
          <React.Fragment>
            <SwapMoney
              currentPoint={currentPoint}
              setRenderChildContainer={setRenderChildContainer}
            />
          </React.Fragment>
        );
    }
  }

  return (
    <React.Fragment>
      {/* <Breadcrumb
        data={[
          { 
            link: routes.home,
            text: getTranslatedText('home')
          },
          {
            link: routes.accumulate,
            text: getTranslatedText('accumulate')
          }
        ]}
      /> */}
      <AccountBreadCrumb />
      <Row gutter={16} id="container-wrap">
        <Col
          className="mb-5"
          xs={{ span: 24 }}
          md={{ span: 24 }}
          lg={{ span: 5 }}
        >
          <MemberCard
            memberName={currentUser.name}
            memberCode={currentUser.code}
            memberAvatar={currentUser.avatar}
            memberScore={currentPoint}
            setRenderChildContainer={setRenderChildContainer}
          />
        </Col>
        <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 18 }}>
          <Row gutter={16}>
            <Col
              xs={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 24 }}
            >
              <MemberRank
                memberRank={currentUser.rank}
                setRenderChildContainer={setRenderChildContainer}
              />
            </Col>
            <Col
              xs={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 24 }}
            >
              {renderChilContainerAction()}
            </Col>
          </Row>
        </Col>
      </Row>
    </React.Fragment>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    dashboard: state.profile.data || {},
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    actions: bindActionCreators(
      {
        getProfileAction,
      },
      dispatch,
    ),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AccumulateContainer);
