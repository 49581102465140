import React, { useState } from 'react';
import { get } from 'lodash';
import './CourseLevel.scss';
import TimeIcon from '../../assets/images/icon_time.svg';
import BookIcon from '../../assets/images/icon_book.svg';
import OwnerIcon from '../../assets/images/icon_owner.svg';
import { routes } from '../../constants';
import { useHistory } from 'react-router-dom';
import {
  currencyFormatter,
  getTranslatedText,
  getUserFormLocal,
} from '../../services/appService';
import { formatDurationText } from '../../services/appService';
import { Checkbox, Layout, Modal, Row } from 'antd';
import logo from '../../assets/images/logo.png';
import head_phone from '../../assets/images/icons/head_phone_gray.svg';
import SellInfo from '../GoldenWeekPromotion/components/common/Promotion/SellInfo';
import PromotionDiscount from '../GoldenWeekPromotion/components/common/Promotion/Discount';
import AudioPriceCard from '../AudioPriceCard';

function CourseLevel(props) {
  const { courseDetail, userCourses, displayEventPrice, eventSellInfo, eventDiscount, audioBookPriceVisible } = props;
  const [modalVisible, setModalVisible] = useState(false);
  const [select, setSelect] = useState(1);
  const [buyInsurance, setBuyInsurance] = useState(true);
  const history = useHistory();

  let isCourseBought = !!get(userCourses, 'buy', []).find(
    boughtCourse => boughtCourse.id === courseDetail.id,
  );
  const lang = localStorage.getItem('current_lang');

  const onPayClick = () => {
    const url = getUserFormLocal()
      ? routes.courseOrder
        .replace(':id', courseDetail.id)
        .replace(':insurance', (buyInsurance) ? 1 : 0)
      : routes.signin;
    history.push(url);
  };

  const onBuyInsurance = () => {
    const url = getUserFormLocal()
      ? routes.buyInsurance.replace(':id', courseDetail.id)
      : routes.signin;
    history.push(url);
  };

  const onSelect = (value) => {
    setSelect(value);
  };

  return (
    <div className='CourseLevelComponentContainer'>
      <div className='CourseName'>
        {lang === 'vi' ? courseDetail.title_vi : courseDetail.title}
      </div>
      <div className='CourseLevelContainer relative'>
        {displayEventPrice && <PromotionDiscount discount={eventDiscount} containerClassName="absolute right-14 top-3 md:right-28 md:top-3.5 lg:right-0" />}
        <div className='CourseLevel'>
          <div className='Level_PriceContainer'>
            <div className='LevelContainer'>
              <div className='Text'>
                {getTranslatedText('level')}
              </div>
              <div className='Level'>{courseDetail.level}</div>
            </div>
            {displayEventPrice && eventSellInfo.soldNum < eventSellInfo.total ? (
              <div className='PriceContainerWrap flex-col items-center'>
                <div className='Text'>
                  {getTranslatedText('price')}
                </div>
                <div className='NumberContainer max-w-max'
                  style={{
                    backgroundColor: '#d59e29'
                  }}
                >
                  <div className='PriceNumber'>
                    <span className="text-red-600 font-extrabold text-sm md:text-md lg:text-xl">
                      {currencyFormatter((courseDetail.price * (100 - eventDiscount)) / 100, true)}
                    </span>
                    <sup className='Currency'>USD</sup>
                    <span className="pl-2.5 text-white font-medium text-8 line-through md:text-10 lg:text-md">
                      {currencyFormatter(courseDetail.price, true)}
                    </span>
                    <sup className='Currency'>USD</sup>
                  </div>
                </div>
              </div>
            ) : (
              <div className='PriceContainerWrap'>
                <div className='PriceContainer'>
                  <div className='Text'>
                    {getTranslatedText('price')}
                  </div>
                  {courseDetail.promotion_price !== courseDetail.price ? (
                    <div className='NumberContainer'
                      style={{
                        backgroundColor: '#FFD371'
                      }}
                    >
                      <div className='PriceNumber'>
                        <span>
                          {currencyFormatter(courseDetail.price, true)}
                        </span>
                        <sup className='Currency'>USD</sup>
                      </div>
                    </div>
                  ) : (
                    <div className='NumberContainer'
                      style={{
                        backgroundColor: '#d59e29'
                      }}
                    >
                      <div className='PriceNumber'>
                        <span>
                          {currencyFormatter(courseDetail.price, true)}
                        </span>
                        <sup className='Currency'>USD</sup>
                      </div>
                    </div>
                  )}
                </div>
                {courseDetail.promotion_price !== courseDetail.price && (
                  <div className='PriceContainer'>
                    <div className='Text'>
                      {getTranslatedText('promotion_price')}
                    </div>
                    <div className='NumberContainer'
                      style={{
                        backgroundColor: '#d59e29'
                      }}
                    >
                      <div className='PriceNumber'>
                        <span>
                          {currencyFormatter(courseDetail.promotion_price, true)}
                        </span>
                        <sup className='Currency'>USD</sup>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
          {displayEventPrice && (
            <SellInfo
              containerClassName="bg-gold-50 bg-opacity-50 rounded-2xl w-full md:w-60 h-8 mx-auto flex items-center justify-center divide-x divide-grey mb-2"
              soldNum={eventSellInfo.soldNum}
              remainNum={eventSellInfo.remainNum}
              total={eventSellInfo.total}
            />
          )}
          {!isCourseBought && (
            <div className='AddInsurance'>
              <div className='Text'>
                <Checkbox checked={buyInsurance}
                  onChange={e => setBuyInsurance(e.target.checked)} /> {getTranslatedText('insurance_purchase')}
              </div>
              <div className='FeeInsurance'>
                <span>{getTranslatedText('insurance_fee')}</span>
                <div className='NumberContainer'
                  style={{
                    backgroundColor: '#d59e29'
                  }}
                >
                  <div className='PriceNumber'>
                    <span>{courseDetail.price / 10}</span>
                    <sup className='Currency'>$</sup>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className='PayNow'>
            {!isCourseBought && (
              <div className='PayButton' onClick={() => onPayClick()}>
                {getTranslatedText('purchase_now')}
              </div>
            )}
            <div className='Include'>
              {getTranslatedText('course_include')}:
              <div className='Quantity'>
                {getTranslatedText('combo')}{' '}
                {courseDetail.total_course}{' '}
                {getTranslatedText('course')}
              </div>
            </div>
          </div>
          <div className='Info'>
            <div className='Container'>
              <img alt='time' src={TimeIcon}></img>
              <div className='Text'>
                {getTranslatedText('duration')}{' '}
                <span>
                  {formatDurationText(courseDetail.duration)}
                </span>
              </div>
            </div>
            <div className='Container'>
              <img alt='book' src={BookIcon}></img>
              <div className='Text'>
                {getTranslatedText('content')}:{' '}
                <span>{courseDetail.total_video} videos</span>
              </div>
            </div>
            <div className='Container'>
              <img alt='book' src={head_phone}></img>
              <div className='Text'>
                {getTranslatedText('audio_license_title')}:{' '}
                <span>{courseDetail.price < 500 ? `1 ${getTranslatedText('year_title')}` : getTranslatedText('lifetime')}</span>
              </div>
            </div>
            <div className='Container'>
              <img alt='own' src={OwnerIcon}></img>
              <div className='Text'>
                {getTranslatedText('lifetime')}
              </div>
            </div>
          </div>
        </div>
        {(isCourseBought && (courseDetail.can_join_insurance == 1)) && (
          <div className='CourseLevel InsurranceBox'>
            <div className='TitleContainer'>
              <div className='PrivilegesTitle'>
                <p>{getTranslatedText('user_promotion')}</p>
              </div>
              <div className='BoxInsurranceTitle'>
                <p dangerouslySetInnerHTML={{ __html: getTranslatedText('insurance_package') }}>
                </p>
              </div>
            </div>
            <div className='TitleFeeContainer'>
              <p>
                <b>{getTranslatedText('insurance_fee')}:</b>
              </p>
              <i>{getTranslatedText('insurance_fee_percent')}</i>
            </div>
            <div className='FeeBox'>
              <div className='Box'>
                <b>{courseDetail.price / 10}</b>
                <span>usd</span>
              </div>
            </div>
            <br />
            <hr />
            <br />
            <div className='ButtonBuyContainer'>
              <div
                className='BuyButton'
                onClick={() => onBuyInsurance()}
              >
                <b>{getTranslatedText('want_buy')}</b>
              </div>
            </div>
            <br />
            <hr />
            <div className='QuestionContainer'>
              <p>
                <i
                  onClick={() => setModalVisible(true)}
                >
                  {getTranslatedText('insurance_frequently')}
                </i>
              </p>
            </div>
          </div>
        )}
      </div>
      <Modal
        visible={modalVisible}
        footer={false}
        width={1000}
        onCancel={() => setModalVisible(false)}
        destroyOnClose={true}
        style={{ zIndex: '2' }}
      >
        <React.Fragment>
          <Layout.Header className='logo__container'>
            <img src={logo} width='150px' alt='' />
          </Layout.Header>
          <Layout.Content className='content__container'>
            <Row>
              <div className='wrapper__content'>
                <div className='left'>
                  <div className={'question ' + ((select === 1) ? 'active' : '')}
                    onClick={() => onSelect(1)}>
                    {getTranslatedText('insurance_who')}
                  </div>
                  <div className={'question ' + ((select === 2) ? 'active' : '')}
                    onClick={() => onSelect(2)}>
                    {getTranslatedText('insurance_long')}
                  </div>
                  <div className={'question ' + ((select === 3) ? 'active' : '')}
                    onClick={() => onSelect(3)}>
                    {getTranslatedText('insurance_recommend')}
                  </div>
                  <div className={'question ' + ((select === 4) ? 'active' : '')}
                    onClick={() => onSelect(4)}>
                    {getTranslatedText('insurance_stop')}
                  </div>
                  <div className={'question ' + ((select === 5) ? 'active' : '')}
                    onClick={() => onSelect(5)}>
                    {getTranslatedText('insurance_how_refund')}
                  </div>
                </div>
                <div className='right'>
                  {select === 1 && (
                    <div className='answer__container'>
                      <h4>
                        <b>{getTranslatedText('insurance_who')}</b>
                      </h4>
                      <p dangerouslySetInnerHTML={{ __html: getTranslatedText('insurance_who_answer') }} />
                    </div>
                  )}
                  {select === 2 && (
                    <div className='answer__container'>
                      <h4>
                        <b>{getTranslatedText('insurance_long')}</b>
                      </h4>
                      <p dangerouslySetInnerHTML={{ __html: getTranslatedText('insurance_long_answer') }} />
                    </div>
                  )}
                  {select === 3 && (
                    <div className='answer__container'>
                      <h4>
                        <b>{getTranslatedText('insurance_recommend')}</b>
                      </h4>
                      <p dangerouslySetInnerHTML={{ __html: getTranslatedText('insurance_recommend_answer') }} />
                    </div>
                  )}
                  {select === 4 && (
                    <div className='answer__container'>
                      <h4>
                        <b>{getTranslatedText('insurance_stop')}</b>
                      </h4>
                      <p dangerouslySetInnerHTML={{ __html: getTranslatedText('insurance_stop_answer') }} />
                    </div>
                  )}
                  {select === 5 && (
                    <div className='answer__container'>
                      <h4>
                        <b>{getTranslatedText('insurance_how_refund')}</b>
                      </h4>
                      <p dangerouslySetInnerHTML={{ __html: getTranslatedText('insurance_how_refund_answer') }} />
                    </div>
                  )}
                </div>
              </div>
            </Row>
          </Layout.Content>
          <Layout.Footer className='footer__container'>
            <Row gutter={16}></Row>
          </Layout.Footer>
        </React.Fragment>
      </Modal>
      {audioBookPriceVisible && (
        <div className='AudioBookContainer mt-3'>
          <AudioPriceCard />
        </div>
      )}
    </div>
  );
}

export default CourseLevel;
