import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  getRedeemHistory,
} from '../../../actions/accumulate';
import {
  Table,
  Row,
  Col,
} from 'antd';

import '../styles/redemhistorystyle.scss';
import { getTranslatedText } from '../../../services/appService';


const columns = [
  {
    title: getTranslatedText('update_at'),
    dataIndex: 'updated_at',
    key: 'daupdated_atte'
  },
  {
    title: getTranslatedText('name_redeem'),
    dataIndex: 'title',
    key: 'title',
  },
  {
    title: getTranslatedText('point_redeem'),
    dataIndex: 'point',
    key: 'point'
  },
  {
    title: getTranslatedText('redeem_status'),
    dataIndex: 'status',
    key: 'status',
    render: text => getTranslatedText(text),
  }
];

function RedemHistory(props) {

  const {
    redeemHistory,
    actions
  } = props;

  useEffect(() => {
    actions.getRedeemHistory(
      {
        token: localStorage.getItem('token'),
      }
    );
  }, []);

  function renderTotal() {
    let total = 0;
    redeemHistory.forEach(element => {
      let point = parseFloat(element.point);
      total += point;
    });
    return total;
  }

  return (
    <React.Fragment>
      <Row
        id='redem-history-container'
        gutter={[16, 16]}
      >
        <Col span={24}>
          <p id='container-header'>{getTranslatedText('history_exchange_title')}</p>
        </Col>
        <Col span={24}>
          <Table
            className='redem-history-table'
            columns={columns}
            dataSource={redeemHistory}
          />
        </Col>
        <Col span={24}>
          <div id='container-footer'>
            <p>{getTranslatedText('redeem_total')}</p>
            <p id='total-score'>{renderTotal()}</p>
          </div>  
        </Col>
      </Row>
    </React.Fragment>
  );
};

const mapStateToProps = state => {
  return {
    redeemHistory: state.accumulate.redeemHistory || [],
  };
};

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(
      {
        getRedeemHistory
      },
      dispatch
    ),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RedemHistory);