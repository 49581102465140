import React from 'react';
import { Link } from 'react-router-dom';
import { routes } from '../../../../constants';

function HowItWorkEn(props) {
  return (
    <div className="w-full">
      <div className="w-full">
        <p className="px-5 text-center font-bold text-lg xl:text-3xl text-black mb-3">
          OPTIMA GROUP - HOW IT WORK
        </p>
        <p className="text-center sm:text-sm md:text-base xl:px-5 text-black">
          Optima Group was established on September 16, 2019, registered and incorporated in Singapore.
          Formerly known as Edunetwork Global, the company, after its first flourishing year, has strategically planned to expand into more areas in the future, besides education. Hence, the company has changed its name to Optima Group, reflecting this vision.
          In the Education category, members can join a course combo and will receive the following benefits:
        </p>
      </div>
      <div className="w-full grid grid-cols-1 xl:grid-cols-2 gap-5 xl:gap-12 mt-5">
        <div className="w-full h-0 pb-3/4 relative">
          <img
            className="w-full h-full absolute inset-0 object-cover"
            src="/assets/images/co_che_hoat_dong_1.png"
            alt="co-che-hoat-dong" />
        </div>
        <div className="w-full text-left">
          <p className="text-gold font-bold sm:text-base md:text-2xl mb-2.5">LEARNING RIGHTS</p>
          <div className="w-full flex items-start gap-1.5">
            <img src="/assets/images/arrow-right.svg" alt="arrow-right-icon" />
            <p className="text-black sm:text-sm md:text-base">
              Different course combos are curated from renowned and world-class speakers, who have been making an impact on people all over the world.
            </p>
          </div>
          <div className="w-full flex items-start gap-1.5 mt-8">
            <img src="/assets/images/arrow-right.svg" alt="arrow-right-icon" />
            <p className="text-black sm:text-sm md:text-base">
              These courses provide many hours of training and are constantly updated to keep up with the evolving trends, giving you a huge amount of knowledge.
              You can have lifetime access to various updated knowledge with only a one-time tuition fee.
            </p>
          </div>
          <div className="w-full flex items-start gap-1.5 mt-8">
            <img src="/assets/images/arrow-right.svg" alt="arrow-right-icon" />
            <p className="text-black sm:text-sm md:text-base">
              You can get lifelong learning at no extra maintenance cost.
              This is something that no other platform can do. Optima Group pioneers to bring the most convenience and affordability to students.

            </p>
          </div>
          <div className="w-full flex items-start gap-1.5 mt-8">
            <img src="/assets/images/arrow-right.svg" alt="arrow-right-icon" />
            <p className="text-black sm:text-sm md:text-base">
              Our digital educational products are amazing with a wide variety of courses. To keep up with the evolving society and ever-changing global economy, we must study and innovate every day to grow ourselves.

            </p>
          </div>
          <div className="w-full flex items-start gap-1.5 mt-8">
            <img src="/assets/images/arrow-right.svg" alt="arrow-right-icon" />
            <p className="text-black sm:text-sm md:text-base">
              Discover our wide library of audiobooks with preferential pricing at only $15/year on many various categories. Each month, we release new audiobooks, from which we select only the best ones to be featured exclusively on Optima’s platform.

            </p>
          </div>
        </div>
      </div>
      <div className="w-full grid grid-cols-1 xl:grid-cols-2 gap-5 xl:gap-12 mt-5 xl:pt-5">
        <div className="w-full h-0 pb-3/4 relative block xl:hidden">
          <img
            className="w-full h-full absolute inset-0 object-cover"
            src="/assets/images/co_che_hoat_dong_2.png"
            alt="co-che-hoat-dong" />
        </div>
        <div className="w-full text-left">
          <p className="text-gold font-bold sm:text-base md:text-2xl mb-2.5">BUSINESS RIGHTS</p>
          <div className="w-full flex items-start gap-1.5">
            <img src="/assets/images/arrow-right.svg" alt="arrow-right-icon" />
            <p className="text-black sm:text-sm md:text-base">
              If you find the courses useful and want to promote their value to others, the company also grants you a completely free right to become an affiliate.
            </p>
          </div>
          <div className="w-full flex items-start gap-1.5 mt-8">
            <img src="/assets/images/arrow-right.svg" alt="arrow-right-icon" />
            <p className="text-black sm:text-sm md:text-base">
              You are provided with a unique link from the company based on your login name.
              You can immediately apply your acquired knowledge to promote the course(s) you own.
            </p>
          </div>
          <div className="w-full flex items-start gap-1.5 mt-8">
            <img src="/assets/images/arrow-right.svg" alt="arrow-right-icon" />
            <p className="text-black sm:text-sm md:text-base">
              Your direct affiliate income is paid immediately after each successful order from 50% to 90% of the course value. Safe, convenient, with fast withdrawal.
            </p>
          </div>
          <div className="w-full flex items-start gap-1.5 mt-8">
            <img src="/assets/images/arrow-right.svg" alt="arrow-right-icon" />
            <p className="text-black sm:text-sm md:text-base">
              For our audiobooks, you can market and sell them to an unlimited number of customers. For every successful customer purchase, we will pay you a 60% affiliate commission.
            </p>
          </div>
        </div>
        <div className="w-full h-0 pb-3/4 hidden xl:block relative">
          <img
            className="w-full h-full absolute inset-0 object-cover"
            src="/assets/images/co_che_hoat_dong_2.png"
            alt="co-che-hoat-dong" />
        </div>
      </div>
      <div className="w-full grid grid-cols-1 xl:grid-cols-2 gap-5 xl:gap-12 mt-5 xl:pt-5">
        <div className="w-full h-0 pb-3/4 relative">
          <img
            className="w-full h-full absolute inset-0 object-cover"
            src="/assets/images/co_che_hoat_dong_3.png"
            alt="co-che-hoat-dong" />
        </div>
        <div className="w-full text-left">
          <p className="text-gold font-bold sm:text-base md:text-2xl mb-2.5">BONUSES</p>
          <div className="w-full flex items-start gap-1.5">
            <img src="/assets/images/arrow-right.svg" alt="arrow-right-icon" />
            <div>
              <p className="text-black font-bold sm:text-sm md:text-base">For skill-building courses:</p>
              <p className="text-black sm:text-sm md:text-base">
                You will be received a token of appreciation from your direct referral (to whom you have given this opportunity)
                When your referral receives the second order of the course they own, their referral income will be given to you as a token of appreciation.
              </p>
              <p className="text-black mt-8 sm:text-sm md:text-base">
                This only applies once.
                This good-willed gesture represents the gratitude within Optima Group's online education community.
                You must also give this token of appreciation to the person who has given you this opportunity. (For only one single time)
              </p>
            </div>
          </div>
          <div className="w-full flex items-start gap-1.5 mt-8">
            <img src="/assets/images/arrow-right.svg" alt="arrow-right-icon" />
            <div>
              <p className="text-black font-bold sm:text-sm md:text-base">For English-learning courses:</p>
              <p className="text-black sm:text-sm md:text-base">
                You are rewarded with an additional 2-5% by the company when taking good care of your direct referral(s).
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full grid grid-cols-1 xl:grid-cols-2 gap-5 xl:gap-12 mt-5 xl:pt-5">
        <div className="w-full h-0 pb-3/4 relative block xl:hidden">
          <img
            className="w-full h-full absolute inset-0 object-cover"
            src="/assets/images/co_che_hoat_dong_4.png"
            alt="co-che-hoat-dong" />
        </div>
        <div className="w-full text-left">
          <p className="text-gold font-bold sm:text-base md:text-2xl mb-2.5">EARNING POINTS</p>
          <div className="w-full flex items-start gap-1.5">
            <img src="/assets/images/arrow-right.svg" alt="arrow-right-icon" />
            <p className="text-black sm:text-sm md:text-base">
              When you have an order, you not only receive the affiliate income (= 50% - 90% of the order's value) immediately, but also accumulate points to exchange for many attractive gifts.
              (See details of the gifts in the Point Accumulation section)
            </p>
          </div>
        </div>
        <div className="w-full h-0 pb-3/4 relative hidden xl:block">
          <img
            className="w-full h-full absolute inset-0 object-cover"
            src="/assets/images/co_che_hoat_dong_4.png"
            alt="co-che-hoat-dong" />
        </div>
      </div>
      <div className="w-full grid grid-cols-1 xl:grid-cols-2 gap-5 xl:gap-12 mt-5 xl:pt-5">
        <div className="w-full h-0 pb-3/4 relative">
          <img
            className="w-full h-full absolute inset-0 object-cover"
            src="/assets/images/co_che_hoat_dong_5.png"
            alt="co-che-hoat-dong" />
        </div>
        <div className="w-full text-left">
          <p className="text-gold font-bold sm:text-base md:text-2xl mb-2.5">SCHOLARSHIP</p>
          <div className="w-full flex items-start gap-1.5">
            <img src="/assets/images/arrow-right.svg" alt="arrow-right-icon" />
            <p className="text-black sm:text-sm md:text-base">
              This reward program is optional and is not compulsory for you to join. You have the option to opt-in or out.
            </p>
          </div>
          <div className="w-full flex items-start gap-1.5 mt-8">
            <img src="/assets/images/arrow-right.svg" alt="arrow-right-icon" />
            <p className="text-black sm:text-sm md:text-base">
              In the event of joining, you will be charged an additional 10% of the course fee to participate in this program. You will have the opportunity to receive a scholarship to sponsor the original course fee according to the conditions of this program.
            </p>
          </div>
          <div className="w-full flex items-start gap-1.5 mt-8">
            <img src="/assets/images/arrow-right.svg" alt="arrow-right-icon" />
            <p className="text-black sm:text-sm md:text-base">
              Note: It is not an investment program and is not compulsory. Please do your due diligence before participating in this optional program.
            </p>
          </div>
          <div className="w-full flex items-start gap-1.5 mt-8">
            <img src="/assets/images/arrow-right.svg" alt="arrow-right-icon" />
            <p className="text-black sm:text-sm md:text-base">
              Do note that the primary purpose at Optima is to learn and become a lifelong affiliate with a very affordable one-time course fee.
            </p>
          </div>
        </div>
      </div>
      <Link to={routes.courses}>
        <button className="bg-gold py-2.5 px-10 xl:px-24 rounded-3xl mt-12 xl:mt-24">
          <p className="font-bold text-sm xl:text-2xl text-center text-white">VIEW ALL COURSES</p>
        </button>
      </Link>
    </div>
  );
}

export default HowItWorkEn;