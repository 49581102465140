import React, { Component } from 'react';
import './styles.scss';
import { getTranslatedText } from '../../../../services/appService';

class ItemBoxInsurance extends Component {
  render() {
    let { insurance } = this.props;
    return (
      <div className="container__item__insurance">
        <div className="insurance__name">
          <p className="label">{getTranslatedText("insurance_2")}</p>
          <p className="name">{insurance.title}</p>
        </div>
        <div className="money__back">
          <p className="label">{getTranslatedText("insurance_refunded_2")}</p>
          <p className="cash">${insurance.total_refund}</p>
        </div>
      </div>
    );
  }
}

export default ItemBoxInsurance;
