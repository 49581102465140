import React, { Component } from 'react';
import { get } from 'lodash';
import AccountBreadcrumb from '../AccountBreadcrumb/AccountBreadcrumb';
import ManagerRank from '../../assets/images/manager_rank.svg';
import DirectorRank from '../../assets/images/director_rank.svg';
import './AccountDashboardPage.scss';
import DashboardChart from '../../components/DashboardChart/DashboardChart';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  getUserDashboardAction,
  getProfileAction,
  getChildUserAction,
  getBonusList, getIntroduceList,
} from '../../actions/profile';
import {
  capitalizeFirstLetter,
  currencyFormatter,
  getTranslatedText,
  getUserFormLocal,
} from '../../services/appService';
import * as types from '../../actions/index';
import { routes } from '../../constants';
import denied from '../../assets/images/denied.png';
import success from '../../assets/images/success.png';
import warning from '../../assets/images/warning.png';
import gold from '../../assets/images/member-gold.png';
import member from '../../assets/images/member-medal.png';
import platinum from '../../assets/images/member-platinum.png';

class AccountDashboardPage extends Component {
  state = {
    currentUser: {},
    childMenu: 'paid',
  };

  componentDidMount() {
    this.props.actions.getUserDashboardAction();
    this.setState({
      currentUser: getUserFormLocal(),
    });
    this.props.actions.getChildUserAction({
      page: 1,
      active: 1,
      token: localStorage.getItem('token'),
    });
    this.props.actions.getChildUserAction({
      page: 1,
      token: localStorage.getItem('token'),
    });
    this.props.actions.getChildUserAction({
      page: 1,
      active: 1,
      isEnglish: 1,
      token: localStorage.getItem('token'),
    });
    this.props.actions.getChildUserAction({
      page: 1,
      isEnglish: 1,
      token: localStorage.getItem('token'),
    });
    this.props.actions.getProfileAction({
      token: localStorage.getItem(types.TOKEN_KEY),
    });
    this.props.actions.getBonusList(1);
    this.props.actions.getIntroduceList(1);
  }

  toggle = target => {
    this.setState({ childMenu: target });
  };

  showPage = (page, active, isEnglish) => {
    this.props.actions.getChildUserAction({
      page: page,
      active: active,
      isEnglish: isEnglish,
      token: localStorage.getItem('token'),
    });
  };

  loadingTableBody() {
    const { childMenu } = this.state;
    let data = {data: []};
    switch (childMenu){
      case 'paid': data = this.props.activeUser; break;
      case 'unpaid': data = this.props.inactiveUser; break;
      case 'paid_english': data = this.props.activeUserEnglish; break;
      case 'unpaid_english': data = this.props.inactiveUserEnglish; break;
    }
    return (
      <tbody className='MemberTableBody'>
      {data.data.map((item, index) => (
        <tr key={index}>
          <td>{item.name}</td>
          <td>{item.code}</td>
          <td>{item.email}</td>
          <td>{item.phone}</td>
          <td>{item.register_date}</td>
          <td>
            {currencyFormatter(((childMenu === "paid_english" || childMenu === "unpaid_english")? item.max_price_english : item.max_price) || 0)}
          </td>
          <td>
            {currencyFormatter(item.commission) || 0}
          </td>
        </tr>
      ))}
      </tbody>
    );
  }

  loadingTablePaging(){
    let data = {data: []};
    let active = 0;
    let isEnglish = 0;
    switch (this.state.childMenu){
      case 'paid': {
        data = this.props.activeUser;
        active = 1;
        break;
      }
      case 'unpaid': {
        data = this.props.inactiveUser;
        break;
      }
      case 'paid_english': {
        data = this.props.activeUserEnglish;
        active = 1;
        isEnglish = 1;
        break;
      }
      case 'unpaid_english': {
        data = this.props.inactiveUserEnglish;
        isEnglish = 1;
        break;
      }
    }

    return (
      <div className='pagination'>
        {data.current_page != 1 &&
        data.current_page != 2 && (
          <>
            <a
              href={null}
              onClick={() => this.showPage(1, active, isEnglish)}
            >
              &laquo;
            </a>
            <a href={null}>...</a>
          </>
        )}
        {data.current_page > 1 &&
        data.current_page != 1 && (
          <a
            href={null}
            onClick={() =>
              this.showPage(data.current_page - 1, active, isEnglish)
            }
          >
            {data.current_page - 1}
          </a>
        )}
        <a href={null} className='active'>
          {data.current_page}
        </a>
        {data.current_page < data.last_page &&
        data.current_page != data.last_page && (
          <a
            href={null}
            onClick={() =>
              this.showPage(data.current_page + 1, active, isEnglish)
            }
          >
            {data.current_page + 1}
          </a>
        )}
        {data.current_page != data.last_page &&
        data.current_page !=
        data.last_page - 1 && (
          <>
            <a href={null}>...</a>
            <a
              href={null}
              onClick={() =>
                this.showPage(data.last_page, active, isEnglish)
              }
            >
              &raquo;
            </a>
          </>
        )}
      </div>
    )
  }

  showPageBonus(page){
    this.props.actions.getBonusList(page)
  }

  paging (bonusList, bonus = true){
    return (
      <div className='pagination'>
      {bonusList.current_page != 1 &&
      bonusList.current_page != 2 && (
        <>
          <a
            href={null}
            onClick={() => this.showPageBonus(1)}
          >
            &laquo;
          </a>
          <a href={null}>...</a>
        </>
      )}
      {bonusList.current_page > 1 &&
      bonusList.current_page != 1 && (
        <a
          href={null}
          onClick={() =>
            this.showPageBonus(bonusList.current_page - 1)
          }
        >
          {bonusList.current_page - 1}
        </a>
      )}
      <a href={null} className='active'>
        {bonusList.current_page}
      </a>
      {bonusList.current_page < bonusList.last_page &&
      bonusList.current_page != bonusList.last_page && (
        <a
          href={null}
          onClick={() =>
            this.showPageBonus(bonusList.current_page + 1)
          }
        >
          {bonusList.current_page + 1}
        </a>
      )}
      {bonusList.current_page != bonusList.last_page &&
      bonusList.current_page !=
      bonusList.last_page - 1 && (
        <>
          <a href={null}>...</a>
          <a
            href={null}
            onClick={() =>
              this.showPageBonus(bonusList.last_page)
            }
          >
            &raquo;
          </a>
        </>
      )}
    </div>)
  }

  render() {
    const { isShowPaid, currentUser } = this.state;
    const { dashboard, bonusList, introduceList } = this.props;

    return (
      <div>
        <AccountBreadcrumb />
        <div className='AccountDashboardPage'>
          <div className='Overview'>
            <div className='Title'>
              {getTranslatedText('summary')}
            </div>
            {this.state.currentUser.is_lock ? (
              <div className='danger'>
                <img src={denied} alt='' />
                {getTranslatedText('banned')}
              </div>
            ) : this.state.currentUser.is_verify_contract ? (
              <div className='success'>
                <img src={success} alt='' />
                {getTranslatedText('verified')}
              </div>
            ) : (
              <div className='warning'>
                <img src={warning} alt='' />
                {getTranslatedText('need_verify')}
                <a href={routes.accountWithdraw}>
                  {getTranslatedText('here')}
                </a>
              </div>
            )}
            <div className='OverviewContainer'>
              <div
                className={
                  'MyRank ' +
                  (currentUser.rank === null ? 'NoRank' : '')
                }
              >
                <div className='Text'>
                  <div>{getTranslatedText('your_rank')}</div>
                  <div className='level'>
                    {currentUser.rank || 'member'}
                  </div>
                </div>
                {currentUser.rank ? (
                  <img
                    alt='rank'
                    className='RankImg'
                    src={
                      currentUser.rank === 'member'
                        ? member
                        : currentUser.rank === 'gold'
                        ? gold
                        : platinum
                    }
                  />
                ) : (
                  <img
                    alt='rank'
                    className='RankImg'
                    src={member}
                  />
                )}
              </div>
              <div className='Statistics'>
                <div className='MoneyContainer'>
                  <div className='Money TotalCommission'>
                    <div className='Number'>
                      {(dashboard.total_commission &&
                        currencyFormatter(
                          dashboard.total_commission,
                        )) ||
                      '0'}
                    </div>
                    <div className='Text'>
                      {getTranslatedText('total_commission')}
                    </div>
                  </div>
                  <div className='Money TotalRevenue'>
                    <div className='Number'>
                      {(dashboard.total_revenue &&
                        currencyFormatter(dashboard.total_revenue)) ||
                      '0'}
                    </div>
                    <div className='Text'>
                      {getTranslatedText('total_group_revenue')}
                    </div>
                  </div>
                </div>
                <div className='PeopleContainer'>
                  <div className='People TotalReferral'>
                    <div className='Number'>
                      {dashboard.total_user || '0'}
                    </div>
                    <div className='Text'>
                      {getTranslatedText('total_ref')}
                    </div>
                  </div>
                  <div className='People TotalActive'>
                    <div className='Number'>
                      {dashboard.total_active_user || '0'}
                    </div>
                    <div className='Text'>
                      {getTranslatedText('total_active_user')}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='RevenueChart'>
            <div className='Title'>
              {getTranslatedText('revenue_chart')}
            </div>
            <div className='Chart'>
              <DashboardChart
                data={{
                  revenueByMonth: dashboard.total_revenue_month || [],
                  commissionByMonth:
                    dashboard.total_commission_month || [],
                }}
              />
            </div>
          </div>
          <div className='RevenueTable'>
            <div className='Title'>
              {getTranslatedText('statistic')}
            </div>
            <table className='Table'>
              <thead className='RevenueTableHead'>
              <tr>
                <th className='FirstCell'>
                  {getTranslatedText('group_revenue')}
                </th>
                <th className='EqualCell'>Jan</th>
                <th className='EqualCell'>Feb</th>
                <th className='EqualCell'>Mar</th>
                <th className='EqualCell'>Apr</th>
                <th className='EqualCell'>May</th>
                <th className='EqualCell'>Jun</th>
                <th className='EqualCell'>Jul</th>
                <th className='EqualCell'>Aug</th>
                <th className='EqualCell'>Sep</th>
                <th className='EqualCell'>Oct</th>
                <th className='EqualCell'>Nov</th>
                <th className='EqualCell'>Dec</th>
              </tr>
              </thead>
              <tbody className='RevenueTableBody'>
              <tr>
                <td className='FirstCell'>
                  {getTranslatedText('commission')}
                </td>
                {dashboard &&
                dashboard.total_commission_month &&
                dashboard.total_commission_month.map(
                  (item, index) => (
                    <td key={index}>{currencyFormatter(item)}</td>
                  ),
                )}
              </tr>
              <tr>
                <td>{getTranslatedText('total_group_revenue')}</td>
                {dashboard &&
                dashboard.total_revenue_month &&
                dashboard.total_revenue_month.map(
                  (item, index) => (
                    <td key={index}>{currencyFormatter(item)}</td>
                  ),
                )}
              </tr>
              </tbody>
            </table>
          </div>
          {(currentUser.max_price == 0 && currentUser.max_price_english == 0) ? null : (
            <div className='Member'>
              <div className='Title'>
                {getTranslatedText("bonus_history")}
              </div>
              <table className='Table'>
                <thead className='MemberTableHead'>
                <tr>
                  <th>
                    {getTranslatedText("date")}
                  </th>
                  <th>{getTranslatedText("user_name")}</th>
                  <th>{getTranslatedText("user_code")}</th>
                  <th>{getTranslatedText("user_email")}</th>
                  <th>{getTranslatedText("user_phone")}</th>
                  <th>{getTranslatedText("level")}</th>
                  <th>{getTranslatedText("course_type")}</th>
                  <th>{getTranslatedText("commission")} F1</th>
                  <th>{getTranslatedText("bonus_company")}</th>
                </tr>
                </thead>
                <tbody>
                {bonusList.data.map((item, index) => (
                  <tr key={index}>
                    <td>{item.created_at}</td>
                    <td>{item.f1_name}</td>
                    <td>{item.f1_code}</td>
                    <td>{item.f1_email}</td>
                    <td>{item.f1_phone}</td>
                    <td>{item.course_level}</td>
                    <td>{item.course_type}</td>
                    <td>${item.f1_receive}</td>
                    <td>${item.parent_f1_receive}</td>
                  </tr>
                ))}
                </tbody>
              </table>
              {this.paging(bonusList)}
            </div>
          ) }
          <div className='Member'>
            <div className='Title'>
              {getTranslatedText("affiliate_history")}
            </div>
            <table className='Table'>
              <thead className='MemberTableHead'>
              <tr>
                <th>
                  {getTranslatedText("date")}
                </th>
                <th>{getTranslatedText("user_name")}</th>
                <th>{getTranslatedText("user_code")}</th>
                <th>{getTranslatedText("user_email")}</th>
                <th>{getTranslatedText("user_phone")}</th>
                <th>{getTranslatedText("level")}</th>
                <th>{getTranslatedText("course_type")}</th>
                <th>{getTranslatedText("commission")} F1</th>
              </tr>
              </thead>
              <tbody>
              {introduceList.data.map((item, index) => (
                <tr key={index}>
                  <td>{item.created_at}</td>
                  <td>{item.f1_name}</td>
                  <td>{item.f1_code}</td>
                  <td>{item.f1_email}</td>
                  <td>{item.f1_phone}</td>
                  <td>{item.course_level}</td>
                  <td>{item.course_type}</td>
                  <td>${item.f1_receive}</td>
                </tr>
              ))}
              </tbody>
            </table>
            {this.paging(introduceList)}
          </div>
          <div className='Member'>
            <div className='Title'>
              {getTranslatedText((currentUser.max_price == 0 && currentUser.max_price_english == 0) ? 'your_customer' : 'your_member')}
            </div>
            <div className='Status'>
              <div
                className={'Paid ' + ((this.state.childMenu == 'paid') ? 'active' : '')}
                onClick={() => this.toggle('paid')}
              >
                {getTranslatedText('paid')}
              </div>
              <div
                className={'Paid ' + ((this.state.childMenu == 'unpaid') ? 'active' : '')}
                onClick={() => this.toggle('unpaid')}
                style={{ marginRight: '100px' }}
              >
                {getTranslatedText('unpaid')}
              </div>
              <div
                className={'Paid ' + ((this.state.childMenu == 'paid_english') ? 'active' : '')}
                onClick={() => this.toggle('paid_english')}
                style={{ marginRight: '100px' }}
              >
                {getTranslatedText('paid_english')}
              </div>
              <div
                className={'Paid ' + ((this.state.childMenu == 'unpaid_english') ? 'active' : '')}
                onClick={() => this.toggle('unpaid_english')}
              >
                {getTranslatedText('unpaid_english')}
              </div>

            </div>
            <table className='Table'>
              <thead className='MemberTableHead'>
              <tr>
                <th>
                  {getTranslatedText('full_name').replace('*', '')}
                </th>

                <th>{getTranslatedText('username')}</th>
                <th>Email</th>
                <th>{getTranslatedText('your_phone')}</th>
                <th>
                  {(this.state.childMenu == 'paid' || this.state.childMenu == 'paid_english')
                    ? getTranslatedText('active_date')
                    : getTranslatedText('register_date')}
                </th>
                <th>{getTranslatedText('course')}</th>
                <th>
                  {capitalizeFirstLetter(
                    getTranslatedText('commission').toLowerCase(),
                  )}
                </th>
              </tr>
              </thead>
              {this.loadingTableBody()}
            </table>
            {this.loadingTablePaging()}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ profile }, ownProps) => {
  return {
    dashboard: get(profile, 'dashboard', {}),
    activeUser: get(profile, 'activeUser', {}),
    inactiveUser: get(profile, 'inactiveUser', {}),
    activeUserEnglish: get(profile, 'activeUserEnglish', {}),
    inactiveUserEnglish: get(profile, 'inactiveUserEnglish', {}),
    bonusList: get(profile, 'bonusList', {data: []}),
    introduceList: get(profile, 'introduceList', {data: []}),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    actions: bindActionCreators(
      {
        getUserDashboardAction,
        getProfileAction,
        getChildUserAction,
        getBonusList,
        getIntroduceList
      },
      dispatch,
    ),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AccountDashboardPage);
