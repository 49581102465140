import * as actionTypes from '../actions/index';


const initialState = {
  loading: false,
  document_state: null,
  video_state: null,
  video_by_category_list: null,
  error: null,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET_LIST_DOCUMENT_REQUEST:
      return {
        ...state,
        loading: true
      };
    case actionTypes.GET_LIST_DOCUMENT_SUCCESS:
      return {
        ...state,
        document_state: action.payload.data,
        loading: false 
      };
    case actionTypes.GET_LIST_DOCUMENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      }
    case actionTypes.GET_LIST_VIDEO_CATEGORY_REQUEST:
      return {
        ...state,
        loading: true
      };
    case actionTypes.GET_LIST_VIDEO_CATEGORY_SUCCESS:
      return {
        ...state,
        video_state: action.payload.data,
        loading: false,
      }
    case actionTypes.GET_LIST_VIDEO_CATEGORY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      }
    case actionTypes.GET_LIST_VIDEO_BY_CATEGORY_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.GET_LIST_VIDEO_BY_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
        video_by_category_list: action.payload.data,
      }
    case actionTypes.GET_LIST_VIDEO_BY_CATEGORY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      }
    default:
      return state;
  };
};