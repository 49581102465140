import React, { useState, useEffect } from 'react';
import {
  Button,
  Col,
  Form,
  Input,
  Row,
} from 'antd';
import {
  getTranslatedText,
} from '../../../services/appService';


function DescriptionAndForm(props) {

  const {
    handleOnFinishForm,
    giftName,
    giftScore,
    setModalVisible,
  } = props;

  const [currentUser, setCurrentUser] = useState({});

  useEffect(() => {
    let userText = localStorage.getItem('current_user');
    if (userText) {
      let user = JSON.parse(userText);
      setCurrentUser({
        fullname: user.name,
        phone: user.phone,
      });
    };
  }, []);

  return (
    <React.Fragment>
      <div>
        <div id='extra-container-header'>
          <p>
            {giftName}
          </p>
          <p id='header-score-text'>{giftScore} {getTranslatedText('score_text')}</p>
        </div>
        <div id='extra-container-body'>
          <p>{getTranslatedText('description_form_exchange')}</p>
          <Form
            id='form-wrapper'
            onFinish={(value) => handleOnFinishForm(value)}
          >
            <Row gutter={16}>
              <Col
                xs={{
                  span: 24
                }}
                md={{
                  span: 24
                }}
                lg={{
                  span: 12
                }}
              >
                <Form.Item
                  name='fullname'
                  rules={[{ required: true}]}
                >
                  <Input
                    placeholder={getTranslatedText('input_your_name')}
                  />
                </Form.Item>
              </Col>
              <Col
                xs={{
                  span: 24
                }}
                md={{
                  span: 24
                }}
                lg={{
                  span: 12
                }}
              >
                <Form.Item
                  name='phone'
                  rules={[{ required: true}]}
                >
                  <Input
                    placeholder={getTranslatedText('input_your_phone')}
                  />
                </Form.Item>
              </Col>
              <Col
                span={24}
              >
                <Form.Item
                  name='address'
                  rules={[{ required: true}]}
                >
                  <Input
                    placeholder={getTranslatedText('address')}
                  />
                </Form.Item>
              </Col>
              <Col
                span={24}
              >
              </Col>
              <p><div style={{ textAlign: 'left', padding: "0 10px", marginBottom: "20px" }} dangerouslySetInnerHTML={{ __html: getTranslatedText('note_exchange') }}/>
              </p>
              <Col
                span={12}
              >
                <Form.Item>
                  <Button htmlType='submit'>{getTranslatedText('button_exchange_okay')}</Button>
                </Form.Item>
              </Col>
              <Col
                span={12}
              >
                <button
                  id='btn-cancel'
                  onClick={() => setModalVisible(false)}
                >
                  {getTranslatedText('button_exchange_cancel')}
                </button>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    </React.Fragment>
  );
};

export default DescriptionAndForm;
