import axios from 'axios';
import {
  BASE_URL,
  GET_PROFILE_REQUEST,
  GET_PROFILE_SUCCESS,
  GET_PROFILE_FAILURE,
  UPDATE_PROFILE_REQUEST,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PROFILE_FAILURE,
  SHOW_UPDATE_FORM,
  GET_CHARGE_HISTORY_REQUEST,
  GET_CHARGE_HISTORY_SUCCESS,
  GET_CHARGE_HISTORY_FAILURE,
  WITHDRAW_MONEY_REQUEST,
  WITHDRAW_MONEY_SUCCESS,
  WITHDRAW_MONEY_FAILURE,
  GET_USER_DASHBOARD_REQUEST,
  GET_USER_DASHBOARD_SUCCESS,
  GET_USER_DASHBOARD_FAILURE,
  DEPOSIT_MONEY_REQUEST,
  DEPOSIT_MONEY_SUCCESS,
  DEPOSIT_MONEY_FAILURE,
  GET_ORDER_DETAIL_BY_CODE_REQUEST,
  GET_ORDER_DETAIL_BY_CODE_SUCCESS,
  GET_ORDER_DETAIL_BY_CODE_FAILURE,
  GET_NOTIFICATIONS_REQUEST,
  GET_NOTIFICATIONS_SUCCESS,
  GET_NOTIFICATIONS_FAILURE,
  GET_NOTIFICATION_REQUEST,
  GET_NOTIFICATION_SUCCESS,
  GET_NOTIFICATION_FAILURE,
  GET_CHILD_USER_REQUEST,
  GET_CHILD_USER_SUCCESS,
  GET_CHILD_USER_FAILURE,
  POST_CONTRACT_REQUEST,
  POST_CONTRACT_SUCCESS,
  POST_CONTRACT_FAILURE,
  GET_USERS_REQUEST,
  GET_USERS_SUCCESS,
  GET_USERS_FAILURE,
  POST_TRANSFER_MONEY_REQUEST,
  POST_TRANSFER_MONEY_SUCCESS,
  POST_TRANSFER_MONEY_FAILURE,
  SUBMIT_VERIFY_TRANSFER_REQUEST,
  SUBMIT_VERIFY_TRANSFER_SUCCESS,
  SUBMIT_VERIFY_TRANSFER_FAILURE,
  GET_TRANSFER_LIST_REQUEST,
  GET_TRANSFER_LIST_SUCCESS,
  GET_TRANSFER_LIST_FAILURE,
  GET_BONUS_LIST_REQUEST,
  GET_BONUS_LIST_SUCCESS,
  GET_BONUS_LIST_FAILURE,
  GET_INTRODUCE_LIST_REQUEST,
  GET_INTRODUCE_LIST_SUCCESS,
  GET_INTRODUCE_LIST_FAILURE,
  SUBMIT_ACTIVE_CODE_REQUEST,
  SUBMIT_ACTIVE_CODE_SUCCESS,
  SUBMIT_ACTIVE_CODE_FAILURE,
} from './index';
import * as types from '../actions/index';
import { headerLang } from '../constants';

// GET PROFILE
export const getProfileAction = payload => {
  return dispatch => {
    dispatch(getProfileRequest());
    axios
      .post(
        `${BASE_URL}/users/profile`,
        {
          token: payload?.token,
        },
        headerLang,
      )
      .then(response => {
        dispatch(
          getProfileSuccess({
            data: response.data,
            options: payload.options,
          }),
        );
      })
      .catch(error => dispatch(getProfileFailure(error.message)));
  };
};

const getProfileRequest = () => ({
  type: GET_PROFILE_REQUEST,
});

const getProfileSuccess = payload => ({
  type: GET_PROFILE_SUCCESS,
  payload: payload,
});

const getProfileFailure = error => ({
  type: GET_PROFILE_FAILURE,
  payload: { error },
});

// UPDATE PROFILE
export const updateProfileAction = userProfile => {
  return dispatch => {
    dispatch(updateProfileRequest(userProfile));
    axios
      .post(
        `${BASE_URL}/users/update-profile`,
        userProfile,
        headerLang,
      )
      .then(response => {
        dispatch(updateProfileSuccess(response.data));
        dispatch(
          getProfileAction({
            token: localStorage.getItem(types.TOKEN_KEY),
            options: { redirect: false },
          }),
        );
      })
      .catch(error => {
        dispatch(updateProfileFailure(error.message));
      });
  };
};

const updateProfileRequest = userProfile => ({
  type: UPDATE_PROFILE_REQUEST,
  payload: userProfile,
});

const updateProfileSuccess = response => ({
  type: UPDATE_PROFILE_SUCCESS,
  payload: { ...response },
});

const updateProfileFailure = error => ({
  type: UPDATE_PROFILE_FAILURE,
  payload: { error },
});

// SHOW UPDATE FORM
export const showUpdateFormAction = () => {
  return dispatch => {
    dispatch(showUpProfileRequest());
  };
};

const showUpProfileRequest = () => ({
  type: SHOW_UPDATE_FORM,
});

// GET CHARGE HISTORY
export const getChargeHistoryAction = () => {
  return dispatch => {
    dispatch(getChargeHistoryRequest());
    axios
      .post(
        `${BASE_URL}/users/charge-history`,
        {
          token: localStorage.getItem(types.TOKEN_KEY),
        },
        headerLang,
      )
      .then(response => {
        dispatch(getChargeHistorySuccess(response.data));
      })
      .catch(error =>
        dispatch(getChargeHistoryFailure(error.message)),
      );
  };
};

const getChargeHistoryRequest = () => ({
  type: GET_CHARGE_HISTORY_REQUEST,
});

const getChargeHistorySuccess = response => ({
  type: GET_CHARGE_HISTORY_SUCCESS,
  payload: { ...response },
});

const getChargeHistoryFailure = error => ({
  type: GET_CHARGE_HISTORY_FAILURE,
  payload: { error },
});

// WITHDRAW MONEY
export const withdrawMoneyAction = payload => {
  return dispatch => {
    dispatch(withdrawMoneyRequest());
    payload.append('token', localStorage.getItem(types.TOKEN_KEY));
    axios
      .post(`${BASE_URL}/users/draw-money`, payload, headerLang)
      .then(response => {
        dispatch(withdrawMoneySuccess(response.data));
      })
      .catch(error => dispatch(withdrawMoneyFailure(error.response)));
  };
};

const withdrawMoneyRequest = payload => ({
  type: WITHDRAW_MONEY_REQUEST,
  payload: payload,
});

const withdrawMoneySuccess = response => ({
  type: WITHDRAW_MONEY_SUCCESS,
  payload: { ...response },
});

const withdrawMoneyFailure = error => ({
  type: WITHDRAW_MONEY_FAILURE,
  payload: { error },
});

// DEPOSIT MONEY
export const requestDepositAction = payload => {
  return dispatch => {
    dispatch(requestDepositRequest());
    payload.token = localStorage.getItem(types.TOKEN_KEY);
    axios
      .post(`${BASE_URL}/users/recharge`, payload, headerLang)
      .then(response => {
        dispatch(
          getProfileAction({
            token: localStorage.getItem(types.TOKEN_KEY),
            options: { redirect: false },
          }),
        );
        dispatch(requestDepositSuccess(response.data));
      })
      .catch(error =>
        dispatch(requestDepositFailure(error.response)),
      );
  };
};

const requestDepositRequest = payload => ({
  type: DEPOSIT_MONEY_REQUEST,
  payload: payload,
});

const requestDepositSuccess = response => ({
  type: DEPOSIT_MONEY_SUCCESS,
  payload: { ...response },
});

const requestDepositFailure = error => ({
  type: DEPOSIT_MONEY_FAILURE,
  payload: { error },
});

// GET ORDER DETAIL BY CODE
export const getOrderDetailByCode = code => {
  return dispatch => {
    dispatch(getOrderDetailByCodeRequest());
    axios
      .get(
        `${BASE_URL}/users/get-order-detail-by-code/${code}`,
        headerLang,
      )
      .then(response => {
        dispatch(getOrderDetailByCodeSuccess(response.data));
      })
      .catch(error =>
        dispatch(getOrderDetailByCodeFailure(error.response)),
      );
  };
};

const getOrderDetailByCodeRequest = code => ({
  type: GET_ORDER_DETAIL_BY_CODE_REQUEST,
  payload: code,
});

const getOrderDetailByCodeSuccess = response => ({
  type: GET_ORDER_DETAIL_BY_CODE_SUCCESS,
  payload: { ...response },
});

const getOrderDetailByCodeFailure = error => ({
  type: GET_ORDER_DETAIL_BY_CODE_FAILURE,
  payload: { error },
});

// GET USER DASHBOARD
export const getUserDashboardAction = () => {
  return dispatch => {
    dispatch(getUserDashboardRequest());
    axios
      .post(
        `${BASE_URL}/users/dashboard`,
        {
          token: localStorage.getItem(types.TOKEN_KEY) || '',
        },
        headerLang,
      )
      .then(response => {
        dispatch(getUserDashboardSuccess(response.data));
      })
      .catch(error =>
        dispatch(getUserDashboardFailure(error.message)),
      );
  };
};

const getUserDashboardRequest = () => ({
  type: GET_USER_DASHBOARD_REQUEST,
});

const getUserDashboardSuccess = response => ({
  type: GET_USER_DASHBOARD_SUCCESS,
  payload: { ...response },
});

const getUserDashboardFailure = error => ({
  type: GET_USER_DASHBOARD_FAILURE,
  payload: { error },
});

// GET USER NOTIFICATIONS
export const getNotifications = (currentPage = 1) => {
  return dispatch => {
    dispatch(getNotificationRequest());
    let token = localStorage.getItem(types.TOKEN_KEY) || '';
    axios
      .get(
        `${BASE_URL}/users/get-notification?page=${currentPage}&token=${token}`,
        headerLang,
      )
      .then(response => {
        dispatch(
          getNotificationSuccess({
            notifications: response.data,
          }),
        );
      })
      .catch(error =>
        dispatch(getNotificationFailure(error.message)),
      );
  };
};

const getNotificationRequest = () => ({
  type: GET_NOTIFICATIONS_REQUEST,
});

const getNotificationSuccess = payload => ({
  type: GET_NOTIFICATIONS_SUCCESS,
  payload: payload,
});

const getNotificationFailure = error => ({
  type: GET_NOTIFICATIONS_FAILURE,
  payload: { error },
});

// VIEW NOTIFICATION
export const viewNotification = notiId => {
  return dispatch => {
    dispatch(viewNotificationRequest());
    axios
      .post(
        `${BASE_URL}/users/view-notification/${notiId}`,
        {
          token: localStorage.getItem(types.TOKEN_KEY) || '',
        },
        headerLang,
      )
      .then(response => {
        dispatch(
          viewNotificationSuccess({
            notifications: response.data,
          }),
        );
      })
      .catch(error =>
        dispatch(viewNotificationFailure(error.message)),
      );
  };
};

const viewNotificationRequest = () => ({
  type: GET_NOTIFICATION_REQUEST,
});

const viewNotificationSuccess = payload => ({
  type: GET_NOTIFICATION_SUCCESS,
  payload: payload,
});

const viewNotificationFailure = error => ({
  type: GET_NOTIFICATION_FAILURE,
  payload: { error },
});

// GET CHILD USER
export const getChildUserAction = payload => {
  // console.log(payload);
  return dispatch => {
    dispatch(getChildUserRequest());
    axios
      .get(
        `${BASE_URL}/users/child-user?token=` +
          payload.token +
          '&active=' +
          (payload.active || 0) +
          '&is_english=' +
          (payload.isEnglish || 0) +
          '&page=' +
          payload.page,
        {},
        headerLang,
      )
      .then(response => {
        dispatch(
          getChildUserSuccess({
            data: response.data,
            active: payload.active || 0,
            isEnglish: payload.isEnglish || 0
          }),
        );
      })
      .catch(error => dispatch(getChildUserFailure(error.message)));
  };
};

const getChildUserRequest = () => ({
  type: GET_CHILD_USER_REQUEST,
});

const getChildUserSuccess = payload => ({
  type: GET_CHILD_USER_SUCCESS,
  payload: payload,
});

const getChildUserFailure = error => ({
  type: GET_CHILD_USER_FAILURE,
  payload: { error },
});

// Store contract
export const postContract = payload => {
  // console.log(payload);
  return dispatch => {
    dispatch(postContractRequest());
    axios
      .post(`${BASE_URL}/users/contract`, payload, headerLang)
      .then(response => {
        let code = response.data.statusCode;
        console.log(code);
        if (code == 200) {
          dispatch(
            postContractSuccess({
              data: response.data.data,
            }),
          );
        } else dispatch(postContractFailure(response.data.errors));
      })
      .catch(error =>
        dispatch(postContractFailure(['System error!'])),
      );
  };
};

const postContractRequest = () => ({
  type: POST_CONTRACT_REQUEST,
});

const postContractSuccess = payload => ({
  type: POST_CONTRACT_SUCCESS,
  payload: payload,
});

const postContractFailure = error => ({
  type: POST_CONTRACT_FAILURE,
  payload: error,
});

export const getUsers = () => {
  // console.log(payload);
  return dispatch => {
    dispatch(getUsersRequest());
    axios
      .get(
        `${BASE_URL}/users/list-user?token=` +
          localStorage.getItem(types.TOKEN_KEY),
        headerLang,
      )
      .then(response => {
        let code = response.data.statusCode;
        if (code == 200) {
          dispatch(
            getUsersSuccess({
              data: response.data.data,
            }),
          );
        } else dispatch(getUsersFailure(response.data.errors));
      })
      .catch(error => dispatch(getUsersFailure(['System error!'])));
  };
};

const getUsersRequest = () => ({
  type: GET_USERS_REQUEST,
});

const getUsersSuccess = payload => ({
  type: GET_USERS_SUCCESS,
  payload: payload,
});

const getUsersFailure = error => ({
  type: GET_USERS_FAILURE,
  payload: error,
});

export const getTransferList = () => {
  // console.log(payload);
  return dispatch => {
    dispatch(getTransferListRequest());
    axios
      .get(
        `${BASE_URL}/users/list-transfer?token=` +
          localStorage.getItem(types.TOKEN_KEY),
        headerLang,
      )
      .then(response => {
        let code = response.data.statusCode;
        if (code == 200) {
          dispatch(
            getTransferListSuccess({
              data: response.data.data,
            }),
          );
        } else dispatch(getTransferListFailure(response.data.errors));
      })
      .catch(error =>
        dispatch(getTransferListFailure(['System error!'])),
      );
  };
};

const getTransferListRequest = () => ({
  type: GET_TRANSFER_LIST_REQUEST,
});

const getTransferListSuccess = payload => ({
  type: GET_TRANSFER_LIST_SUCCESS,
  payload: payload,
});

const getTransferListFailure = error => ({
  type: GET_TRANSFER_LIST_FAILURE,
  payload: error,
});

export const postTransferMoney = payload => {
  // console.log(payload);
  payload.token = localStorage.getItem(types.TOKEN_KEY);
  return dispatch => {
    dispatch(postTransferMoneyRequest());
    axios
      .post(
        `${BASE_URL}/users/post-transfer-money`,
        payload,
        headerLang,
      )
      .then(response => {
        let code = response.data.statusCode;
        if (code == 200) {
          dispatch(
            postTransferMoneySuccess({
              data: response.data.data,
            }),
          );
        } else
          dispatch(postTransferMoneyFailure(response.data.errors));
      })
      .catch(error =>
        dispatch(postTransferMoneyFailure(['System error!'])),
      );
  };
};

const postTransferMoneyRequest = () => ({
  type: POST_TRANSFER_MONEY_REQUEST,
});

const postTransferMoneySuccess = payload => ({
  type: POST_TRANSFER_MONEY_SUCCESS,
  payload: payload,
});

const postTransferMoneyFailure = error => ({
  type: POST_TRANSFER_MONEY_FAILURE,
  payload: error,
});

export const submitVerifyTransferCode = payload => {
  // console.log(payload);
  payload.token = localStorage.getItem(types.TOKEN_KEY);
  return dispatch => {
    dispatch(submitVerifyTransferCodeRequest());
    axios
      .post(
        `${BASE_URL}/users/verify-transfer-money`,
        payload,
        headerLang,
      )
      .then(response => {
        let code = response.data.statusCode;
        if (code == 200) {
          dispatch(
            submitVerifyTransferCodeSuccess({
              data: response.data.data,
            }),
          );
        } else
          dispatch(
            submitVerifyTransferCodeFailure(response.data.errors),
          );
      })
      .catch(error =>
        dispatch(submitVerifyTransferCodeFailure(['System error!'])),
      );
  };
};

const submitVerifyTransferCodeRequest = () => ({
  type: SUBMIT_VERIFY_TRANSFER_REQUEST,
});

const submitVerifyTransferCodeSuccess = payload => ({
  type: SUBMIT_VERIFY_TRANSFER_SUCCESS,
  payload: payload,
});

const submitVerifyTransferCodeFailure = error => ({
  type: SUBMIT_VERIFY_TRANSFER_FAILURE,
  payload: error,
});

export const getBonusList = (page) => {
  // console.log(payload);
  let token = localStorage.getItem(types.TOKEN_KEY);
  return dispatch => {
    dispatch(getBonusListRequest());
    axios
      .get(
        `${BASE_URL}/users/bonus-history?token=${token}&page=${page}`,
        headerLang,
      )
      .then(response => {
        let code = response.data.statusCode;
        if (code == 200) {
          dispatch(
            getBonusListSuccess({
              data: response.data.data,
            }),
          );
        } else
          dispatch(
            getBonusListFailure(response.data.errors),
          );
      })
      .catch(error =>
        dispatch(getBonusListFailure(['System error!'])),
      );
  };
};

const getBonusListRequest = () => ({
  type: GET_BONUS_LIST_REQUEST,
});

const getBonusListSuccess = payload => ({
  type: GET_BONUS_LIST_SUCCESS,
  payload: payload,
});

const getBonusListFailure = error => ({
  type: GET_BONUS_LIST_FAILURE,
  payload: error,
});

export const getIntroduceList = (page) => {
  // console.log(payload);
  let token = localStorage.getItem(types.TOKEN_KEY);
  return dispatch => {
    dispatch(getIntroduceListRequest());
    axios
      .get(
        `${BASE_URL}/users/affiliate-history?token=${token}&page=${page}`,
        headerLang,
      )
      .then(response => {
        let code = response.data.statusCode;
        if (code == 200) {
          dispatch(
            getIntroduceListSuccess({
              data: response.data.data,
            }),
          );
        } else
          dispatch(
            getIntroduceListFailure(response.data.errors),
          );
      })
      .catch(error =>
        dispatch(getIntroduceListFailure(['System error!'])),
      );
  };
};

const getIntroduceListRequest = () => ({
  type: GET_INTRODUCE_LIST_REQUEST,
});

const getIntroduceListSuccess = payload => ({
  type: GET_INTRODUCE_LIST_SUCCESS,
  payload: payload,
});

const getIntroduceListFailure = error => ({
  type: GET_INTRODUCE_LIST_FAILURE,
  payload: error,
});

export const submitActiveCode = (active_code) => {
  // console.log(payload);
  let token = localStorage.getItem(types.TOKEN_KEY);
  return dispatch => {
    dispatch(submitActiveCodeRequest());
    axios
      .post(
        `${BASE_URL}/users/active-code`,
        {token: token, code: active_code},
        headerLang,
      )
      .then(response => {
        let code = response.data.statusCode;
        if (code == 200) {
          dispatch(
            submitActiveCodeSuccess({
              data: response.data.data,
            }),
          );
        } else
          dispatch(
            submitActiveCodeFailure(response.data.errors),
          );
      })
      .catch(error =>
        dispatch(submitActiveCodeFailure(['System error!'])),
      );
  };
};

const submitActiveCodeRequest = () => ({
  type: SUBMIT_ACTIVE_CODE_REQUEST,
});

const submitActiveCodeSuccess = payload => ({
  type: SUBMIT_ACTIVE_CODE_SUCCESS,
  payload: payload,
});

const submitActiveCodeFailure = error => ({
  type: SUBMIT_ACTIVE_CODE_FAILURE,
  payload: error,
});
