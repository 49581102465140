import React, { Component, useEffect } from 'react';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { routes } from '../../constants';
import { getTranslatedText } from '../../services/appService';
import { getProfileAction } from '../../actions/profile';
import AccountBreadcrumb from '../../components/AccountBreadcrumb/AccountBreadcrumb';
import { Link } from 'react-router-dom';

class ActiveSuccess extends Component {
  render() {
    let { codeInfo, codeType } = this.props;
    return (
      <div>
        <div>
          <AccountBreadcrumb />
          <Breadcrumb
            data={[
              { link: routes.home, text: getTranslatedText('home') },
              { link: routes.key_active, text: getTranslatedText('course') },
            ]}
          />
          <div className='w-full max-w-screen-xl mx-auto'>
            <div className='w-full space-y-10 pt-12 mb-24'>
              <h4 className='font-bold px-4  md:px-0' style={{color:'#b97a2a'}}>{getTranslatedText('active_license_success')}</h4>
              <p className="bold pl-4  md:pl-0">{getTranslatedText('active_code_success_content')}
              <strong>{" " + ((codeType == 'audio_books') ? 'Audio Books' : codeInfo.level + ' ' + (codeInfo.is_english ? "English" : "Skill"))}</strong>
              </p>
              {codeType == 'audio_books' ? (
                <div className='bg-brown w-80 py-2 rounded-xl pl-4  md:pl-0'>
                  <Link to={routes.audio_book.list}>
                    <p className='text-white font-semibold text-center'>{getTranslatedText('audio_book_cta_btn')}</p>
                  </Link>
                </div>
              ) : ''}
              {codeType == 'course' ? (
                <div className="flex flex-col md:flex-row gap-2 md:gap-0 text-center justify-content-around pl-4 md:pl-0">
                  <Link to={routes.courseDetail.replace(':id', codeInfo.id)}>
                    <p className='btn-cta'>{getTranslatedText('audio_alert_comfirm')}</p>
                  </Link>
                  <Link to={routes.accountCourses}>
                    <p className='btn-cta'>{getTranslatedText('view_my_course')}</p>
                  </Link>
                </div>
              ) : ''}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return state.profile;
};
export default connect(mapStateToProps, null)(ActiveSuccess);