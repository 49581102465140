import axios from 'axios';
import {
  BASE_URL,
  GET_LIST_SPEAKER_REQUEST,
  GET_LIST_SPEAKER_SUCCESS,
  GET_LIST_SPEAKER_FAILURE,
  GET_DETAIL_SPEAKER_REQUEST,
  GET_DETAIL_SPEAKER_SUCCESS,
  GET_DETAIL_SPEAKER_FAILURE,
} from './index';


export function getListSpeakerAction(payload) {
  return dispatch => {
    dispatch(getListSpeakerRequest());
    axios
      .get(`${BASE_URL}/guest/presenter`, {params: payload})
      .then(response => {
        dispatch(getListSpeakerSuccess(response.data));
      })
      .catch(error => {
        dispatch(getListSpeakerFailure(error.message));
      });
  };
};

function getListSpeakerRequest() {
  return {
    type: GET_LIST_SPEAKER_REQUEST,
  };
};

function getListSpeakerSuccess(payload) {
  return {
    type: GET_LIST_SPEAKER_SUCCESS,
    payload: payload,   
  };
};

function getListSpeakerFailure(payload) {
  return {
    type: GET_LIST_SPEAKER_FAILURE,
    payload: payload,
  };
};

export function getDetailSpeakerAction(payload) {
  return dispatch => {
    dispatch(getDetailSpeakerRequest());
    axios
      .get(`${BASE_URL}/guest/presenter/${payload}`)
      .then(response => {
        dispatch(getDetailSpeakerSuccess(response.data));
      })
      .catch(error => {
        dispatch(getDetailSpeakerFailure(error.message));
      });
  };
};

function getDetailSpeakerRequest() {
  return {
    type: GET_DETAIL_SPEAKER_REQUEST
  };
};

function getDetailSpeakerSuccess(payload) {
  return {
    type: GET_DETAIL_SPEAKER_SUCCESS,
    payload: payload
  };
};

function getDetailSpeakerFailure(payload) {
  return {
    type: GET_DETAIL_SPEAKER_FAILURE,
    payload: payload
  };
};


