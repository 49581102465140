import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { getTranslatedText } from '../../../../services/appService';

function CountDownClock(props) {
  const {
    eventStartDate,
    eventEndDate,
  } = props;
  let targetStart = moment(eventStartDate);
  let targetEnd = moment(eventEndDate);
  const [eventState, setEventState] = useState();
  const [currentTime, setCurrentTime] = useState(moment());
  const timeToStart = moment.duration(targetStart.diff(currentTime));
  const timeToEnd = moment.duration(targetEnd.diff(moment(currentTime)));

  useEffect(() => {
    if (currentTime.isBefore(targetStart)) {
      const interval = setInterval(() => {
        setCurrentTime(moment());
      }, 1000);
  
      return () => clearInterval(interval);
    };
    if (currentTime.isBefore(targetEnd)) {
      const interval = setInterval(() => {
        setCurrentTime(moment());
      }, 1000);
  
      return () => clearInterval(interval);
    }
  }, []);

  useEffect(() => {
    if (currentTime.isBefore(moment(eventStartDate))) {
      setEventState('future');
    };
    if (currentTime.isSameOrAfter(moment(eventStartDate)) && currentTime.isBefore(moment(eventEndDate))) {
      setEventState('present');
    };
    if (currentTime.isSameOrAfter(moment(eventEndDate))) {
      setEventState('past');
    };
  }, []);

  return (
    <div className="w-full text-center absolute bottom-2">
      {eventState === 'future' && <h3 className="text-white text-base lg:text-28 font-semibold mb-3">{getTranslatedText('event_future_title')}</h3>}
      {eventState === 'present' && <h3 className="text-white text-base lg:text-28 font-semibold mb-3">{getTranslatedText('event_present_title')}</h3>}
      {eventState === 'past' && <h3 className="text-white text-base lg:text-28 font-semibold mb-3">{getTranslatedText('event_past_title')}</h3>}
      <div className="w-full flex gap-2 items-center justify-center">
        <div>
          <p className="text-red-600 text-xl lg:text-3xl font-bold space-x-0.5">
            {/* <span className="bg-white px-1.5 py-1.5">0</span> */}
            {eventState === 'future' && <span className="bg-white px-1.5 py-1.5">{timeToStart.days()}</span>}
            {eventState === 'present' && <span className="bg-white px-1.5 py-1.5">{timeToEnd.days()}</span>}
            {eventState === 'past' && <span className="bg-white px-1.5 py-1.5">0</span>}
          </p>
          <p className="text-8 font-bold text-white mt-2 mt-lg-3">{getTranslatedText('clock_days')}</p>  
        </div>
        <div>
          <p className="text-red-600 text-xl lg:text-3xl font-bold space-x-0.5">
            {/* <span className="bg-white px-1.5 py-1.5">0</span> */}
            {eventState === 'future' && <span className="bg-white px-1.5 py-1.5">{timeToStart.hours()}</span>}
            {eventState === 'present' && <span className="bg-white px-1.5 py-1.5">{timeToEnd.hours()}</span>}
            {eventState === 'past' && <span className="bg-white px-1.5 py-1.5">0</span>}
          </p>
          <p className="text-8 font-bold text-white mt-2 mt-lg-3">{getTranslatedText('clock_hours')}</p>  
        </div>
        <div>
          <p className="text-red-600 text-xl lg:text-3xl font-bold space-x-0.5">
            {/* <span className="bg-white px-1.5 py-1.5">0</span> */}
            {eventState === 'future' && <span className="bg-white px-1.5 py-1.5">{timeToStart.minutes()}</span>}
            {eventState === 'present' && <span className="bg-white px-1.5 py-1.5">{timeToEnd.minutes()}</span>}
            {eventState === 'past' && <span className="bg-white px-1.5 py-1.5">0</span>}
          </p>
          <p className="text-8 font-bold text-white mt-2 mt-lg-3">{getTranslatedText('clock_minutes')}</p>  
        </div>
        <div>
          <p className="text-red-600 text-xl lg:text-3xl font-bold space-x-0.5">
            {/* <span className="bg-white px-1.5 py-1.5">0</span> */}
            {eventState === 'future' && <span className="bg-white px-1.5 py-1.5">{timeToStart.seconds()}</span>}
            {eventState === 'present' && <span className="bg-white px-1.5 py-1.5">{timeToEnd.seconds()}</span>}
            {eventState === 'past' && <span className="bg-white px-1.5 py-1.5">0</span>}
          </p>
          <p className="text-8 font-bold text-white mt-2 mt-lg-3">{getTranslatedText('clock_seconds')}</p>  
        </div>
      </div>
    </div>
  );
};

CountDownClock.propTypes = {
  eventStartDate: PropTypes.string,
  eventEndDate: PropTypes.string,
};

export default CountDownClock;