import React, {useState, useEffect} from 'react';

import {
  Layout,
  Form,
  Input,
  Button,
  Row,
  Col,
  Tag,
  Typography,
} from 'antd';

import { 
  submitReceiveGift,
  getTranslatedText,
} from '../../../services/appService';

import '../Game/GameItem.scss';


const ResultWheelModal = props => {
  const {
    givenResult,
    setResultGameModalVisible,
    setAddMoneyToWaletModalVisible,
    setTotalMoneyAdded,
    setResulModalTrigger,
  } = props;

  const lang = localStorage.getItem('current_lang') || 'en';

  const handleNextRoundButton = () => {
    setResultGameModalVisible(false);
  };

  const handleOnFormFinish = (values) => {
    const receiverInformation = {
      receiver_name: values.receiver_name,
      receiver_phone: values.receiver_phone,
      receiver_address: values.receiver_address,
      result_id: givenResult.id
    };
    setResulModalTrigger(true);
    submitReceiveGift(receiverInformation)
      .then(response => {
        setResultGameModalVisible(false);
      })
      .catch(error => {
        console.log(error)
      });
  };

  const renderFooterByResultType = (givenResult) => {
    switch (givenResult.reward.type) {
      case 'item':
        return (
          <React.Fragment>
            <Form
              onFinish={(values) => handleOnFormFinish(values)}
            >
              <Row gutter={16}>
                <Col className='button-footer-wrap' md={0} xs={0} lg={8}>
                  <p>{getTranslatedText('receive_gift_info')}</p>
                  <Form.Item>
                    <Button
                      className="modal__button"
                      htmlType='submit'
                    >
                      <Typography.Text className="button_label">
                        {getTranslatedText(
                          'button_exchange_okay',
                        )}
                      </Typography.Text>
                    </Button>
                  </Form.Item>
                </Col>
                <Col span={16} xs={24} xl={16}>
                    <Row gutter={16}>
                      <Col
                        lg={0}
                        md={24}
                        xs={24}
                      >
                        <p id='form-mobile-title'>{getTranslatedText('receive_gift_info')}</p>
                      </Col>
                      <Col
                        lg={12}
                        md={24}
                        xs={24}
                      >
                        <Form.Item
                          name='receiver_name'
                          rules={[
                            {
                              required: true,
                              message: getTranslatedText('fill_input_field')
                            }
                          ]}
                        >
                          <Input
                            placeholder={getTranslatedText('full_name')}
                          />
                        </Form.Item>
                      </Col>
                      <Col
                        lg={12}
                        md={24}
                        xs={24}
                      >
                        <Form.Item
                          name='receiver_phone'
                          rules={[
                            {
                              required: true,
                              message: getTranslatedText('fill_input_field')
                            }
                          ]}
                        >
                          <Input
                            placeholder={getTranslatedText('your_phone')}
                          />
                        </Form.Item>
                      </Col>
                      <Col
                        lg={24}
                        md={24}
                        xs={24}
                      >
                        <Form.Item
                          name='receiver_address'
                          rules={[
                            {
                              required: true,
                              message: getTranslatedText('fill_input_field')
                            }
                          ]}
                        >
                          <Input
                            placeholder={getTranslatedText('address')}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                </Col>
                <Col className='button-footer-wrap' md={24} xs={24} lg={0}>
                  <Form.Item>
                    <Button
                      className="modal__button"
                      htmlType='submit'
                    >
                      <Typography.Text className="button_label">
                        {getTranslatedText(
                          'button_exchange_okay',
                        )}
                      </Typography.Text>
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </React.Fragment>
        )
      case 'money':
        setResulModalTrigger(true);
        return (
          <React.Fragment>
            <Button
              className='modal__button_next_round'
              onClick={() => handleNextRoundButton()}
            >
              {getTranslatedText('result_modal_next_round_button')}
            </Button>
          </React.Fragment>
        )
      default:
        break;
    }
  };

  return (
    <React.Fragment>
      <Layout className='layout_result'>
        <Layout.Header className="item__container">
          {givenResult.reward.image !== "" ? (
            <div>
              <img alt='' src={givenResult.reward.image} />
            </div>
          ) : (
            <div id='logo-image'>
              <img alt='' src={require('../../../assets/images/logo.png')} />
            </div>
          )}
        </Layout.Header>
        <Layout.Content className="content__container">
          <Row>
            <Col span={
              givenResult.reward.name === 'May mắn lần sau' ? 0 : 24  
            }
            >
              <Typography.Text className="content_heading">
                {getTranslatedText('result_modal_heading')}
              </Typography.Text>
            </Col>
            <Col
              span={
                givenResult.reward.name === 'May mắn lần sau' ? 0 : 24
              }
            >
              <Typography.Text className="container___text">
                {getTranslatedText('result_modal_sub_heading')}
              </Typography.Text>
            </Col>
            <Col span={24}>
                {lang == 'vi' ? (
                  <Tag color="#FFFFFF" className="tag__info">    
                    {givenResult.reward.name_vi}
                  </Tag>
                ) : (
                  <Tag color="#FFFFFF" className="tag__info">
                    {givenResult.reward.name}
                  </Tag>
                )}
            </Col>
          </Row>
        </Layout.Content>
        <Layout.Footer className="footer__container">
            {renderFooterByResultType(givenResult)}
        </Layout.Footer>
				<div className='footer-logo-wrap'>
          <img alt='' src={require('../../../assets/images/logo.png')} />
				</div>
      </Layout>
    </React.Fragment>
  );
};


export default ResultWheelModal;