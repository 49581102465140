export const translatedText = {
  course: {
    en: 'Courses',
    vi: 'Khoá học',
  },
  home: {
    en: 'HOME',
    vi: 'TRANG CHỦ',
  },
  blog: {
    en: 'AFFILIATE',
    vi: 'LIÊN KẾT',
  },
  contact: {
    en: 'CONTACT US',
    vi: 'LIÊN HỆ',
  },
  login: {
    en: 'Login',
    vi: 'Đăng nhập',
  },
  register: {
    en: 'Register',
    vi: 'Đăng ký',
  },
  signin: {
    en: 'Sign in',
    vi: 'Đăng nhập',
  },
  vision: {
    en: 'VISION',
    vi: 'TẦM NHÌN',
  },
  mission: {
    en: 'MISSION',
    vi: 'SỨ MỆNH',
  },
  core_values: {
    en: 'CORE VALUES',
    vi: 'GIÁ TRỊ CỐT LÕI',
  },
  soar_to_greater: {
    en: 'SOAR TO GREATER HEIGHTS',
    vi: 'CHINH PHỤC NHỮNG TẦM CAO MỚI',
  },
  vision_detail: {
    en:
      'To bring education and networking easily available for everyone making our portal the most comprehensive and resourceful platform in the world at anytime and anywhere.',
    vi:
      'Trở thành nền tảng dẫn đầu trong lĩnh vực giáo dục và đào tạo trực tuyến trên thế giới với cam kết mang đến những trải nghiệm học tập quý giá, nhằm giúp học viên khám phá bản thân, thay đổi tầm nhìn và đạt được những thành công trong cuộc sống.',
  },
  mission_detail: {
    en:
      'Helping all members regardless of age, industry, religion and gender to enhance their knowledge, increase income and succeed. We are committed to constantly improving all activities, updating more courses and programs to help our members become more and m',
    vi:
      'Giúp cho tất cả thành viên không kể độ tuổi, ngành nghề, tôn giáo, giới tính đều có thể nâng cao tri thức, nâng cao thu nhập và thành công.Chúng tôi cam kết không ngừng cải tiến mọi hoạt động, cập nhật thêm các khoá học, các chương trình để giúp cho thà',
  },
  core_value_detail: {
    en:
      'We put customer satisfaction as the core value. We are committed to creating a friendly, professional working and learning environment where every member has the opportunity to grow. We operate on transparency, ethics and efficiency. We make continuous ef',
    vi:
      'Chúng tôi đặt sự hài lòng của khách hàng là giá trị cốt lõi. Chúng tôi cam kết xây dựng môi trường học tập và làm việc chuyên nghiệp thân thiện, nơi mọi thành viên đều có cơ hội phát triển. Chúng tôi hoạt động dựa trên tính minh bạch, đạo đức và hiệu quả.',
  },
  slogan: {
    en:
      "BUILDING A COMMUNITY TO EMPOWER AND ENRICH EVERY INDIVIDUAL'S LIFE THROUGH EDUCATION AND NETWORKING",
    vi:
      'CHÚNG TÔI MUỐN XÂY DỰNG MỘT CỘNG ĐỒNG THAY ĐỔI VỀ TRI THỨC VÀ THAY ĐỔI VỀ THU NHẬP',
  },
  our_courses: {
    en: 'OUR SELF IMPROVEMENT COURSES',
    vi: 'CÁC KHOÁ HỌC PHÁT TRIỂN BẢN THÂN',
  },
  my_course_note: {
    en:
      '<b>Note:</b> Lower level courses will automatically be included when you purchased a higher level course. ' +
      '<br> <br> <b>Example:</b> Join the Marketing Hacks Course for $500. The $50 and $200 courses will be included. <br> It’s a one time payment.',
    vi:
      '<b>Lưu ý:</b> Các khóa học thấp hơn sẽ được bao gồm khi bạn mua khóa học cao hơn.' +
      ' <br> <br> <b>Ví dụ:</b> Tham gia mua khoá học: Chiến Lược Marketing Thành Công giá 12.000.000 đ sẽ bao gồm 2 khoá 4.800.000 đ và 1.200.000 đ. <br> Tất cả chỉ thanh toán một lần.',
  },

  course_note: {
    en:
      'Note: The price is included tax, you do not have to pay any more extra cost.',
    vi:
      'Lưu ý: Giá đã bao gồm thuế phí, bạn không phải trả thêm bất kỳ chi phí nào.',
  },
  '': {
    en: null,
    vi: '',
  },
  see_detail: {
    en: 'SEE DETAIL',
    vi: 'XEM CHI TIẾT',
  },
  connect_with_us: {
    en: 'CONNECT WITH US',
    vi: 'KẾT NỐI VỚI CHÚNG TÔI',
  },
  contact_OPTIMAGROUP: {
    en: 'Contact OPTIMA GROUP',
    vi: 'Liên hệ với OPTIMA GROUP',
  },
  your_name: {
    en: 'Your name*',
    vi: 'Tên của bạn*',
  },
  input_your_name: {
    en: 'Your name',
    vi: 'Nhập tên của bạn',
  },
  your_email: {
    en: 'Email*',
    vi: 'Email*',
  },
  input_your_email: {
    en: 'Your email',
    vi: 'Nhập email của bạn',
  },
  your_phone: {
    en: 'Phone Number',
    vi: 'Số điện thoại',
  },
  input_your_phone: {
    en: 'Your phone number',
    vi: 'Nhập số điện thoại của bạn',
  },
  text: {
    en: 'Text*',
    vi: 'Nội dung*',
  },
  let_us_know: {
    en: 'Let us know what do you think',
    vi: 'Hãy cho chúng tôi biết bạn nghĩ gì',
  },
  send: {
    en: 'SEND',
    vi: 'GỬI',
  },
  already_member: {
    en: 'Already a member?',
    vi: 'Đã là thành viên?',
  },
  password: {
    en: 'Password',
    vi: 'Mật khẩu',
  },
  forgot_password: {
    en: 'Forgot password?',
    vi: 'Quên mật khẩu?',
  },
  input_email_reset_password: {
    en: 'Please input your email to reset your password.',
    vi: 'Vui lòng cung cấp email để lấy lại mật khẩu.',
  },
  become_member: {
    en: 'Become a new member!',
    vi: 'Dành cho thành viên mới!',
  },
  full_name: {
    en: 'Full Name*',
    vi: 'Họ và tên*',
  },
  username: {
    en: 'Username',
    vi: 'Tên đăng nhập',
  },
  ref_code: {
    en: 'Referral Code',
    vi: 'Mã giới thiệu',
  },
  confirm_password: {
    en: 'Confirm password',
    vi: 'Nhập lại mật khẩu',
  },
  country: {
    en: 'Country',
    vi: 'Quốc gia',
  },
  select_country: {
    en: 'Select country',
    vi: 'Chọn quốc gia',
  },
  dashboard: {
    en: 'Dashboard',
    vi: 'Thống kê',
  },
  summary: {
    en: 'SUMMARY',
    vi: 'TỔNG QUAN',
  },
  your_rank: {
    en: 'YOUR RANK',
    vi: 'CẤP BẬC HIỆN TẠI CỦA BẠN',
  },
  member: {
    en: 'MEMBER',
    vi: 'THÀNH VIÊN',
  },
  manager: {
    en: 'MANAGER',
    vi: 'QUẢN LÝ',
  },
  director: {
    en: 'DIRECTOR',
    vi: 'Đốc',
  },
  total_commission: {
    en: 'TOTAL COMMISSION',
    vi: 'TỔNG HOA HỒNG',
  },
  total_group_revenue: {
    en: 'TOTAL GROUP REVENUE',
    vi: 'TỔNG DOANH THU TOÀN NHÓM',
  },
  total_ref: {
    en: 'TOTAL REFERRAL',
    vi: 'TỔNG SỐ NGƯỜI ĐÃ GIỚI THIỆU',
  },
  total_active_user: {
    en: 'TOTAL ACTIVE USER',
    vi: 'TỔNG THÀNH VIÊN HOẠT ĐỘNG',
  },
  revenue_chart: {
    en: 'REVENUE CHART',
    vi: 'BIỂU ĐỒ DOANH THU',
  },
  statistic: {
    en: 'STATISTICS',
    vi: 'THỐNG KÊ',
  },
  group_revenue: {
    en: 'GROUP REVENUE',
    vi: 'DOANH THU NHÓM',
  },
  commission: {
    en: 'COMMISSION',
    vi: 'HOA HỒNG',
  },
  your_member: {
    en: 'YOUR MEMBER',
    vi: 'DANH SÁCH THÀNH VIÊN CỦA BẠN',
  },
  paid: {
    en: 'PAID SKILL',
    vi: 'ĐÃ MUA (KHÓA HỌC KỸ NĂNG)',
  },
  paid_english: {
    en: 'PAID ENGLISH',
    vi: 'ĐÃ MUA (KHÓA HỌC TIẾNG ANH)',
  },
  active_date: {
    en: 'Active Date',
    vi: 'Ngày kích hoạt',
  },
  unpaid: {
    en: 'UNPAID SKILL',
    vi: 'CHƯA MUA (KHÓA HỌC KỸ NĂNG)',
  },
  unpaid_english: {
    en: 'UNPAID ENGLISH',
    vi: 'CHƯA MUA (KHÓA HỌC TIẾNG ANH)',
  },
  register_date: {
    en: 'Register Date',
    vi: 'Ngày đăng ký',
  },
  profile: {
    en: 'Profile',
    vi: 'Trang cá nhân',
  },
  personal_info: {
    en: 'Personal Information',
    vi: 'Thông tin cá nhân',
  },
  update_avatar: {
    en: 'Update avatar',
    vi: 'Cập nhật ảnh đại diện',
  },
  update: {
    en: 'UPDATE',
    vi: 'CẬP NHẬT',
  },
  my_wallet: {
    en: 'My Wallet',
    vi: 'Thông tin ví của tôi',
  },
  balance: {
    en: 'Balance:',
    vi: 'Số dư hiện tại:',
  },
  transactions: {
    en: 'TRANSACTIONS',
    vi: 'LỊCH SỬ GIAO DỊCH',
  },
  withdraw: {
    en: 'Withdraw',
    vi: 'Rút tiền',
  },
  date: {
    en: 'Date',
    vi: 'Ngày',
  },
  transaction_code: {
    en: 'Transaction Code',
    vi: 'Mã giao dịch',
  },
  amount: {
    en: 'Amount',
    vi: 'Số tiền giao dịch*',
  },
  status: {
    en: 'Status',
    vi: 'Trạng thái',
  },
  waiting: {
    en: 'Waiting',
    vi: 'Đang chờ',
  },
  accept: {
    en: 'Accept',
    vi: 'Thành công',
  },
  accepted: {
    en: 'Accept',
    vi: 'Thành công',
  },
  deny: {
    en: 'Denied',
    vi: 'Từ chối',
  },
  denied: {
    en: 'Denied',
    vi: 'Từ chối',
  },
  fill_to_withdraw: {
    en: 'Please fill in the following information to withdraw',
    vi: 'Vui lòng điền các thông tin bên dưới để tiến hành rút tiền',
  },
  bank_info: {
    en: 'Please enter your receipt below!',
    vi: 'Vui lòng nhập thông tin nhận tiền dưới đây',
  },
  bank_name: {
    en: 'Name of Beneficiary Bank*',
    vi: 'Tên ngân hàng thụ hưởng*',
  },
  your_bank_name: {
    en: 'What is your name of beneficiary bank',
    vi: 'Tên ngân hàng thụ hưởng của bạn là gì',
  },
  bank_number: {
    en: 'Bank Account number*',
    vi: 'Số tài khoản ngân hàng*',
  },
  your_bank_number: {
    en: 'What is your bank account number',
    vi: 'Số tài khoản ngân hàng của bạn là gì',
  },
  bank_address: {
    en: 'Recipient Address',
    vi: 'Địa chỉ người nhận',
  },
  bank_full_name: {
    en: 'Bank Full Name',
    vi: 'Tên chủ tài khoản thụ hưởng',
  },
  your_bank_address: {
    en: 'What is your address of recipient',
    vi: 'Địa chỉ người nhận',
  },
  swift_code: {
    en: 'Swift Code',
    vi: 'Mã Swift ngân hàng',
  },
  your_swift_code: {
    en: 'What is your swift code',
    vi: 'Mã swift ngân hàng của bạn là gì',
  },
  amount_withdraw: {
    en: 'How amount do you want to withdraw',
    vi: 'Bạn muốn rút bao nhiêu tiền',
  },
  important_withdraw: {
    en:
      'Important: The money you want to withdraw must be lower than you balance <br> and will be convert to integer. Ex: 40.000 = 40 or 40.500 = 40',
    vi:
      'Quan trọng: Số tiền bạn muốn rút phải thấp hơn hoặc bằng số dư của bạn, <br> và sẽ được chuyển đổi thành số nguyên. Vd: 40.000 = 40 hoặc 40.500 = 40',
  },
  request: {
    en: 'REQUEST',
    vi: 'YÊU CẦU GIAO DỊCH',
  },
  cancel: {
    en: 'CANCEL',
    vi: 'HỦY BỎ',
  },
  success_withdraw: {
    en:
      'Your withdraw request has been sent to OPTIMA GROUP. We will inform you when your request is processed.',
    vi:
      'Yêu cầu rút tiền của bạn đã được gửi đến OPTIMA GROUP. Chúng tôi sẽ thông báo cho bạn khi yêu cầu của bạn được xử lý thành công.',
  },
  back_wallet: {
    en: 'Back to Wallet',
    vi: 'Trở lại Ví của tôi',
  },
  back_dashboard: {
    en: 'Back to Dashboard',
    vi: 'Trở lại Bảng điều khiển',
  },
  deposit: {
    en: 'Deposit',
    vi: 'Nạp tiền',
  },
  input_amount_deposit: {
    en: 'Please please enter the amount of money you want to deposit',
    vi: 'Vui lòng nhập số tiền bạn muốn nạp',
  },
  amount_deposit: {
    en: 'How much do you want to deposit',
    vi: 'Bạn muốn nạp bao nhiêu',
  },
  important_deposit: {
    en:
      'Important: You cannot change information after sending request.',
    vi:
      'Quan trọng: Bạn không thể thay đổi thông tin sau khi gửi yêu cầu.',
  },
  learn_now: {
    en: 'LEARN NOW',
    vi: 'VÀO HỌC NGAY',
  },
  other_courses: {
    en: 'Other courses',
    vi: 'Khóa học chưa mua',
  },
  detail: {
    en: 'DETAILS',
    vi: 'CHI TIẾT',
  },
  course_detail: {
    en: 'Course detail',
    vi: 'Chi tiết khoá học',
  },
  course_introduction: {
    en: 'Course introduction',
    vi: 'Giới thiệu khoá học',
  },
  content: {
    en: 'Content:',
    vi: 'Nội dung:',
  },
  level: {
    en: 'Level',
    vi: 'Cấp độ',
  },
  price: {
    en: 'Price',
    vi: 'Giá',
  },
  course_include: {
    en: 'This course included:',
    vi: 'Khóa học này bao gồm:',
  },
  combo: {
    en: 'Combo',
    vi: 'Trọn bộ',
  },
  duration: {
    en: 'Duration:',
    vi: 'Thời lượng:',
  },
  hour: {
    en: 'hours',
    vi: 'giờ',
  },
  minute: {
    en: 'minutes',
    vi: 'phút',
  },
  second: {
    en: 'seconds',
    vi: 'giây',
  },
  lifetime: {
    en: 'Full Lifetime Access',
    vi: 'Học trọn đời',
  },
  purchase_now: {
    en: 'PURCHASE NOW',
    vi: 'THANH TOÁN NGAY',
  },
  order: {
    en: 'ORDER',
    vi: 'ĐẶT HÀNG',
  },
  transfer_money_vn: {
    en: 'VIET NAM BANK',
    vi: 'NGÂN HÀNG VIỆT NAM',
  },
  transfer_money_singapore: {
    en: 'SINGAPORE BANK',
    vi: 'NGÂN HÀNG SINGAPORE',
  },
  visa: {
    en: 'VISA',
    vi: 'VISA',
  },
  card_number: {
    en: 'Card number',
    vi: 'Mã số thẻ',
  },
  exprie_date: {
    en: 'Expiry date Month/Year',
    vi: 'Ngày hết hạn',
  },
  security_code: {
    en: 'Security code',
    vi: 'Mã bảo vệ',
  },
  name_account_holder: {
    en: 'Name of account holder',
    vi: 'Tên chủ tài khoản',
  },
  your_order_info: {
    en: 'YOUR ORDER INFORMATION',
    vi: 'THÔNG TIN ĐẶT HÀNG CỦA BẠN',
  },
  course_name: {
    en: 'Course name',
    vi: 'Tên khóa học',
  },
  note_after_payment: {
    en:
      'Note: \n' +
      '- There will be an additional 10% fee for payment using PAYPAL or Credit Card.\n' +
      '- There will be NO additional fees for payment to our Singapore Bank Account.',
    vi:
      'Ghi chú:\n' +
      '- Sẽ phải trả thêm 10% phí khi thanh toán bằng PAYPAL hoặc Thẻ tín dụng.\n' +
      '- Sẽ KHÔNG có phí bổ sung khi thanh toán vào Tài khoản Ngân hàng Singapore của chúng tôi.',
  },
  note_buy_braintree: {
    en:
      'When buying a course with PAYPAL account, VISA card or BANKS IN VIETNAM will cost an additional 10% of the course fee',
    vi:
      'Khi mua khóa học bằng tài khoản PAYPAL, thẻ VISA hoặc chuyển khoản từ các NGÂN HÀNG VIỆT NAM sẽ mất thêm 10% phí của khóa học',
  },
  your_wallet: {
    en: 'YOUR WALLET',
    vi: 'VÍ CỦA BẠN',
  },
  need_purchase: {
    en: 'Need to purchased:',
    vi: 'Khoá học cần thanh toán:',
  },
  amount_top_up: {
    en: 'Amount to top up:',
    vi: 'Để thanh toán khoá học này bạn cần nạp thêm:',
  },
  deposit_now: {
    en: 'DEPOSIT NOW',
    vi: 'NẠP TIỀN NGAY',
  },
  purchase_success_1: {
    en: 'Your deposit request has been sent to OPTIMA GROUP.',
    vi: 'Yêu cầu nạp tiền của bạn đã được gửi tới OPTIMA GROUP.',
  },
  purchase_success_2: {
    en: 'We will inform you when your request is processed.',
    vi:
      'Chúng tôi sẽ thông báo cho bạn khi yêu cầu của bạn được xử lý.',
  },
  purchase_success_3: {
    en: 'You would need to make payment with the amount of',
    vi: 'Bạn cần thanh toán với số tiền',
  },
  verification_code: {
    en: 'Verification code',
    vi: 'Mã xác minh',
  },
  purchase_success_4: {
    en: 'Transfer information: Please indicate FAST Transfer',
    vi:
      'Thông tin chuyển khoản: Vui lòng chọn hình thức CHUYỂN KHOẢN NHANH',
  },
  company_name: {
    en: 'Company Name',
    vi: 'Tên công ty',
  },
  bitcoin_address: {
    en: 'Bitcoin wallet address',
    vi: 'Địa chỉ ví Bitcoin',
  },
  purchase_success_5: {
    en:
      'Upon receipt of your request, we will process it within the next 24 hours',
    vi:
      'Khi nhận được yêu cầu của bạn, chúng tôi sẽ xử lý trong vòng 24 giờ tới.',
  },
  purchase_success_6: {
    en:
      'Should you have any further queries, please do not hesitate to contact our support team via: admin@optimagroup.sg',
    vi:
      'Nếu bạn có thêm bất kỳ câu hỏi nào, xin vui lòng liên hệ với nhóm hỗ trợ của chúng tôi qua: admin@optimagroup.sg',
  },
  respectfully: {
    en: 'Respectfully',
    vi: 'Trân trọng',
  },
  my_course: {
    en: 'My Courses',
    vi: 'Khóa học của tôi',
  },
  view_my_course: {
    en: 'My Courses',
    vi: 'Xem các Khóa học của tôi',
  },
  logout: {
    en: 'Logout',
    vi: 'Đăng xuất',
  },
  new_password: {
    en: 'New Password',
    vi: 'Mật khẩu mới',
  },
  wait_moment: {
    en: 'Please wait a moment. Your account is being activated.',
    vi:
      'Xin vui lòng chờ trong giây lát. Tài khoản của bạn đang được kích hoạt.',
  },
  account_activated: {
    en:
      'Your account has been activated. Now you can login with your account. Thanks.',
    vi:
      'Tài khoản của bạn đã được kích hoạt. Bây giờ bạn có thể đăng nhập bằng tài khoản của bạn. Cảm ơn.',
  },
  login_now: {
    en: 'LOGIN NOW',
    vi: 'Đăng nhập ngay bây giờ',
  },
  check_email_active_account: {
    en: 'Please check your email to active the account. Thanks!',
    vi:
      'Vui lòng kiểm tra email của bạn để kích hoạt tài khoản. Cảm ơn!',
  },
  input_deposit: {
    en: 'Please enter the amount of money you want to deposit',
    vi: 'Vui lòng nhập số tiền bạn muốn nạp',
  },
  input_transfer: {
    en: 'Please enter the amount of money you want to transfer',
    vi: 'Vui lòng nhập số tiền bạn muốn chuyển',
  },
  purchase_success: {
    en: 'PURCHASE SUCCESSFUL',
    vi: 'Mua khóa học thành công!',
  },
  own_course: {
    en: 'Congratulation! You owned this course.',
    vi: 'Chúc mừng! Bạn đã sở hữu khóa học này.',
  },
  request_payment_success: {
    en: 'REQUEST A PAYMENT SUCCESSFUL!',
    vi: 'YÊU CẦU THANH TOÁN THÀNH CÔNG!',
  },
  request_process_soon: {
    en:
      'Your request will be processed soon. Please check your mail box for more information.',
    vi:
      'Yêu cầu của bạn sẽ được xử lý sớm. Vui lòng kiểm tra hộp thư của bạn để biết thêm thông tin.',
  },
  go_to_course_page: {
    en: 'GO TO COURSE PAGE',
    vi: 'Đi đến trang Khóa học',
  },
  ref_link: {
    en: 'Referral Link',
    vi: 'Link giới thiệu',
  },
  your_ref: {
    en: 'Your Referral',
    vi: 'Người giới thiệu của bạn',
  },
  login_payment: {
    en: 'Please login to proceed with payment',
    vi: 'Hãy đăng nhập để tiến hành thanh toán',
  },
  for_new_member: {
    en: 'For new members',
    vi: 'Dành cho thành viên mới',
  },
  is_member: {
    en: 'Already a member of OPTIMA GROUP',
    vi: 'Đã là thành viên của OPTIMA GROUP',
  },
  contact_sent: {
    en: 'Your contact email has been sent to OPTIMA GROUP. Thanks!',
    vi:
      'Email liên lạc của bạn đã được gửi đến OPTIMA GROUP. Cảm ơn!',
  },
  we_will_back: {
    en: 'We will be back to you soon.',
    vi: 'Chúng tôi sẽ trở lại với bạn sớm.',
  },
  go_to_home_page: {
    en: 'GO TO HOME PAGE',
    vi: 'Trở vê trang chủ',
  },
  lang_en: {
    en: 'English',
    vi: 'Tiếng Anh',
  },
  lang_vi: {
    en: 'Vietnamese',
    vi: 'Tiếng Việt',
  },
  your_course: {
    en: 'Your courses',
    vi: 'Khóa học đã mua',
  },
  note_policy: {
    en: 'Note: By clicking on REGISTER, you accept with our',
    vi: 'Lưu ý: Khi click nút ĐĂNG KÝ, bạn đã đồng ý với',
  },
  privacy_policy: {
    en: 'Privacy Policy',
    vi: 'Chính sách bảo mật',
  },
  term_of_service: {
    en: 'Term Of Service',
    vi: 'Điều khoản sử dụng',
  },
  login_success: {
    en: 'Login successful!',
    vi: 'Đăng nhập thành công',
  },
  active_account_success: {
    en: 'Active account successful!',
    vi: 'Kích hoạt tài khoản thành công',
  },
  check_email_reset: {
    en: 'Please check your email to reset password!',
    vi: 'Vui lòng kiểm tra email của bạn để thay đổi mật khẩu',
  },
  password_reset: {
    en: 'Password have been reset',
    vi: 'Mật khẩu đã được thay đổi',
  },
  send_success: {
    en: 'Contact has been sent!',
    vi: 'Thông tin liên hệ đã được gửi đi!',
  },
  request_buy_course: {
    en: 'Request to buy course successfully!',
    vi: 'Yêu cầu mua khóa học đã được gửi đi!',
  },
  buy_course: {
    en: 'Buy course successfully!',
    vi: 'Mua khóa học thành công',
  },
  update_profile_success: {
    en: 'Update profile success!',
    vi: 'Cập nhật thông tin cá nhân thành công',
  },
  withdraw_success: {
    en: 'Request to withdraw successfully',
    vi: 'Yêu cầu rút tiền đã được gửi đi',
  },
  deposit_success: {
    en: 'Request to deposit successfully',
    vi: 'Yêu cầu nạp tiền đã được gửi đi',
  },
  sorry_disable_deposit: {
    en: 'Sorry but deposit is disabled',
    vi: 'Xin lỗi nhưng tính năng nạp tiền hiện đang bảo trì',
  },
  rule_game: {
    en: 'TERMS & CONDITIONS',
    vi: 'ĐIỀU KIỆN & QUY ĐỊNH',
  },
  game_term_condition_content: {
    en: `###### **RULES**
    *  Each member will be given lucky draw by OPTIMA GROUP corresponding to the number of members currently managing. 
    *  When the lucky spin starts, the member has a chance to receive the corresponding bonuses on the wheel. 
    ###### **NOTE**
    * If the member's turn is not over, Admin will save it for later rounds
    
    ###### **CONDITIONS OF PARTICIPATION**
    *  As a member of OPTIMA GROUP
    *  The account owner is real, does not violate any regulations on OPTIMA GROUP.
    
    ###### **REGULATIONS**:
    *  Members are not allowed to use the software to disrupt and adjust the results of the Lucky Draw. If violating this regulation and discovered, members will be permanently locked account at OPTIMA GROUP.
    *  The amount of member drawn will be deposited directly into that member's wallet account. No transfer or transfer in any form to another member.`,
    vi: `###### **THỂ LỆ**
    *  Mỗi thành viên sẽ được OPTIMA GROUP tặng số lượt quay may mắn tương ứng với số thành viên đang quản lý. 
    *  Khi Vòng quay may mắn bắt đầu khởi động, thành viên có cơ hội nhận được những số tiền thưởng tương ứng trên vòng quay. 
    ###### **LƯU Ý**
    * Nếu lượt quay của thành viên chưa hết thì sẽ được Admin bảo lưu lại để quay tiếp ở những vòng sau
    
    ###### **ĐIỀU KIỆN THAM GIA**
    *  Là thành viên của OPTIMA GROUP
    *  Chủ tài khoản là người thật, không vi phạm bất kỳ quy định nào trên OPTIMA GROUP.
    
    ###### **QUY ĐỊNH**:
    *  Thành viên không được sử dụng phần mềm để gây rối, điều chỉnh kết quả của Vòng quay may mắn. Nếu vi phạm quy định này và bị phát hiện, thành viên sẽ bị khóa tài khoản vĩnh viễn tại OPTIMA GROUP.
    *  Số tiền thành viên đã quay trúng thưởng sẽ được nạp trực tiếp vào tài khoản Ví của thành viên đó. Không chấp nhận việc sang nhượng hay chuyển đổi dưới mọi hình thức cho thành viên khác.`,
  },
  play_game: {
    en: 'WHEEL REWARDS',
    vi: 'VÒNG QUAY MAY MẮN',
  },
  rank_game: {
    en: 'LUCKY SPINNER HISTORY',
    vi: 'LỊCH SỬ VÒNG QUAY',
  },
  button_add_money_to_wallet: {
    en: 'TAKE MONEY TO WALLET',
    vi: 'NẠP TIỀN VÀO VÍ NGAY',
  },
  game_turn: {
    en: 'Turn',
    vi: 'Lượt',
  },
  current_result_text: {
    en: 'You have just received',
    vi: 'Bạn vừa quay được:',
  },
  current_total_money_text: {
    en: 'Your total bonus',
    vi: 'Tổng tiền thưởng của bạn:',
  },
  table_column_rank: {
    en: 'RANK',
    vi: 'HẠNG',
  },
  table_column_item: {
    en: 'GIFT',
    vi: 'QUÀ TẶNG',
  },
  table_column_date_created: {
    en: 'WINNING DAY',
    vi: 'NGÀY TRÚNG THƯỞNG',
  },
  table_column_username: {
    en: 'USERNAME',
    vi: 'USERNAME',
  },
  table_column_name: {
    en: 'NAME',
    vi: 'HỌ TÊN',
  },
  table_column_sale_amount: {
    en: 'SALE AMOUNT',
    vi: 'SỐ SALE SỐ VÒNG QUAY',
  },
  turn_list_label: {
    en: 'Your draw turn',
    vi: 'Lượt quay thưởng',
  },
  add_money_success: {
    en: 'DEPOSIT TO SUCCESSFULLY!',
    vi: 'NẠP TIỀN VÀO VÍ THÀNH CÔNG!',
  },
  add_money_content: {
    en:
      'Congratulations! You have just added money to your wallet successfully.',
    vi: 'Xin chúc mừng! Bạn vừa nạp thêm tiền vào ví thành công.',
  },
  current_money_add: {
    en: 'The amount you just added into your wallet',
    vi: 'Số tiền bạn vừa nạp vào ví',
  },
  total_money_added: {
    en: 'The total amount you have deposited into your wallet',
    vi: 'Tổng số tiền bạn đã nạp vào ví',
  },
  button_check_wallet: {
    en: 'VIEW WALLET NOW',
    vi: 'XEM VÍ NGAY',
  },
  result_modal_sub_heading: {
    en: 'You receive a gift',
    vi: 'Bạn vừa quay trúng một phần quà',
  },
  result_modal_heading: {
    en: 'CONGRATULATIONS!',
    vi: 'XIN CHÚC MỪNG!',
  },
  result_modal_next_round_button: {
    en: 'NEXT TURN',
    vi: 'QUAY LƯỢT TIẾP',
  },
  result_modal_watch_bonus: {
    en: 'SEE MY REWARDS',
    vi: 'XEM TIỀN THƯỞNG CỦA TÔI',
  },
  login_game_sub_heading: {
    en: 'To join the lucky draw, please log in to your account.',
    vi:
      'Để tham gia vòng quay may mắn trúng thưởng, bạn vui lòng đăng nhập vào tài khoản của mình.',
  },
  hello: {
    en: 'Hello!',
    vi: 'Xin chào!',
  },
  time_event_title: {
    en: 'Event time:',
    vi: 'Thời gian event:',
  },
  roll_amount_left: {
    en: 'Your remaining spin',
    vi: 'Lượt quay còn lại của bạn',
  },
  end_of_roll: {
    en:
      'You do not have any sales yet, please refill the sale to participate in the prize drawing',
    vi:
      'Bạn chưa có lượt sale nào, vui lòng nạp sale để tham gia quay thưởng',
  },
  date_update: {
    en: 'Update',
    vi: 'Cập nhật',
  },
  withdraw_type: {
    en: 'Withdraw Type',
    vi: 'Hình thức chuyển khoản',
  },
  deposit_type: {
    en: 'Deposit Type',
    vi: 'Hình thức chuyển khoản',
  },
  paypal_name: {
    en: 'PayPal Name',
    vi: 'Tên tài khoản PayPal',
  },
  paypal_email: {
    en: 'PayPal Email',
    vi: 'Email tài khoản PayPal',
  },
  quiz: {
    en: 'Quiz',
    vi: 'Thi Trắc Nghiệm',
  },
  quiz_rank: {
    en: 'Leaderboard',
    vi: 'Bảng Xếp Hạng',
  },
  result: {
    en: 'result',
    vi: 'KẾT QUẢ',
  },
  rank: {
    en: 'rank',
    vi: 'BẢNG XẾP HẠNG',
  },
  column_sale: {
    en: 'SALE',
    vi: 'SỐ SALE',
  },
  start_quiz: {
    en: 'START QUIZ',
    vi: 'BẮT ĐẦU THI',
  },
  quiz_during_time: {
    en: 'Time of the event:',
    vi: 'Thời gian diễn ra chương trình:',
  },
  quiz_time_from: {
    en: 'from',
    vi: 'từ',
  },
  quiz_title: {
    en: 'QUIZ TEST',
    vi: 'ĐỀ THI TRẮC NGHIỆM',
  },
  quiz_time_exam: {
    en: 'Time to do the quiz',
    vi: 'Thời gian làm bài',
  },
  time_unit_minute: {
    en: 'minute',
    vi: 'phút',
  },
  time_unit_seconds: {
    en: 'seconds',
    vi: 'giây',
  },
  quiz_result_success: {
    en:
      'Congratulations on answering our entire quiz question correctly. You have been participating in the rankings with other members.',
    vi:
      'Xin chúc mừng bạn đã trả lời chính xác toàn bộ câu hỏi trắc nghiệm của chúng tôi. Bạn đã được tham gia vào bảng xếp hạng với các thành viên khác.',
  },
  quiz_result_failure_1: {
    en: 'You need to get enoungh',
    vi: 'Bạn cần phải đạt đủ',
  },
  quiz_result_failure_2: {
    en:
      'SCORE to participate in ranking with other members. Do you want to retake the quiz?',
    vi:
      'ĐIỂM để tham gia xếp hạng với các thành viên khác. Bạn muốn thi lại không?',
  },
  btn_result: {
    en: 'VIEW RESULT',
    vi: 'XEM KẾT QUẢ NGAY',
  },
  btn_rank: {
    en: 'VIEW RANK',
    vi: 'XEM BẢNG XẾP HẠNG NGAY',
  },
  btn_test_again: {
    en: 'DO QUIZ AGAIN',
    vi: 'THI LẠI NGAY',
  },
  btn_cancel: {
    en: 'CANCEL',
    vi: 'HỦY BỎ',
  },
  quiz_result: {
    en: 'YOUR RESULT',
    vi: 'KẾT QUẢ THI TRẮC NGHIỆM CỦA BẠN',
  },
  quiz_result_subheading: {
    en: 'Thank you for taking your quiz. Below are your results',
    vi:
      'Cảm ơn bạn đã tham gia phần thi trắc nghiệm của mình. Bên dưới là kết quả của bạn',
  },
  quiz_level: {
    en: 'Level',
    vi: 'Cấp độ',
  },
  quiz_score: {
    en: 'TOTAL SCORE YOU REACH',
    vi: 'TỔNG SỐ ĐIỂM BẠN ĐẠT ĐƯỢC',
  },
  quiz_answer: {
    en: 'TOTAL QUESTION YOU HAVE ANSWED',
    vi: 'TỔNG CÂU HỎI BẠN ĐÃ TRẢ LỜI',
  },
  quiz_not_enough_answer: {
    en:
      'You have not answered all our questions. You need to answer all 30 questions to see the result.',
    vi:
      'Bạn trả lời chưa hết các câu hỏi của chúng tôi. Bạn cần trả lời hết 30 câu hỏi để xem kết quả.',
  },
  btn_watch_question_again: {
    en: 'REVIEW THE QUESTION TABLE',
    vi: 'XEM LẠI BẢNG CÂU HỎI',
  },
  quiz_over_time: {
    en:
      'You have run out of 15 minutes of test time. Do you want to retake the exam?',
    vi:
      'Bạn đã hết 15 phút thời gian làm bài. Bạn có muốn thi lại không?',
  },
  btn_cancel_test: {
    en: 'I DON"T WANT TO TAKE ANYTHING',
    vi: 'TÔI KHÔNG MUỐN THI NỮA',
  },
  quiz_over_turn: {
    en:
      'You have already taken the multiple-choice test twice today. If you have not met the test score and want to retake the test, join it again tomorrow or another day!',
    vi:
      'Bạn đã tham gia thi trắc nghiệm 2 lần trong ngày hôm nay rồi. Nếu bạn chưa đạt đủ số điểm thi trắc nghiệm và muốn thi lại, hãy tham gia trở lại vào ngày mai hoặc ngày khác nhé!',
  },
  btn_test_another_day: {
    en: 'OK, I"M PARTICIPATING ON THE OTHER DAY',
    vi: 'OK, TÔI SẼ THAM GIA VÀO NGÀY KHÁC',
  },
  cannot_active_account: {
    en: 'Cannot active the account',
    vi: 'Không thể kích hoạt tài khoản',
  },
  cannot_reset_password: {
    en:
      'Cannot reset password, make sure you enter correct email address!',
    vi:
      'Không tìm thấy người dùng, vui lòng kiểm tra lại địa chỉ email',
  },
  reset_password: {
    en:
      'Reset password',
    vi:
      'Thay đổi mật khẩu',
  },
  verify_contract: {
    en: 'You need to verify contract first!',
    vi: 'Vui lòng hoàn thiện hợp đồng của bạn!',
  },
  contract_info: {
    en: 'Contract information',
    vi: 'Nội dung hợp đồng',
  },
  contract_request: {
    en: 'REQUEST',
    vi: 'GỬI YÊU CẦU',
  },
  max_filesize: {
    en:
      'Upload image size should be less than 2MB. Images can be compressed online at ',
    vi:
      'Kích cỡ ảnh up lên phải dưới 2MB. Có thể nén ảnh online tại ',
  },
  id_card: {
    en: 'Your id card number',
    vi: 'Số chứng minh thư',
  },
  address: {
    en: 'Your address',
    vi: 'Địa chỉ',
  },
  tax_code: {
    en: 'Tax code (Optional)',
    vi: 'Mã số thuế (nếu có)',
  },
  front_cmnd: {
    en: 'The front of your identity card',
    vi: 'Mặt trước CMND',
  },
  back_cmnd: {
    en: 'The back of your identity card',
    vi: 'Mặt sau CMND',
  },
  image_of: {
    en: 'Image of',
    vi: 'Hình ảnh của',
  },
  accept_contract: {
    en:
      'Agree to the contract (When you agree to the contract, it means that you have signed and abided by our terms)',
    vi:
      'Đồng ý với Hợp đồng (Khi bạn đồng ý với hợp đồng là đồng nghĩa với việc bạn đã ký tên và tuân thủ các điều khoản của chúng tôi)',
  },
  first_of_page: {
    en: 'First of page',
    vi: 'Đây là trang đầu tiên',
  },
  last_of_page: {
    en: 'Last of page',
    vi: 'Đây là trang cuối cùng',
  },
  page: {
    en: 'Page',
    vi: 'Trang',
  },
  of: {
    en: 'of',
    vi: 'của',
  },
  post_contract_success: {
    en: 'Your contract is sent!',
    vi: 'Hợp đồng của bạn đã được gửi!',
  },
  post_contract_success_msg: {
    en:
      'Your contract request has been sent to OPTIMA GROUP. We will inform you when your request is processed.',
    vi:
      'Hợp đồng của bạn đã được gửi đến OPTIMA GROUP. Chúng tôi sẽ thông báo cho bạn khi yêu cầu của bạn được xử lý',
  },
  note_contract: {
    en:
      'Your contract will be approved in 2-3 working days. Please wait and check email for more contract status',
    vi:
      'Hợp đồng của bạn sẽ được duyệt từ 2-3 ngày làm việc. Bạn vui lòng đợi và kiểm tra email để biết thêm tình trạng hợp đồng',
  },
  operation_regulation: {
    en: 'Regulation of Operations',
    vi: 'Quy chế hoạt động',
  },
  banned: {
    en:
      'Account has been terminated from the course distribution affiliate cooperation contract OPTIMA GROUP',
    vi:
      'Tài khoản đã bị cắt hợp đồng hợp tác liên kết phân phối khóa học OPTIMA GROUP',
  },
  verified: {
    en: 'Account is verified',
    vi: 'Tài khoản đã được xác minh',
  },
  need_verify: {
    en: 'Account needs to be verified. Please verify at ',
    vi: 'Tài khoản cần được xác minh. Vui lòng xác minh tại ',
  },
  matching_info: {
    en:
      'Note: The contract information must match the withdrawal information',
    vi:
      'Lưu ý: Thông tin hợp đồng phải trùng khớp với thông tin rút tiền',
  },
  here: {
    en: 'here',
    vi: 'đây',
  },
  accumulate: {
    en: 'ACCUMULATE',
    vi: 'TÍCH LUỸ ĐIỂM',
  },
  accumulate_text: {
    en: 'Accumulate',
    vi: 'Tích luỹ điểm',
  },
  view_promotion: {
    en: 'View interest',
    vi: 'Xem đặc quyền',
  },
  current_rank: {
    en: 'Your current rank',
    vi: 'Hạng hiện tại của bạn',
  },
  user_promotion: {
    en: 'YOUR PROMOTION',
    vi: 'ĐẶC QUYỀN CỦA BẠN',
  },
  button_gift: {
    en: 'Gifts',
    vi: 'Quà tặng ưu đãi',
  },
  button_other_option: {
    en: 'Promotion',
    vi: 'Đặc quyền khác',
  },
  current_score_text: {
    en: 'Your current point',
    vi: 'Số điểm hiện tại của bạn',
  },
  score_text: {
    en: 'point',
    vi: 'điểm',
  },
  button_exchange: {
    en: 'Exchange now',
    vi: 'Đổi ngay',
  },
  exchange_comfirm_text: {
    en: 'Want to redeem this offer?',
    vi: 'Bạn muốn đổi ưu đãi này?',
  },
  description_form_exchange: {
    en:
      'Please fill in your information to receive promotion gift from us',
    vi: 'Hãy điền thông tin của bạn để nhận quà ưu đãi từ chúng tôi',
  },
  button_exchange_okay: {
    en: 'Comfirm',
    vi: 'Đồng ý',
  },
  button_exchange_cancel: {
    en: 'Cancel',
    vi: 'Huỷ bỏ',
  },
  history_exchange: {
    en: 'View redemption history',
    vi: 'Xem lịch sử đổi điểm',
  },
  history_exchange_title: {
    en: 'YOUR REDEEM HISTORY',
    vi: 'LỊCH SỬ ĐỔI ĐIỂM CỦA BẠN',
  },
  update_at: {
    en: 'Date',
    vi: 'Ngày',
  },
  name_redeem: {
    en: 'Gift',
    vi: 'Tên quà tặng',
  },
  point_redeem: {
    en: 'Point',
    vi: 'Số điểm đã đổi',
  },
  redeem_status: {
    en: 'Status',
    vi: 'Trạng thái',
  },
  redeem_total: {
    en: 'Total point redeem',
    vi: 'Tổng số điểm đã đổi',
  },
  note_exchange: {
    vi:
      '*Tại Việt Nam và Singapore công ty đã có văn phòng vì vậy công ty sẽ hỗ trợ ship tận nhà và phí ship khách hàng thanh toán. <br>' +
      '*Nếu ở các nước khác khách hàng vui lòng nhờ người thân tại Việt Nam hoặc Singapore nhận hộ.',
    en:
      '*In Vietnam and Singapore the company already has an office so the company will support home shipping and the shipping fee paid by customers. <br>' +
      '*If in other countries, customers please ask relatives in Vietnam or Singapore to accept them.',
  },
  buy_course_confirm: {
    en: 'Are you sure to buy this course?',
    vi: 'Bạn có chắc chắn là mua khóa học này?',
  },
  wallet_not_enough: {
    en:
      'Your wallet is not enough! You need recharge to buying this course!',
    vi:
      'Số dư của bạn không đủ! Vui lòng nạp thêm để tiếp tục mua khóa học này!',
  },
  need_recharge: {
    en: 'You need to top up an extra  to buy this course!',
    vi:
      'Số dư của bạn không đủ! Vui lòng nạp thêm để tiếp tục mua khóa học này!',
  },
  transfer_money: {
    en: 'Transfer Money',
    vi: 'Chuyển tiền',
  },
  user: {
    en: 'User',
    vi: 'Người dùng',
  },
  select: {
    en: 'Select',
    vi: 'Chọn',
  },
  post_transfer_success: {
    en: 'Create transfer money request successfully!',
    vi: 'Tạo giao dịch chuyển tiền thành công!',
  },
  fill_input: {
    en: 'Please fill input below!',
    vi: 'Vui lòng nhập đầy đủ thông tin dưới đây!',
  },
  fill_input_field: {
    en: 'Please input this field',
    vi: 'Vui lòng không để trống ô này',
  },
  note: {
    en: 'Note',
    vi: 'Ghi chú',
  },
  verify_transfer: {
    en: 'Verify your transfer',
    vi: 'Xác thực chuyển tiền',
  },
  your_verify_code: {
    en: 'Verify code',
    vi: 'Mã xác thực',
  },
  input_verify_code: {
    en: 'Please input verify code',
    vi: 'Vui lòng nhập mã xác thực',
  },
  note_transfer: {
    en:
      'After sending request, we will send an OTP to your email for authentication!',
    vi:
      'Sau khi gửi yêu cầu, chúng tôi sẽ gửi một email đính kèm mã xác thực đến hòm thư của bạn',
  },
  receive_money: {
    en: 'RECEIVE',
    vi: 'NHẬN TIỀN',
  },
  send_money: {
    en: 'SEND',
    vi: 'CHUYỂN TIỀN',
  },
  receiver_name: {
    en: 'Receiver name',
    vi: 'Tên người nhận',
  },
  receiver_code: {
    en: 'Receiver code',
    vi: 'Mã người nhận',
  },
  sender_name: {
    en: 'Sender name',
    vi: 'Tên người chuyển',
  },
  sender_code: {
    en: 'Sender code',
    vi: 'Mã người chuyển',
  },
  note_deposit: {
    en:
      'After payment is made, your request will be process within 1 business day. <br/> We will send the confirmation notice to your email.',
    vi:
      'Sau khi gửi, yêu cầu của bạn sẽ được thực hiện trong vòng 1 ngày làm việc. <br/> Chúng tôi sẽ gửi thông báo xác nhận đến email của bạn.',
  },
  login_type: {
    en: 'Username or Email',
    vi: 'Mã người dùng hoặc Email',
  },
  receive_username: {
    en: 'Receive Username',
    vi: 'Username người nhận',
  },
  contact_optimagroup: {
    en: 'Contact Optima Group',
    vi: 'Liên hệ Optima Group',
  },
  insurance: {
    en: 'Cash-back fund',
    vi: 'Quỹ hoàn tiền',
  },
  insurance_2: {
    en: 'Cash-back fund',
    vi: 'Quỹ hoàn tiền',
  },
  insurance_for_course: {
    en: 'Cash-back fund for course',
    vi: 'Các Quỹ hoàn tiền cho khóa học',
  },
  confirm_buy_insurance: {
    en: 'Are you sure to join our Cash-back fund for this course?',
    vi:
      'Bạn có chắc chắn muốn tham gia Quỹ hoàn tiền cho khóa học này?',
  },
  buy_insurance_successful: {
    en:
      'Congratulation! You have joined Cash-back fund this course.!',
    vi: 'Xin chúc mừng bạn đã tham gia Quỹ hoàn tiền tuyệt vời này.!',
  },
  insurance_history: {
    en: 'View your course Cash-back fund',
    vi: 'Xem các Quỹ hoàn tiền đã tham gia',
  },
  capital_insurance: {
    en: 'Capital Cash-back fund',
    vi: 'Quỹ hoàn học phí',
  },
  insurance_package: {
    en: 'Cash-back fund <br> <b>package</b>',
    vi: 'Gói <br> <b>Quỹ hoàn tiền</b>',
  },
  insurance_fee: {
    en: 'The participation fee is for you',
    vi: 'Phí tham gia dành cho bạn',
  },
  insurance_fee_percent: {
    en:
      'corresponding to 10% of the value of the course you have purchased',
    vi: '(tương ứng 10% với giá trị khoá học bạn đã mua)',
  },
  want_buy: {
    en: 'I want to buy',
    vi: 'Tôi muốn mua',
  },
  insurance_who: {
    en: 'Who are eligible to purchase ASIA INSURE?',
    vi: 'Những ai được quyền tham gia Quỹ hoàn tiền ASIA INSURE ?',
  },
  insurance_who_answer: {
    en:
      'ASIA INSURE is a volunteer-based (optional) Cash-back fund program offered by ASIA INSURE’s Cash-back Fund' +
      ' to support the students of OPTIMA GROUP. Therefore, all customers of OPTIMA GROUP' +
      ' are eligible to buy ASIA INSURE.',
    vi:
      'Quỹ hoàn tiền ASIA INSURE là quỹ tự nguyện ( không bắt buộc) do Quỹ bảo hiểm ASIA INSURE đưa ra ' +
      'để hỗ trợ các học viên của OPTIMA GROUP vì vậy tất cả khách hàng của OPTIMA GROUP' +
      ' đều có thể đăng ký tham gia mua bảo hiểm ASIA INSURE.',
  },
  insurance_long: {
    en: 'How long does it take to receive the payouts?',
    vi: 'Thời gian bao lâu thì được nhận số tiền hoàn trả?',
  },
  insurance_long_answer: {
    en:
      'The time to receive the payouts is not fixed. It can be days, weeks, months or longer, depending on 2 fully automatically programmed factors:' +
      '<br><br>1. Time of Cash-back fund purchase: First-come first-served basis.\n' +
      '<br>2. Number of Cash-back fund buyers (System automatically pays out once the milestone is hit => Money goes straight to your wallets)\n' +
      '<br><i>Every month, we declare the list of payees publicly and transparently on the Website so that customers can track and check.</i>',
    vi:
      'Thời gian nhận số tiền bảo hiểm sẽ không cố định có thể là vài ngày, vài tuần, vài tháng hoặc có thể lâu hơn vì thời gian nhận nó sẽ phụ thuộc vào 2 yếu tố được lập trình hoàn toàn tự động : ' +
      '<br><br>1. Thời gian mua bảo hiểm, khách hàng mua bảo hiểm sớm thì nhận sớm hơn, khách hàng mua bảo hiểm muộn hơn thì nhận muộn hơn\n' +
      '<br>2. Số lượng khách hàng tham gia mua bảo hiểm.(Quỹ cứ đạt mốc là hệ thống đã được lập trình sẵn sẽ tự động chi trả các mức bảo hiểm => Tiền chuyển thẳng về ví )\n' +
      '<br><i>Hàng tháng chúng tôi sẽ công bố danh sách khách hàng được nhận tiền bảo hiểm công khai minh bạch trên Website để khách hàng có thể theo dõi và đối chiếu</i>',
  },
  insurance_recommend: {
    en:
      'Do i have to recommend or sell something to receive the payouts?',
    vi:
      'Để nhận tiền bảo hiểm thì tôi có phải giới thiệu hay bán hàng gì không?',
  },
  insurance_recommend_answer: {
    en:
      'The ASIA INSURE program does not require any selling or referral at all.' +
      '<br><br>However, if you actively help us share the value of ASIA INSURE to more people, it means that more customers will know ASIA INSURE. ' +
      'The more customers join ASIA INSURE, the faster the fund will increase and the faster the customers will receive the payouts.',
    vi:
      'Chương trình bảo hiểm ASIA INSURE hoàn toàn không yêu cầu khách hàng phải bán hàng hay giới thiệu.' +
      '<br><br>Tuy nhiên nếu bạn tích cực giúp chúng tôi chia sẻ giá trị của ASIA INSURE đến với nhiều người' +
      ' thì đồng nghĩa với việc sẽ có nhiều khách hàng biết đến ASIA INSURE, có nhiều khách hàng tham gia ASIA INSURE thì quỹ bảo hiểm sẽ ngày càng tăng nhanh' +
      ' và khách hàng sẽ được nhận bảo hiểm nhanh hơn.',
  },
  insurance_stop: {
    en:
      'I have purchased ASIA INSURE and paid the fee. however due to personal reason,' +
      ' i would want to stop and withdraw the fees. Is it possible?',
    vi:
      'Tôi đã mua bảo hiểm ASIA INSURE và đóng phí bảo hiểm nhưng vì lý do cá nhân' +
      ' tôi muốn dừng bảo hiểm và rút lại tiền phí đã đóng có được không?',
  },
  insurance_stop_answer: {
    en:
      'ASIA INSURE’S Cash-back fund program operates under a smart contract programmed fully automatically.' +
      ' Hence, customers should not stop halfway. They have to receive all Cash-back fund levels. (RECEIVED 100% CAPITAL)',
    vi:
      'Chương trình bảo hiểm của ASIA INSURE hoạt động theo hợp đồng thông minh được lập trình hoàn toàn tự động' +
      ' vì vậy khách hàng sẽ không được dừng giữa chừng mà phải chờ nhận đủ các mức bảo hiểm (NHẬN ĐỦ 100% VỐN )',
  },
  insurance_how_refund: {
    en: 'Does ASIA INSURE get the later buyers to pay the earlier?',
    vi: 'Bảo hiểm ASIA INSURE có phải lấy người sau trả người trước?',
  },
  insurance_how_refund_answer: {
    en:
      'Insurance is a very humane model that has great meaning to human life.' +
      ' It not only helps to stabilize life when unexpected risks occur' +
      ' but also is a way of sharing risks in the community.' +
      ' At a certain point, the Cash-back fund received from the majority will be used to pay for the few at risk.' +
      ' <br><br>Hence, while your question is correct to some extent, it is not complete.' +
      ' You can learn more about the nature of Cash-back fund to understand this meaningful humane model,' +
      ' which has a history of development for hundreds of years.' +
      ' Please avoid misunderstanding this with the financial Ponzi scheme, which is used to mobilize capital with high-interest payments.',
    vi:
      'Bảo hiểm là một hình thức rất nhân văn có ý nghĩa to lớn với cuộc sống của con người.' +
      ' Nó không chỉ giúp ổn định cuộc sống khi rủi ro bất ngờ xảy ra mà còn là cách thức chia sẻ rủi ro trong cộng đồng.' +
      ' Tại 1 thời điểm nào đó thì số tiền bảo hiểm thu được của số đông sẽ dùng để chi trả cho số ít gặp rủi ro.' +
      ' Vì vậy câu hỏi của bạn xét 1 góc độ nào đó thì cũng có ý đúng nhưng nó chưa đầy đủ.' +
      ' <br><br>Bạn có thể tìm hiểu thêm về bản chất của bảo hiểm để hiểu hơn về mô hình nhân văn đầy ý nghĩa' +
      ' có lịch sử phát triển hàng trăm năm này tránh hiểu sai với các mô hình Ponzi tài chính huy động vốn trả lãi cao.',
  },
  insurance_purchased: {
    en: 'CASH-BACK FUND PACKAGES YOU HAVE PURCHASED',
    vi: 'CÁC QUỸ HOÀN TIỀN BẠN ĐÃ MUA',
  },
  insurance_refunded: {
    en: 'THE AMOUNT YOU ARE REFUNDED',
    vi: 'SỐ TIỀN BẠN ĐƯỢC HOÀN TRẢ',
  },
  insurance_refunded_2: {
    en: 'AMOUNT REFUNDED',
    vi: 'SỐ TIỀN BẠN ĐƯỢC HOÀN TRẢ',
  },
  insurance_add_now: {
    en: 'Add to your wallet now',
    vi: 'Nạp vào ví ngay',
  },
  insurance_refund_history: {
    en: 'View your refund history',
    vi: 'Xem lịch sử hoàn tiền',
  },
  insurance_purchased_2: {
    en: 'Cash-back fund purchased',
    vi: 'Đã tham gia Quỹ hoàn tiền',
  },
  insurance_purchase: {
    en: 'Purchase Cash-back fund',
    vi: 'Tham gia thêm Quỹ hoàn tiền',
  },
  insurance_frequently: {
    en: 'Frequently Asked Questions',
    vi: 'Những câu hỏi thường gặp',
  },
  insurance_total_referral: {
    vi: 'Tổng số lượt giới thiệu gói bảo hiểm',
    en: 'Total Cash-back fund referral',
  },
  progress: {
    en: 'Progress',
    vi: 'Tiến trình',
  },
  username_filter: {
    en: 'Sorry, only letters (a-z), numbers (0-9) are allowed',
    vi: 'Xin lỗi, chỉ được phép sử dụng các chữ cái (a-z), số (0-9)',
  },
  complete_video_success: {
    en: 'Complete video successfully',
    vi: 'Đã hoàn thành video!',
  },
  next_video: {
    en: 'Move to next video?',
    vi: 'Chuyển tới video tiếp theo?',
  },
  total_video: {
    en: 'Total video',
    vi: 'Tổng số video',
  },
  total_video_watch: {
    en: 'Total watched video',
    vi: 'Tổng số video đã xem',
  },
  complete: {
    en: 'Complete',
    vi: 'Hoàn thành',
  },
  num_of_lesson: {
    en: 'courses',
    vi: 'bài học',
  },
  num_of_video: {
    en: 'videos',
    vi: 'video',
  },
  course_info: {
    en: 'Course Information',
    vi: 'Thông tin khóa học',
  },
  course_info_include: {
    en: 'CONTENT OF THIS COURSE IS:',
    vi: 'NỘI DUNG KHÓA HỌC NÀY BAO GỒM:',
  },
  curriculum_info: {
    en: 'Curriculum',
    vi: 'Giáo trình',
  },
  courses_suggest: {
    en: 'OTHER SELF IMPROVEMENT COURSES',
    vi: 'CÁC KHOÁ HỌC PHÁT TRIỂN BẢN THÂN KHÁC',
  },
  course_price: {
    en: 'Price',
    vi: 'Giá tiền',
  },
  new_member_price: {
    en: 'For new member',
    vi: 'Áp dụng cho thành viên mới',
  },
  member_price: {
    en: 'For loyalty member',
    vi: 'Áp dụng cho thành viên thân thiết',
  },
  lessons_are_included: {
    en: 'Courses included',
    vi: 'Các khóa học bao gồm',
  },
  improve_skill_course: {
    en: 'IMPROVE SKILL COURSES',
    vi: 'KHÓA HỌC PHÁT TRIỂN KỸ NĂNG',
  },
  english_course: {
    en: 'ENGLISH COURSES',
    vi: 'KHÓA HỌC TIẾNG ANH',
  },
  no_courses: {
    en: 'No any courses',
    vi: 'Không có khoá học nào cả',
  },
  combo_include: {
    en: 'COMBO INCLUDE WHEN YOU BUY THIS COURSE',
    vi: 'COMBO CÁC KHÓA HỌC DÀNH CHO BẠN KHI SỞ HỮU KHÓA HỌC NÀY',
  },
  course_part_1: {
    en: 'THIS',
    vi: 'KHÓA',
  },
  course_part_2: {
    en: 'LEVEL CONSISTS OF VARIOUS COURSES:',
    vi: 'BAO GỒM CÁC KHÓA HỌC SAU:',
  },
  select_courses: {
    en: 'CHOOSE COURSE YOU ARE INTERESTED',
    vi: 'HÃY LỰA CHỌN KHÓA HỌC BẠN QUAN TÂM',
  },
  english_note: {
    en:
      'Note: The price of the course includes taxes, members do not have to pay any extra costs.',
    vi:
      'Lưu ý: Giá của khóa học đã bao gồm thuế phí, thành viên không  phải mất thêm bất kì khoản chi phí nào.',
  },
  our_english_courses_title: {
    en: 'OUR ENGLISH COURSES',
    vi: 'CÁC KHÓA HỌC TIẾNG ANH',
  },
  suggest_english_courses_title: {
    en: 'OTHER ENGLISH COURSES',
    vi: 'CÁC KHOÁ HỌC TIẾNG ANH KHÁC',
  },
  partner_list_title: {
    en: 'PARTNER',
    vi: 'ĐỐI TÁC',
  },
  receive_gift_info: {
    en: 'Information receive gift',
    vi: 'Thông tin nhận quà',
  },
  put_info_success: {
    en: 'Congratulations on your successful gift receipt',
    vi: 'Chúc mừng bạn đã gửi thông tin nhận quà thành công',
  },
  lucky_spinner_title: {
    en: 'LUCKY SPINNER',
    vi: 'VÒNG QUAY MAY MẮN',
  },
  total_turn: {
    en: 'Your total turn is:',
    vi: 'Tổng số lượt quay của bạn là:',
  },
  total_turn_left: {
    en: 'Your total turn left is:',
    vi: 'Số lượt quay còn lại của bạn là:',
  },
  input_infor_alert: {
    en: 'Please enter information to receive gifts',
    vi: 'Vui lòng nhập thông tin để nhận quà',
  },
  bonus_history: {
    en: 'BONUS HISTORY',
    vi: 'LỊCH SỬ NHẬN THƯỞNG',
  },
  bonus_company: {
    en: 'Bonus Company',
    vi: 'Thưởng từ công ty',
  },
  user_name: {
    en: 'Full Name',
    vi: 'Tên người dùng',
  },
  user_code: {
    en: 'User code',
    vi: 'Tên đăng nhập',
  },
  user_email: {
    en: 'User email',
    vi: 'Email',
  },
  user_phone: {
    en: 'User phone',
    vi: 'SĐT',
  },
  course_type: {
    en: 'Course type',
    vi: 'Loại khóa học',
  },
  speaker_breadcrumb: {
    en: 'SPEAKERS',
    vi: 'DIỄN GIẢ',
  },
  profile_button: {
    en: 'View profile',
    vi: 'Xem profile',
  },
  about_me: {
    en: 'ABOUT ME',
    vi: 'THÔNG TIN VỀ TÔI',
  },
  teaching_process: {
    en: 'TEACHING PROCESS',
    vi: 'QUÁ TRÌNH GIẢNG DẠY',
  },
  document: {
    en: 'Document',
    vi: 'Tài liệu',
  },
  video: {
    en: 'Video',
    vi: 'Video',
  },
  speaker: {
    en: 'SPEAKERS',
    vi: 'DIỄN GIẢ',
  },
  btn_view_all: {
    en: 'View all',
    vi: 'Xem tất cả',
  },
  download: {
    en: 'Download',
    vi: 'Download',
  },
  revenue: {
    en: 'revenue',
    vi: 'doanh thu',
  },
  revenue_rank: {
    en: 'Revenue Rank',
    vi: 'BXH Doanh Thu',
  },
  promotion_price: {
    en: 'Promotion',
    vi: 'Khuyến mãi',
  },
  member_opinion: {
    en: 'OTHER TESTIMONIALS',
    vi: 'Ý KIẾN HỌC VIÊN KHÁC',
  },
  feature_opinion: {
    en: 'HIGHLIGHTS TESTIMONIALS',
    vi: 'Ý KIẾN NỔI BẬT',
  },
  opinions: {
    en: 'TESTIMONIALS',
    vi: 'Ý KIẾN HỌC VIÊN',
  },
  how_it_work: {
    en: 'HOW IT WORK',
    vi: 'CƠ CHẾ HOẠT ĐỘNG',
  },
  nav_more: {
    en: 'MORE',
    vi: 'THÊM',
  },
  security_privacy: {
    en: 'SECURITY & PRIVACY',
    vi: 'BẢO MẬT & RIÊNG TƯ',
  },
  privacy: {
    en: 'Privacy Policy',
    vi: 'Chính sách bảo mật',
  },
  terms: {
    en: 'Terms of Service',
    vi: 'Điều khoản dịch vụ',
  },
  regulation: {
    en: 'Regulation of Operations',
    vi: 'Quy chế hoạt động',
  },
  event_week_banner_line_1: {
    en: '+ Only for the first 60 Customers.',
    vi: '+ Chỉ dành cho 60 khách hàng nhanh nhất.',
  },
  event_week_banner_line_2: {
    en: '+ EVENT TIME',
    vi: '+ THỜI GIAN ÁP DỤNG CHƯƠNG TRÌNH:',
  },
  event_week_banner_line_3: {
    en: '+ Offer applicable for all courses.',
    vi: '+ Áp dụng cho tất cả các khóa học.',
  },
  event_from: {
    en: 'from',
    vi: 'từ',
  },
  event_to: {
    en: 'to',
    vi: 'đến',
  },
  event_future_title: {
    en: 'EVENT START AFTER..',
    vi: 'DIỄN RA SAU...',
  },
  event_present_title: {
    en: 'EVENT END AFTER...',
    vi: 'KẾT THÚC SAU...',
  },
  event_past_title: {
    en: 'EVENT ENDED',
    vi: 'ĐÃ KẾT THÚC',
  },
  event_sold_title: {
    en: 'Sold:',
    vi: 'Đã bán:',
  },
  event_remain_title: {
    en: 'Remain:',
    vi: 'Chỉ còn:',
  },
  event_sold_out_title: {
    en: 'ALL PROMOTIONS ARE SOLD OUT',
    vi: 'ĐÃ BÁN HẾT ƯU ĐÃI',
  },
  clock_days: {
    en: 'DAYS',
    vi: 'NGÀY',
  },
  clock_hours: {
    en: 'HOURS',
    vi: 'GIỜ',
  },
  clock_minutes: {
    en: 'MINUTES',
    vi: 'PHÚT',
  },
  clock_seconds: {
    en: 'SECONDS',
    vi: 'GIÂY',
  },
  your_current_point: {
    en: 'Your current point',
    vi: 'Số điểm hiện tại của bạn',
  },
  point: {
    en: 'point',
    vi: 'điểm',
  },
  points: {
    en: 'points',
    vi: 'điểm',
  },
  exchange_rule: {
    en: 'Points exchange rules',
    vi: 'điểm',
  },
  point_want_to_redeem: {
    en: 'Point redeem',
    vi: 'Số điểm bạn muốn quy đổi',
  },
  amount_corresponding: {
    en: 'Corresponding amount',
    vi: 'Tương ứng số tiền',
  },
  exchange_now: {
    en: 'Exchange now',
    vi: 'Đổi ngay',
  },
  exchange_money: {
    en: 'Convert to money',
    vi: 'Đổi thành tiền',
  },
  back: {
    en: 'Back',
    vi: 'Trở lại',
  },
  convert_success: {
    en: 'You have successfully converted',
    vi: 'Bạn vừa quy đổi thành công',
  },
  get_corresponding: {
    en: 'And you get the corresponding',
    vi: 'Và bạn nhận được tương ứng',
  },
  ratio_change: {
    en:
      '* The point exchange rate will vary <br> depending on the time horizon and <br> performance of the company.',
    vi:
      '* Tỷ giá quy đổi điểm sẽ luôn thay đổi <br> tùy thuộc vào thời gian và hiệu quả <br> hoạt động của công ty.',
  },
  audio_book: {
    en: 'Audiobooks',
    vi: 'Audiobooks',
  },
  kidz_book: {
    en: 'Kidz',
    vi: 'Kidz',
  },
  audio_alert_title: {
    en: 'Notify',
    vi: 'Thông báo',
  },
  audio_alert: {
    en:
      'To listen to the entire library of audiobooks, you must be an Advanced member or higher.',
    vi:
      'Để nghe toàn bộ thư viện sách nói, bạn phải là thành viên Advanced trở lên.',
  },
  kidz_alert: {
    en:
      'To access to the entire library of Kidz, you must be an member of ',
    vi:
      'Để nghe toàn bộ thư viện Kidz, bạn phải là thành viên từ ',
  },
  kidz_sale_alert: {
    en:
      'To access to the entire library of Kidz, you must be have at lease 1 sale.',
    vi:
      'Để nghe toàn bộ thư viện Kidz, bạn phải có ít nhất 1 lần bán hàng.',
  },
  audio_alert_cancel: {
    en: 'Cancel',
    vi: 'Huỷ bỏ',
  },
  audio_alert_comfirm: {
    en: 'View the course now',
    vi: 'Xem khoá học ngay',
  },
  btn_buy_now: {
    en: 'BUY NOW',
    vi: 'MUA NGAY',
  },
  only_title: {
    en: 'Only',
    vi: 'Chỉ',
  },
  year_title: {
    en: 'year',
    vi: 'năm',
  },
  audio_book_content_1: {
    en:
      '- Over 10000 High-Quality Audiobooks for you to learn and explore.',
    vi:
      '- Hơn 10000 Audiobooks chất lượng cao luôn được cập nhật. Bạn có thể tìm hiểu về tất cả mọi lĩnh vực trong cuộc sống. ',
  },
  audio_book_content_2: {
    en:
      '- The Audiobooks are carefully selected and categorized into various categories for you to choose from.',
    vi:
      '- Audiobooks chúng tôi được lựa chọn cẩn thận và phân loại với nhiều lĩnh vực khác nhau để bạn thoả sức khám phá!',
  },
  audio_book_content_promo: {
    en: '1 free advanced course included',
    vi: 'Tặng kèm 1 khoá học nâng cao.',
  },
  product_name_title: {
    en: 'Product',
    vi: 'Tên sản phẩm',
  },
  license_title: {
    en: 'Bundle:',
    vi: 'Quyền truy cập:',
  },
  balance_title: {
    en: 'Current balance:',
    vi: 'Số dư hiện tại trong ví:',
  },
  need_purchase_title: {
    en: 'Need to purchase:',
    vi: 'Đơn hàng cần thanh toán:',
  },
  order_info_title: {
    en: 'YOUR ORDER INFORMATION',
    vi: 'THÔNG TIN ĐƠN HÀNG CỦA BẠN',
  },
  audio_book_order: {
    en: 'AUDIOBOOKS ORDER',
    vi: 'AUDIOBOOKS ORDER',
  },
  audio_license_title: {
    en: 'Audiobooks Bundle',
    vi: 'Quyền truy cập Audiobooks',
  },
  audio_book_buy_success_message: {
    en:
      'Congratulations, you now have access to our Library of Audiobooks for a term of 1 year.',
    vi:
      'Xin chúc mừng, bạn đã được quyền truy cập vào Thư viện Sách nói của chúng tôi trong thời hạn 1 năm.',
  },
  audio_book_cta_btn: {
    en: 'VIEW AUDIOBOOKS NOW',
    vi: 'VÀO XEM AUDIOBOOKS NGAY',
  },
  active_license: {
    en: 'Active course',
    vi: 'Kích hoạt khóa học',
  },
  active_license_success: {
    en: 'Active course successfully!',
    vi: 'Kích hoạt khóa học thành công!',
  },
  active_your_course: {
    en: 'Active your course',
    vi: 'Kích hoạt khóa học của bạn',
  },
  insert_code_below: {
    en: 'Enter your activation code in the box below',
    vi: 'Nhập mã kích hoạt của bạn vào ô dưới đây',
  },
  key_active_note: {
    en: '*** If you have problems with the course activation, please contact: ',
    vi: '*** Nếu bạn có gặp vấn đề về kích hoạt khóa họcm xin vui lòng liên hệ: ',
  },
  active_code_success_content: {
    en: 'Congratulation, you have been to unlock',
    vi: 'Xin chúc mừng, bạn đã sở hữu khóa học',
  },
  affiliate_history: {
    en: 'Introduce History',
    vi: 'Lịch sử giới thiệu',
  },
  your_customer: {
    en: 'Your customer',
    vi: 'Khách hàng của bạn',
  },
};
