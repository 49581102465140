import { toast } from 'react-toastify';
import { CURRENT_USER_KEY, CURRENT_LANG_KEY } from '../actions';
import { routes } from '../constants';
import { translatedText } from './lang';
import axios from 'axios';
import _ from 'lodash';
import {
  message
} from 'antd';
import moment from 'moment';

export const saveSelectedCoursesToLocal = function(payload) {
  switch (payload.method) {
    case 'save':
      localStorage.setItem('favorited_courses', JSON.stringify(payload.value));
      break;
    case 'load':
      let selectedCourses = localStorage.getItem('favorited_courses');
      if (selectedCourses && selectedCourses !== 'null' && selectedCourses !== 'undefined') {
        return JSON.parse(selectedCourses);
      } else {
        return false;
      }
  }
};

export const getUserFormLocal = function() {
  let user = localStorage.getItem(CURRENT_USER_KEY);
  if (user && user !== 'null' && user !== 'undefined') {
    return JSON.parse(user);
  } else {
    return false;
  }
};

export const getUrlParameter = function getUrlParameter(sParam) {
  var sPageURL = window.location.search.substring(1),
    sURLVariables = sPageURL.split('&'),
    sParameterName,
    i;

  for (i = 0; i < sURLVariables.length; i++) {
    sParameterName = sURLVariables[i].split('=');

    if (sParameterName[0] === sParam) {
      return sParameterName[1] === undefined
        ? true
        : decodeURIComponent(sParameterName[1]);
    }
  }
};

// Create our number formatter.
export const currencyFormatter = (amount, currency = false) => {
  let formatter = new Intl.NumberFormat('en-US', {
    maximumFractionDigits: 3,
  });
  return (!currency ? '$' : '') + formatter.format(amount);
};

const splitHoursMinutesSeconds = duration => {
  duration = parseInt(duration, 10);
  let hours = Math.floor(duration / 3600);
  if (hours < 10) {
    hours = `0${hours}`;
  }
  let minutes = Math.floor(duration / 60) % 60;
  if (minutes < 10) {
    minutes = `0${minutes}`;
  }
  let seconds = duration % 60;
  if (seconds < 10) {
    seconds = `0${seconds}`;
  }

  return {
    hours,
    minutes,
    seconds,
  };
};

export const formatDuration = duration => {
  const formattedDuration = splitHoursMinutesSeconds(duration);
  return `${formattedDuration.hours}:${formattedDuration.minutes}:${formattedDuration.seconds}`;
};

export const formatDurationText = duration => {
  const formattedDuration = splitHoursMinutesSeconds(duration);
  return `${formattedDuration.hours} hours ${formattedDuration.minutes} minutes ${formattedDuration.seconds} seconds`;
};

export const extractAndShoweErrorMessages = error => {
  try {
    let errObj = JSON.parse(error.data.message);
    let keys = Object.keys(errObj);
    keys.map(key => toast.error(errObj[key][0]));
  } catch (e) {
    toast.error(error.data.message);
  }
};

export const formDataToObject = formData => {
  let jsonObject = {};
  for (const [key, value] of formData.entries()) {
    jsonObject[key] = value;
  }
  return jsonObject;
};

export const checkSessionLogout = action => {
  if (action === 'logout') {
    clearLocalStorage();
  }
};

export const clearLocalStorage = () => {
  localStorage.clear('persist:root');
  localStorage.removeItem('current_user');
  localStorage.removeItem('token');
  setTimeout(function() {
    window.location.pathname = routes.signin;
  }, 500);
};

export const getTranslatedText = key => {
  key = key.toLowerCase();
  let currentLang = localStorage.getItem(CURRENT_LANG_KEY) || 'en';
  if (typeof translatedText[key] == 'undefined') return key;
  return translatedText[key][currentLang];
};

export const capitalizeFirstLetter = string => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const rollingGame = async (itemId) => {
  // This function is service used to play game
  // Request url : https://api.edunetwork.dev.gkcsoftware.com/api/v1/users/rolling-item
  // Method: POST
  // Body: token
  const base_url = process.env.REACT_APP_API_ENV;
  const login_token = window.localStorage.getItem('token');
  return await axios
    .post(base_url + '/v1/users/rolling-item', {
      token: login_token,
      result: itemId
    })
    .then(response => {
      return response;
    })
    .catch(error => {
      console.log(error);
    });
};

export const addMoneyToWallet = async () => {
  // This function is service used to push money to wallet
  // Request url : https://api.edunetwork.dev.gkcsoftware.com/api/v1/users/add-to-wallet
  // Method: POST
  // Body: token, money
  const base_url = process.env.REACT_APP_API_ENV;
  const login_token = window.localStorage.getItem('token');
  return await axios
    .post(base_url + '/v1/users/add-to-wallet', {
      token: login_token,
    })
    .then(response => {
      return response;
    })
    .catch(error => {
      console.log(error);
    });
};

export const getRollAmount = async () => {
  // This function is service used to get total roll of user, current used turn, current total bonus
  // Request url : https://api.edunetwork.dev.gkcsoftware.com/api/v1/users/profile
  // Method: GET
  // return array object roll, current total bonus
  const base_url = process.env.REACT_APP_API_ENV;
  const login_token = window.localStorage.getItem('token');
  return await axios
    .post(base_url + '/v1/users/profile', {
      token: login_token,
    })
    .then(response => {
      const currentLastTotalBonus = response.data.data.roll_amount;
      const rollUsed = response.data.data.rolled;
      const rollAmount = response.data.data.roll_amount;
      const rollAmountLeft = rollAmount - rollUsed;
      if (rollAmount === 0) {
        return {
          rollAmount: rollAmount,
          rollAmountLeft: 0,
          currentLastTotalBonus: 0,
        };
      } else {
        return {
          rollAmount: rollAmount,
          rollAmountLeft: rollAmountLeft,
          currentLastTotalBonus: currentLastTotalBonus,
        };
      }
    })
    .catch(error => {
      console.log(error);
    });
};

export const resultGame = async () => {
  // This function is service used to get result of game
  // Request url : https://api.edunetwork.dev.gkcsoftware.com/api/v1/users/result
  // Method: GET
  const base_url = process.env.REACT_APP_API_ENV;
  return await axios
    .get(base_url + '/v1/result')
    .then(response => {
      return response;
    })
    .catch(error => {
      console.log(error);
    });
};

export const getRatioWheelOption = async () => {
  // This function is service used to get option in whell of game
  // Request url : https://api.edunetwork.dev.gkcsoftware.com/api/v1/get-rolling-item
  // Method: GET
  const base_url = process.env.REACT_APP_API_ENV;
  return await axios
    .get(base_url + '/v1/get-rolling-item')
    .then(response => {
      return response;
    })
    .catch(error => {
      console.log(error);
    });
};

export const getEventProgress = async () => {
  // This function is service used to event progress,
  // 1. Start Date
  // 2. End date
  // 3. Now time => thoi gian khi request
  // Request url : https://api.edunetwork.dev.gkcsoftware.com/api/v1/get-date-event
  // Method: GET
  // After get response, this service will handle data to return progess of event
  const base_url = process.env.REACT_APP_API_ENV;
  return await axios
    .get(base_url + '/v1/get-date-event')
    .then(response => {
      const startDate = Date.parse(response.data.data.start);
      const endDate = Date.parse(response.data.data.end);
      const nowDate = Date.parse(response.data.data.now);
      let nowDateConvert = _.replace(
        response.data.data.now,
        '-',
        '.',
      );
      nowDateConvert = _.replace(nowDateConvert, '-', '.');
      const totalTimeOfEvent = endDate - startDate;
      const currentTotalTimeOfEvent = nowDate - startDate;
      const processEvent = parseInt(
        (currentTotalTimeOfEvent / totalTimeOfEvent) * 100,
      );

      return {
        processEvent: processEvent,
        dateUpdate: nowDateConvert,
      };
    })
    .catch(error => {
      console.log(error);
    });
};

export const getEventTime = async () => {
  // This function is service used to get time of event,
  // 1. Start Date
  // 2. End date
  // 3. Now time => thoi gian khi request
  // Request url : https://api.edunetwork.dev.gkcsoftware.com/api/v1/get-date-event
  // Method: GET
  // After get response, this service will handle data to return start date and end date
  const base_url = process.env.REACT_APP_API_ENV;
  return await axios
    .get(base_url + '/v1/get-date-event')
    .then(response => {
      let startDateConvert = _.replace(
        response.data.data.start,
        '-',
        '/',
      );
      startDateConvert = _.replace(startDateConvert, '-', '/');
      let endDateConvert = _.replace(
        response.data.data.end,
        '-',
        '/',
      );
      endDateConvert = _.replace(endDateConvert, '-', '/');
      return {
        startDate: startDateConvert,
        endDate: endDateConvert,
      };
    })
    .catch(error => {
      console.log(error);
    });
};

export const submitReceiveGift = async (receiverInformation) => {
  // This function is service used to submit user address to receive gift from event
  // Request url : https://api.optimagroup.dev.gkcsoftware.com/api/v1/users/update-result
  // Method: POST
  const base_url = process.env.REACT_APP_API_ENV;
  const login_token = window.localStorage.getItem('token');
  return await axios
    .put(base_url + '/v1/users/update-result', {
      token: login_token,
      receiver_name: receiverInformation.receiver_name,
      receiver_phone: receiverInformation.receiver_phone,
      receiver_address: receiverInformation.receiver_address,
      result_id: receiverInformation.result_id
    })
    .then(response => {
      message.success(getTranslatedText('put_info_success'));
    })
    .catch(error => {
      console.log(error);
    });
};

export const getRollingHistory = async () => {
  // This function is service used to get user's rolling history
  // Request url : https://api.optimagroup.dev.gkcsoftware.com/api/v1/users/rolling-history
  // Method: GET
  const base_url = process.env.REACT_APP_API_ENV;
  const login_token = window.localStorage.getItem('token');
  return await axios
    .get(base_url + `/v1/users/rolling-history?token=${login_token}`)
    .then(response => {
      console.log(response.data.data);
      return response;
    })
    .catch(error => {
      console.log(error);
    });
};

export const fetchDirectData = async (
  url,
  useToken = false,
  data = {},
) => {
  if (useToken) data.token = localStorage.getItem('token');
  data = encodeQueryData(data);
  let response = await fetch(
    process.env.REACT_APP_API_ENV + '/v1/' + url + '?' + data,
  );

  return response.json();
};
export const encodeQueryData = data => {
  let ret = [];
  for (let d in data)
    ret.push(
      encodeURIComponent(d) + '=' + encodeURIComponent(data[d]),
    );
  return ret.join('&');
};

export const removeVietnameseTones = str => {
  str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g,"a");
  str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g,"e");
  str = str.replace(/ì|í|ị|ỉ|ĩ/g,"i");
  str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g,"o");
  str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g,"u");
  str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g,"y");
  str = str.replace(/đ/g,"d");
  str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, "A");
  str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, "E");
  str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, "I");
  str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, "O");
  str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, "U");
  str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, "Y");
  str = str.replace(/Đ/g, "D");
  // Some system encode vietnamese combining accent as individual utf-8 characters
  // Một vài bộ encode coi các dấu mũ, dấu chữ như một kí tự riêng biệt nên thêm hai dòng này
  str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, ""); // ̀ ́ ̃ ̉ ̣  huyền, sắc, ngã, hỏi, nặng
  str = str.replace(/\u02C6|\u0306|\u031B/g, ""); // ˆ ̆ ̛  Â, Ê, Ă, Ơ, Ư
  // Remove extra spaces
  // Bỏ các khoảng trắng liền nhau
  str = str.replace(/ + /g," ");
  // Bỏ dấu câu, kí tự đặc biệt
  str = str.replace(/!|@|%|\^|\*|\(|\)|\+|\=|\<|\>|\?|\/|,|\.|\:|\;|\'|\"|\&|\#|\[|\]|~|\$|`|{|}|\||\\/g," ");
  // Remove all space
  str = str.replaceAll(" ", "");
  return str;
}

export const checkIsEventDay = (eventStartDate, eventEndDate) => {
  let now = moment();
  if (now.isSameOrAfter(moment(eventStartDate)) && now.isSameOrBefore(moment(eventEndDate))) {
    return true
  }
  return false;
};

export function truncateText(text, maxLength, ellipsis = '…') {
  if (text?.length > maxLength) {
    return text.substr(0, maxLength - ellipsis.length + 1).replace(/\s+\S*$/, '') + ellipsis;
  }

  return text;
}