import React, { useEffect, useState } from 'react';
import { Modal } from 'antd';
import { getTranslatedText } from '../../../../services/appService';
import { Link } from 'react-router-dom';
import { routes } from '../../../../constants';
import { getExChangeRatio, getKidzRequireCourse } from '../../../../actions/accumulate';

const AlertModal = ({ visible, onCancel, type = 'book', course }) => {
  const [name, setName] = useState(null);

  const getName = async () => {
    const data = await getKidzRequireCourse();
    setName(data);
  };
  useEffect(() => {
    getName();
  }, []);
  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      destroyOnClose
      footer={null}
    >
      <div className="w-full px-16 pt-6 pb-8">
        <div className="w-full pb-2 border-b border-black">
          <p className="text-center text-base font-bold uppercase">{getTranslatedText('audio_alert_title')}</p>
        </div>
        <div className="mt-6">
          <p>{getTranslatedText(type === 'book' ? 'audio_alert' : (course < 1000 ? 'kidz_alert' : 'kidz_sale_alert'))}
            {(type !== 'book' && course < 1000) ? name : ''}
          </p>
        </div>
        <div className="mt-12 px-1 w-full flex justify-between">
          <button className="px-9 py-2 bg-lightgrey rounded-xl font-bold text-darkgrey" onClick={onCancel}>
            {getTranslatedText('audio_alert_cancel')}
          </button>
          <Link to={routes.courses}>
            <button className="px-9 py-2 bg-brown rounded-xl font-bold text-white">
              {getTranslatedText('audio_alert_comfirm')}
            </button>
          </Link>
        </div>
      </div>
    </Modal>
  );
};

export default AlertModal;