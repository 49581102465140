import React from 'react';
import { Modal } from 'antd';
import { Link } from 'react-router-dom';
import {getTranslatedText} from "../../../services/appService";

export default function ModalSuccessExchange({
  isModalVisible,
  toggleModal,
  point,
  money,
}) {
  return (
    <Modal
      title={getTranslatedText("result_modal_heading")}
      visible={isModalVisible}
      className="modal-swap__point__success"
      footer={null}
    >
      <p className="label">
        {getTranslatedText("convert_success")}:
        <span className="modal-point success">{point} {getTranslatedText("points")}</span>
      </p>
      <p className="label">
        {getTranslatedText("get_corresponding")}:{' '}
        <span className="modal-money success">{money} USD</span>
      </p>
      <div className="footer-modal">
        <button
          className="btn-cancel"
          disabled={!point}
          onClick={toggleModal}
        >
          {getTranslatedText("back")}
        </button>
        <Link to="/account/profile/wallet">
          <button className="btn-vist-wallet" onClick={toggleModal}>
            {getTranslatedText("button_check_wallet")}
          </button>
        </Link>
      </div>
    </Modal>
  );
}
