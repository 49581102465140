import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  Row,
  Col,
  Button,
  Typography,
  Layout,
  Tag,
  Modal,
	Form,
	Input,
  message
} from 'antd';
import 'antd/dist/antd.css';
import '../Game/GameItem.scss';
import { getTranslatedText } from '../../../services/appService';
import { routes } from '../../../constants';
import {
  rollingGame,
  addMoneyToWallet,
  getRollAmount,
  getRatioWheelOption,
  getEventTime,
  submitReceiveGift,
} from '../../../services/appService';

import ResultWheelModal from '../ResultModal/ResultModal';

function Wheel(props) {
  const {
    setResultGameModalVisible,
    setGivenResult,
    listOptionWheel,
    setTotalBonus,
    setRollAmountLeft,
    rollAmountLeft,
    setResulModalTrigger
  } = props;

  const lang = localStorage.getItem('current_lang') || 'en';

  const [selectedItem, setSelectedItem] = useState(null);
  const [spinning, setSpinning] = useState('');
  const [selectedItemIndex, setSelectedItemIndex] = useState(null);

  useEffect(() => {
    if (selectedItem !== null) {
      setSpinning('spinning');
      setTimeout(() => {
        setSelectedItem(null);
        setSpinning('');
      }, 4000);
    }
  }, [selectedItem]);

  const selectItem = async () => {
    let item = listOptionWheel[Math.floor(Math.random() * listOptionWheel.length)];
    setSelectedItemIndex(listOptionWheel.indexOf(item));
    if (rollAmountLeft === 0) {
      alert(getTranslatedText('end_of_roll'));
    } else {
      setResulModalTrigger(false);
      rollingGame(item.id)
        .then(response => {
          if (response.statusCode === 403) {
            alert(getTranslatedText('end_of_roll'));
          } else {
            const givenResult = response.data.data;
            const givenSelectItem = listOptionWheel.indexOf(
              givenResult.reward.name,
            );
            setSelectedItem(givenSelectItem);
            setRollAmountLeft(response.data.data.number);
            setTimeout(() => {
              setGivenResult(givenResult);
              setResultGameModalVisible(true);
              setTotalBonus(response.data.data.money);
            }, 4000);
          }
        })
        .catch(error => {
          console.log(error);
        });
    }
  };

  const wheelVars = {
    '--nb-item': listOptionWheel.length,
    '--selected-item': selectedItemIndex,
  };

  return (
    <div className="wheel-container">
      <div
        className={`wheel ${spinning}`}
        style={wheelVars}
      >
        {listOptionWheel.map((item, index) => (
          <div
            className="wheel-item"
            key={index}
            style={{ '--item-nb': index }}
          >
            {lang == 'vi' ? (
              <p>{item.name_vi}</p>
            ) : (
              <p>{item.name}</p>
            )}
            {item.image && (
              <img src={item.image} alt='' />
            )}
          </div>
        ))}
      </div>
      <span
        onClick={() => selectItem()}
        id='start-button'
      >
        START
      </span>
    </div>
  );
}

const AddMoneyToWallet = props => {
  const {
    setAddMoneyToWaletModalVisible,
    totalBonus,
    totalMoneyAdded,
  } = props;

  const handleCheckWalletButton = () => {
    setAddMoneyToWaletModalVisible(false);
  };

  return (
    <React.Fragment>
      <Layout>
        <Layout.Header className="logo__container">
          <img alt='' src={require('../../../assets/images/logo.svg')} />
        </Layout.Header>
        <Layout.Content className="content__container">
          <Row>
            <Col span={24}>
              <Typography.Text className="content_heading">
                {getTranslatedText('add_money_success')}
              </Typography.Text>
            </Col>
            <Col span={16}>
              <Typography.Text className="container___text">
                {getTranslatedText('add_money_content')}
              </Typography.Text>
            </Col>
            <Col span={8}>
              <Typography.Text className="container___text">
                {getTranslatedText('current_money_add')}
              </Typography.Text>
              <Tag color="#FAC857" className="tag__info">
                {totalBonus} $
              </Tag>
              <Typography.Text className="container___text">
                {getTranslatedText('total_money_added')}
              </Typography.Text>
              <Tag color="#FAC857" className="tag__info">
                {totalMoneyAdded} $
              </Tag>
            </Col>
          </Row>
        </Layout.Content>
        <Layout.Footer className="footer__container">
          <Row>
            <Col span={24}>
              <Link to={routes.accountWallet}>
                <Button
                  className="button"
                  onClick={() => handleCheckWalletButton()}
                >
                  <Typography.Text className="button_label">
                    {getTranslatedText('button_check_wallet')}
                  </Typography.Text>
                </Button>
              </Link>
            </Col>
          </Row>
        </Layout.Footer>
      </Layout>
    </React.Fragment>
  );
};

const GameItem = () => {
  
  const [resultGameModalVisible, setResultGameModalVisible] = useState(false);
  const [addMoneyToWalletModalVisible, setAddMoneyToWaletModalVisible] = useState(false);
  const [rollAmountLeft, setRollAmountLeft] = useState([]);
  const [rollAmount, setRollAmount] = useState(0);
  const [givenResult, setGivenResult] = useState(null);
  const [totalBonus, setTotalBonus] = useState(0);
  const [listOptionWheel, setListOptionWheel] = useState([]);
  const [totalMoneyAdded, setTotalMoneyAdded] = useState(0);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [resultModalTrigger, setResulModalTrigger] = useState(false);

  // render component Game, request list option cho con quay
  useEffect(() => {
    getRatioWheelOption()
      .then(response => {
        setListOptionWheel(response.data.data);
      })
      .catch(e => {
        console.log(e);
      });
  }, []);

  // get data time of event to display to screen
  useEffect(() => {
    getEventTime()
      .then(response => {
        setStartDate(response.startDate);
        setEndDate(response.endDate);
      })
      .catch(e => {
        console.log(e);
      });
  }, []);

  // Lay tong so roll cua nguoi dung
  useEffect(() => {
    // const time = (new Date()).toISOString().split('T')[0];
    // console.log(time);
    getRollAmount()
      .then(response => {
        setRollAmount(response.rollAmount);
        setRollAmountLeft(response.rollAmountLeft);
        setTotalBonus(response.currentLastTotalBonus);
      })
      .catch(e => {
        console.log(e);
      });
  }, [resultGameModalVisible]);

  // reset total bonus after add money to wallet success
  useEffect(() => {
    if (addMoneyToWalletModalVisible === false) {
      setTotalBonus(0);
    }
  }, [addMoneyToWalletModalVisible]);

  // const handleAddMoneyToWallet = () => {
  //   addMoneyToWallet()
  //     .then(response => {
  //       setTotalMoneyAdded(response.data.data.total);
  //       setAddMoneyToWaletModalVisible(true);
  //     })
  //     .catch(error => {
  //       console.log(error);
  //     });
  // };

  const handleOnModalCancle = () => {
    if (resultModalTrigger) {
      setResultGameModalVisible(false);
    } else {
      message.warning(getTranslatedText('input_infor_alert'))
    }
  }



  return (
    <React.Fragment>
      <div id='turn_amount_info_wrap'>
        <div id='turn_amount_wrap'>
          <p>{getTranslatedText('total_turn')} {rollAmount}</p>
          <p>---</p>
          <p>{getTranslatedText('total_turn_left')} {rollAmountLeft}</p>
        </div>
          <div id='time_event_wrap'>
            <p>{getTranslatedText('time_event_title')}</p>
            <p>{startDate}</p>
            <p>---</p>
            <p>{endDate}</p>
          </div>
      </div>
      <Row className='main-container-wrap' gutter={16}>
        <Col
          lg={12}
          md={0}
          xs={0}
          className='col-main-container-wrap'
        >
          <div id='content-col-main-container-wrap'>
            <p>{getTranslatedText('lucky_spinner_title')}</p>
            <div id='image-col-content-main-container-wrap'>
              <img src={require('../../../assets/images/gift_image.png')} alt='' />
            </div>
          </div>
        </Col>
        <Col
          lg={12}
          md={24}
          xs={24}
          className='col-main-container-wrap'
        >
          <Wheel
            setResultGameModalVisible={setResultGameModalVisible}
            setGivenResult={setGivenResult}
            listOptionWheel={listOptionWheel}
            setTotalBonus={setTotalBonus}
            setRollAmountLeft={setRollAmountLeft}
            rollAmountLeft={rollAmountLeft}
            setResulModalTrigger={setResulModalTrigger}
          />
          <div id='lucky-spinner-footer'>
            <img src={require('../../../assets/images/lucky_spinner_footer.png')} alt=' ' />
          </div>
        </Col>
        <Col
          lg={0}
          md={24}
          xs={24}
          id='mobile-col-main-container-wrap'
        >
          <div id='content-col-main-container-wrap'>
            <p>{getTranslatedText('lucky_spinner_title')}</p>
            <div id='image-col-content-main-container-wrap'>
              <img src={require('../../../assets/images/gift_image.png')} alt='' />
            </div>
          </div>
        </Col>
        <Modal
          className="modal_card_container"
          visible={resultGameModalVisible}
          footer={null}
          onCancel={() => handleOnModalCancle()}
          destroyOnClose={true}
          closeIcon={null}
        >
          <ResultWheelModal
            givenResult={givenResult}
            setResultGameModalVisible={setResultGameModalVisible}
            setAddMoneyToWaletModalVisible={
              setAddMoneyToWaletModalVisible
            }
            setTotalMoneyAdded={setTotalMoneyAdded}
            setTotalBonus={setTotalBonus}
            setResulModalTrigger={setResulModalTrigger}
          />
        </Modal>
        <Modal
          className="modal_card_container"
          visible={addMoneyToWalletModalVisible}
          footer={null}
          onCancel={() => setAddMoneyToWaletModalVisible(false)}
          destroyOnClose={true}
        >
          <AddMoneyToWallet
            totalBonus={totalBonus}
            totalMoneyAdded={totalMoneyAdded}
            setAddMoneyToWaletModalVisible={
              setAddMoneyToWaletModalVisible
            }
          />
        </Modal>
      </Row>
    </React.Fragment>
  );
};

export default GameItem;
