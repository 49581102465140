import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import '../styles/membermedalstyle.scss';
import {
  getTranslatedText,
} from '../../../services/appService';
import {
  getPromotionAction,
  getListSpecialOption
} from '../../../actions/accumulate';

function MemberMedal(props) {

  const {
    medal,
    rank,
    current,
    actions,
    setRenderChildContainer
  } = props;

  function getPromotionClick() {
    setRenderChildContainer('promotion')
    actions.getPromotionAction({
      token: localStorage.getItem('token'),
      rank: rank.toLowerCase()
    });
    actions.getListSpecialOption({
      token: localStorage.getItem('token'),
      rank: rank.toLowerCase()
    });
  };

  return (
    <React.Fragment>
      <button id='membermedal-container'
        onClick={() => getPromotionClick()}
      >
        <img src={medal} alt='' />
        {current ? (
          <React.Fragment>
            <p id='text-rank-current'>{rank.toUpperCase()}</p>
            <p
              id='text-note-current'
            >
              {getTranslatedText('current_rank')}
            </p>
            <span id='pointer'></span>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <p id='text-rank'>{rank.toUpperCase()}</p>
            <p
              id='text-note'
            >
              {getTranslatedText('view_promotion')}
            </p>
          </React.Fragment>
        )}
      </button>
    </React.Fragment>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(
      {
        getPromotionAction,
        getListSpecialOption
      },
      dispatch
    ),
  };
};

export default connect(
  null,
  mapDispatchToProps
)(MemberMedal);