import React from 'react';
import {
  Link
} from 'react-router-dom';
import {
  PlayCircleOutlined,
  FileTextOutlined,
} from '@ant-design/icons';
import './styles/english-course-card-styles.scss';
import { routes } from '../../constants';
import { getTranslatedText } from '../../services/appService';
import PromotionPrice from '../GoldenWeekPromotion/components/common/Promotion/Price';
import PromotionDiscount from '../GoldenWeekPromotion/components/common/Promotion/Discount';
import SellInfo from '../GoldenWeekPromotion/components/common/Promotion/SellInfo';


function EnglishCourseCard(props) {

  const {
    coursesItemData,
    hideButton,
    isBuy,
    isDayOfEvent,
    eventDiscount,
    isCourseInEvent,
    eventSellInfo,
  } = props;

  return (
    <React.Fragment>
      <div className='course-card-wrapper'>
        <Link
          to={routes.englishCourse.englishCourseView.replace(':id', coursesItemData.id)}
        >
          <div className='content-course-card-wrapper'>
            {isCourseInEvent && isDayOfEvent && <PromotionDiscount discount={eventDiscount} /> }
            <img
              className='cover-content-course-card-wrapper'
              alt=''
              src={coursesItemData.avatar}
            />
            <div className="pt-8 relative">
              {isCourseInEvent && isDayOfEvent ? (
                <>
                  <PromotionPrice total={eventSellInfo.total} soldNum={eventSellInfo.soldNum} discountPrice={(coursesItemData.price*(100 - eventDiscount))/100} stockPrice={coursesItemData.price} />
                  <SellInfo total={eventSellInfo.total} soldNum={eventSellInfo.soldNum} remainNum={eventSellInfo.remainNum} />
                </>
              ) : (
                <div className='price-content-course-card-wrapper'>
                  <p>{coursesItemData.price}$</p>
                </div>
              )}
              <h4 className='title-content-course-card-wrapper'>
                {coursesItemData.level}
              </h4>
              <div className='footer-content-course-card-wrapper'>
                <div className='item-footer-content-course-card-wrapper'>
                  <div className='icon'>
                    <FileTextOutlined />
                  </div>
                  <p>{coursesItemData.total_course} {getTranslatedText('num_of_lesson')}</p>
                </div>
                <div className='line-footer-content-course-card-wrapper'></div>
                <div className='item-footer-content-course-card-wrapper'>
                  <div className='icon'>
                    <PlayCircleOutlined />
                  </div>
                  <p>{coursesItemData.total_video} {getTranslatedText('num_of_video')}</p>
                </div>
              </div>
            </div>
          </div>
          {hideButton ? (
            <div className='action-course-card-wrapper'>
            <Link
              to={routes.englishCourse.englishCourseView.replace(':id', coursesItemData.id)}
            >
              {isBuy ? (
                <button className='btn-action-course-card-wrapper'>
                  {getTranslatedText('learn_now')}
                </button>
              ) : (
                <button className='btn-action-course-card-wrapper'>
                  {getTranslatedText('see_detail')}
                </button>
              )}
            </Link>
          </div>
          ) : (
            <div className='action-course-card-wrapper'>
              <Link
                to={routes.courseOrder.replace(':id', coursesItemData.id).replace(':insurance', 1)}
              >
                <button className='btn-action-course-card-wrapper'>
                  {getTranslatedText('purchase_now')}
                </button>
              </Link>
            </div>
          )}
        </Link>
      </div>
    </React.Fragment>
  );
};

export default EnglishCourseCard;