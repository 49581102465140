import axios from 'axios';
import { toast } from 'react-toastify';

import {
  BASE_URL,
  GET_PROMOTION_LIST_REQUEST,
  GET_PROMOTION_LIST_SUCCESS,
  GET_PROMOTION_LIST_FAILURE,
  RECEIVE_PROMOTION_REQUEST,
  RECEIVE_PROMOTION_SUCCESS,
  RECEIVE_PROMOTION_FAILURE,
  GET_LIST_SPECIAL_OPTION_REQUEST,
  GET_LIST_SPECIAL_OPTION_SUCCESS,
  GET_LIST_SPECIAL_OPTION_FAILURE,
  GET_REDEEM_HISTORY_REQUEST,
  GET_REDEEM_HISTORY_SUCCESS,
  GET_REDEEM_HISTORY_FAILURE,
} from './index';

export function getPromotionAction(payload) {
  return dispatch => {
    dispatch(getPromotionRequest());
    axios
      .get(
        `${BASE_URL}/users/list-promotion?token=${payload.token}&&rank=${payload.rank}`,
      )
      .then(response => {
        if (response.data.statusCode === 200) {
          dispatch(getPromotionSuccess(response.data));
        } else {
          dispatch(getPromotionFailure(response.data));
        }
      })
      .catch(error => {
        toast.error('Something went wrong!');
      });
  };
}

function getPromotionRequest() {
  return {
    type: GET_PROMOTION_LIST_REQUEST,
  };
}

function getPromotionSuccess(payload) {
  return {
    type: GET_PROMOTION_LIST_SUCCESS,
    payload: payload,
  };
}

function getPromotionFailure(payload) {
  return {
    type: GET_PROMOTION_LIST_FAILURE,
    payload: payload,
  };
}

export function postInfoToReceivePromotionAction(payload) {
  return dispatch => {
    dispatch(postInfoToReceivePromotionRequest());
    axios
      .post(
        `${BASE_URL}/users/receive-promotion?token=${payload.token}`,
        payload.bodyRequest,
      )
      .then(response => {
        console.log(response);
        if (response.data.statusCode === 200) {
          dispatch(postInfoToReceivePromotionSuccess(response.data));
        } else {
          dispatch(postInfoToReceivePromotionFailure(response.data));
        }
      })
      .catch(error => {
        console.log(error);
        toast.error('Something went wrong!');
      });
  };
}

function postInfoToReceivePromotionRequest() {
  return {
    type: RECEIVE_PROMOTION_REQUEST,
  };
}

function postInfoToReceivePromotionSuccess(payload) {
  return {
    type: RECEIVE_PROMOTION_SUCCESS,
    payload: payload,
  };
}

function postInfoToReceivePromotionFailure(payload) {
  return {
    type: RECEIVE_PROMOTION_FAILURE,
    payload: payload,
  };
}

export function getListSpecialOption(payload) {
  return dispatch => {
    dispatch(getListSpecialOptionRequest());
    axios
      .get(
        `${BASE_URL}/users/list-special-option?token=${payload.token}&rank=${payload.rank}`,
      )
      .then(response => {
        if (response.data.statusCode === 200) {
          dispatch(getListSpecialOptionSuccess(response.data));
        } else {
          dispatch(getListSpecialOptionFailure(response.data));
        }
      });
  };
}

function getListSpecialOptionRequest() {
  return {
    type: GET_LIST_SPECIAL_OPTION_REQUEST,
  };
}

function getListSpecialOptionSuccess(payload) {
  return {
    type: GET_LIST_SPECIAL_OPTION_SUCCESS,
    payload: payload,
  };
}

function getListSpecialOptionFailure(payload) {
  return {
    type: GET_LIST_SPECIAL_OPTION_FAILURE,
    payload: payload,
  };
}

export function getRedeemHistory(payload) {
  return dispatch => {
    dispatch(getRedeemHistoryRequest());
    axios
      .get(
        `${BASE_URL}/users/redeem-point-history?token=${payload.token}`,
      )
      .then(response => {
        if (response.data.statusCode === 200) {
          dispatch(getRedeemHistorySuccess(response.data));
        } else {
          dispatch(getRedeemHistoryFailure(response.data));
        }
      });
  };
}

export async function getExChangeRatio() {
  const response = await axios.get(`${BASE_URL}/exchange-ratio`);
  if (response.data.statusCode === 200) {
    return response.data.data.ratio;
  } else {
    toast.error('Something went wrong!');
  }
}
export async function getKidzRequireCourse() {
  const response = await axios.get(`${BASE_URL}/kidz-require`);
  if (response.data.statusCode === 200) {
    return response.data.data.name;
  } else {
    toast.error('Something went wrong!');
  }
}

export async function postChangePointMoney({ token, point }) {
  const response = await axios.post(
    `${BASE_URL}/users/exchange-point-money?token=${token}&point=${point}`,
  );
  if (response.data.statusCode === 200) {
    return response;
  } else {
    toast.error('Something went wrong!');
  }
}

function getRedeemHistoryRequest() {
  return {
    type: GET_REDEEM_HISTORY_REQUEST,
  };
}

function getRedeemHistorySuccess(payload) {
  return {
    type: GET_REDEEM_HISTORY_SUCCESS,
    payload: payload,
  };
}

function getRedeemHistoryFailure(payload) {
  return {
    type: GET_REDEEM_HISTORY_FAILURE,
    payload: payload,
  };
}
