import React from 'react';
import SigninSignup from '../SigninSignup/SigninSignup';

function SigninPage() {
  return (
    <div>
      <SigninSignup />
    </div>
  );
}

export default SigninPage;
