import React, { useState, useEffect } from 'react';
import './styles/partner-component-style.scss';

export default function PartnerComponent (prop) {
    return (
        <a className='partner-component-logo' href={prop.href} target={prop.target}>
            <img src={prop.src} alt={prop.alt}/>
        </a>
        
    )
}

