import * as actionTypes from '../actions/index';

const initialState = {
  loading: false,
  evaluate_list: null,
  total_evaluate: null,
  feature_evaluate_list: null,
  total_feature_evaluate: null,
  is_success: false,
  error: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET_LIST_EVALUATE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_LIST_EVALUATE_SUCCESS:
      return {
        ...state,
        evaluate_list: action.payload.data,
        total_evaluate: action.payload.total,
        loading: false,
        is_success: true,
      };
    case actionTypes.GET_LIST_EVALUATE_FAILURE:
      return {
        ...state,
        loading: false,
        is_success: false,
        error: action.payload,
      };
    case actionTypes.GET_LIST_FEATURE_EVALUATE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_LIST_FEATURE_EVALUATE_SUCCESS:
      return {
        ...state,
        feature_evaluate_list: action.payload.data,
        total_feature_evaluate: action.payload.total,
        loading: false,
        is_success: true,
      };
    case actionTypes.GET_LIST_FEATURE_EVALUATE_FAILURE:
      return {
        ...state,
        loading: false,
        is_success: false,
        error: action.payload,
      };
    default:
      return state;
  };
};