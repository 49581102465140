import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import CountDownClock from '../CountDownClock';

import moment from 'moment';
import { getTranslatedText } from '../../../../services/appService';
import { CURRENT_LANG_KEY } from '../../../../actions';

function BannerPromotion(props) {
  const {
    bannerPromotionURL,
    bannerPromotionURLEn,
    eventStartDate,
    eventEndDate
  } = props;
  const [currentLanguage, setCurrentLanguage] = useState('');

  useEffect(() => {
    let current_language =
      window.localStorage.getItem(CURRENT_LANG_KEY) || 'en';
    setCurrentLanguage(current_language);
  }, []);

  return (
    <div className="w-11/12 xl:w-full max-w-screen-xl mx-auto mt-7 flex flex-col md:flex-row border-2 border-gold">
      <div className="w-full relative">
        <img className="w-full" src={currentLanguage === 'en' ? bannerPromotionURLEn : bannerPromotionURL} alt="banner-promotion" />
        <CountDownClock eventStartDate={eventStartDate} eventEndDate={eventEndDate} />
      </div>
      <div className="w-full py-12 px-2.5 lg:px-24 lg:py-6 xl:py-12 text-base lg:text-xl text-black lg:space-y-10">
        <p>{getTranslatedText('event_week_banner_line_1')}</p>
        <p>{getTranslatedText('event_week_banner_line_2')} {getTranslatedText('event_from')} {moment(eventStartDate).format('DD-MM-YYYY')} {getTranslatedText('event_to')} {moment(eventEndDate).format('DD-MM-YYYY')}.</p>
        <p>{getTranslatedText('event_week_banner_line_3')}</p>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  bannerPromotionURL: state.eventWeek.activeEventData?.thumbnail,
  bannerPromotionURLEn: state.eventWeek.activeEventData?.thumbnail_en,
  eventStartDate: state.eventWeek.activeEventData?.start,
  eventEndDate: state.eventWeek.activeEventData?.end,
});

export default connect(
  mapStateToProps,
  null,
)(BannerPromotion);