import React, { Component } from 'react';
import './CourseCard.scss';
import { routes } from '../../constants';
import { Link } from 'react-router-dom';
import {
  currencyFormatter,
  getTranslatedText,
} from '../../services/appService';

import {
  PlayCircleOutlined,
  FileTextOutlined,
} from '@ant-design/icons';
import PromotionDiscount from '../GoldenWeekPromotion/components/common/Promotion/Discount';
import SellInfo from '../GoldenWeekPromotion/components/common/Promotion/SellInfo';

class CourseCard extends Component {

  moveToCourseDetail(id) {
    window.location.href = routes.courseDetail.replace(":id", id)
  };

  render() {
    const { courseCarouselItem, isBought, isDayOfEvent, eventDiscount, isCourseInEvent, eventSellInfo } = this.props;
    const lang = localStorage.getItem('current_lang');

    return (
      <div className="CourseCard">
        <div className="Level">{courseCarouselItem.level}</div>
        {courseCarouselItem.join_insurance === 1 && (
          <div className="HaveInsurance">
            {getTranslatedText("insurance_purchased_2")}
            <div className="ImgHaveInsurance" />
          </div>
        )}
        <img
          src={`/assets/images/${courseCarouselItem.level.toLowerCase()}_background.jpg`}
          alt="carousel background"
        />
        {isCourseInEvent && isDayOfEvent && <PromotionDiscount discount={this.props.eventDiscount} />}
        {isCourseInEvent && isDayOfEvent && this.props.eventSellInfo.soldNum < this.props.eventSellInfo.total ? (
          <div className="Price">
            <div className="ContentWrapper">
              <span className="text-red-600 font-extrabold text-sm md:text-md lg:text-xl transform skew-x-25">
                {currencyFormatter((courseCarouselItem.price* (100 - eventDiscount))/100, true)}
              </span>
              <sup className="text-red-600 font-extrabold transform skew-x-25">USD</sup>
              <span className="pl-2.5 text-white font-medium text-8 line-through md:text-10 lg:text-md transform skew-x-25">
                {currencyFormatter(courseCarouselItem.price, true)}
              </span>
              <sup className="text-white text-8 line-through md:text-10 lg:text-md transform skew-x-25">USD</sup>
            </div>
          </div>
        ) : (
          <div className="Price">
            <div className="ContentWrapper">
              <span className="Amount">
                {currencyFormatter(courseCarouselItem.price, true)}
              </span>
              <sup className="Currency">USD</sup>
            </div>
          </div>
        )}
        {isCourseInEvent && isDayOfEvent && (
          <SellInfo
            containerClassName="bg-gold-50 bg-opacity-50 rounded-2xl w-60 h-8 mx-auto flex items-center justify-center divide-x divide-grey mt-1.5"
            soldNum={eventSellInfo?.soldNum}
            remainNum={eventSellInfo?.remainNum}
            total={eventSellInfo?.total}
          />
        )}
        <div className="TitleButtonGroup">
          <div className="Title">
            {lang == 'vi'
              ? courseCarouselItem.title_vi
              : courseCarouselItem.title}
          </div>
          <div className='footer-content-course-card-wrapper'>
              <div className='item-footer-content-course-card-wrapper'>
                <div className='icon'>
                  <FileTextOutlined />
                </div>
                <p>{courseCarouselItem.total_course} {getTranslatedText('num_of_lesson')}</p>
              </div>
              <div className='line-footer-content-course-card-wrapper'></div>
              <div className='item-footer-content-course-card-wrapper'>
                <div className='icon'>
                  <PlayCircleOutlined />
                </div>
                <p>{courseCarouselItem.total_video} {getTranslatedText('num_of_video')}</p>
              </div>
            </div>
        </div>
        <div className="PayContainer">
            <Link
              to={routes.courseDetail.replace(':id', courseCarouselItem.id)}
            >
              <a href={null}>
                <div className="PayNow">
                  {isBought
                    ? getTranslatedText('learn_now')
                    : getTranslatedText('see_detail')}
                </div>
              </a>
            </Link>
          </div>
      </div>
    );
  }
}

export default CourseCard;
