// vendor
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';

// custom
import './App.scss';
import { routes, toastDuration } from './constants';
import Header from './components/HeaderV2';
import Footer from './components/FooterV2';
import HomePage from './components/HomePage/HomePage';
import CoursePage from './components/CoursePage/CoursePage';
import CourseDetailPage from './components/CourseDetailPage/CourseDetailPage';
import OrderPage from './components/OrderPage/OrderPage';
import ContactPage from './components/ContactPage/ContactPage';
import PaymentSuccessfulPage from './components/PaymentSuccessfulPage/PaymentSuccessfulPage';
import SigninPage from './components/SigninPage/SigninPage';
import ResetPassword from './components/ResetPassword/ResetPassword';
import AccountDashboardPage from './components/AccountDashboardPage/AccountDashboardPage';
import AccountProfilePage from './components/AccountProfilePage/AccountProfilePage';
import MyWallet from './components/MyWallet/MyWallet';
import MyWallet_Withdraw from './components/MyWallet_Withdraw/MyWallet_Withdraw';
import RequestDeposit from './components/RequestDeposit/RequestDeposit';
import WithdrawNotification from './components/WithdrawNotification/WithdrawNotification';
import AccountCoursePage from './components/AccountCoursePage/AccountCoursePage';
import LoginPopup from './components/LoginPopup/LoginPopup';
import ForgotPasswordPopup from './components/ForgotPasswordPopup/ForgotPasswordPopup';
import SignupVerify from './components/SignupVerify/SignupVerify';
import SignupPendingVerify from './components/SignupPendingVerify/SignupPendingVerify';
import SendContactSuccessful from './components/SendContactSuccessful/SendContactSuccessful';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';
import LoadingOverlay from 'react-loading-overlay';
import ScaleLoader from 'react-spinners/ScaleLoader';
import MainGame from '../src/components/Game/MainGame/MainGame';
import AccumulateContainer from '../src/containers/Accumulate/container/AccumulateContainer';
import DepositNotification from './components/DepositNotification/DepositNotification';
import PrivacyPolicy from './components/PrivacyPolicy/PrivacyPolicy';
import TermOfService from './components/TermOfService/TermOfService';
import OperationRegulation from './components/OperationRegulation/OperationRegulation';
import LockNotification from './components/LockNotification/LockNotification';
import TransferMoney from './components/TransferMoney/TransferMoney';
import BuyInsurance from './components/BuyInsurance/BuyInsurance';
import BuyInsuranceSuccessful from './components/BuyInsuranceSuccessful/BuyInsuranceSuccessful';
import CapitalInsurancePage from './components/CapitalInsurancePage';
import EnglishCourseList from './containers/EnglishCourseScreen/EnglishCourseList/EnglishCourseList';
import EnglishCourseView from './containers/EnglishCourseScreen/EnglistCourseView/EnglishCourseView';
import SpeakerList from './containers/SpeakersScreen/SpeakerList/SpeakerList';
import QuizContainer from './containers/Quiz/Quiz';
import { AudioBookList } from './containers/AudioBook';

// services
import { getUserFormLocal } from './services/appService';
import SpeakerView from './containers/SpeakersScreen/SpeakerView/SpeakerView';
import DocumentScreen from './containers/DocumentScreen/DocumentScreen';
import VideoListByCategory from './containers/DocumentScreen/VideoList/VideoListByCategory';
import Evaluate from './containers/Evaluate';
import HowItWork from './containers/HowitWork';

import {
  getActiveEventWeek,
} from './actions/eventWeek';

import { Modal } from 'antd';
import GoldenWeekPromotion from './components/GoldenWeekPromotion';
import AudioBookDetail from './containers/AudioBook/Detail';
import AudioBookOrder from './containers/AudioBook/Order';
import OrderSuccess from './containers/AudioBook/OrderSuccess';
import InputKeyActive from './containers/KeyActive/InputKeyActive';
import ActiveSuccess from './containers/KeyActive/ActiveSuccess';

function PrivateRoute({ component: Component, authed, ...rest }) {
  return (
    <Route
      {...rest}
      render={props =>
        authed === true ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: routes.signin,
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
}

class App extends Component {
  state = {
    isLogined: false,
    modalVisible: false,
  };

  componentDidMount() {
    toast.configure({
      autoClose: toastDuration,
    });
    let isModalVisible = sessionStorage.getItem('popupPromotionVisible');
    if (this.props.activeEvent?.courses) {
      if (isModalVisible === 'false') {
        this.setState({modalVisible: true});
      };
      if (isModalVisible === null) {
        this.setState({modalVisible: true});
      };
      if (isModalVisible === 'false' && this.state.isLogined) {
        this.setState({modalVisible: true});
      };
    }
    this.props.actions.getActiveEventWeek();
  };

  handleOnCloseModal() {
    this.setState({modalVisible: false});
    sessionStorage.setItem('popupPromotionVisible', false);
  };

  checkCurrentUser() {
    if (getUserFormLocal()) {
      this.state.isLogined = true;
      this.state.currentUser = getUserFormLocal();
    }
  }

  render() {
    this.checkCurrentUser();
    return (
      <LoadingOverlay
        active={this.props.loading}
        spinner={<ScaleLoader />}
      >
        <Router>
          <ToastContainer />
          <div className="App">
            <ScrollToTop />
            <div className="HeaderContentContainer">
              <Header />
              <div className="">
                <Switch>
                  <Route exact path={routes.game}>
                    <MainGame />
                  </Route>
                  <Route exact path={routes.home}>
                    <HomePage />
                  </Route>
                  <Route exact path={routes.courses}>
                    <CoursePage displayEventBanner={this.props.activeEvent?.courses ? true : false} />
                  </Route>
                  {/* this should be declared before courseDetail or "paymentsucessful" will become the id param */}
                  <Route exact path={routes.coursePaymentSuccessful}>
                    <PaymentSuccessfulPage />
                  </Route>
                  <Route exact path={routes.buyInsuranceSuccessful}>
                    <BuyInsuranceSuccessful />
                  </Route>
                  {/* this should be declared before courseDetail or "order" will become the id param */}
                  <Route exact path={routes.courseOrder}>
                    <OrderPage />
                  </Route>
                  <Route exact path={routes.buyInsurance}>
                    <BuyInsurance />
                  </Route>
                  <Route exact path={routes.courseDetail}>
                    <ScrollToTop>
                      <CourseDetailPage />
                    </ScrollToTop>
                  </Route>
                  <Route exact path={routes.signin}>
                    <SigninPage />
                  </Route>
                  <Route exact path={routes.verify}>
                    <SignupVerify />
                  </Route>
                  <Route exact path={routes.registerPendingActive}>
                    <SignupPendingVerify />
                  </Route>
                  <Route path={routes.resetPassword}>
                    <ResetPassword />
                  </Route>
                  <Route exact path={routes.sendContactSuccessful}>
                    <SendContactSuccessful />
                  </Route>
                  <PrivateRoute
                    authed={this.state.isLogined}
                    exact
                    path={routes.accountDashboard}
                    component={AccountDashboardPage}
                  />
                  <PrivateRoute
                    authed={this.state.isLogined}
                    exact
                    path={routes.accountProfile}
                    component={AccountProfilePage}
                  />
                  <PrivateRoute
                    authed={this.state.isLogined}
                    exact
                    path={routes.accountWallet}
                    component={MyWallet}
                  />
                  <PrivateRoute
                    authed={this.state.isLogined}
                    exact
                    path={routes.accountWithdraw}
                    component={MyWallet_Withdraw}
                  />
                  <PrivateRoute
                    authed={this.state.isLogined}
                    exact
                    path={routes.accountDeposit}
                    component={RequestDeposit}
                  />
                  <PrivateRoute
                    authed={this.state.isLogined}
                    exact
                    path={routes.accountTransferMoney}
                    component={TransferMoney}
                  />
                  <PrivateRoute
                    authed={this.state.isLogined}
                    exact
                    path={routes.accountWithdrawNoti}
                    component={WithdrawNotification}
                  />
                  <PrivateRoute
                    authed={this.state.isLogined}
                    exact
                    path={routes.accountTerminate}
                    component={LockNotification}
                  />
                  <PrivateRoute
                    authed={this.state.isLogined}
                    exact
                    path={routes.accountDepositNoti}
                    component={DepositNotification}
                  />
                  <PrivateRoute
                    authed={this.state.isLogined}
                    exact
                    path={routes.accountCourses}
                    component={AccountCoursePage}
                  />
                  {/*<PrivateRoute*/}
                  {/*  authed={this.state.isLogined}*/}
                  {/*  path={routes.quiz.main}*/}
                  {/*  component={QuizContainer}*/}
                  {/*/>*/}
                  <PrivateRoute
                    authed={this.state.isLogined}
                    path={routes.accumulate}
                    component={AccumulateContainer}
                  />
                  <PrivateRoute
                    authed={this.state.isLogined}
                    path={routes.capitalInsurance}
                    component={CapitalInsurancePage}
                  />
                  <Route exact path={routes.contact}>
                    <ContactPage />
                  </Route>
                  <Route exact path={routes.privacyPolicy}>
                    <PrivacyPolicy />
                  </Route>
                  <Route exact path={routes.termOfService}>
                    <TermOfService />
                  </Route>
                  <Route exact path={routes.operationRegulation}>
                    <OperationRegulation />
                  </Route>
                  <Route
                    exact
                    path={routes.englishCourse.englishCourseList}
                  >
                    <EnglishCourseList />
                  </Route>
                  <Route
                    exact
                    path={routes.englishCourse.englishCourseView}
                  >
                    <EnglishCourseView />
                  </Route>
                  <Route
                    exact
                    path={routes.speaker.speakerList}
                  >
                    <SpeakerList />
                  </Route>
                  <Route
                    exact
                    path={routes.document.documentList}
                  >
                    <DocumentScreen />
                  </Route>
                  <Route
                    exact
                    path={routes.document.videoListByCategory}
                  >
                    <VideoListByCategory />
                  </Route>
                  <Route
                    exact
                    path={routes.speaker.speakerView}
                  >
                    <SpeakerView />
                  </Route>
                  <Route
                    path={routes.quiz.main}
                  >
                    <QuizContainer />
                  </Route>
                  <Route
                    path={routes.evaluate}
                  >
                    <Evaluate />
                  </Route>
                  <Route
                    path={routes.how_it_work}
                  >
                    <HowItWork />
                  </Route>
                  <Route
                    exact
                    path={routes.audio_book.list}
                  >
                    <AudioBookList />
                  </Route>
                  <Route path={routes.audio_book.detail}>
                    <AudioBookDetail />
                  </Route>
                  <Route
                    exact
                    path={routes.kidz_book.list}
                  >
                    <AudioBookList type={'kidz'} />
                  </Route>
                  <Route path={routes.kidz_book.detail}>
                    <AudioBookDetail type={'kidz'} />
                  </Route>
                  <PrivateRoute
                    authed={this.state.isLogined}
                    exact
                    path={routes.audio_book.order}
                    component={AudioBookOrder}
                  />
                  <Route exact path={routes.audio_book.order_success}>
                    <OrderSuccess />
                  </Route>
                  <PrivateRoute authed={this.state.isLogined} exact path={routes.key_active} component={InputKeyActive} />
                  <Route exact path={routes.key_active_success}>
                    <ActiveSuccess />
                  </Route>

                </Switch>
              </div>
            </div>
            <Footer />
            <LoginPopup />
            {this.props.isForgotPasswordPopupShown && (
              <ForgotPasswordPopup />
            )}
            <Modal
              className="popup-modal-wrapper"
              visible={this.state.modalVisible}
              footer={null}
              onCancel={() => this.handleOnCloseModal()}
            >
              <GoldenWeekPromotion
                bannerURL={this.props.activeEvent?.popup_thumbnail}
                bannerURLEn={this.props.activeEvent?.popup_thumbnail_en}
              />
            </Modal>
          </div>
        </Router>
      </LoadingOverlay>
    );
  }
}

const mapStateToProps = state => ({
  loading:
    // state.auth.loading ||
    state.profile.loading ||
    state.courses.loading ||
    state.courses.loading_detail ||
    state.contact.loading ||
    state.accumulate.loading ||
    state.speaker.loading ||
    state.document.loading ||
    state.evaluate.loading,
  isForgotPasswordPopupShown: state.auth.isForgotPasswordPopupShown,
  activeEvent: state.eventWeek.activeEventData,
});

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(
      {
        getActiveEventWeek,
      },
      dispatch
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
