import React from 'react';
import {
  Link
} from 'react-router-dom';
import { routes } from '../../../../constants';
import { getTranslatedText } from '../../../../services/appService';
import VideoCard from '../VideoCard/VideoCard';

import './styles/video-category-item-styles.scss';

function VideoCategoryItem(props) {

  const {
    video_category,
    handleOnVideoCardClick,
  } = props;

  return (
    <React.Fragment>
      <div className='video-category-item-wrapper'>
        <div className='header-video-category-item-wrapper'>
          <p>{video_category.name.toUpperCase()}</p>
          <Link
            to={routes.document.videoListByCategory.replace(':id', video_category.id)}
          >
            <button>{getTranslatedText('btn_view_all')}</button>
          </Link>
        </div>
        <div className='body-video-category-item-wrapper'>
          {video_category.posts.map(item => (
            <VideoCard
              cover={item.thumbnail}
              title={item.name}
              video_link={item.link}
              handleOnVideoCardClick={handleOnVideoCardClick}
            />
          ))}
        </div>
      </div>
    </React.Fragment>
  );
};

export default VideoCategoryItem;