import React, {useState, useEffect} from 'react';
import { useParams } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  getListVideoByCategoryAction,
} from '../../../actions/documents';
import {
  List,
  Modal
} from 'antd';

import ReactPlayer from 'react-player';

import VideoCard from '../components/VideoCard/VideoCard';
import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb';

import { routes } from '../../../constants';
import { getTranslatedText } from '../../../services/appService';

import './styles/video-list-by-category-styles.scss';


function VideoListByCategory(props) {

  const {
    video_list,
    actions,
  } = props;

  const param = useParams();

  const [modalVisible, setModalVisible] = useState(false);
  const [videoLink, setVideoLink] = useState('');
  const [modalTitle, setModalTitle] = useState('');

  useEffect(() => {
    const payload = {
      category_id: param.id,
      length: 9,
      page: 1,
    };
    actions.getListVideoByCategoryAction(payload);
  }, []);

  function handleOnVideoCardClick(videoLink, title) {
    setVideoLink(videoLink);
    setModalTitle(title);
    setModalVisible(true);
  };

  function handleOnPageChange(page) {
    const payload = {
      category_id: param.id,
      length: 9,
      page: page,
    };
    actions.getListVideoByCategoryAction(payload);
  }

  return (
    <React.Fragment>
      <Breadcrumb
        data={[
          { link: routes.home, text: getTranslatedText('HOME') },
          { link: routes.document.documentList, text: getTranslatedText('video') },
        ]}
      />
      <List
        id='video-list-by-category-wrapper'
        dataSource={video_list !== null ? video_list.data : []}
        pagination={{
          pageSize: 9,
          total: video_list !== null ? video_list.total : 0,
          onChange: (page) => handleOnPageChange(page)
        }}
        grid={{
          xs: 1,
          md: 3,
          lg: 3,
          xl: 3,
          xxl: 3
        }}
        renderItem={video => (
          <VideoCard
            cover={video.thumbnail}
            title={video.name}
            video_link={video.link}
            handleOnVideoCardClick={handleOnVideoCardClick}
          />
        )}
      />
      <Modal
        className='video-modal-wrapper'
        destroyOnClose
        visible={modalVisible}
        onCancel={() => setModalVisible(!modalVisible)}
        title={modalTitle}
        footer={null}
      >
        <ReactPlayer
          className='react-player-video-modal-wrapper'
          url={videoLink}
          controls
          playing
        />
      </Modal>
    </React.Fragment>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    video_list: state.document.video_by_category_list,
  };
};
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    actions: bindActionCreators(
      {
        getListVideoByCategoryAction,
      },
      dispatch,
    ),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VideoListByCategory);