import * as types from '../actions';
import { toast } from 'react-toastify';
import { routes, toastDuration } from '../constants';
import {
  extractAndShoweErrorMessages,
  checkSessionLogout,
  getTranslatedText,
} from '../services/appService';
import React from 'react';

const initialState = {
  loading: false,
  data: {},
  error: null,
  isEditing: false,
  transactionList: [],
  dashboard: {},
  timeStamp: Date.now(),
  notificationLst: [],
  notificationCurrentPage: 1,
  notificationLastPage: 1,
  unreadNotification: 0,
  activeUser: {
    data: [],
  },
  inactiveUser: {
    data: [],
  },
  activeUserEnglish: {
    data: [],
  },
  inactiveUserEnglish: {
    data: [],
  },
  users: [],
  transfer: null,
  transfers: [],
  bonusList: {
    data: []
  },
  introduceList: {
    data: []
  },
  codeType: "",
  codeInfo: {},
};

export default function(state = initialState, action) {
  switch (action.type) {
    // GET CURRENT USER PROFILE
    case types.GET_PROFILE_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.GET_PROFILE_SUCCESS:
      if (
        action.payload.data.statusCode === 200 &&
        action.payload.data.errors.length === 0
      ) {
        let currentUser = action.payload.data.data;
        localStorage.setItem(
          types.CURRENT_USER_KEY,
          JSON.stringify(currentUser),
        );
        if (
          action.payload &&
          action.payload.options &&
          action.payload.options.redirect
        ) {
          setTimeout(function() {
            window.location.pathname = `${routes.accountDashboard}`;
          }, 500);
        }
      } else {
        toast.error(
          (action.payload.data && action.payload.data.message) || '',
        );
      }

      checkSessionLogout(
        (action.payload.data &&
          action.payload.data.data &&
          action.payload.data.data.action) ||
        '',
      );

      return {
        ...state,
        loading: false,
        error: null,
        data: action.payload.data.data,
        timeStamp: Date.now(),
      };

    case types.GET_PROFILE_FAILURE:
      // localStorage.removeItem(types.TOKEN_KEY);

      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };

    // UPDATE CURRENT USER PROFILE
    case types.UPDATE_PROFILE_REQUEST:
      return {
        ...state,
        loading: true,
        isEditing: true,
      };

    case types.UPDATE_PROFILE_SUCCESS:
      state.isEditing = false;
      if (
        action.payload.statusCode === 200 &&
        action.payload.errors.length === 0
      ) {
        toast.success(getTranslatedText('update_profile_success'));
      } else {
        toast.error(action.payload.errors[0]);
      }

      return {
        ...state,
        loading: false,
        error: null,
      };

    case types.UPDATE_PROFILE_FAILURE:
      toast.error('Cannot update profile');

      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };

    // SHOW UPDATE FORM
    case types.SHOW_UPDATE_FORM:
      return {
        ...state,
        isEditing: !state.isEditing,
      };

    // GET CHARGE HISTORY
    case types.GET_CHARGE_HISTORY_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.GET_CHARGE_HISTORY_SUCCESS:
      if (
        action.payload.statusCode === 200 &&
        action.payload.errors.length === 0
      ) {
        return {
          ...state,
          loading: false,
          transactionList: action.payload.data,
        };
      } else {
        toast.error(action.payload.errors[0]);
      }

      return {
        ...state,
        loading: false,
        error: null,
      };

    case types.GET_CHARGE_HISTORY_FAILURE:
      toast.error('Cannot get charge history');
      return {
        ...state,
        loading: false,
      };

    // WITHDRAW MONEY
    case types.WITHDRAW_MONEY_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.WITHDRAW_MONEY_SUCCESS:
      if (
        action.payload.statusCode === 200 &&
        action.payload.errors.length === 0
      ) {
        toast.success(getTranslatedText('withdraw_success'));
        let route = routes.accountWithdrawNoti.replace(
          ':type',
          'default',
        );
        setTimeout(function() {
          window.location.pathname = route;
        }, 3000);
      } else {
        toast.error(action.payload.errors[0]);
      }

      return {
        ...state,
        loading: false,
        error: null,
      };

    case types.WITHDRAW_MONEY_FAILURE:
      extractAndShoweErrorMessages(action.payload.error);

      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };

    // DEPOSIT MONEY
    case types.DEPOSIT_MONEY_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.DEPOSIT_MONEY_SUCCESS:
      if (
        action.payload.statusCode === 200 &&
        action.payload.errors.length === 0
      ) {
        toast.success(getTranslatedText('deposit_success'), {
          autoClose: toastDuration,
        });
        let pathname = routes.accountDepositNoti
          .replace(':isBuyCourse', 'deposit')
          .replace(':code', action.payload.data.code)
          .replace(':amount', action.payload.data.amount);
        setTimeout(function() {
          window.location.pathname = pathname;
        }, 3000);
      } else {
        toast.error(action.payload.errors[0]);
      }

      return {
        ...state,
        loading: false,
        error: null,
      };

    case types.DEPOSIT_MONEY_FAILURE:
      extractAndShoweErrorMessages(action.payload.error);

      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };

    // DASHBOARD
    case types.GET_USER_DASHBOARD_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.GET_USER_DASHBOARD_SUCCESS:
      if (
        action.payload.statusCode === 200 &&
        action.payload.errors.length === 0
      ) {
        return {
          ...state,
          loading: false,
          error: null,
          dashboard: action.payload.data,
        };
      } else {
        toast.error(action.payload.errors[0]);
      }

      return {
        ...state,
        loading: false,
        error: null,
      };

    case types.GET_USER_DASHBOARD_FAILURE:
      toast.error('Cannot get user dashboard!');

      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };

    // GET NOTIFICATIONS
    case types.GET_NOTIFICATIONS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.GET_NOTIFICATIONS_SUCCESS:
      if (
        action.payload.notifications.statusCode === 200 &&
        action.payload.notifications.errors.length === 0
      ) {
        return {
          ...state,
          loading: false,
          error: null,
          notificationLst: state.notificationLst.concat(
            action.payload.notifications.data.data.data,
          ),
          notificationCurrentPage:
          action.payload.notifications.data.data.current_page,
          notificationLastPage:
          action.payload.notifications.data.data.last_page,
          unreadNotification:
          action.payload.notifications.data.unread,
        };
      } else {
        toast.error(action.payload.notifications.errors[0]);
      }

      return {
        ...state,
        loading: false,
        error: null,
      };

    case types.GET_NOTIFICATIONS_FAILURE:
      toast.error('Cannot get notifications!');

      return {
        ...state,
        loading: false,
      };

    // GET NOTIFICATIONS
    case types.GET_CHILD_USER_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.GET_CHILD_USER_SUCCESS:
      if (
        action.payload.data.statusCode === 200 &&
        action.payload.data.errors.length === 0
      ) {
        if (action.payload.active && action.payload.isEnglish)
          return {
            ...state,
            loading: false,
            error: null,
            activeUserEnglish: action.payload.data.data,
          };
        else if (action.payload.active && !action.payload.isEnglish)
          return {
            ...state,
            loading: false,
            error: null,
            activeUser: action.payload.data.data,
          };
        else if (!action.payload.active && action.payload.isEnglish)
          return {
            ...state,
            loading: false,
            error: null,
            inactiveUserEnglish: action.payload.data.data,
          };
        else if (!action.payload.active && !action.payload.isEnglish)
          return {
            ...state,
            loading: false,
            error: null,
            inactiveUser: action.payload.data.data,
          };
      } else {
        toast.error(action.payload.notifications.errors[0]);
      }

      return {
        ...state,
        loading: false,
        error: null,
      };

    case types.GET_CHILD_USER_FAILURE:
      toast.error('Cannot get your users!');

      return {
        ...state,
        loading: false,
      };

    case types.GET_USERS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.GET_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        users: action.payload.data,
      };

    case types.GET_USERS_FAILURE:
      toast.error('Cannot get users!');

      return {
        ...state,
        loading: false,
      };

    // POST CONTRACT
    case types.POST_CONTRACT_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.POST_CONTRACT_SUCCESS:
      toast.success(getTranslatedText('post_contract_success'));
      let route = routes.accountWithdrawNoti.replace(
        ':type',
        'contract',
      );
      setTimeout(function() {
        window.location.pathname = route;
      }, 3000);

      return {
        ...state,
        loading: false,
        error: null,
      };

    case types.POST_CONTRACT_FAILURE:
      // console.log(action.payload);
      toast.error(
        <div
          dangerouslySetInnerHTML={{
            __html: action.payload.join(' '),
          }}
        />,
      );

      return {
        ...state,
        loading: false,
      };

    case types.POST_TRANSFER_MONEY_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.POST_TRANSFER_MONEY_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        transfer: action.payload.data,
      };

    case types.POST_TRANSFER_MONEY_FAILURE:
      console.log(action.payload);
      toast.error(
        <div
          dangerouslySetInnerHTML={{
            __html: action.payload.join(' '),
          }}
        />,
      );

      return {
        ...state,
        loading: false,
      };

    case types.SUBMIT_VERIFY_TRANSFER_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.SUBMIT_VERIFY_TRANSFER_SUCCESS:
      toast.success('Transfer money successful!');

      setTimeout(() => {
        window.location.href = routes.accountDashboard;
      }, 2000);

      return {
        ...state,
        loading: false,
        error: null,
        transfer: null,
      };

    case types.SUBMIT_VERIFY_TRANSFER_FAILURE:
      console.log(action.payload);
      toast.error(
        <div
          dangerouslySetInnerHTML={{
            __html: action.payload.join(' '),
          }}
        />,
      );

      return {
        ...state,
        loading: false,
      };

    case types.GET_TRANSFER_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.GET_TRANSFER_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        transfers: action.payload.data,
      };

    case types.GET_TRANSFER_LIST_FAILURE:
      toast.error(
        <div
          dangerouslySetInnerHTML={{
            __html: action.payload.join(' '),
          }}
        />,
      );

      return {
        ...state,
        loading: false,
      };

    case types.GET_BONUS_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.GET_BONUS_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        bonusList: action.payload.data,
      };

    case types.GET_BONUS_LIST_FAILURE:
      toast.error(
        <div
          dangerouslySetInnerHTML={{
            __html: action.payload.join(' '),
          }}
        />,
      );

      return {
        ...state,
        loading: false,
      };

    case types.GET_INTRODUCE_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.GET_INTRODUCE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        introduceList: action.payload.data,
      };

    case types.GET_INTRODUCE_LIST_FAILURE:
      toast.error(
        <div
          dangerouslySetInnerHTML={{
            __html: action.payload.join(' '),
          }}
        />,
      );

      return {
        ...state,
        loading: false,
      };

    case types.SUBMIT_ACTIVE_CODE_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.SUBMIT_ACTIVE_CODE_SUCCESS:
      toast.success("Active code successfully!")
      let r = routes.key_active_success
      setTimeout(function() { window.location.href = r }, 500)
      return {
        ...state,
        loading: false,
        error: null,
        codeType: action.payload.data.type,
        codeInfo: action.payload.data.course,
      };

    case types.SUBMIT_ACTIVE_CODE_FAILURE:
      toast.error(
        <div
          dangerouslySetInnerHTML={{
            __html: action.payload.join(' '),
          }}
        />,
      );

      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
}
