import React from 'react';
import { Link } from 'react-router-dom';
import { routes } from '../../../../constants';
import { getTranslatedText } from '../../../../services/appService';

import './styles/speaker-card-styles.scss';


function SpeakerCard(props) {

  const {
    speaker,
  } = props;

  return (
    <React.Fragment>
      <div className='speaker-card-wrapper'>
        <div className='avatar-speaker-card-wrapper'>
          <img
            className='image-avatar-speaker-card-wrapper'
            src={speaker.avatar}
            alt='' />
        </div>
        <div className='content-speaker-card-wrapper'>
          <p className='header-content-speaker-card-wrapper'>
            {speaker.name.toUpperCase()}
          </p>
          <p className='body-content-speaker-card-wrapper'>
            {speaker.level.toUpperCase()}
          </p>
          <Link
            to={routes.speaker.speakerView.replace(':id', speaker.id)}
          >
            <button className='footer-content-speaker-card-wrapper'>
              {getTranslatedText('profile_button')}
            </button>
          </Link>
        </div>
      </div>
    </React.Fragment>
  );
};

export default SpeakerCard;