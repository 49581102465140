import React, { useState, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  getEnglishCoursesAction,
} from '../../../actions/courses';
import {
  Carousel,
} from 'antd';
import {
  LeftCircleOutlined,
  RightCircleOutlined,
} from '@ant-design/icons';
import EnglishCourseCard from '../../../components/EnglishCourseCard/EnglishCourseCard';

import './styles/english-course-list-styles.scss'
import { getTranslatedText, checkIsEventDay } from '../../../services/appService';
import { enquireScreen } from 'enquire-js';

let isMobile;
enquireScreen((b) => {
  isMobile = b;
});

function EnglishCourseList(props) {
  
  const {
    courses,
    actions,
    excludeId,
    title,
    eventStartDate,
    eventEndDate,
    eventDiscount,
    eventCourses,
  } = props;

  const [slidesToShow, setSlideToShow] = useState(3);
  const [englishCoursesArray, setEnglishCoursesArray] = useState([]);
  const [isDayOfEvent, setIsDayOfEvent] = useState(false);
  
  useEffect(() => {
    actions.getEnglishCoursesAction();
    let checkIsDayOfEvent = checkIsEventDay(eventStartDate, eventEndDate);
    setIsDayOfEvent(checkIsDayOfEvent);
  }, []);

  useEffect(() => {
    if (isMobile) {
      setSlideToShow(1);
    } else {
      setSlideToShow(3);
    }
  }, []);

  useEffect(() => {
    if (excludeId) {
      let array = courses.filter(item => item.id != excludeId);
      setEnglishCoursesArray(array);
    } else {
      setEnglishCoursesArray(courses);
    }
  }, [courses, excludeId]);

  function checkCourseInEvent(course) {
    let check = false;
    eventCourses?.forEach((eventCourse) => {
      if (course.id === eventCourse.course_id) {
        check = true;
      };
    });
    return check;
  };

  function getEventSellInfo(course) {
    let sellInfo = {
      soldNum: 0,
      remainNum: 0,
      total: 0,
    };
    eventCourses?.forEach((eventCourse) => {
      if (course.id === eventCourse.course_id) {
        sellInfo.soldNum = eventCourse.sold_number;
        sellInfo.remainNum = eventCourse.remain_display;
        sellInfo.total = eventCourse.total;
      };
    });
    return sellInfo;
  };


  return (
    <div id='english-courses-list-wrap'>
      {title ? (
        <p id='title-english-courses'>{getTranslatedText('suggest_english_courses_title')}</p>
      ) : (
        <p id='title-english-courses'>{getTranslatedText('our_english_courses_title')}</p>
      )}
      <p id='bonus-title'>{getTranslatedText('english_note')}</p>
      <Carousel
        dots={false}
        className='english-course-list-wrapper'
        slidesToShow={slidesToShow}
        // autoplay
        arrows={true}
        nextArrow={<RightCircleOutlined />}
        prevArrow={<LeftCircleOutlined />}
      >
        {englishCoursesArray.map(item => (
          <EnglishCourseCard
            coursesItemData={item}
            hideButton={true}
            isDayOfEvent={isDayOfEvent}
            eventDiscount={eventDiscount}
            isCourseInEvent={checkCourseInEvent(item)}
            eventSellInfo={getEventSellInfo(item)}
          />
        ))}
      </Carousel>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    courses: state.courses.english_courses || [],
    eventCourses: state.eventWeek.activeEventData?.courses,
    eventStartDate: state.eventWeek.activeEventData?.start,
    eventEndDate: state.eventWeek.activeEventData?.end,
    eventDiscount: state.eventWeek.activeEventData?.discount,
  };
};
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    actions: bindActionCreators(
      {
        getEnglishCoursesAction,
      },
      dispatch,
    ),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EnglishCourseList);