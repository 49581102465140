import React from 'react';
import { Link } from 'react-router-dom';
import { routes } from '../../../../constants';

function HowItWorkVi() {
  return (
    <div className="w-full">
      <div className="w-full">
        <p className="px-5 text-center font-bold text-lg xl:text-3xl text-black mb-3">
          CƠ CHẾ HOẠT ĐỘNG CỦA OPTIMA GROUP
        </p>
        <p className="text-center sm:text-sm md:text-base xl:px-5 text-black">
          Optima Group được thành lập vào 16/9/2019. 
          Đăng ký dưới sự quản lý của chính phủ 
          Singapore.  Trước đây là Edunetwork Global, chỉ 
          sau một năm phát triển mạnh mẽ, công ty đã 
          có những định hướng chiến lược mở rộng thêm 
          nhiều mảng trong tương lai, không chỉ riêng 
          giáo dục. Vì vậy đã nâng cấp lên tên mới là 
          Optima Group. Về mảng giáo dục, thành viên 
          có thể chọn một combo khoá học để tham gia 
          và sẽ được những quyền lợi sau: 
        </p>
      </div>
      <div className="w-full grid grid-cols-1 xl:grid-cols-2 gap-5 xl:gap-12 mt-5">
        <div className="w-full h-0 pb-3/4 relative">
          <img 
            className="w-full h-full absolute inset-0 object-cover"
            src="/assets/images/co_che_hoat_dong_1.png"
            alt="co-che-hoat-dong" />
        </div>
        <div className="w-full text-left">
          <p className="text-gold font-bold sm:text-base md:text-2xl mb-2.5">QUYỀN HỌC TẬP</p>
          <div className="w-full flex items-start gap-1.5">
            <img src="/assets/images/arrow-right.svg" alt="arrow-right-icon" />
            <p className="text-black sm:text-sm md:text-base">
              Chúng tôi đã tập hợp các combo khóa học 
              khác nhau được chọn lọc từ những diễn giả 
              có tên tuổi và đẳng cấp quốc tế. Đã và đang 
              tạo ra ảnh hưởng cho mọi người trên khắp 
              thế giới.  
            </p>
          </div>
          <div className="w-full flex items-start gap-1.5 mt-8">
            <img src="/assets/images/arrow-right.svg" alt="arrow-right-icon" />
            <p className="text-black sm:text-sm md:text-base">
              Các khóa học này cung cấp nhiều giờ đào 
              tạo và liên tục, được cập nhật theo đúng xu 
              thế phát triển để giúp bạn có lượng kiến thức 
              khổng lồ. Bạn được học rất nhiều kiến thức 
              được cập nhật mới trọn đời chỉ với mức học 
              phí một lần duy nhất. 
            </p>
          </div>
          <div className="w-full flex items-start gap-1.5 mt-8">
            <img src="/assets/images/arrow-right.svg" alt="arrow-right-icon" />
            <p className="text-black sm:text-sm md:text-base">
              Bạn được học trọn đời không tốn thêm bất 
              cứ một chi phí duy trì nào. Đây là điều mà 
              chưa có nền tảng nào làm được. Optima 
              Group tiên phong đi đầu mang đến sự tiện 
              lợi và tiết kiệm nhất cho học viên. 
            </p>
          </div>
          <div className="w-full flex items-start gap-1.5 mt-8">
            <img src="/assets/images/arrow-right.svg" alt="arrow-right-icon" />
            <p className="text-black sm:text-sm md:text-base">
              Các sản phẩm giáo dục kỹ thuật số của 
              chúng tôi rất tuyệt vời với rất nhiều khoá học 
              khác nhau. Xã hội luôn phát triển, nền kinh tế 
              toàn cầu luôn thay đổi và chúng ta phải đổi 
              mới từng ngày, phải chăm chỉ học tập mỗi 
              ngày để phát triển bản thân và theo kịp thời 
              đại.
            </p>
          </div>
          <div className="w-full flex items-start gap-1.5 mt-8">
            <img src="/assets/images/arrow-right.svg" alt="arrow-right-icon" />
            <p className="text-black sm:text-sm md:text-base">
              Chúng tôi còn phát hành thư viện sách nói rất phong phú
              và nhiều chủ đề khác nhau với giá ưu đãi hỗ trợ chỉ $15 / năm  .
              Mỗi tháng, chúng tôi tiếp tục cập nhật thêm nhiều sách mới,
              chúng tôi chọn lọc những sách hay nhất để giới thiệu độc quyền trên nền tảng của Optima.
            </p>
          </div>
        </div>
      </div>
      <div className="w-full grid grid-cols-1 xl:grid-cols-2 gap-5 xl:gap-12 mt-5 xl:pt-5">
        <div className="w-full h-0 pb-3/4 relative block xl:hidden">
          <img 
            className="w-full h-full absolute inset-0 object-cover"
            src="/assets/images/co_che_hoat_dong_2.png"
            alt="co-che-hoat-dong" />
        </div>
        <div className="w-full text-left">
          <p className="text-gold font-bold sm:text-base md:text-2xl mb-2.5">QUYỀN KINH DOANH</p>
          <div className="w-full flex items-start gap-1.5">
            <img src="/assets/images/arrow-right.svg" alt="arrow-right-icon" />
            <p className="text-black sm:text-sm md:text-base">
              Không dừng lại ở học tập, nếu bạn thấy khoá học bổ ích và muốn lan toả giá trị đến nhiều người, công ty còn cấp quyền hoàn toàn miễn phí để trở thành một nhà tiếp thị.
            </p>
          </div>
          <div className="w-full flex items-start gap-1.5 mt-8">
            <img src="/assets/images/arrow-right.svg" alt="arrow-right-icon" />
            <p className="text-black sm:text-sm md:text-base">
              Bạn được công ty cung cấp một đường link web riêng biệt mang chính tên đăng nhập của bạn. Bạn có thể áp dụng ngay chính kiến thức đã học để làm tiếp thị liên kết kinh doanh chính sản phẩm khoá học bạn đang sỡ hữu. 
            </p>
          </div>
          <div className="w-full flex items-start gap-1.5 mt-8">
            <img src="/assets/images/arrow-right.svg" alt="arrow-right-icon" />
            <p className="text-black sm:text-sm md:text-base">
              Tiền tiếp thị trực tiếp nhận về ngay lập tức sau mỗi đơn hàng thành công từ 50 - 90% trên giá trị khoá học. An toàn, tiện lợi, rút về nhanh chóng.
            </p>
          </div>
          <div className="w-full flex items-start gap-1.5 mt-8">
            <img src="/assets/images/arrow-right.svg" alt="arrow-right-icon" />
            <p className="text-black sm:text-sm md:text-base">
              Đối với audiobooks , bạn được quyền tiếp thị kinh doanh không giới hạn số lượng khách hàng . Mỗi khách hàng mua thành công , chúng tôi sẽ trả ngay lập tức cho bạn 60% tiền hoa hồng tiếp thị.
            </p>
          </div>
        </div>
        <div className="w-full h-0 pb-3/4 hidden xl:block relative">
          <img 
            className="w-full h-full absolute inset-0 object-cover"
            src="/assets/images/co_che_hoat_dong_2.png"
            alt="co-che-hoat-dong" />
        </div>
      </div>
      <div className="w-full grid grid-cols-1 xl:grid-cols-2 gap-5 xl:gap-12 mt-5 xl:pt-5">
        <div className="w-full h-0 pb-3/4 relative">
          <img 
            className="w-full h-full absolute inset-0 object-cover"
            src="/assets/images/co_che_hoat_dong_3.png"
            alt="co-che-hoat-dong" />
        </div>
        <div className="w-full text-left">
          <p className="text-gold font-bold sm:text-base md:text-2xl mb-2.5">ĐƯỢC THƯỞNG</p>
          <div className="w-full flex items-start gap-1.5">
            <img src="/assets/images/arrow-right.svg" alt="arrow-right-icon" />
            <div>
              <p className="text-black font-bold sm:text-sm md:text-base">Đối với khoá học kỹ năng:</p>
              <p className="text-black sm:text-sm md:text-base">
                Bạn sẽ được tri ân cảm ơn từ đối tác trực 
                tiếp của bạn (tức người mà đã được bạn 
                trao cơ hội này). Họ sẽ phải làm nhiệm vụ tri 
                ân cảm ơn bạn một đơn đồng khoá với khoá 
                họ đang sở hữu khi họ tiếp thị được đơn 
                hàng thứ 2. 
              </p>
              <p className="text-black mt-8 sm:text-sm md:text-base">
                Điều này chỉ thực hiện 1 lần duy nhất. Đây là 
                cử chỉ rất nhân văn để tỏ lòng biết ơn trong 
                nền tảng giáo dục online của Optima Group. 
                Và bạn cũng phải thực hiện việc tri ân cảm 
                ơn này với người đã trao cho bạn cơ hội này. 
                (chỉ 1 lần duy nhất )
              </p>
            </div>
          </div>
          <div className="w-full flex items-start gap-1.5 mt-8">
            <img src="/assets/images/arrow-right.svg" alt="arrow-right-icon" />
            <div>
              <p className="text-black font-bold sm:text-sm md:text-base">Đối với khoá học tiếng anh:</p>
              <p className="text-black sm:text-sm md:text-base">
                Bạn được công ty thưởng thêm từ 2-5% khi 
                chăm sóc tốt đối tác trực tiếp của bạn. 
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full grid grid-cols-1 xl:grid-cols-2 gap-5 xl:gap-12 mt-5 xl:pt-5">
        <div className="w-full h-0 pb-3/4 relative block xl:hidden">
          <img 
            className="w-full h-full absolute inset-0 object-cover"
            src="/assets/images/co_che_hoat_dong_4.png"
            alt="co-che-hoat-dong" />
        </div>
        <div className="w-full text-left">
          <p className="text-gold font-bold sm:text-base md:text-2xl mb-2.5">ĐƯỢC TÍCH ĐIỂM</p>
          <div className="w-full flex items-start gap-1.5">
            <img src="/assets/images/arrow-right.svg" alt="arrow-right-icon" />
            <p className="text-black sm:text-sm md:text-base">
              Khi bạn tiếp thị được đơn hàng, không những bạn nhận về ngay 50% - 90% tiền tiếp thị mà còn được công ty tặng điểm để tích lũy đổi nhiều phần quà hấp dẫn.
              ( Xem chi tiết quà tặng ở phần tích lũy điểm )
            </p>
          </div>
        </div>
        <div className="w-full h-0 pb-3/4 relative hidden xl:block">
          <img 
            className="w-full h-full absolute inset-0 object-cover"
            src="/assets/images/co_che_hoat_dong_4.png"
            alt="co-che-hoat-dong" />
        </div>
      </div>
      <div className="w-full grid grid-cols-1 xl:grid-cols-2 gap-5 xl:gap-12 mt-5 xl:pt-5">
        <div className="w-full h-0 pb-3/4 relative">
          <img 
            className="w-full h-full absolute inset-0 object-cover"
            src="/assets/images/co_che_hoat_dong_5.png"
            alt="co-che-hoat-dong" />
        </div>
        <div className="w-full text-left">
          <p className="text-gold font-bold sm:text-base md:text-2xl mb-2.5">QUỸ HOÀN HỌC PHÍ</p>
          <div className="w-full flex items-start gap-1.5">
            <img src="/assets/images/arrow-right.svg" alt="arrow-right-icon" />
            <p className="text-black sm:text-sm md:text-base">
              Là chương trình của Asia insure. Đây là quỹ tự nguyện, không bắt buộc bạn phải tham gia. Bạn có quyền chọn tham gia thêm hoặc không tham gia.
            </p>
          </div>
          <div className="w-full flex items-start gap-1.5 mt-8">
            <img src="/assets/images/arrow-right.svg" alt="arrow-right-icon" />
            <p className="text-black sm:text-sm md:text-base">
              Nếu bạn chọn tham gia thêm, bạn sẽ phải đóng thêm 10% trên giá tiền khóa học bạn đã mua vào quỹ này. Bạn sẽ có cơ hội nhận được học bổng hoàn lại học phí ban đầu theo điều kiện của chương trình này.
            </p>
          </div>
          <div className="w-full flex items-start gap-1.5 mt-8">
            <img src="/assets/images/arrow-right.svg" alt="arrow-right-icon" />
            <p className="text-black sm:text-sm md:text-base">
              Lưu Ý: Đây là quỹ tự nguyện, không phải là chương trình đầu tư và không bắt buộc.
              Vui lòng thẩm định và tìm hiểu kỹ trước khi tham gia thêm chương trình quỹ tự nguyện này.
            </p>
          </div>
          <div className="w-full flex items-start gap-1.5 mt-8">
            <img src="/assets/images/arrow-right.svg" alt="arrow-right-icon" />
            <p className="text-black sm:text-sm md:text-base">
              Và hãy nhớ mục đích chính ở Optima là được học tập và trở thành nhà tiếp thị liên kết trọn đời với mức phí rất thấp và chỉ một lần duy nhất.
            </p>
          </div>
        </div>
      </div>
      <Link to={routes.courses}>
          <button className="bg-gold py-2.5 px-10 xl:px-24 rounded-3xl text-white mt-12 xl:mt-24">
            <p className="font-bold text-sm xl:text-2xl text-center text-white">XEM CÁC KHOÁ HỌC</p>
          </button>
      </Link>
    </div>
  );
}

export default HowItWorkVi;