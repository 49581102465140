import React, { useState, useEffect } from 'react';
import { 
  Card,
} from "antd";
import '../styles/giftcardstyle.scss';
import {
  getTranslatedText,
} from '../../../services/appService';
import giftIcon from '../../../assets/images/icon_gift.png';
import giftGold from '../../../assets/images/gift-gold.png';


const {
  Meta,
} = Card;


function GiftCard(props) {

  const {
    cover,
    giftName,
    giftScore,
    rank,
    memberRank
  } = props;

  const [hideButton, setHideButton] = useState(false);
  
  useEffect(() => {
    switch (memberRank) {
      case 'member':
        if (rank === 'gold' || rank === 'platinum') {
          setHideButton(true);
        }
        break;
      case 'gold':
        if (rank === 'platinum') {
          setHideButton(true);
        }
        break;
    }
  }, [rank]);

  return (
    <React.Fragment>
      <Card
        className='custom-card'
        hoverable
        cover={
          <img className='card-image-cover' alt="example" src={cover} />
        }
      >
        <img
          className='gift-icon-abso'
          alt=''
          src={rank === 'platinum'
            ? giftIcon
            : rank === 'gold'
            ? giftGold
            : null
          }
        />
        <Meta
          title={giftName}
          description={
            <div className='card-footer-container'>
              <p>{giftScore} {getTranslatedText('score_text')}</p>
              {hideButton ? (
                null
              ) : (
                <button>{getTranslatedText('button_exchange')}</button>
              )}
            </div>
          }
        />
      </Card>
    </React.Fragment>
  );
};

export default GiftCard;