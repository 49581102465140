import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import './RequestDeposit.scss';
import AccountBreadcrumb from '../AccountBreadcrumb/AccountBreadcrumb';
import {
  getUserFormLocal,
  currencyFormatter,
  getTranslatedText,
} from '../../services/appService';
import DefaultUserAvatar from '../../assets/images/user_default_avatar.png';
import {
  requestDepositAction,
  getProfileAction,
} from '../../actions/profile';
import { routes } from '../../constants';
import ArrowRight from '../../assets/images/icon_arrow_right.svg';
import * as types from '../../actions/index';
import { PayPalButton } from 'react-paypal-button-v2';
import { Popover, Row } from 'antd';

class RequestDeposit extends Component {
  state = {
    currentUser: {},
    bankName: '',
    bankAccount: '',
    fullName: '',
    amount: '',
    type: 'vn-banking',
    hide_tooltip: false
  };

  checkCurrentUser() {
    if (getUserFormLocal()) {
      this.setState({
        currentUser: getUserFormLocal(),
      });
    }
  }

  toggleShowTab = () => {
    this.setState({
      isShowWithdraw: !this.state.isShowWithdraw,
    });
  };

  componentDidMount() {
    this.props.actions.getProfileAction({
      token: localStorage.getItem(types.TOKEN_KEY),
    });
    this.checkCurrentUser();
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      currentUser: nextProps.currentUser,
    });
    this.checkCurrentUser();

    const { loading } = nextProps;

    this.setState({hide_tooltip: loading})
  }

  requestDeposit = (type, paypal_id = '') => {
    let payload = {
      price: this.state.amount,
      method: type,
      paypal_order_id: paypal_id,
    };

    this.props.actions.requestDepositAction(payload);
  };

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value,
    });
  };

  renderButtons(amount) {
    amount = parseInt(amount);
    amount += parseFloat(amount / 10);
    return (
      (!this.state.hide_tooltip) && (
        <div>
          <Row gutter={16} justify={'center'}>
            <button
              className="pay_button"
              onClick={() => this.requestDeposit('sin-banking')}
            >
              {getTranslatedText('transfer_money_singapore')}
            </button>
          </Row>
          <Row
            gutter={16}
            justify={'center'}
            className="paypal-container-row"
          >
            <div className="paypal-button-row">
              <PayPalButton
                amount={amount}
                currency={'USD'}
                onSuccess={(details, data) => {
                  let paypal_transaction_id =
                    details.purchase_units[0].payments.captures[0].id;
                  this.requestDeposit('paypal', paypal_transaction_id);
                }}
                options={{
                  clientId:
                    'AZ9qz2qPukEpvGcDhK8Br7A_XuPLzPaa-vv0-9-ruQ3k_48UpZpQnkyUNJ8mjUpJfOBJ4LSBP7MAIfsV', //live
                  //"AZik4FOJQcDjyMk48gPIakTLkg_N-ifZnX7jPGPFBU9qGEl88D32GH3ZZooYlniWTi4Fzp61TEIQyL21", //client
                  //"AWmXubxlWhM8bfL6zwEHYQRVKG3O4kZPyPuhE2xaH-TtdDM2mAm-n9ZCMQ7V0jTUIqPhgdf8XHb-U4nt", //dev
                  locale: 'en_VN',
                }}
              />
            </div>
          </Row>
        </div>
      )
    );
  }

  render() {
    const { amount, type } = this.state;

    return (
      <div>
        <AccountBreadcrumb />
        <div className="MyWallet_Withdraw">
          <div className="Title">
            <div>{getTranslatedText('my_wallet')}</div>
            <img alt="arrow right" src={ArrowRight}></img>
            <div>{getTranslatedText('Deposit')}</div>
          </div>

          <div className="ContentContainer">
            <div className="TransactionInfo">
              <div>{getTranslatedText('input_deposit')}</div>
              <div className="WithdrawAmount">
                <div>{getTranslatedText('amount')}* (USD)</div>
                <input
                  type="number"
                  placeholder={getTranslatedText('amount_deposit')}
                  value={amount}
                  onChange={this.handleChange('amount')}
                />
              </div>
              <div className="Note">

                {getTranslatedText('important_deposit')} <br/>
                <div dangerouslySetInnerHTML={{ __html:  getTranslatedText('note_deposit')}}/>
              </div>
            </div>

            <div className="Card">
              <div className="User">
                <img
                  className="Photo"
                  alt="avatar"
                  src={
                    this.state.currentUser.avatar || DefaultUserAvatar
                  }
                />
                <div>{this.state.currentUser.name || ''}</div>
              </div>
              <div className="Balance">
                <div className="Text">
                  {getTranslatedText('balance')}
                </div>
                <div className="Number">
                  {currencyFormatter(
                    this.state.currentUser.total_price,
                  )}
                </div>
              </div>
            </div>
          </div>
          <Popover
            placement="bottom"
            // content={<RenderButtons courseDetail={this.props.courseDetail} />}
            content={this.renderButtons(amount)}
            trigger="click"
            overlayStyle={{ width: '255px' }}
            style={{zIndex: 1}}
          >
            <div className="TransactionRequest">
              {getTranslatedText('DEPOSIT')}
            </div>
          </Popover>
          <Link to={routes.accountWallet}>
            <div className="CancelButton">
              {getTranslatedText('CANCEL')}
            </div>
          </Link>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ profile }, ownProps) => {
  return {
    currentUser: profile.data,
    loading: profile.loading
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    actions: bindActionCreators(
      {
        requestDepositAction,
        getProfileAction,
      },
      dispatch,
    ),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RequestDeposit);
