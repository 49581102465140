import axios from 'axios';
import {
  BASE_URL,
  GET_ACTIVE_EVENT_WEEK_REQUEST,
  GET_ACTIVE_EVENT_WEEK_SUCCESS,
  GET_ACTIVE_EVENT_WEEK_FAILURE,
} from './index';

export function getActiveEventWeek() {
  return dispatch => {
    dispatch(getActiveEventWeekRequest());
    axios
      .get(`${BASE_URL}/active-event`)
      .then(response => {
        dispatch(getActiveEventWeekSuccess(response.data))
      })
      .catch(error => {
        dispatch(getActiveEventWeekFailure(error.message))
      });
  };
};

function getActiveEventWeekRequest() {
  return {
    type: GET_ACTIVE_EVENT_WEEK_REQUEST,
  };
};

function getActiveEventWeekSuccess(payload) {
  return {
    type: GET_ACTIVE_EVENT_WEEK_SUCCESS,
    payload: payload,
  };
};

function getActiveEventWeekFailure(payload) {
  return {
    type: GET_ACTIVE_EVENT_WEEK_FAILURE,
    payload: payload,
  };
};