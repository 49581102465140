import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  getListEvaluate,
  getListFeatureEvaluate,
} from '../../actions/evaluate';
import { Modal, Pagination } from 'antd';
import ReactPlayer from 'react-player';
import VideoItem from './components/VideoItem';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb'

import './styles/styles.scss';
import { routes } from '../../constants';
import { getTranslatedText } from '../../services/appService';

const DEFAULT_PAGE_SIZE = 15;

function Evaluate(props) {
  const {
    evaluates,
    feature_evaluates,
    total_evaluate,
    actions,
  } = props;
  const [modalVisible, setModalVisible] = useState(false);
  const [videoLink, setVideoLink] = useState();
  const [modalTitle, setModalTitle] = useState();

  useEffect(() => {
    actions.getListEvaluate({length: DEFAULT_PAGE_SIZE, page: 1, feature: 0});
    actions.getListFeatureEvaluate();
  }, []);

  function handleOnPageChange(currentPage) {
    console.log(currentPage);
    actions.getListEvaluate({length: DEFAULT_PAGE_SIZE, page: currentPage, feature: 0})
  }

  return (
    <div className="w-full max-w-screen-xl mx-auto mt-5">
      <Breadcrumb
        data={[
          { link: routes.home, text: getTranslatedText('HOME') },
          { link: routes.evaluate, text: getTranslatedText('OPINIONS') },
        ]}
      />
      <div className="w-full px-4 px-xl-0">
        <div className="w-full flex items-end">
          <h3 className="min-w-max font-bold text-lg mb-0">{getTranslatedText('feature_opinion')}</h3>
          <div className="flex-1 h-1 border-b border-black"></div>
        </div>
        <div className="w-full grid grid-cols-1 md:grid-cols-3 gap-7 mt-7">
          {feature_evaluates?.map((item, index) => (
            <VideoItem
              key={index}
              imgURL={item?.thumbnail}
              videoURL={item?.video_url}
              memberName={item?.member_name}
              setModalTitle={setModalTitle}
              setModalVisible={setModalVisible}
              setVideoLink={setVideoLink}
            />
          ))}
        </div>
        <div className="w-full flex items-end mt-14">
          <h3 className="min-w-max font-bold text-lg mb-0">{getTranslatedText('member_opinion')}</h3>
          <div className="flex-1 h-1 border-b border-black"></div>
        </div>
        <div className="w-full grid grid-cols-1 md:grid-cols-3 gap-7 md:gap-x-4 md:gap-y-7 mt-7">
          {evaluates?.map((item, index) => (
            <VideoItem
              key={index}
              imgURL={item?.thumbnail}
              videoURL={item?.video_url}
              memberName={item?.member_name}
              setModalTitle={setModalTitle}
              setModalVisible={setModalVisible}
              setVideoLink={setVideoLink}
            />
          ))}
        </div>
        <div className="w-full flex items-center justify-center mt-7 md:gap-x-4 md:gap-y-7">
          <Pagination
            className='custom-pagination'
            pageSize={DEFAULT_PAGE_SIZE}
            total={total_evaluate !== null && total_evaluate}
            onChange={(currentPage) => handleOnPageChange(currentPage)}
          />
        </div>
      </div>
      <Modal
        className='video-modal-wrapper'
        destroyOnClose
        visible={modalVisible}
        onCancel={() => setModalVisible(!modalVisible)}
        title={modalTitle}
        footer={null}
      >
        <ReactPlayer
          className='react-player-video-modal-wrapper'
          url={videoLink}
          controls
          playing
        />
      </Modal>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    evaluates: state.evaluate.evaluate_list,
    total_evaluate: state.evaluate.total_evaluate,
    feature_evaluates: state.evaluate.feature_evaluate_list,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      {
        getListEvaluate,
        getListFeatureEvaluate,
      },
      dispatch,
    ),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Evaluate);
