import React, { Component, Fragment } from 'react';
import { get } from 'lodash';

import './CourseInfo.scss';
import BulletIcon from '../../assets/images/icon_bullet.svg';
import PlusIcon from '../../assets/images/icon_plus.svg';
import PlayIcon from '../../assets/images/icon_play.svg';
import AttachmentIcon from '../../assets/images/icon_attachment.svg';
import {
  formatDuration,
  getTranslatedText,
  getUserFormLocal,
} from '../../services/appService';
import { CURRENT_LANG_KEY } from '../../actions';
import { toastDuration } from '../../constants';
import { bindActionCreators, compose } from 'redux';
import { completeVideo, getCourseProgress } from '../../actions/courses';
import { connect } from 'react-redux';
import ReactPlayer from 'react-player';

class CourseInfo extends Component {
  state = {
    isIntroductionVisible: true,
    showTab: 1, // 1: Information, 2: Learn, 3: Progress
    activeAttachment: {},
    currentUser: {},
    visibleButton: false,
    player: null,
    getProgress: false,
    getNextAttachment: false,
    isThisCoursesBought: false,
    duration: 0,
  };

  showCourseIntroduction = () => {
    this.setState({
      isIntroductionVisible: true,
    });
  };

  hideCourseIntroduction = () => {
    this.setState({
      isIntroductionVisible: false,
    });
  };

  toggleChapter = chapter => {
    if (chapter.parts) {
      const key = `isChapter${chapter.id}Visible`;
      this.setState({
        [key]: !this.state[key],
      });
    }
  };

  setActiveTab = num => {
    this.setState({ showTab: num });
  };

  togglePart = part => {
    const key = `isPart${part.id}Visible`;
    this.setState({
      [key]: !this.state[key],
    });
  };

  setActiveAttachment = async attachment => {
    // let oldPlayer = this.state.player;
    // if (oldPlayer)
    //   oldPlayer.off("timeupdate");
    await this.setState({
      activeAttachment: attachment,
      visibleButton: false,
    });
    // let iframe = document.querySelector('iframe');
    // let player = new ReactPlayer(iframe);
    // console.log(player)
    // await this.setState({player: player});

    window.scrollTo({ top: 0, behavior: 'smooth' });

    // setTimeout(() => {
    //   this.optionPlayer();
    // }, toastDuration)
  };

  optionPlayer = async () => {
    let { player, activeAttachment } = this.state;
    let duration = await player.getDuration().then(function(duration) {
      return duration;
    }).catch(function(error) {
      return 0;
    });
    // await this.setState({visibleButton: false});
    let _this = this;
    player.on('timeupdate', function() {
      player.getCurrentTime().then(function(seconds) {
        // console.log(this3);
        if (!activeAttachment.watched && (parseFloat(duration) - parseFloat(seconds) < 10))
          _this.setState({ visibleButton: true });
        // else
        //   _this.setState({visibleButton: false});
      }).catch(function(error) {
        console.log(error);
      });
    });
  };

  watchedVideo = (course_id) => {
    let { activeAttachment } = this.state;

    this.props.actions.completeVideo({
      course_id: course_id,
      attachment_id: activeAttachment.id,
    });

    this.setState({ getNextAttachment: false });
  };

  componentDidMount() {
    this.setState({
      currentUser: getUserFormLocal(),
    });
  }

  componentWillReceiveProps(nextProps, nextContext) {
    const { nextAttachment, courseDetail } = nextProps;
    if (courseDetail.id && !this.state.getProgress) {
      this.props.actions.getCourseProgress({ course_id: courseDetail.id });
      this.setState({ getProgress: true });
    }
    if (nextAttachment && !this.state.getNextAttachment) {
      this.setState({ getNextAttachment: true });
      setTimeout(() => {
        if (!window.confirm(getTranslatedText('next_video'))) return;
        this.props.actions.getCourseProgress({ course_id: courseDetail.id });
        this.setActiveAttachment(nextAttachment);
      }, toastDuration);
    }
  }

  progressVideo(event, t_this) {
    let { duration } = t_this.state;
    let play = event.playedSeconds;
    duration = parseFloat(duration);
    play = parseFloat(play);
    if ((play + 10) > duration)
      t_this.setState({ visibleButton: true });
    else
      t_this.setState({ visibleButton: false });
  }

  async setDuration(event, t_this) {
    let duration = 0;
    if (!(event.getDuration())) {
      duration = await event.getInternalPlayer().getDuration().then(function(d) {
        return d;
      });
    } else
      duration = event.getDuration();

    t_this.setState({ duration: duration });
  }

  render() {
    const {
      isIntroductionVisible,
      activeAttachment,
      currentUser,
      showTab,
    } = this.state;
    const { courseDetail, courseProgress } = this.props;
    const chapters = get(courseDetail, 'child', []);

    return (
      <div className='CourseInfoContainer'>
        {(activeAttachment && activeAttachment.link_file) ? (
          <div className='AttachmentPlayer' style={{ position: 'relative' }}>
            <ReactPlayer
              width={'auto'}
              url={(localStorage.getItem(CURRENT_LANG_KEY) === 'vi')? this.state.activeAttachment.link_file_vi : this.state.activeAttachment.link_file}
              controls={true}
              playing
              onProgress={event => this.progressVideo(event, this)}
              onReady={event => this.setDuration(event, this)}
            />
            {/*<iframe*/}
            {/*  title="hero_youtube"*/}
            {/*  src={`${activeAttachment.link_file}?autoplay=1&showinfo=0&controls=1`}*/}
            {/*  frameBorder="0"*/}
            {/*  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"*/}
            {/*  allowFullScreen*/}
            {/*/>*/}
            {(this.state.visibleButton) ? (
              <button style={{ position: 'absolute', top: 5, right: 45 }} className='PayButton'
                      onClick={() => this.watchedVideo(courseDetail.id)}
                      id='button-complete'>{getTranslatedText('complete')}</button>
            ) : (<React.Fragment />)}
          </div>
        ) : (
          <div className='CourseAvatar'>
            {courseDetail.avatar && (
              <img src={courseDetail.avatar} alt='course avatar' />
            )}
          </div>
        )}

        <div className='TitleDescriptionCurriculumnContainer'>
          <div className='Title'>
            <div
              className={`IntroductionTitle ${(showTab == 1) ? 'Active' : ''}`}
              onClick={() => this.setActiveTab(1)}
            >
              <span>{getTranslatedText('course_detail')}</span>
            </div>
            {/*{!(currentUser.max_price < courseDetail.price) && (*/}
            {chapters.length > 0 && (
              <React.Fragment>
                <div
                  className={`CurriculumTitle ${(showTab == 2) ? 'Active' : ''}`}
                  onClick={() => this.setActiveTab(2)}
                >
                  <span>{getTranslatedText('content')}</span>
                </div>
                <div
                  className={`CurriculumTitle ${(showTab == 3) ? 'Active' : ''}`}
                  onClick={() => this.setActiveTab(3)}
                >
                  <span>{getTranslatedText('progress')}</span>
                </div>
              </React.Fragment>
            )}

            {/*)}*/}
          </div>

          {showTab == 1 && (
            <div className='CourseIntroduction'>
              <div className='IntroductionContent'>
                <div className='Head'>
                  {getTranslatedText('content')}
                </div>
                <p dangerouslySetInnerHTML={{__html: (localStorage.getItem(CURRENT_LANG_KEY) === 'vi')
                      ? courseDetail.description_vi
                      : courseDetail.description}}/>
              </div>
            </div>
          )}

          {showTab == 2 && (
            <div className='CourseCurriculum'>
              <div className='Head'>
                {getTranslatedText('content')}
              </div>
              <div className='CurriculumContent'>
                {chapters.map(chapter => (
                  <div className='Course' key={chapter.id}>
                    <div
                      className='Name'
                      onClick={() => this.toggleChapter(chapter)}
                    >
                      {this.state[`isChapter${chapter.id}Visible`] ? (
                        <img alt='bullet' src={BulletIcon}></img>
                      ) : (
                        <img alt='plus' src={PlusIcon}></img>
                      )}
                      <div>{(localStorage.getItem('current_lang') == "vi")? chapter.title_vi : chapter.title}</div>
                    </div>

                    {this.state[`isChapter${chapter.id}Visible`] &&
                    chapter.parts.map((part, index) => (
                      <Fragment key={index}>
                        <div
                          className='Part'
                          key={part.id}
                          onClick={() => this.togglePart(part)}
                        >
                          {this.state[`isPart${part.id}Visible`] ? (
                            <img
                              alt='bullet'
                              src={BulletIcon}
                            ></img>
                          ) : (
                            <img alt='plus' src={PlusIcon}></img>
                          )}
                          <div>{(localStorage.getItem('current_lang') == "vi")? part.title_vi : part.title}</div>
                        </div>

                        {this.state[`isPart${part.id}Visible`] &&
                        part.lessons.map((lesson, index) => (
                          <div
                            className='Lessons'
                            key={lesson.id}
                          >
                            <div className='LessonContainer'>
                              <div
                                className='Lesson'
                                onClick={() => this.setActiveAttachment(lesson.attachments[0])}>
                                <div className='Duration'>
                                  {formatDuration(
                                    lesson.duration,
                                  )}
                                </div>
                                <div
                                  className={`LessonTitle ${(lesson.attachments[0] && lesson.attachments[0].watched) ? 'Watch' : ''}`}>
                                  {(localStorage.getItem('current_lang') == "vi")? lesson.title_vi : lesson.title}
                                </div>
                              </div>
                              <div>
                                <img alt='play' src={PlayIcon} onClick={() => this.setActiveAttachment(lesson.attachments[0])} />
                              </div>
                            </div>
                            <div className='Attachments'>
                              {lesson.attachments.map(
                                (attachment, index) =>
                                  attachment.type === 'PDF' && (
                                    <a
                                      href={(localStorage.getItem('current_lang') == "vi")? attachment.link_file_vi : attachment.link_file}
                                      target='_blank'
                                    >
                                      <div
                                        key={index}
                                        className='Attachment'
                                      >
                                        <img
                                          src={AttachmentIcon}
                                          alt='attachment'
                                        />
                                        {(localStorage.getItem('current_lang') == "vi")? attachment.name_vi : attachment.name}
                                      </div>
                                    </a>
                                  ),
                              )}
                            </div>
                          </div>
                        ))}
                      </Fragment>
                    ))}
                  </div>
                ))}
              </div>
            </div>
          )}
          {showTab == 3 && (
            <div className='CourseIntroduction'>
              <div className='IntroductionContent'>
                <div className='Head'>
                  {getTranslatedText('progress')}
                </div>
                <br />
                {courseProgress.en && (
                  <p>
                    <h5>{getTranslatedText('course')}: {getTranslatedText('lang_en')}</h5>
                    {getTranslatedText('total_video')}: {courseProgress.en.total_video} videos <br />
                    {getTranslatedText('total_video_watch')}: {courseProgress.en.total_video_watched} videos <br />
                    {getTranslatedText('progress')}: {courseProgress.en.percent}%
                  </p>
                )}
                <br />
                {courseProgress.vi && (
                  <p>
                    <h5>{getTranslatedText('course')}: {getTranslatedText('lang_vi')}</h5>
                    {getTranslatedText('total_video')}: {courseProgress.vi.total_video} videos <br />
                    {getTranslatedText('total_video_watch')}: {courseProgress.vi.total_video_watched} videos <br />
                    {getTranslatedText('progress')}: {courseProgress.vi.percent}%
                  </p>
                )}
                <br />
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    nextAttachment: get(state, 'courses.next_attachment', null),
    courseProgress: get(state, 'courses.course_progress', {}),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    actions: bindActionCreators(
      {
        completeVideo,
        getCourseProgress,
      },
      dispatch,
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CourseInfo);
