import React from 'react';
import { Link } from 'react-router-dom';
import './styles.scss';
import audioBookBanner from '../../../../assets/images/audioBookBanner.png';
import ArrowRight from '../../../../assets/images/icon_arrow_right_white.svg';
import { routes } from '../../../../constants';
import { getTranslatedText } from '../../../../services/appService';

const AudioBookBanner = () => {

  return (
    <div className='relative hidden md:block w-full max-w-screen-xl mx-auto pb-9 border-b-2 mb-8'>
      <img className='w-full' src={audioBookBanner} alt='' />
      <div className='absolute md:top-6 lg:top-14 left-10 w-96'>
        <p className='text-black'>{getTranslatedText('audio_book_content_1')}</p>
        <p className='text-black'>{getTranslatedText('audio_book_content_2')}</p>
        <p className='text-black'>{getTranslatedText('audio_book_content_promo')}</p>
        <Link to={routes.audio_book.order}>
          <div className='md:mt-4 lg:mt-10 bg-gold w-40 rounded-3xl items-center justify-between px-4 py-md-1 py-lg-3 flex shadow'>
            <p className='text-white'>{getTranslatedText('btn_buy_now')}</p>
            <img src={ArrowRight} alt='' />
          </div>
        </Link>
      </div>
      <div className='absolute md:top-10 lg:top-12 md:right-40 lg:right-80 w-32 h-32 bg-white shadow transform rotate-45'>
        <div className='transform -rotate-45 ml-3 mt-2'>
          <p className='font-bold text-darkgrey ml-4 pl-1'>{getTranslatedText('only_title')}</p>
          <p className='text-brown text-4xl flex items-center'>
            $ <span className='font-bold text-5xl'>15</span>
          </p>
          <p className='text-brown ml-4 pl-1'>/{getTranslatedText('year_title')}</p>
        </div>
      </div>
    </div>
  );
};

export default AudioBookBanner;