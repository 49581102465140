import { toast } from 'react-toastify';
import * as types from '../actions/index';

const initialState = {
  loading: false,
  promotions: [],
  receivePromotion: [],
  specialOptions: [],
  redeemHistory: [],
};

export default function accumulate(state = initialState, action) {
  switch (action.type) {
    case types.GET_PROMOTION_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.GET_PROMOTION_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        promotions: action.payload.data,
      };
    case types.GET_PROMOTION_LIST_FAILURE:
      toast.error(action.payload.error.join(', '));
      return {
        ...state,
        loading: false,
      };
    case types.RECEIVE_PROMOTION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.RECEIVE_PROMOTION_SUCCESS:
      toast.success(action.payload.message);
      return {
        ...state,
        loading: false,
        receivePromotion: action.payload.data,
      };
    case types.RECEIVE_PROMOTION_FAILURE:
      toast.error(action.payload.errors.join(', '));
      return {
        ...state,
        loading: false,
      };
    case types.GET_LIST_SPECIAL_OPTION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.GET_LIST_SPECIAL_OPTION_SUCCESS:
      return {
        ...state,
        loading: false,
        specialOptions: action.payload.data,
      };
    case types.GET_LIST_SPECIAL_OPTION_FAILURE:
      toast.error(action.payload.error.join(', '));
      return {
        ...state,
        loading: false,
      };
    case types.GET_REDEEM_HISTORY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.GET_REDEEM_HISTORY_SUCCESS:
      return {
        ...state,
        loading: false,
        redeemHistory: action.payload.data,
      };
    case types.GET_REDEEM_HISTORY_FAILURE:
      toast.error(action.payload.error.join(', '));
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}
