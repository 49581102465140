import React, { useRef } from 'react';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/src/styles.scss';
import './styles/index.scss';
import { CURRENT_LANG_KEY } from '../../../../actions';

import PlayIconPrimary from '../../../../assets/images/icon_play_primary.svg';
import PauseIconPrimary from '../../../../assets/images/pause_icon.svg';
import PrevIcon from '../../../../assets/images/prev_icon.svg';
import NextIcon from '../../../../assets/images/next_icon.svg';

const Player = ({ playList, currentAudio, setCurrentAudio }) => {
  const audioRef = useRef(null);

  const handleNext = () => {
    const playedAudioIndex = playList.indexOf(currentAudio);
    if (
      playedAudioIndex < playList?.length - 1 &&
      playList?.[playedAudioIndex + 1]?.lock === 0
    ) {
      setCurrentAudio(playList?.[playedAudioIndex + 1]);
    }
  };

  const handlePrevious = () => {
    const playedAudioIndex = playList.indexOf(currentAudio);
    if (
      playedAudioIndex > 0 &&
      playList?.[playedAudioIndex - 1]?.lock === 0
    ) {
      setCurrentAudio(playList?.[playedAudioIndex - 1]);
    }
  };

  return (
    <div className="w-full">
      <p className="text-center text-black mb-2">
        {localStorage.getItem(CURRENT_LANG_KEY) === 'vi'
          ? currentAudio?.name_vi
          : currentAudio?.name}
      </p>
      {currentAudio?.lock === 0 && (
        <AudioPlayer
          className="audio-player-container"
          layout="stacked-reverse"
          ref={audioRef}
          src={
            localStorage.getItem(CURRENT_LANG_KEY) === 'vi'
              ? currentAudio?.audio_file_vi
              : currentAudio?.audio_file
          }
          autoPlay={currentAudio?.lock === 0}
          onEnded={handleNext}
          customVolumeControls={[]}
          customAdditionalControls={[]}
          onClickNext={handleNext}
          onClickPrevious={handlePrevious}
          showSkipControls
          showJumpControls={false}
          customIcons={{
            play: (
              <button>
                <img src={PlayIconPrimary} alt="" />
              </button>
            ),
            pause: (
              <button>
                <img src={PauseIconPrimary} alt="" />
              </button>
            ),
            next: (
              <button className="flex items-center justify-center mx-auto">
                <img src={NextIcon} alt="" />
              </button>
            ),
            previous: (
              <button className="flex items-center justify-center mx-auto">
                <img src={PrevIcon} alt="" />
              </button>
            ),
          }}
        />
      )}
    </div>
  );
};

export default Player;
