import React from 'react';
import {
  Row,
  Col,
  List,
} from 'antd';

import './styles/english-course-combo-styles.scss';

import EnglishCourseCard from '../../../../components/EnglishCourseCard/EnglishCourseCard';
import { getTranslatedText } from '../../../../services/appService';

const arrayTerm = [1,2,3,4,5];

function EnglishCourseCombo(props) {

  const {
    englishCourseComboItem,
  } = props;

  const lang = localStorage.getItem('current_lang') || 'en';
  
  return (
    <React.Fragment>
      <Row
        id='english-course-combo-wrapper'
      >
        <Col
          lg={12}
          xs={24}
        >
          <EnglishCourseCard
            coursesItemData={englishCourseComboItem}
            hideButton={true}
          />
        </Col>
        <Col
          lg={12}
          xs={24}
          id='list-english-course-combo-wrapper'
        >
          <h5>{getTranslatedText('course_part_1')} {englishCourseComboItem.level.toUpperCase()} {getTranslatedText('course_part_2')}</h5>
          <List
            dataSource={englishCourseComboItem.child}
            renderItem={(item) => (
              <div className='item-include-wrapper'>
                {lang === 'en' ? (
                  <p>{item.title}</p>
                ) : (
                  <p>{item.title_vi}</p>
                )}
              </div>
            )}
          />
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default EnglishCourseCombo;