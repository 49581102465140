import axios from 'axios';
import {
  BASE_URL,
  GET_LIST_DOCUMENT_REQUEST,
  GET_LIST_DOCUMENT_SUCCESS,
  GET_LIST_DOCUMENT_FAILURE,
  GET_LIST_VIDEO_CATEGORY_REQUEST,
  GET_LIST_VIDEO_CATEGORY_SUCCESS,
  GET_LIST_VIDEO_CATEGORY_FAILURE,
  GET_LIST_VIDEO_BY_CATEGORY_REQUEST,
  GET_LIST_VIDEO_BY_CATEGORY_SUCCESS,
  GET_LIST_VIDEO_BY_CATEGORY_FAILURE,
} from './index';


export function getListDocumentAction(payload) {
  return dispatch => {
    dispatch(getListDocumentRequest());
    axios
      .get(`${BASE_URL}/guest/resource`, {params: payload})
      .then(response => {
        dispatch(getListDocumentSuccess(response.data));
      })
      .catch(error => {
        dispatch(getListDocumentFailure(error.message));
      });
  };
};

function getListDocumentRequest() {
  return {
    type: GET_LIST_DOCUMENT_REQUEST,
  };
};

function getListDocumentSuccess(payload) {
  return {
    type: GET_LIST_DOCUMENT_SUCCESS,
    payload: payload
  };
};

function getListDocumentFailure(payload) {
  return {
    type: GET_LIST_DOCUMENT_FAILURE,
    payload: payload
  };
};

export function getListVideoCategoryAction(payload) {
  return dispatch => {
    dispatch(getVideoCategoyRequest());
    axios
      .get(`${BASE_URL}/guest/resource-list-by-category`)
      .then(response => {
        console.log(response)
        dispatch(getVideoCategoySuccess(response.data));
      })
      .catch(error => {
        dispatch(getVideoCategoyFailure(error.message));
      });
  };
};

function getVideoCategoyRequest() {
  return {
    type: GET_LIST_VIDEO_CATEGORY_REQUEST,
  };
};

function getVideoCategoySuccess(payload) {
  return {
    type: GET_LIST_VIDEO_CATEGORY_SUCCESS,
    payload: payload
  };
};

function getVideoCategoyFailure(payload) {
  return {
    type: GET_LIST_VIDEO_CATEGORY_FAILURE,
    payload: payload
  };
};

export function getListVideoByCategoryAction(payload) {
  return dispatch => {
    dispatch(getVideoByCategoryRequest());
    axios
      .get(`${BASE_URL}/guest/resource`, {params: payload})
      .then(response => {
        dispatch(getVideoByCategorySuccess(response.data));
      })
      .catch(error => {
        dispatch(getVideoByCategoryFailure(error.message));
      });
  };
};

function getVideoByCategoryRequest() {
  return {
    type: GET_LIST_VIDEO_BY_CATEGORY_REQUEST,
  };
};

function getVideoByCategorySuccess(payload) {
  return {
    type: GET_LIST_VIDEO_BY_CATEGORY_SUCCESS,
    payload: payload
  };
};

function getVideoByCategoryFailure(payload) {
  return {
    type: GET_LIST_VIDEO_BY_CATEGORY_FAILURE,
    payload: payload
  };
};

