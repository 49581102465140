import React, {useState, useEffect} from 'react';
import {
  Row,
  Col,
} from 'antd';
import MemberMedal from '../component/MemberMedal';

import '../styles/memberrankstyle.scss';
import gold from '../../../assets/images/member-gold.png';
import member from '../../../assets/images/member-medal.png';
import platinum from '../../../assets/images/member-platinum.png'


function MemberRank(props) {

  const {
    memberRank,
    setRenderChildContainer,
  } = props;

  const [normalRank, setNormalRank] = useState(false);
  const [goldRank, setGoldRank] = useState(false);
  const [platinumRank, setPlatinumRank] = useState(false);

  useEffect(() => {
    switch(memberRank) {
      case 'member':
        setNormalRank(true);
        break;
      case 'gold':
        setGoldRank(true);
        break;
      case 'platinum':
        setPlatinumRank(true);
        break;
      case null:
        setNormalRank(true);
        break;
    };
  }, [memberRank]);

  return (
    <React.Fragment>
      <Row gutter={2} id='rank-container'>
        <Col 
          xs={{
            span:5
          }}
          md={{
            span: 4
          }}
          lg={{
            span: 4
          }}
        >
          <MemberMedal
            medal={member}
            rank='MEMBER'
            current={normalRank}
            setRenderChildContainer={setRenderChildContainer}
          />
        </Col>
        <Col
          xs={{
            span:4
          }}
          md={{
            span: 6
          }}
          lg={{
            span: 6
          }}
        >
          <div className='line'></div>
        </Col>
        <Col 
          xs={{
            span:5
          }}
          md={{
            span: 4
          }}
          lg={{
            span: 4
          }}
        >
          <MemberMedal
            medal={gold}
            rank='GOLD'
            current={goldRank}
            setRenderChildContainer={setRenderChildContainer}
          />
        </Col>
        <Col
          xs={{
            span:4
          }}
          md={{
            span: 6
          }}
          lg={{
            span: 6
          }}
        >
          <div className='line'/>
        </Col>
        <Col span={4}>
          <MemberMedal
            medal={platinum}
            rank='PLATINUM'
            current={platinumRank}
            setRenderChildContainer={setRenderChildContainer}
          />
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default MemberRank;