import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './MyWallet.scss';
import DefaultUserAvatar from '../../assets/images/user_default_avatar.png';
import AccountBreadcrumb from '../AccountBreadcrumb/AccountBreadcrumb';
import {
  getUserFormLocal,
  currencyFormatter,
  getTranslatedText, fetchDirectData,
} from '../../services/appService';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  getChargeHistoryAction,
  getProfileAction,
  getTransferList,
} from '../../actions/profile';
import { routes } from '../../constants';
import * as types from '../../actions/index';

class MyWallet extends Component {
  state = {
    currentUser: {},
    isShowWithdraw: true,
    showReceive: true,
    withdrawList: [],
    depositList: [],
    sendList: [],
    receiveList: [],
    withdrawPagination: [],
    depositPagination: [],
    sendPagination: [],
    receivePagination: [],
    withdrawPage: 1,
    depositPage: 1,
    sendPage: 1,
    receivePage: 1,
  };

  checkCurrentUser() {
    if (getUserFormLocal()) {
      this.setState({
        currentUser: getUserFormLocal(),
      });
    }
  }

  componentDidMount() {
    this.props.actions.getProfileAction({
      token: localStorage.getItem(types.TOKEN_KEY),
    });
    this.loadData('withdraw');
    this.loadData('deposit');
    this.loadData('send');
    this.loadData('receive');
  }

  loadData = (type, page = 1) => {
    switch (type) {
      case 'withdraw': {
        fetchDirectData('users/charge-history', true, {
          type: 'withdraw',
          page: page,
        }).then(response => {
          this.setState({ withdrawList: response.data.data });
          this.loadPagination(type, response.data);
        });
        break;
      }
      case 'deposit': {
        fetchDirectData('users/charge-history', true, {
          type: 'deposit',
          page: page,
        }).then(response => {
          this.setState({ depositList: response.data.data });
          this.loadPagination(type, response.data);
        });
        break;
      }
      case 'send': {
        fetchDirectData('users/transfer-history', true, {
          type: 'send',
          page: page,
        }).then(response => {
          this.setState({ sendList: response.data.data });
          this.loadPagination(type, response.data);
        });
        break;
      }
      case 'receive': {
        fetchDirectData('users/transfer-history', true, {
          type: 'receive',
          page: page,
        }).then(response => {
          this.setState({ receiveList: response.data.data });
          this.loadPagination(type, response.data);
        });
        break;
      }

    }
  };

  componentWillReceiveProps(nextProps) {
    this.setState({
      currentUser: nextProps.currentUser,
    });
    this.checkCurrentUser();
  }

  loadPagination = (type, data) => {
    let arr = [];

    let isShowFirst = true;
    let isShowPrev = true;
    let isShowLast = true;
    let isShowNext = true;

    if (data.current_page == 1)
      isShowPrev = false;
    if (data.last_page < data.current_page + 2)
      isShowNext = false;

    if (!isShowPrev)
      isShowFirst = false;
    if (!isShowNext)
      isShowLast = false;

    //First
    if (isShowFirst)
      arr.push({
        index: 1,
        name: 'first',
      });

    //Prev
    if (isShowPrev)
      arr.push({
        index: data.current_page - 1,
        name: 'prev',
      });

    // Current
    arr.push({
      index: data.current_page,
      name: 'current',
    });

    //Current + 1
    if (data.last_page >= data.current_page + 1)
      arr.push({
        index: data.current_page + 1,
        name: 'additional',
      });

    //Next
    if (isShowNext)
      arr.push({
        index: data.current_page + 2,
        name: 'next',
      });

    //Last
    if (isShowLast)
      arr.push({
        index: data.last_page,
        name: 'last',
      });

    console.log(arr);


    switch (type) {
      case 'withdraw':
        this.setState({ withdrawPagination: arr });
        break;
      case 'deposit':
        this.setState({ depositPagination: arr });
        break;
      case 'send':
        this.setState({ sendPagination: arr });
        break;
      case 'receive':
        this.setState({ receivePagination: arr });
        break;
    }
  };

  selectPage = (page, type) => {
    let typePage = type + 'Page';
    this.loadData(type, page);
    this.setState({
      [typePage]: page,
    });
  };

  render() {
    const { currentUser, isShowWithdraw, showReceive } = this.state;

    return (
      <div>
        <AccountBreadcrumb />
        <div className="MyWallet">
          <div className="Title">
            {getTranslatedText('my_wallet')} <i className="fa fa-check"></i>
          </div>
          <div className="Card">
            <div className="User">
              <img
                className="Photo"
                alt="avatar"
                src={currentUser.avatar || DefaultUserAvatar}
              />
              <div>{currentUser.name || ''}</div>
            </div>
            <div className="Balance">
              <div className="Text">
                {getTranslatedText('Balance')}
              </div>
              <div className="Number">
                {currencyFormatter(currentUser.total_price)}
              </div>
            </div>
          </div>
          <div className="ButtonContainer">
            <Link to={routes.accountWithdraw}>
              <div className="WithdrawButton">
                {getTranslatedText('WITHDRAW')}
              </div>
            </Link>
            <Link to={routes.accountDeposit}>
              <div className="TopupButton">
                {getTranslatedText('DEPOSIT')}
              </div>
            </Link>
          </div>
          <div className="Transactions">
            <div className="Text">
              {getTranslatedText('TRANSACTIONS')}
            </div>
            <div className="Actions">
              <div
                className={
                  'Withdraw ' + (isShowWithdraw && 'ActiveTab')
                }
                onClick={() => this.setState({ isShowWithdraw: true })}
              >
                {getTranslatedText('Withdraw')}
              </div>
              <div
                className={
                  'Topup ' + (!isShowWithdraw && 'ActiveTab')
                }
                onClick={() => this.setState({ isShowWithdraw: false })}
              >
                {getTranslatedText('Deposit')}
              </div>
            </div>
            {(isShowWithdraw) ? (
              // Withdraw
              <React.Fragment>
                <table>
                  <thead className="TransactionTableHead">
                  <tr>
                    <th className="Date">
                      {getTranslatedText('date')}
                    </th>
                    <th className="Code">
                      {getTranslatedText('transaction_code')}
                    </th>
                    <th className="Amount">
                      {getTranslatedText('Amount')}
                    </th>
                    <th className="Status">
                      {getTranslatedText('Status')}
                    </th>
                  </tr>
                  </thead>
                  <tbody className="TransactionTableBody">
                  {this.state.withdrawList.map((item, index) => (

                    <tr key={index}>
                      <td>{item.created_at || ''}</td>
                      <td>{item.charge_code || 'not available'}</td>
                      <td>{currencyFormatter(item.price) || ''}</td>
                      <td className="capitalize">
                        {item.status || ''}
                      </td>
                    </tr>

                  ))}
                  </tbody>
                </table>
                <div className="pagination">
                  {this.state.withdrawPagination.map((item, index) => (
                    <a
                      className={(item.name == 'current') ? 'active' : ''}
                      href={null}
                      onClick={() => this.selectPage(item.index, 'withdraw')}
                    >
                      {(item.name == 'first') ? '<<' : ((item.name == 'prev') ? '<' : ((item.name == 'next') ? '>' : ((item.name == 'last') ? '>>' : item.index)))}
                    </a>
                  ))}
                </div>
              </React.Fragment>
            ) : (
              // Deposit
              <React.Fragment>
                <table>
                  <thead className="TransactionTableHead">
                  <tr>
                    <th className="Date">
                      {getTranslatedText('date')}
                    </th>
                    <th className="Code">
                      {getTranslatedText('transaction_code')}
                    </th>
                    <th className="Amount">
                      {getTranslatedText('Amount')}
                    </th>
                    <th className="Status">
                      {getTranslatedText('Status')}
                    </th>
                  </tr>
                  </thead>
                  <tbody className="TransactionTableBody">
                  {this.state.depositList.map((item, index) => (
                    <tr key={index}>
                      <td>{item.created_at || ''}</td>
                      <td>{item.charge_code || 'not available'}</td>
                      <td>{currencyFormatter(item.price) || ''}</td>
                      <td className="capitalize">
                        {item.status || ''}
                      </td>
                    </tr>
                  ))}
                  </tbody>
                </table>
                <div className="pagination">
                  {this.state.depositPagination.map((item, index) => (
                    <a
                      className={(item.name == 'current') ? 'active' : ''}
                      href={null}
                      onClick={() => this.selectPage(item.index, 'deposit')}
                    >
                      {(item.name == 'first') ? '<<' : ((item.name == 'prev') ? '<' : ((item.name == 'next') ? '>' : ((item.name == 'last') ? '>>' : item.index)))}
                    </a>
                  ))}
                </div>
              </React.Fragment>
            )}
          </div>
          <div className="Transactions" style={{ marginTop: '20px' }}>
            <div className="Text">
              {getTranslatedText('transfer_money').toUpperCase()}
            </div>
            <div className="Actions">
              <div
                className={
                  'Withdraw ' + (showReceive && 'ActiveTab')
                }
                onClick={() => this.setState({ showReceive: true })}
              >
                {getTranslatedText('receive_money')}
              </div>
              <div
                className={
                  'Topup ' + (!showReceive && 'ActiveTab')
                }
                onClick={() => this.setState({ showReceive: false })}
              >
                {getTranslatedText('send_money')}
              </div>
            </div>
            {(showReceive) ? (
              // Receive
              <React.Fragment>
                <table>
                  <thead className="TransactionTableHead">
                  <tr>
                    <th className="Date">
                      {getTranslatedText('date')}
                    </th>
                    <th className="Code">
                      {getTranslatedText('transaction_code')}
                    </th>
                    <th className="Amount">
                      {getTranslatedText('Amount')}
                    </th>
                    <th className="Amount">
                      {getTranslatedText('sender_code')}
                    </th>
                    <th className="Amount">
                      {getTranslatedText('sender_name')}
                    </th>
                  </tr>
                  </thead>
                  <tbody className="TransactionTableBody">
                  {this.state.receiveList.map((item, index) => (
                    <tr key={index}>
                      <td>{item.created_at || ''}</td>
                      <td>{item.code || 'not available'}</td>
                      <td>{currencyFormatter(item.amount) || ''}</td>
                      <td>{item.user_code}</td>
                      <td>{item.user_name}</td>
                    </tr>
                  ))}
                  </tbody>
                </table>
                <div className="pagination">
                  {this.state.receivePagination.map((item, index) => (
                    <a
                      className={(item.name == 'current') ? 'active' : ''}
                      href={null}
                      onClick={() => this.selectPage(item.index, 'receive')}
                    >
                      {(item.name == 'first') ? '<<' : ((item.name == 'prev') ? '<' : ((item.name == 'next') ? '>' : ((item.name == 'last') ? '>>' : item.index)))}
                    </a>
                  ))}
                </div>
              </React.Fragment>
            ) : (
              // Send
              <React.Fragment>
                <table>
                  <thead className="TransactionTableHead">
                  <tr>
                    <th className="Date">
                      {getTranslatedText('date')}
                    </th>
                    <th className="Code">
                      {getTranslatedText('transaction_code')}
                    </th>
                    <th className="Amount">
                      {getTranslatedText('Amount')}
                    </th>
                    <th className="Amount">
                      {getTranslatedText('receiver_code')}
                    </th>
                    <th className="Amount">
                      {getTranslatedText('receiver_name')}
                    </th>
                  </tr>
                  </thead>
                  <tbody className="TransactionTableBody">
                  {this.state.sendList.map((item, index) => (
                    <tr key={index}>
                      <td>{item.created_at || ''}</td>
                      <td>{item.code || 'not available'}</td>
                      <td>{currencyFormatter(item.amount) || ''}</td>
                      <td>{item.target_code}</td>
                      <td>{item.target_name}</td>
                    </tr>
                  ))}
                  </tbody>
                </table>
                <div className="pagination">
                  {this.state.sendPagination.map((item, index) => (
                    <a
                      className={(item.name == 'current') ? 'active' : ''}
                      href={null}
                      onClick={() => this.selectPage(item.index, 'send')}
                    >
                      {(item.name == 'first') ? '<<' : ((item.name == 'prev') ? '<' : ((item.name == 'next') ? '>' : ((item.name == 'last') ? '>>' : item.index)))}
                    </a>
                  ))}
                </div>
              </React.Fragment>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ profile }, ownProps) => {
  return {
    state: {
      transactionList: profile.transactionList,
      currentUser: profile.data,
      transfers: profile.transfers,
    },
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    actions: bindActionCreators(
      {
        getChargeHistoryAction,
        getProfileAction,
        getTransferList,
      },
      dispatch,
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MyWallet);
