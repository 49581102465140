import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  postInfoToReceivePromotionAction,
} from '../../../actions/accumulate';
import {
  Card,
} from 'antd';

import DescriptionAndForm from './DescriptionAndForm';

import '../styles/comfirmexchangeprevilegestyle.scss'
import {
  getTranslatedText,
} from '../../../services/appService';

const {
  Meta
} = Card;


function ComfirmExchangePrevilegeModal(props) {

  const {
    actions,
    cover,
    giftName,
    giftScore,
    setModalVisible,
    promotionId,
  } = props;

  const handleOnFinishForm = (value) => {
    console.log(value);
    let bodyRequest = {
      promotion_id: promotionId,
      user_name: value.fullname,
      user_tel: value.phone,
      user_address: value.address,
    };
    actions.postInfoToReceivePromotionAction(
      {
        token: localStorage.getItem('token'),
        bodyRequest: bodyRequest,
      }
    );
    setModalVisible(false);
  };

  return (
    <React.Fragment>
      <Card
        className='custom-card-modal'
        bordered={false}
        cover={<img alt="example" src={cover} />}
      >
        <Meta
          title={getTranslatedText('exchange_comfirm_text')}
          description={
            <DescriptionAndForm
              giftName={giftName}
              giftScore={giftScore}
              setModalVisible={setModalVisible}
              handleOnFinishForm={handleOnFinishForm}
            />
          }
        />
      </Card>
    </React.Fragment>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(
      {
        postInfoToReceivePromotionAction,
      },
      dispatch
    ),
  };
};

export default connect(
  null,
  mapDispatchToProps,
)(ComfirmExchangePrevilegeModal);