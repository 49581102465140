import React, { useEffect, useState } from 'react';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import { routes } from '../../constants';
import { getTranslatedText } from '../../services/appService';
import HowItWorkEn from './components/HowItWorkEn';
import HowItWorkVi from './components/HowItWorkVi';
import { CURRENT_LANG_KEY } from '../../actions';

function HowItWork() {
  const [currentLanguage, setCurrentLanguage] = useState();

  useEffect(() => {
    let current_language =
      window.localStorage.getItem(CURRENT_LANG_KEY) || 'en';
    setCurrentLanguage(current_language);
  });

  return (
    <div className="w-full max-w-screen-xl mx-auto mb-10">
      <Breadcrumb
        data={[
          { link: routes.home, text: getTranslatedText('HOME') },
          { link: routes.how_it_work, text: getTranslatedText('HOW_IT_WORK') },
        ]}
      />
      <div className="w-full px-3.5 md:px-24 px-xl-0 pt-4 text-center">
        {currentLanguage === 'en' ? (
          <HowItWorkEn />
        ) : (
          <HowItWorkVi />
        )}
      </div>
    </div>
  );
}

export default HowItWork;